import React from 'react';
import styles from './ServiceType.module.scss';

export function ServiceType(props: Props) {
  const { serviceTypeCode, style } = props;

  return (
    <div className={styles.container}>
      <div className={style || styles.content}>{toLabel(serviceTypeCode)}</div>
    </div>
  );
}

type Props = {
  serviceTypeCode: string;
  style?: string;
};

export function toLabel(serviceTypeCode: string): string {
  switch (serviceTypeCode) {
    case '6':
      return '通所介護';
    case '7':
      return '認知症対応型通所介護';
    case '8':
      return '指定療養通所介護';
    case '9':
      return '通所リハビリテーション';
    case '33':
      return '地域密着型通所介護';
    case '1':
      return '訪問介護';
    case '3':
      return '訪問入浴介護';
    case '4':
      return '訪問看護';
    case '5':
      return '訪問リハビリテーション';
    case '31':
      return '定期巡回・随時対応型訪問介護看護';
    case '25':
      return '短期入所生活介護';
    case '28':
      return '短期入所療養介護(療養病床を有する病院等)';
    case '30':
      return '短期入所療養介護(介護老人保健施設)';
    default:
      return '';
  }
}
