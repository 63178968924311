import 'whatwg-fetch';
import { ApiError } from '../../ApiError';
import { post } from '../../functions';
import { FetchSuggestionNeeds } from './FetchSuggestionNeeds';

export const SuggestionNeedApi = new (class {
  async fetchSuggestionNeeds(
    projectId: string,
  ): Promise<FetchSuggestionNeeds | ApiError> {
    const body = {
      projectId,
    };
    const response = await post('/api/suggestion/needs', body);
    return (response.ok ? FetchSuggestionNeeds.extract : ApiError.extract)(
      response,
    );
  }
})();
