import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../state/store';
import styles from './GlobalFooter.module.scss';
import { Link } from 'react-router-dom';
import { Paths } from '../../../Paths';
import { AuthenticationStatus } from '../../../domain/authentication/AuthenticationStatus';

export function GlobalFooter(props: Props) {
  const authenticationStatus = useSelector(
    (state: State) => state.authentication.status,
  );
  const authenticated =
    authenticationStatus === AuthenticationStatus.AUTHENTICATED;
  var rsLoginIn = useSelector((state: State) => state.authentication.rsLoginIn);
  if (props.rsLoginView) {
    rsLoginIn = props.rsLoginView;
  }
  return (
    <footer className={styles.footer}>
      <div className={styles.logoAndLinks}>
        {!rsLoginIn && (
          <img
            src={'/images/welmo-logo.svg'}
            width={162}
            height={32}
            alt={'株式会社ウェルモ'}
          />
        )}
        {!rsLoginIn && (
          <ul className={styles.nav}>
            <li>
              <ExternalLink to={'https://welmo.co.jp/about/'}>
                会社概要
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                to={
                  'https://docs.google.com/forms/d/e/1FAIpQLSdWsMa2FJq3PBko3IiujxqBUrHnUsdyefnnN7bSy2jovfAiMQ/viewform'
                }
              >
                お問い合わせ
              </ExternalLink>
            </li>
            <li>
              <InternalLink to={Paths.help}>ヘルプ</InternalLink>
            </li>
            <li>
              <InternalLink to={Paths.notifications}>お知らせ</InternalLink>
            </li>
            <li>
              <ExternalLink to={'https://welmo.co.jp/privacy'}>
                プライバシーポリシー
              </ExternalLink>
            </li>
            <li>
              <ExternalLink to={'https://milmoplan.welmo.co.jp/terms'}>
                利用規約
              </ExternalLink>
            </li>
          </ul>
        )}
        {authenticated ? (
          <ul className={styles.nav}>
            <li>
              <ExternalLink
                to={'https://sites.google.com/welmo.co.jp/milmoplan-manual/'}
              >
                操作マニュアル
              </ExternalLink>
            </li>
          </ul>
        ) : null}
      </div>
      {!rsLoginIn && (
        <small className={styles.copyright}>&copy; 2021 Welmo, Inc.</small>
      )}
    </footer>
  );
}

function InternalLink(props: LinkProps) {
  const { to, children } = props;
  return (
    <Link to={to} className={styles.link}>
      {children}
    </Link>
  );
}

function ExternalLink(props: LinkProps) {
  const { to, children } = props;
  return (
    <a
      href={to}
      target={'_blank'}
      rel={'noopener noreferrer'}
      className={styles.link}
    >
      {children}
    </a>
  );
}

type LinkProps = {
  to: string;
  children?: ReactNode;
};

type Props = {
  rsLoginView?: boolean;
};
