import React, { ReactNode } from 'react';
import styles from './AssessmentSheetSectionTitle.module.scss';
import {
  AssessmentSheetSection,
  assessmentSheetSectionId,
} from '../AssessmentSheetSection';

export function AssessmentSheetSectionTitle(props: Props) {
  const { section, children } = props;
  return (
    <h2 className={styles.container} id={assessmentSheetSectionId(section)}>
      {children}
    </h2>
  );
}

type Props = {
  section: AssessmentSheetSection;
  children?: ReactNode;
};
