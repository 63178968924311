import React, { ButtonHTMLAttributes } from 'react';
import styles from './LogoutButton.module.scss';

export function LogoutButton(props: ButtonHTMLAttributes<any>) {
  return (
    <button className={styles.button} {...props}>
      ログアウト
    </button>
  );
}
