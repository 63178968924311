import React, { ReactNode } from 'react';
import styles from './CareRecipientListHeader.module.scss';
import { SyllabaryColumn } from '../../../../domain/carerecipient/SyllabaryColumn';
import { HashLink } from 'react-router-hash-link';
import { syllabaryColumnId } from './functions';
import { displayForSyllabaryColumn } from '../../misc/display';

export function CareRecipientListHeader() {
  return (
    <>
      <div className={styles.index}>
        {renderSyllabaryColumnLink('A')}
        {renderSyllabaryColumnLink('KA')}
        {renderSyllabaryColumnLink('SA')}
        {renderSyllabaryColumnLink('TA')}
        {renderSyllabaryColumnLink('NA')}
        {renderSyllabaryColumnLink('HA')}
        {renderSyllabaryColumnLink('MA')}
        {renderSyllabaryColumnLink('YA')}
        {renderSyllabaryColumnLink('RA')}
        {renderSyllabaryColumnLink('WA')}
        {renderSyllabaryColumnLink('OTHER')}
      </div>
      <div className={styles.header}>
        <span className={styles.name}>氏名</span>
        <span className={styles.phonetic}>フリガナ</span>
        <span className={styles.responsibleUser}>担当</span>
        <span className={styles.deactivated}>属性</span>
        <span className={styles.lastEditedAt}>更新日</span>
        <span className={styles.contextMenu} />
      </div>
    </>
  );
}

function renderSyllabaryColumnLink(
  syllabaryColumn: SyllabaryColumn,
): ReactNode {
  return (
    <HashLink
      className={styles.indexLink}
      to={`#${syllabaryColumnId(syllabaryColumn)}`}
      smooth
    >
      {displayForSyllabaryColumn(syllabaryColumn)}
    </HashLink>
  );
}
