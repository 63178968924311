import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import { CancelButton } from '../../../components/button/CancelButton';
import { PrimaryButton } from '../../../components/button/PrimaryButton';
import { NewEmailChangeTextField } from './NewEmailChangeTextField';
import styles from './EmailChangeForm.module.scss';

type Props = {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  validate: () => boolean;
  onSubmit: (event: FormEvent) => void;
  updateFailed: boolean;
  onCancel: (event: FormEvent) => void;
};

export function EmailChangeForm(props: Props) {
  const { email, setEmail, validate, onSubmit, updateFailed, onCancel } = props;

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <NewEmailChangeTextField get={email} set={setEmail} />
      {updateFailed && (
        <p className={styles.error}>メールアドレス変更に失敗しました</p>
      )}
      <div className={styles.row} />
      <div className={styles.button}>
        <PrimaryButton width={256} disabled={!validate()}>
          変更
        </PrimaryButton>
      </div>
      <div className={styles.row} />
      <div className={styles.button}>
        <CancelButton width={256} onClick={onCancel}>
          キャンセル
        </CancelButton>
      </div>
    </form>
  );
}
