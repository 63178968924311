import React, { MouseEvent, RefObject, useRef, useState } from 'react';
import styles from './TableTwoCellIcons.module.scss';
import classNames from 'classnames';
import { PlusIcon } from '../../components/icon/PlusIcon';
import { ArrowAboveIcon } from '../../components/icon/ArrowAboveIcon';
import { ArrowUnderIcon } from '../../components/icon/ArrowUnderIcon';
import { MinusIcon } from '../../components/icon/MinusIcon';
import { Modal } from '../../components/Modal';
import { TableTwoRowRemoveConfirmDialog } from './TableTwoRowRemoveConfirmDialog';

export function TableTwoCellIcons(props: Props) {
  const {
    isFirstRowCell,
    isLastRowCell,
    handleAdd,
    handleShiftUp,
    handleShiftDown,
    handleRemove,
    tableTwoHeaderRef,
  } = props;

  const [openRemoveConfirmDialog, setOpenRemoveConfirmDialog] = useState(false);

  // 行更新時の枠線表示用
  const boxScopeRef = useRef<HTMLDivElement>(null);
  const lineScopeRef = useRef<HTMLDivElement>(null);

  const cellOf = (element: Element, f: CellFunction) => {
    const container = element.closest('[data-container]');
    const textCell = container?.parentElement;
    const row = textCell?.parentElement;
    if (textCell && row && boxScopeRef.current && lineScopeRef.current) {
      f(textCell, row, boxScopeRef.current, lineScopeRef.current);
    }
  };

  const handleAddIconMouseEnter = (event: MouseEvent<HTMLButtonElement>) => {
    cellOf(event.currentTarget, (textCell, row, boxScope, lineScope) => {
      const { width } = row.getBoundingClientRect();
      const height = rowHeight(row);
      const left = leftOffset(textCell, row);
      hideBoxScope(boxScope);
      showLineScope(lineScope, width, height, left, tableTwoHeaderRef);
    });
  };

  const handleShiftUpIconMouseEnter = (
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    cellOf(event.currentTarget, (textCell, row, boxScope, lineScope) => {
      const previousRow = row.previousElementSibling;
      if (previousRow) {
        const { width } = row.getBoundingClientRect();
        const height = rowHeight(row);
        const left = leftOffset(textCell, row);
        const top = -(BORDER_WIDTH + rowHeight(previousRow) + BORDER_WIDTH);
        showBoxScope(boxScope, width, height, left, tableTwoHeaderRef);
        showLineScope(lineScope, width, top, left, tableTwoHeaderRef);
      }
    });
  };

  const handleShiftDownIconMouseEnter = (
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    cellOf(event.currentTarget, (textCell, row, boxScope, lineScope) => {
      const nextRow = row.nextElementSibling;
      if (nextRow) {
        const { width } = row.getBoundingClientRect();
        const height = rowHeight(row);
        const left = leftOffset(textCell, row);
        const top = height + BORDER_WIDTH + rowHeight(nextRow);
        showBoxScope(boxScope, width, height, left, tableTwoHeaderRef);
        showLineScope(lineScope, width, top, left, tableTwoHeaderRef);
      }
    });
  };

  const handleRemoveIconMouseEnter = (event: MouseEvent<HTMLButtonElement>) => {
    cellOf(event.currentTarget, (textCell, row, boxScope, lineScope) => {
      const { width } = row.getBoundingClientRect();
      const height = rowHeight(row);
      const left = leftOffset(textCell, row);
      showBoxScope(boxScope, width, height, left, tableTwoHeaderRef, true);
      hideLineScope(lineScope);
    });
  };

  const handleIconMouseLeave = () => {
    if (boxScopeRef.current) hideBoxScope(boxScopeRef.current);
    if (lineScopeRef.current) hideLineScope(lineScopeRef.current);
  };

  const handleRemoveConfirm = () => {
    setOpenRemoveConfirmDialog(true);
  };

  const onRequestClose = () => {
    setOpenRemoveConfirmDialog(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div>
          <button
            className={classNames(styles.button, styles.buttonShiftDown)}
            tabIndex={-1}
            disabled={isLastRowCell}
            onClick={handleShiftDown}
            onMouseEnter={handleShiftDownIconMouseEnter}
            onMouseLeave={handleIconMouseLeave}
          >
            <ArrowUnderIcon />
            <span className={styles.tooltip}>下に移動</span>
          </button>
          <button
            className={classNames(styles.button, styles.buttonShiftUp)}
            tabIndex={-1}
            disabled={isFirstRowCell}
            onClick={handleShiftUp}
            onMouseEnter={handleShiftUpIconMouseEnter}
            onMouseLeave={handleIconMouseLeave}
          >
            <ArrowAboveIcon />
            <span className={styles.tooltip}>上に移動</span>
          </button>
        </div>
        <div>
          <button
            className={classNames(styles.button, styles.buttonRemove)}
            tabIndex={-1}
            onClick={handleRemoveConfirm}
            onMouseEnter={handleRemoveIconMouseEnter}
            onMouseLeave={handleIconMouseLeave}
          >
            <MinusIcon />
            <span className={styles.tooltip}>行削除</span>
          </button>
          <button
            className={classNames(styles.button, styles.buttonAdd)}
            tabIndex={-1}
            onClick={handleAdd}
            onMouseEnter={handleAddIconMouseEnter}
            onMouseLeave={handleIconMouseLeave}
          >
            <PlusIcon />
            <span className={styles.tooltip}>行追加</span>
          </button>
        </div>
      </div>
      <Modal open={openRemoveConfirmDialog} onRequestClose={onRequestClose}>
        <TableTwoRowRemoveConfirmDialog
          onRemove={handleRemove}
          onRequestClose={onRequestClose}
        />
      </Modal>
      <div className={styles.boxScope} ref={boxScopeRef} />
      <div className={styles.lineScope} ref={lineScopeRef} />
    </>
  );
}

type Props = {
  isFirstRowCell: boolean;
  isLastRowCell: boolean;
  handleAdd: () => void;
  handleShiftUp: () => void;
  handleShiftDown: () => void;
  handleRemove: () => void;
  tableTwoHeaderRef: RefObject<HTMLDivElement>;
};

type CellFunction = (
  textCell: HTMLElement,
  row: HTMLElement,
  boxScope: HTMLDivElement,
  lineScope: HTMLDivElement,
) => void;

const BORDER_WIDTH = 2;

function showBoxScope(
  boxScope: HTMLDivElement,
  width: number,
  height: number,
  left: number,
  tableTwoHeader: RefObject<HTMLDivElement>,
  danger: boolean = false,
) {
  boxScope.className = classNames(styles.boxScope, styles.visible, {
    [styles.danger]: danger,
  });
  boxScope.style.left = `${left - BORDER_WIDTH}px`;
  boxScope.style.top = `${-BORDER_WIDTH}px`;
  boxScope.style.width = `${width + BORDER_WIDTH * 2}px`;
  boxScope.style.height = `${height + BORDER_WIDTH * 2}px`;

  if (!tableTwoHeader.current) return;

  const tableTwoHeaderBottom =
    tableTwoHeader.current.getBoundingClientRect().bottom;
  const boxScopeBottom = boxScope.getBoundingClientRect().bottom;
  const boxScopeTop = boxScope.getBoundingClientRect().top;
  if (tableTwoHeaderBottom - (BORDER_WIDTH + 1) > boxScopeTop) {
    boxScope.style.top = '';
    boxScope.style.bottom = `${-BORDER_WIDTH}px`;
    boxScope.style.borderTop = 'none';
    boxScope.style.height = `${boxScopeBottom - tableTwoHeaderBottom}px`;
  } else {
    boxScope.style.borderTop = '';
  }
}

function hideBoxScope(boxScope: HTMLDivElement) {
  boxScope.className = classNames(styles.boxScope);
}

function showLineScope(
  lineScope: HTMLDivElement,
  width: number,
  top: number,
  left: number,
  tableTwoHeader: RefObject<HTMLDivElement>,
  danger: boolean = false,
) {
  lineScope.style.display = '';
  lineScope.className = classNames(styles.lineScope, styles.visible, {
    [styles.danger]: danger,
  });
  lineScope.style.width = `${width}px`;
  lineScope.style.top = `${top}px`;
  lineScope.style.left = `${left}px`;

  if (!tableTwoHeader?.current) return;

  const tableTwoHeaderBottom =
    tableTwoHeader.current.getBoundingClientRect().bottom;
  const lineScopeBottom = lineScope.getBoundingClientRect().bottom;
  if (tableTwoHeaderBottom - BORDER_WIDTH > lineScopeBottom) {
    lineScope.style.display = 'none';
  } else {
    lineScope.style.display = '';
  }
}

function hideLineScope(lineScope: HTMLDivElement) {
  lineScope.className = classNames(styles.lineScope);
}

function leftOffset(cell: Element, row: Element): number {
  return row.getBoundingClientRect().left - cell.getBoundingClientRect().left;
}

function rowHeight(row: Element): number {
  // row.clientHeightおよびfirstCell.clientHeightではボーダーの高さを含んでしまう。
  // そのためfirstContainerまで掘って、その高さを行の高さとする。
  const firstCell = row.firstElementChild;
  const firstContainer = firstCell?.firstElementChild;
  return firstContainer?.clientHeight ?? 0;
}
