export enum Section {
  HOW_TO_USE,
  FAQ,
  CONTENTS,
}

export function labelForSection(section: Section): string {
  switch (section) {
    case Section.HOW_TO_USE:
      return '操作方法';
    case Section.FAQ:
      return 'FAQ';
    case Section.CONTENTS:
      return 'コンテンツ';
  }
}
