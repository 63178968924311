import React, { useEffect, useState } from 'react';
import styles from './DiseaseKnowledgeSearchResult.module.scss';
import { DiseaseCategory } from '../DiseaseCategory';
import { RightArrowIcon } from '../../components/icon/RightArrowIcon';
import { Loading } from '../../components/loading/Loading';
import { Link } from 'react-router-dom';
import { SearchedDisease } from '../../../../domain/diseaseknowledge/SearchedDisease';
import { DiseaseKnowledgeApi } from '../../../../state/api/diseaseknowledge/DiseaseKnowledgeApi';
import { SearchResult } from '../../../../state/api/diseaseknowledge/SearchResult';
import { DiseaseKnowledgeDialogMode } from '../../components/MedicalKnowledgeButton';

export function DiseaseKnowledgeSearchResult(props: Props) {
  const { onClickClear, keyword, setDialogMode } = props;
  const [onFetchingDiseaseKnowledge, setOnFetchingDiseaseKnowledge] =
    useState<boolean>(false);

  const openDetailDialogButton = (diseaseId: string) => () => {
    setDialogMode({ diseaseId: diseaseId });
  };

  const [searchedDiseases, setSearchedDiseases] = useState<SearchedDisease[]>(
    [],
  );

  useEffect(() => {
    let mounted = true;
    setOnFetchingDiseaseKnowledge(true);
    DiseaseKnowledgeApi.search(keyword).then((res) => {
      if (!mounted) return;
      setOnFetchingDiseaseKnowledge(false);
      if (res instanceof SearchResult) {
        setSearchedDiseases(res.searchedDiseases);
      }
    });
    return () => {
      mounted = false;
    };
  }, [keyword]);

  const resultCount = () => {
    return (
      <h3 className={styles.resultCount}>
        <span>
          [{keyword}]の検索結果({searchedDiseases?.length ?? 0}件)
        </span>
        <Link className={styles.clearKeyword} to={'#'} onClick={onClickClear}>
          検索結果をクリア
        </Link>
      </h3>
    );
  };

  const searchNoneResult = () => {
    return (
      <>
        {resultCount()}
        <div className={styles.noneDiseases}>該当する医療知識が0件でした。</div>
      </>
    );
  };

  const searchResult = (searchedDiseases: SearchedDisease[]) => {
    return (
      <>
        {resultCount()}
        <ul className={styles.diseases}>
          {searchedDiseases.map((searchedDisease) => {
            return (
              <li
                key={searchedDisease.id}
                onClick={openDetailDialogButton(searchedDisease.id)}
              >
                <h3 className={styles.diseaseName}>{searchedDisease.name}</h3>
                <ul>
                  <li className={styles.symptom}>
                    {searchedDisease.symptoms[0]}
                  </li>
                </ul>
                <div className={styles.diseaseCategory}>
                  <DiseaseCategory categoryId={searchedDisease.category.id} />
                  {searchedDisease.category.name}
                </div>
                <div className={styles.detail}>
                  詳細
                  <RightArrowIcon />
                </div>
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  if (onFetchingDiseaseKnowledge) {
    return <Loading />;
  } else if (searchedDiseases.length) {
    return searchResult(searchedDiseases);
  } else {
    return searchNoneResult();
  }
}

type Props = {
  onClickClear: () => void;
  keyword: string;
  setDialogMode: (dialogMode: DiseaseKnowledgeDialogMode) => void;
};
