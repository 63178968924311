import { Reducer } from 'redux';
import { TableOneActions } from './type';
import { TableOne } from '../../../domain/careplan/tableone/TableOne';
import { ChangeLog } from '../../../domain/changelog/ChangeLog';

type Slot = {
  tableOne: TableOne;
  latestChangeLog: ChangeLog | null;
};

export type TableOneState = Readonly<{
  slots: { [key: string]: Slot };
  getting: boolean;
}>;

const initialState: TableOneState = {
  slots: {},
  getting: false,
};

export const tableOneReducer: Reducer<TableOneState, TableOneActions> = (
  state = initialState,
  action,
): TableOneState => {
  switch (action.type) {
    case 'TABLEONE_GET':
      return {
        ...state,
        getting: true,
      };
    case 'TABLEONE_GET_SUCCESS':
      return {
        ...state,
        slots: {
          ...state.slots,
          [action.payload.projectId]: {
            tableOne: action.payload.tableOne,
            latestChangeLog: action.payload.latestChangeLog,
          },
        },
        getting: false,
      };
    case 'TABLEONE_GET_FAILURE':
      return {
        ...state,
        getting: false,
      };
    case 'TABLEONE_UPDATE_ITEM':
      return updateSlot(state, action.payload.projectId, (slot) => {
        return {
          tableOne: slot.tableOne.updateValue(
            action.payload.path,
            action.payload.value,
          ),
          latestChangeLog: slot.latestChangeLog,
        };
      });
    case 'TABLEONE_UPDATE_ITEM_SUCCESS':
      return updateSlot(state, action.payload.projectId, (slot) => {
        return {
          tableOne: slot.tableOne,
          latestChangeLog: action.payload.changeLog,
        };
      });
    case 'TABLEONE_UPDATE_ITEM_FAILURE':
      return updateSlot(state, action.payload.projectId, (slot) => {
        return {
          tableOne: slot.tableOne.updateValue(
            action.payload.path,
            action.payload.value,
          ),
          latestChangeLog: slot.latestChangeLog,
        };
      });
    case 'TABLEONE_IMPORT_ITEM':
      return updateSlot(state, action.payload.projectId, (slot) => {
        slot.tableOne.updateValue(
          action.payload.honninPath,
          action.payload.honninValue,
        );
        return {
          tableOne: slot.tableOne.updateValue(
            action.payload.kaigoShaKazokuPath,
            action.payload.kaigoShaKazokuValue,
          ),
          latestChangeLog: slot.latestChangeLog,
        };
      });
    case 'TABLEONE_IMPORT_ITEM_SUCCESS':
      return updateSlot(state, action.payload.projectId, (slot) => {
        return {
          tableOne: slot.tableOne,
          latestChangeLog: action.payload.changeLog,
        };
      });
    case 'TABLEONE_IMPORT_ITEM_FAILURE':
      return updateSlot(state, action.payload.projectId, (slot) => {
        return {
          tableOne: slot.tableOne,
          latestChangeLog: slot.latestChangeLog,
        };
      });
    default:
      return state;
  }
};

function updateSlot(
  state: TableOneState,
  projectId: string,
  update: (slot: Slot) => Slot,
): TableOneState {
  const slot = state.slots[projectId];
  if (!slot) return state;
  return {
    ...state,
    slots: {
      ...state.slots,
      [projectId]: update(slot),
    },
  };
}
