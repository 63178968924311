import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './ImportIndicator.module.scss';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { Successful } from '../../assessmentsheet/components/sections/Successful';
import { Failed } from '../../assessmentsheet/components/sections/Failed';

export enum ImportIndicatorStatus {
  INITIAL,
  VISIBLE,
  HIDDEN,
  FAILED,
}

export function ImportIndicator(props: Props) {
  const { status } = props;

  const [start, setStart] = useState(false);
  const transitionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (status === ImportIndicatorStatus.VISIBLE) {
      setStart(true);
    }
  }, [status]);

  return (
    <CSSTransition in={start} timeout={0} nodeRef={transitionRef}>
      {() => {
        return (
          <div className={styles.container} ref={transitionRef}>
            {renderStatus(status)}
          </div>
        );
      }}
    </CSSTransition>
  );
}

type Props = {
  status: ImportIndicatorStatus;
};

function renderStatus(status: ImportIndicatorStatus): ReactNode {
  switch (status) {
    case ImportIndicatorStatus.INITIAL:
      return null;
    case ImportIndicatorStatus.VISIBLE:
      return (
        <>
          <Successful />
          <span className={styles.text}>取込が完了しました</span>
        </>
      );
    case ImportIndicatorStatus.FAILED:
      return (
        <>
          <Failed />
          <span className={classNames(styles.text, styles.failed)}>
            取込に失敗しました。アセスメントの相談内容を確認してください。
          </span>
        </>
      );
    case ImportIndicatorStatus.HIDDEN:
      return null;
  }
}
