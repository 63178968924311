export class FetchSuggestionLongTermGoals {
  longTermGoals: string[];

  constructor(longTermGoals: string[]) {
    this.longTermGoals = longTermGoals;
  }

  static async extract(
    response: Response,
  ): Promise<FetchSuggestionLongTermGoals> {
    const json = await response.json();
    return new FetchSuggestionLongTermGoals(json.longTermGoals);
  }
}
