import { TableOnePath } from './TableOnePath';

const tableOneType = Symbol();

export class TableOne {
  [tableOneType]: any;

  content: any;

  constructor(content: any) {
    this.content = content;
  }

  getValue<T>(path: TableOnePath): T {
    const { parent, property } = path.find(this.content);
    return parent[property];
  }

  updateValue<T>(path: TableOnePath, value: T): TableOne {
    const { parent, property } = path.find(this.content);
    parent[property] = value;
    return this;
  }
}
