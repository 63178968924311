import React from 'react';
import { OpenedEyeIcon } from '../icon/OpenedEyeIcon';
import { ClosedEyeIcon } from '../icon/ClosedEyeIcon';
import { InputField } from './InputField';

type Props = {
  value: string;
  setValue: (value: string) => void;
  masked: boolean;
  mask: () => void;
  unMask: () => void;
};

export function PasswordInput(props: Props) {
  const { value, setValue, masked, mask, unMask } = props;
  const encode = (value: string) => value;
  const decode = (value: string) => value;

  return (
    <InputField get={value} set={setValue} encode={encode} decode={decode}>
      {(props) => (
        <>
          <input
            type={masked ? 'password' : 'text'}
            maxLength={32}
            {...props}
          />
          {masked ? (
            <ClosedEyeIcon onClick={unMask} />
          ) : (
            <OpenedEyeIcon onClick={mask} />
          )}
        </>
      )}
    </InputField>
  );
}
