import React from 'react';

export function DownArrowIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7362 5.11248C13.027 5.29926 13.0865 5.65319 12.8691 5.90302L8.69092 10.7037L8.68615 10.7091C8.60543 10.7996 8.50156 10.8729 8.38257 10.9233C8.26358 10.9738 8.13268 11 8 11C7.86732 11 7.73642 10.9738 7.61743 10.9233C7.49844 10.8729 7.39456 10.7996 7.31384 10.7091L7.30907 10.7037L3.13094 5.90302C2.91351 5.65319 2.973 5.29926 3.26381 5.11248C3.55463 4.9257 3.96663 4.97681 4.18406 5.22663L8 9.61118L11.8159 5.22663C12.0334 4.97681 12.4454 4.9257 12.7362 5.11248Z"
        fill="none"
      />
    </svg>
  );
}
