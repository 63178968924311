import { TableCommonPath } from './TableCommonPath';

const tableCommonType = Symbol();

export class TableCommon {
  [tableCommonType]: any;

  content: any;

  constructor(content: any) {
    this.content = content;
  }

  getValue<T>(path: TableCommonPath): T {
    const { parent, property } = path.find(this.content);
    return parent[property];
  }

  updateValue<T>(path: TableCommonPath, value: T): TableCommon {
    const { parent, property } = path.find(this.content);
    parent[property] = value;
    return this;
  }
}
