import React from 'react';

export function CategoryJinhinyokikeiShikkanIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9597 11.1443C10.7127 11.1788 10.4611 11.1347 10.2407 11.0182C10.0202 10.9018 9.84211 10.7189 9.7316 10.4956C9.68477 10.3861 9.66177 10.268 9.6641 10.1489C9.66643 10.0299 9.69403 9.91272 9.7451 9.80514C10.0343 9.15495 11.115 8.66083 11.7168 8.42918C11.9117 8.3542 12.125 8.4625 12.1903 8.66077C12.2555 8.85874 12.1481 9.07163 11.9543 9.14832C11.3204 9.39916 10.5785 9.7902 10.4358 10.1117C10.427 10.1258 10.4218 10.1418 10.4207 10.1584C10.4196 10.1749 10.4226 10.1915 10.4296 10.2066C10.5231 10.4475 11.1545 10.4184 11.7064 10.2915C11.9091 10.2449 12.123 10.3496 12.1897 10.5467C12.2566 10.7444 12.1503 10.9605 11.9476 11.0104C11.6243 11.0899 11.2931 11.1349 10.9597 11.1443Z"
        fill="#7B7C88"
      />
      <path
        d="M12.1911 13.9448C12.1449 14.1479 11.942 14.2763 11.7414 14.2205C10.162 13.7817 9.93135 13.1067 10.0312 12.5996C10.1807 11.8356 11.1291 11.7062 11.8141 11.6843C12.0224 11.6776 12.1912 11.8488 12.1912 12.0572C12.1912 12.2656 12.0217 12.4331 11.8134 12.4422C10.963 12.4794 10.7935 12.6433 10.7732 12.7457C10.7245 12.9935 11.1605 13.271 11.9085 13.4834C12.1092 13.5404 12.2374 13.7414 12.1911 13.9448Z"
        fill="#7B7C88"
      />
      <path
        d="M9.54562 20.7368C9.38529 20.8703 9.14641 20.8488 9.01652 20.6855C6.87345 17.9913 6.60728 15.7491 7.32049 14.6934C8.35082 13.1666 11.0964 14.562 12.8416 15.6868C13.0169 15.7998 13.0627 16.0346 12.9464 16.2077C12.8303 16.3807 12.5963 16.4266 12.421 16.3142C10.6522 15.1813 8.53092 14.2514 7.94689 15.1155C7.49618 15.7839 7.58762 17.6665 9.597 20.1997C9.72702 20.3637 9.7064 20.6029 9.54562 20.7368Z"
        fill="#7B7C88"
      />
      <path
        d="M6.90033 20.738C6.75035 20.8828 6.51076 20.8788 6.3707 20.7245C4.19831 18.3302 3.40729 15.4739 4.19884 12.836C5.07022 9.94636 7.66862 7.78622 11.361 6.86881C11.5632 6.81857 11.7649 6.94732 11.8101 7.15072C11.8554 7.35424 11.7267 7.5554 11.5244 7.60596C8.10776 8.45993 5.71162 10.4314 4.92243 13.0535C4.20927 15.4233 4.9344 18.0094 6.91384 20.199C7.05384 20.3539 7.05055 20.5931 6.90033 20.738Z"
        fill="#7B7C88"
      />
      <path
        d="M13.6961 20.7564C13.1394 20.7811 12.5853 20.6666 12.0841 20.4233C11.5829 20.1799 11.1505 19.8154 10.826 19.3629C10.3797 18.6966 10.1129 17.9266 10.0512 17.1273C9.98945 16.3281 10.135 15.5264 10.4736 14.7996L11.1342 15.1669C10.8688 15.769 10.7577 16.4277 10.811 17.0834C10.8644 17.7392 11.0805 18.3714 11.4398 18.9228C12.0928 19.8304 13.1795 20.1743 14.5808 19.9149C18.1259 19.2613 19.0678 14.7164 19.2721 13.339C19.5461 11.4901 19.2932 8.56402 17.6781 6.40443C17.146 5.69203 16.4734 5.09605 15.7019 4.6532C14.9303 4.21035 14.0762 3.93001 13.1921 3.82947C10.695 3.47568 9.11375 4.40713 8.65385 5.48647C8.49389 5.88836 8.4785 6.33325 8.6103 6.74522C8.7421 7.15718 9.01291 7.51069 9.37654 7.74541L8.9108 8.3397C8.41559 8.00323 8.05091 7.50722 7.87769 6.9345C7.70447 6.36179 7.73319 5.74704 7.95904 5.19292C8.53144 3.8488 10.4071 2.6755 13.2979 3.08369C14.281 3.19705 15.2306 3.51022 16.088 4.00393C16.9455 4.49763 17.6928 5.16142 18.2838 5.95444C20.0388 8.30014 20.3151 11.459 20.0195 13.4514C19.7967 14.9542 18.7555 19.9144 14.7181 20.6589C14.3811 20.7225 14.039 20.7551 13.6961 20.7564Z"
        fill="#7B7C88"
      />
    </svg>
  );
}
