import { Disease } from './Disease';
import { DiseaseCategory } from './DiseaseCategory';

const searchedDiseaseType = Symbol();

export class SearchedDisease extends Disease {
  [searchedDiseaseType]: any;

  category: DiseaseCategory;

  symptoms: string[];

  constructor(disease: Disease, category: DiseaseCategory, symptoms: string[]) {
    super(disease.id, disease.name);
    this.category = category;
    this.symptoms = symptoms;
  }
}
