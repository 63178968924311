import { CareRecipientRow } from '../../../domain/carerecipient/CareRecipientRow';
import { User } from '../../../domain/user/User';
import { offsetDateTime } from '../../../domain/fundamental/OffsetDateTime';

export class GetList {
  type: 'SUCCESS' = 'SUCCESS';

  careRecipients: CareRecipientRow[];

  constructor(careRecipients: CareRecipientRow[]) {
    this.careRecipients = careRecipients;
  }

  static path = '/api/careRecipient/getList';

  static async extract(response: Response): Promise<GetList> {
    const json = await response.json();
    const careRecipients = json.careRecipientRows.map(careRecipient);
    return new GetList(careRecipients);
  }
}

type Item = {
  id: string;
  name: string;
  phonetic: string;
  responsibleUser: User | null;
  activated: boolean;
  latestProject: { lastUpdatedAt: string };
};

function careRecipient(item: Item): CareRecipientRow {
  return new CareRecipientRow(
    item.id,
    item.name,
    item.phonetic,
    item.responsibleUser,
    item.activated,
    { lastUpdatedAt: offsetDateTime(item.latestProject.lastUpdatedAt) },
  );
}
