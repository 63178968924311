import React from 'react';
import { ReactNode } from 'react';
import { Label } from './labels';
import styles from './Section.module.scss';
import { GroupedAssessmentSheetUpdater } from './GroupedAssessmentSheetUpdater';
import { FieldProps } from './AssessmentSheetUpdaterOf';
import { MultilineTextFieldProps } from '../../../components/field/MultilineTextField';
import {
  radioWith,
  multilineTextWith,
  multilineLabelWith,
  labelWith,
  dateLabelWith,
} from './fields';

export class AssessmentSheetField {
  private readonly activated: boolean | null;

  constructor(activated: boolean) {
    this.activated = activated;
  }

  renderField<T>(
    assessmentSheetUpdater: GroupedAssessmentSheetUpdater,
    path: string,
    build: (props: FieldProps<T>) => ReactNode,
    prop: Props = {},
  ): ReactNode {
    if (this.activated) {
      if (prop.tag === undefined || prop.tag) {
        return (
          <div className={styles.field}>
            {assessmentSheetUpdater.of<T>(path, build)}
          </div>
        );
      } else {
        return <>{assessmentSheetUpdater.of<T>(path, build)}</>;
      }
    } else {
      if (prop.tag === undefined || prop.tag) {
        return (
          <div className={styles.labelText}>
            {prop.leading}
            {assessmentSheetUpdater.of<T>(path, labelWith(null))}
            {prop.trailing}
          </div>
        );
      } else {
        return (
          <div className={styles.labelTextPaddingNo}>
            {prop.leading}
            {assessmentSheetUpdater.of<T>(path, labelWith(null))}
            {prop.trailing}
          </div>
        );
      }
    }
  }

  renderDateField<T>(
    assessmentSheetUpdater: GroupedAssessmentSheetUpdater,
    path: string,
    build: (props: FieldProps<T>) => ReactNode,
    prop: Props = {},
  ): ReactNode {
    if (this.activated) {
      return (
        <div className={styles.field}>
          {assessmentSheetUpdater.of<T>(path, build)}
        </div>
      );
    } else {
      return (
        <div className={styles.labelText}>
          {prop.leading}
          {assessmentSheetUpdater.of<T>(path, dateLabelWith())}
          {prop.trailing}
        </div>
      );
    }
  }

  renderTextareaField(
    assessmentSheetUpdater: GroupedAssessmentSheetUpdater,
    path: string,
    props: MultilineTextFieldProps,
  ): ReactNode {
    if (this.activated) {
      return (
        <div className={styles.field}>
          {assessmentSheetUpdater.of<string | null>(
            path,
            multilineTextWith(props),
          )}
        </div>
      );
    } else {
      return (
        <div className={styles.field}>
          {assessmentSheetUpdater.of<string | null>(
            path,
            multilineLabelWith(props),
          )}
        </div>
      );
    }
  }

  renderRadioField<T>(
    assessmentSheetUpdater: GroupedAssessmentSheetUpdater,
    path: string,
    values: Label<T>[],
    prop: Props = {},
  ): ReactNode {
    if (this.activated) {
      if (prop.tag === undefined || prop.tag) {
        if (prop.radioPattern === 'vertical') {
          return (
            <div className={styles.field}>
              <div className={styles.vertical}>
                {assessmentSheetUpdater.of<T>(path, radioWith(values))}
              </div>
            </div>
          );
        } else {
          return (
            <div className={styles.field}>
              <div className={styles.horizontal}>
                {assessmentSheetUpdater.of<T>(path, radioWith(values))}
              </div>
            </div>
          );
        }
      } else {
        if (prop.radioPattern === 'vertical') {
          return (
            <div className={styles.vertical}>
              {assessmentSheetUpdater.of<T>(path, radioWith(values))}
            </div>
          );
        } else {
          return (
            <div className={styles.horizontal}>
              {assessmentSheetUpdater.of<T>(path, radioWith(values))}
            </div>
          );
        }
      }
    } else {
      if (prop.tag === undefined || prop.tag) {
        return (
          <div className={styles.labelText}>
            {prop.leading}
            {assessmentSheetUpdater.of<T>(path, labelWith(values))}
            {prop.trailing}
          </div>
        );
      } else {
        return (
          <div className={styles.labelTextPaddingNo}>
            {prop.leading}
            {assessmentSheetUpdater.of<T>(path, labelWith(values))}
            {prop.trailing}
          </div>
        );
      }
    }
  }
}

type Props = {
  leading?: ReactNode;
  trailing?: ReactNode;
  radioPattern?: string;
  tag?: boolean;
};
