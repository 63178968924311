import React, { useState, MouseEvent } from 'react';
import styles from './CareRecipientListItem.module.scss';
import { useSelector } from 'react-redux';
import { State } from '../../../../state/store';
import { CareRecipientRow } from '../../../../domain/carerecipient/CareRecipientRow';
import { displayForResponsibleUser } from '../../misc/display';
import { Link } from 'react-router-dom';
import { Paths } from '../../../../Paths';
import { ThreeDotIcon } from '../../components/icon/ThreeDotIcon';
import { Modal } from '../../components/Modal';
import { CareRecipientEditingDialog } from './CareRecipientEditingDialog';
import { CareRecipientSettingDialog } from './CareRecipientSettingDialog';
import { CareRecipientDeletionDialog } from './CareRecipientDeletionDialog';
import { jpFormat } from 'dayjs-jp-format';
import dayjs from 'dayjs';
import { CareRecipientApi } from '../../../../state/api/carerecipient/CareRecipientApi';
import { ApiError } from '../../../../state/api/ApiError';

dayjs.extend(jpFormat);

const DialogStatusType = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
};

type DialogStatusType =
  (typeof DialogStatusType)[keyof typeof DialogStatusType];

type Dialogs = {
  careRecipientSettingDialog: DialogStatusType;
  careRecipientEditingDialog: DialogStatusType;
  careRecipientDeletionDialog: DialogStatusType;
};

export function CareRecipientListItem(props: Props) {
  const { careRecipient, refresh } = props;

  const user = useSelector((state: State) => state.authentication.user);

  const [dialogStatus, setDialogStatus] = useState<Dialogs>({
    careRecipientSettingDialog: DialogStatusType.CLOSE,
    careRecipientEditingDialog: DialogStatusType.CLOSE,
    careRecipientDeletionDialog: DialogStatusType.CLOSE,
  });

  const openCareRecipientSettingDialog = (event: MouseEvent) => {
    event.preventDefault();
    setDialogStatus({
      ...dialogStatus,
      careRecipientSettingDialog: DialogStatusType.OPEN,
    });
  };

  const closeCareRecipientSettingDialog = (event: MouseEvent) => {
    event.preventDefault();
    setDialogStatus({
      ...dialogStatus,
      careRecipientSettingDialog: DialogStatusType.CLOSE,
    });
  };

  const toggleCareRecipientSettingDialog = (event: MouseEvent) => {
    switch (dialogStatus.careRecipientSettingDialog) {
      case DialogStatusType.CLOSE:
        openCareRecipientSettingDialog(event);
        break;
      case DialogStatusType.OPEN:
        closeCareRecipientSettingDialog(event);
        break;
      default:
        new Error('unexpected dialog status');
    }
  };

  const openCareRecipientEditingDialog = (event: MouseEvent) => {
    event.preventDefault();
    setDialogStatus({
      ...dialogStatus,
      careRecipientEditingDialog: DialogStatusType.OPEN,
    });
  };

  const openCareRecipientDeletionDialog = (event: MouseEvent) => {
    event.preventDefault();
    setDialogStatus({
      ...dialogStatus,
      careRecipientDeletionDialog: DialogStatusType.OPEN,
    });
  };

  const closeAllDialog = () => {
    setDialogStatus({
      careRecipientSettingDialog: DialogStatusType.CLOSE,
      careRecipientEditingDialog: DialogStatusType.CLOSE,
      careRecipientDeletionDialog: DialogStatusType.CLOSE,
    });
  };

  const onCompleteCareRecipientUpdating = () => {
    refresh();
  };

  const requestCareRecipientDeletionApi = () => {
    CareRecipientApi.delete(careRecipient.id).then((res) => {
      if (!(res instanceof ApiError)) {
        refresh();
      }
      // TODO 削除失敗時のエラーメッセージ
    });
  };

  const responsibleFor = (careRecipient: CareRecipientRow): boolean => {
    return careRecipient.responsibleUser?.id === user?.id;
  };

  return (
    <>
      {dialogStatus.careRecipientSettingDialog === DialogStatusType.OPEN && (
        <div
          className={styles.settingDialogOverlay}
          onClick={closeCareRecipientSettingDialog}
        />
      )}
      <div className={styles.settingDialogPositionReference}>
        <Link
          className={styles.container}
          to={Paths.careRecipient.build({ careRecipientId: careRecipient.id })}
        >
          <span className={styles.name}>{careRecipient.name}</span>
          <span className={styles.phonetic}>{careRecipient.phonetic}</span>
          <span className={styles.responsibleUser}>
            {displayForResponsibleUser(careRecipient.responsibleUser)}
          </span>
          <span className={styles.deactivated}>
            {!careRecipient.activated ? (
              <span className={styles.deactivatedTag}>終了者</span>
            ) : null}
          </span>
          <span className={styles.lastEditedAt}>
            {careRecipient.latestProject.lastUpdatedAt
              .toDate()
              .toLocaleDateString('ja-JP-u-ca-japanese', {
                era: 'narrow',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
          </span>
          <span className={styles.contextMenu}>
            <span
              className={styles.contextMenuLink}
              onClick={toggleCareRecipientSettingDialog}
            >
              <ThreeDotIcon />
            </span>
          </span>
        </Link>
        {dialogStatus.careRecipientSettingDialog === DialogStatusType.OPEN && (
          <span className={styles.settingDialog}>
            <CareRecipientSettingDialog
              openEditDialog={openCareRecipientEditingDialog}
              openDeletionDialog={openCareRecipientDeletionDialog}
              responsibleFor={responsibleFor(careRecipient)}
            />
          </span>
        )}
      </div>
      <Modal
        open={dialogStatus.careRecipientEditingDialog === DialogStatusType.OPEN}
        onRequestClose={closeAllDialog}
      >
        <CareRecipientEditingDialog
          careRecipient={careRecipient}
          onComplete={onCompleteCareRecipientUpdating}
          onRequestClose={closeAllDialog}
        />
      </Modal>
      <Modal
        open={
          dialogStatus.careRecipientDeletionDialog === DialogStatusType.OPEN
        }
        onRequestClose={closeAllDialog}
      >
        <CareRecipientDeletionDialog
          requestCareRecipientDeletionApi={requestCareRecipientDeletionApi}
          closeDialog={closeAllDialog}
        />
      </Modal>
    </>
  );
}

type Props = {
  careRecipient: CareRecipientRow;
  refresh: () => void;
};
