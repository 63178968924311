import React from 'react';
import { GlobalContainer } from '../../components/GlobalContainer';
import { GlobalHeader } from '../../components/GlobalHeader';
import { EmailChangeCard } from './component/EmailChangeCard';
import styles from './EmailChangePage.module.scss';

export function EmailChangePage() {
  return (
    <GlobalContainer>
      <GlobalHeader />
      <div className={styles.container}>
        <EmailChangeCard />
      </div>
    </GlobalContainer>
  );
}
