import React, { ReactNode, RefObject } from 'react';
import styles from './TableTwoCellWithIcons.module.scss';
import { TableTwoCellIcons } from './TableTwoCellIcons';
import { TableTwoTextarea } from './TableTwoTextarea';

export function TableTwoCellWithIcons(props: Props) {
  const {
    initialText,
    isFirstRowCell,
    isLastRowCell,
    suggestionDialog,
    handleTextUpdate,
    handleAdd,
    handleShiftUp,
    handleShiftDown,
    handleRemove,
    activated,
    tableTwoHeaderRef,
  } = props;
  // data-containerはセル内アイコンホバー時のELEMENT取得用シンボル

  const renderCellIcons = () => {
    if (activated) {
      return (
        <div className={styles.icons}>
          <TableTwoCellIcons
            isFirstRowCell={isFirstRowCell}
            isLastRowCell={isLastRowCell}
            handleAdd={handleAdd}
            handleShiftUp={handleShiftUp}
            handleShiftDown={handleShiftDown}
            handleRemove={handleRemove}
            tableTwoHeaderRef={tableTwoHeaderRef}
          />
        </div>
      );
    }
  };

  return (
    <div className={styles.container} data-container={'true'}>
      <div className={styles.text}>
        <TableTwoTextarea
          initialText={initialText}
          onUpdate={handleTextUpdate}
          activated={activated}
          suggestionDialog={suggestionDialog}
        />
      </div>
      {renderCellIcons()}
    </div>
  );
}

type Props = {
  initialText: string | null;
  isFirstRowCell: boolean;
  isLastRowCell: boolean;
  suggestionDialog?: (
    open: boolean,
    onSelect: (text: string) => void,
  ) => ReactNode;
  handleTextUpdate: (text: string | null) => void;
  handleAdd: () => void;
  handleShiftUp: () => void;
  handleShiftDown: () => void;
  handleRemove: () => void;
  activated: boolean;
  tableTwoHeaderRef: RefObject<HTMLDivElement>;
};
