import React from 'react';
import styles from './EmptyCard.module.scss';

export function EmptyCard() {
  return (
    <li className={styles.emptyCard}>
      {/* 中身が空の検索結果を作成するのみ*/}
    </li>
  );
}
