import React from 'react';
import styles from './HolidayStatus.module.scss';
import classNames from 'classnames';

export function HolidayStatus(props: Props) {
  const { saturday, sunday, publicHoliday } = props;
  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.cell,
          saturday ? styles.active : styles.inactive,
        )}
      >
        土
      </div>
      <div
        className={classNames(
          styles.cell,
          sunday ? styles.active : styles.inactive,
        )}
      >
        日
      </div>
      <div
        className={classNames(
          styles.cell,
          publicHoliday ? styles.active : styles.inactive,
        )}
      >
        祝
      </div>
    </div>
  );
}

type Props = {
  saturday: boolean;
  sunday: boolean;
  publicHoliday: boolean;
};
