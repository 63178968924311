export type SyllabaryColumn =
  | 'A'
  | 'KA'
  | 'SA'
  | 'TA'
  | 'NA'
  | 'HA'
  | 'MA'
  | 'YA'
  | 'RA'
  | 'WA'
  | 'OTHER';

const map: { [key in SyllabaryColumn]: RegExp } = {
  A: /[ァ-オヴ]/,
  KA: /[カ-ゴヵヶ]/,
  SA: /[サ-ゾ]/,
  TA: /[タ-ド]/,
  NA: /[ナ-ノ]/,
  HA: /[ハ-ポ]/,
  MA: /[マ-モ]/,
  YA: /[ャ-ヨ]/,
  RA: /[ラ-ロ]/,
  WA: /[ヮ-ヲヷヺ]/,
  OTHER: /[ンー]/,
};

export function syllabaryColumnFor(char: string): SyllabaryColumn {
  const [syllabaryColumn] = Object.entries(map).find(([_, pattern]) =>
    pattern.test(char),
  ) ?? ['OTHER', map.OTHER];
  return syllabaryColumn as SyllabaryColumn;
}
