import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './SaveIndicator.module.scss';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { Saving } from './Saving';
import { Successful } from './Successful';
import { Failed } from './Failed';

export enum SaveIndicatorStatus {
  INITIAL,
  SAVING,
  SUCCESSFUL,
  FAILED,
}

export function SaveIndicator(props: Props) {
  const { status } = props;

  const [start, setStart] = useState(false);
  const transitionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeoutId = controlTransition(status, setStart);
    return () => clearTimeout(timeoutId);
  }, [status]);

  return (
    <CSSTransition in={start} timeout={0} nodeRef={transitionRef}>
      {(state) => {
        return (
          <div
            className={classNames(styles.container, stateClassNames[state])}
            ref={transitionRef}
          >
            {renderStatus(status)}
          </div>
        );
      }}
    </CSSTransition>
  );
}

type Props = {
  status: SaveIndicatorStatus;
};

const stateClassNames: { [key in TransitionStatus]: string } = {
  entering: styles.entering,
  entered: styles.entered,
  exiting: styles.exiting,
  exited: styles.exited,
  unmounted: styles.unmounted,
};

function controlTransition(
  status: SaveIndicatorStatus,
  setStart: (value: boolean) => void,
): NodeJS.Timeout {
  switch (status) {
    case SaveIndicatorStatus.INITIAL:
      return setTimeout(() => {
        /* do nothing */
      }, 0);
    case SaveIndicatorStatus.SAVING:
      return setTimeout(() => setStart(true), 0);
    case SaveIndicatorStatus.SUCCESSFUL:
      return setTimeout(() => setStart(false), 3000);
    case SaveIndicatorStatus.FAILED:
      return setTimeout(() => setStart(false), 3000);
  }
}

function renderStatus(status: SaveIndicatorStatus): ReactNode {
  switch (status) {
    case SaveIndicatorStatus.INITIAL:
      return null;
    case SaveIndicatorStatus.SAVING:
      return (
        <>
          <Saving />
          <span className={styles.text}>保存中...</span>
        </>
      );
    case SaveIndicatorStatus.SUCCESSFUL:
      return (
        <>
          <Successful />
          <span className={styles.text}>保存しました</span>
        </>
      );
    case SaveIndicatorStatus.FAILED:
      return (
        <>
          <Failed />
          <span className={classNames(styles.text, styles.failed)}>
            保存に失敗しました
          </span>
        </>
      );
  }
}
