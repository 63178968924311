import { AssessmentSheet } from '../../../domain/assessmentsheet/AssessmentSheet';
import { ChangeLog } from '../../../domain/changelog/ChangeLog';
import { changeLog } from '../changelog/LatestChangeLog';

export class Get {
  type: 'SUCCESS' = 'SUCCESS';

  projectId: string;

  assessmentSheet: AssessmentSheet;

  latestChangeLog: ChangeLog | null;

  constructor(
    projectId: string,
    assessmentSheet: AssessmentSheet,
    latestChangeLog: ChangeLog | null,
  ) {
    this.projectId = projectId;
    this.assessmentSheet = assessmentSheet;
    this.latestChangeLog = latestChangeLog;
  }

  static path = '/api/assessmentSheet/get';

  static async extract(response: Response): Promise<Get> {
    const json = await response.json();
    const assessmentSheet = new AssessmentSheet(json.assessmentSheet);
    const latestChangeLog = json.latestChangeLog
      ? changeLog(json.latestChangeLog)
      : null;
    return new Get(json.project.id, assessmentSheet, latestChangeLog);
  }
}
