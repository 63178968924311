import React from 'react';

export function CategoryShokakikeiShikkanIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4937 17.2325C3.30485 17.3757 3.03219 17.3247 2.92911 17.1113C2.73551 16.7105 2.60112 16.282 2.53105 15.8394C2.43776 15.2503 2.4603 14.6482 2.59736 14.0681C2.65856 13.6943 2.80087 13.3391 3.01394 13.0284C3.227 12.7176 3.50547 12.4591 3.82908 12.2715C4.9418 11.6996 5.9374 12.5756 6.90254 13.4236C7.43166 13.9383 8.02962 14.3743 8.67868 14.7187C10.31 15.4642 14.9971 14.4203 16.872 11.0961C18.0682 8.97736 17.7449 6.38053 17.1063 4.80088C16.5863 3.51724 15.7903 2.61589 14.9774 2.38947C13.6726 2.02626 12.5453 2.47289 11.5282 3.75462C11.3758 3.94595 11.0865 3.95251 10.9266 3.76749C10.2458 2.98098 9.67236 2.10537 9.2214 1.16444C9.12546 0.964261 9.22247 0.727231 9.42824 0.643948C9.62619 0.563831 9.85135 0.656175 9.942 0.849529C10.3556 1.73182 11.0366 2.93801 11.4746 2.5C12.9746 1.5 13.7063 1.2045 15.1849 1.61776C16.2466 1.91329 17.2122 2.96242 17.8339 4.49582C18.576 6.32714 18.8927 9.12703 17.5561 11.4946C15.4426 15.2383 10.2548 16.3126 8.35868 15.447C7.63968 15.0712 6.97709 14.5929 6.39092 14.0266C5.42297 13.1748 4.79797 12.6686 4.1889 12.9832C3.97135 13.1222 3.78548 13.3069 3.64376 13.5249C3.50204 13.7429 3.40772 13.9892 3.36713 14.2473C3.17416 15.075 3.26304 15.9412 3.61325 16.7062C3.69715 16.8894 3.65433 17.1108 3.4937 17.2325Z"
        fill="#007EFC"
      />
      <path
        d="M1.95106 18.2367C1.78029 18.381 1.52309 18.3567 1.40288 18.1682C0.414635 16.6187 0.0643072 13.8457 1.10795 11.7904C2.61991 8.81043 5.30591 9.1166 7.98303 9.88946C8.10118 9.93695 8.22831 9.95609 8.35473 9.94543C8.48116 9.93477 8.60356 9.89459 8.7126 9.82793C9.29963 9.41026 9.42301 7.9352 9.51322 6.85858C9.53 6.65576 9.54602 6.46426 9.56398 6.28639C9.59325 5.99647 9.51106 5.70414 9.32789 5.47752C8.50931 4.46474 7.79453 3.36772 7.19497 2.20388C7.09618 2.01212 7.17502 1.77789 7.36752 1.68054C7.56384 1.58128 7.80302 1.66217 7.90371 1.85776C8.56369 3.13968 9.37512 4.33347 10.3199 5.41187C10.4165 5.50998 10.4573 5.65007 10.4284 5.78469L10.4279 5.78725C10.3629 6.16457 10.3188 6.54536 10.2956 6.92779C10.1867 8.23319 10.0502 9.85966 9.16035 10.4912C8.95741 10.6278 8.72667 10.7147 8.48592 10.7451C8.24517 10.7755 8.00084 10.7487 7.77178 10.6666C4.96753 9.85677 2.99194 9.82457 1.80434 12.1638C0.915987 13.9147 1.20516 16.3389 2.02522 17.671C2.13805 17.8542 2.11548 18.0978 1.95106 18.2367Z"
        fill="#007EFC"
      />
    </svg>
  );
}
