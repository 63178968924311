import React, { ButtonHTMLAttributes } from 'react';
import styles from './DiseaseKnowledgeButton.module.scss';
import { RightArrowIcon } from '../icon/RightArrowIcon';

export function DiseaseKnowledgeButton(
  props: ButtonHTMLAttributes<any> & Props,
) {
  const { children, label, ...rest } = props;
  return (
    <button className={styles.button} {...rest}>
      <span className={styles.disease}>{children || label}</span>
      <span className={styles.detail}>詳細</span>
      <span className={styles.arrow}>
        <RightArrowIcon />
      </span>
    </button>
  );
}

type Props = {
  label: string;
};
