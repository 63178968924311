import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { ROOT_TYPE, RootActionTypes } from './RootAction';
import {
  authenticationReducer,
  AuthenticationState,
} from './usecase/authentication/reducer';
import {
  assessmentSheetReducer,
  AssessmentSheetState,
} from './usecase/assessmentsheet/reducer';
import {
  tableCommonReducer,
  TableCommonState,
} from './usecase/tablecommon/reducer';
import { tableOneReducer, TableOneState } from './usecase/tableone/reducer';
import {
  tableThreeReducer,
  TableThreeState,
} from './usecase/tablethree/reducer';

export type RootState = Readonly<{
  authentication: AuthenticationState;
  assessmentSheet: AssessmentSheetState;
  tableCommon: TableCommonState;
  tableOne: TableOneState;
  tableThree: TableThreeState;
}>;

const appReducer = combineReducers<RootState>({
  authentication: authenticationReducer,
  assessmentSheet: assessmentSheetReducer,
  tableCommon: tableCommonReducer,
  tableOne: tableOneReducer,
  tableThree: tableThreeReducer,
});

const rootReducer = (state: RootState | undefined, action: RootActionTypes) => {
  if (action.type === ROOT_TYPE.RESET_ALL) {
    state = undefined;
  }
  return appReducer(state, action);
};

// redux dev tools
interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose; // eslint-disable-line
}

declare const window: ExtendedWindow;

const composeReduxDevToolsEnhancers =
  (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

// root state
export const Store = createStore(
  rootReducer,
  composeReduxDevToolsEnhancers(applyMiddleware(thunk)),
);

export type State = ReturnType<typeof Store.getState>;
