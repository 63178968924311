import React, { MouseEvent, useEffect, useState } from 'react';
import styles from './SuggestionDialog.module.scss';
import { Loading } from '../../components/loading/Loading';
import { Link } from 'react-router-dom';
import { SuggestionLongTermGoalApi } from '../../../../state/api/suggestion/longtermgoal/SuggestionLongTermGoalApi';
import { FetchSuggestionLongTermGoals } from '../../../../state/api/suggestion/longtermgoal/FetchSuggestionLongTermGoals';

export function SuggestionLongTermGoalDialog(props: Props) {
  const { open, projectId, needText, onSelect } = props;

  const [onFetching, setOnFetching] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [errorDetail, setErrorDetail] = useState<boolean>(false);

  const [suggestedLongTermGoals, setSuggestedLongTermGoals] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (!open) return;
    let mounted = true;
    SuggestionLongTermGoalApi.fetchSuggestionLongTermGoals(
      projectId,
      needText || '',
    ).then((res) => {
      if (!mounted) return;
      setOnFetching(false);
      setErrorCode('');
      if (res instanceof FetchSuggestionLongTermGoals) {
        setSuggestedLongTermGoals(res.longTermGoals);
      } else {
        setErrorCode(res.code);
      }
    });
    return () => {
      mounted = false;
    };
  }, [open, projectId, needText]);

  // blurはmousedown時に発生するので、mousedownを抑止することによりTableTwoTextareaのblurが発生するのを防ぐ
  const onMouseDown = (event: MouseEvent<any>) => {
    event.preventDefault();
  };

  const onClick = (longTermGoalText: string) => () => {
    onSelect?.(longTermGoalText);
  };

  const renderSuggestionErrorDetail = () => {
    if (!errorDetail) {
      return (
        <Link
          className={styles.errorShow}
          to={'#'}
          onClick={() => setErrorDetail(true)}
        >
          理由を表示する
        </Link>
      );
    } else if (errorCode === 'MISSING_ASSESSMENT_SHEET_ITEMS') {
      return (
        <div className={styles.errorDetail}>
          ・アセスメントシート内の「要介護認定項目」の入力が不十分です。
        </div>
      );
    } else if (errorCode === 'INVALID_TEXT') {
      return (
        <div className={styles.errorDetail}>
          ・「ニーズ」の入力が不十分です（未入力、記号のみなど）。
        </div>
      );
    }
  };

  const renderSuggestionError = () => {
    return (
      <div>
        <div className={styles.error}>提案文言が表示できませんでした。</div>
        {renderSuggestionErrorDetail()}
      </div>
    );
  };

  const renderSuggestion = () => {
    if (onFetching) {
      return <Loading />;
    } else if (errorCode) {
      return renderSuggestionError();
    } else {
      return suggestedLongTermGoals.map((longTermGoal, index) => {
        if (index < 5) {
          return (
            <label
              key={index}
              className={styles.suggestion}
              onClick={onClick(longTermGoal)}
            >
              {longTermGoal}
            </label>
          );
        }
        return;
      });
    }
  };

  if (!open) return null;

  return (
    <div className={styles.container} onMouseDown={onMouseDown}>
      <h2 className={styles.title}>文言提案</h2>
      {renderSuggestion()}
    </div>
  );
}

type Props = {
  open: boolean;
  projectId: string;
  needText: string | null;
  onSelect?: (longTermGoalText: string) => void;
};
