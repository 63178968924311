import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../state/store';
import { verify } from '../../../state/usecase/authentication/action';
import { AuthenticationStatus } from '../../../domain/authentication/AuthenticationStatus';

export function VerifyAuthentication(props: Props) {
  const { authenticated, unauthenticated } = props;

  const dispatch = useDispatch();
  const authenticationStatus = useSelector(
    (state: State) => state.authentication.status,
  );

  useEffect(() => {
    if (authenticationStatus !== null) return;
    dispatch(verify());
  });

  switch (authenticationStatus) {
    case null: // fall through intentionally
    case AuthenticationStatus.PENDING:
      return null;
    case AuthenticationStatus.AUTHENTICATED:
      return <>{authenticated}</>;
    case AuthenticationStatus.UNAUTHENTICATED:
      return <>{unauthenticated}</>;
  }
}

type Props = {
  authenticated: ReactNode;
  unauthenticated: ReactNode;
};
