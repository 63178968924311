export class GetRsLoginStatus {
  static path = '/api/entry-point/rsLoginStatus';

  type: 'SUCCESS' = 'SUCCESS';

  rsLoginIn: boolean;

  constructor(rsLoginIn: boolean) {
    this.rsLoginIn = rsLoginIn;
  }

  static async extract(response: Response): Promise<GetRsLoginStatus> {
    const json = await response.json();
    const rsLoginIn = json.rsLoginIn;
    return new GetRsLoginStatus(rsLoginIn);
  }
}
