import React from 'react';
import styles from './TableThreeHeader.module.scss';
import dayjs from 'dayjs';
import { jpFormat } from 'dayjs-jp-format';
import { useSelector } from 'react-redux';
import { State } from '../../../../state/store';
import { ChangeLog } from '../../../../domain/changelog/ChangeLog';
import { ExcelDownLoadButton } from '../../components/button/ExcelDownloadButton';
import { Paths } from '../../../../Paths';

dayjs.extend(jpFormat);

export function TableThreeHeader(props: Props) {
  const { projectId, latestChangeLog } = props;

  const rsLoginIn = useSelector(
    (state: State) => state.authentication.rsLoginIn,
  );

  const stylesLastEditedAt = rsLoginIn
    ? styles.lastEditedAtRsLogin
    : styles.lastEditedAt;

  const formatEditedAt = (latestChangeLog: ChangeLog | null) => {
    return latestChangeLog?.when
      .toDate()
      .toLocaleDateString('ja-JP-u-ca-japanese', {
        era: 'narrow',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>週間サービス計画(3)</h2>
        <p>
          *Altキーまたは、Ctrlキー(macの場合はOptionキー)を押しながら、ドラッグ&ドロップでコピーできます。
        </p>
      </div>
      <div className={styles.container}>
        <div></div>
        <div className={styles.menu}>
          {latestChangeLog && (
            <div className={stylesLastEditedAt}>
              最終更新: {formatEditedAt(latestChangeLog)}
            </div>
          )}
          {!rsLoginIn && (
            <ExcelDownLoadButton
              path={Paths.tableThreeDownloadExcel.build({ projectId })}
            />
          )}
        </div>
      </div>
    </div>
  );
}

type Props = {
  projectId: string;
  latestChangeLog: ChangeLog | null;
};
