import React, { useEffect, useState, MouseEvent } from 'react';
import styles from './UserForm.module.scss';
import { SelectField } from '../../components/field/SelectField';
import { TextField } from '../../components/field/TextField';
import { PrimaryButton } from '../../components/button/PrimaryButton';
import { AdminUserApi } from '../../../../state/api/admin/user/AdminUserApi';
import { UserDetail } from '../../../../domain/user/UserDetail';
import { CloseIcon } from '../../components/icon/CloseIcon';
import { CheckboxField } from '../../components/field/CheckboxField';
import { AdminOfficeApi } from '../../../../state/api/admin/office/AdminOfficeApi';
import { Office } from '../../../../domain/office/Office';

export default function UserForm(props: Props) {
  const { user, type, onRequestClose, refresh } = props;

  const [offices, setOffices] = useState<Office[]>([]);

  const [userName, setUserName] = useState(user?.displayName ?? '');
  const [officeId, setOfficeId] = useState(user?.officeId ?? '');
  const [email, setEmail] = useState(user?.email ?? '');
  const [password, setPassword] = useState('');
  const [loginName, setLoginName] = useState(user?.loginName ?? '');
  const [checkAdmin, setCheckAdmin] = useState(user?.isAdmin ?? false);

  const [duplicateEmailError, setDuplicateEmailError] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onClick = () => {
    if (type === 'register') {
      AdminUserApi.register(
        userName,
        email,
        officeId,
        checkAdmin,
        password,
        loginName,
      )
        .then(() => {
          setHasError(false);
          refresh();
          onRequestClose();
        })
        .catch((error) => {
          if (error) {
            setDuplicateEmailError(true);
            return;
          }
          setHasError(true);
        });
    } else {
      AdminUserApi.update(user!!.id, userName, officeId, checkAdmin, loginName)
        .then(() => {
          setHasError(false);
          refresh();
          onRequestClose();
        })
        .catch(() => {
          setHasError(true);
        });
    }
  };

  const onClickContainer = (e: MouseEvent<any>) => {
    e.stopPropagation();
  };

  const renderType = () => {
    if (type === 'register') {
      return '登録';
    }
    return '更新';
  };

  useEffect(() => {
    let mounted = true;
    AdminOfficeApi.offices().then((res) => {
      if (mounted) {
        setOffices(res);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (offices && offices.length !== 0 && !officeId) {
      setOfficeId(offices[0].id);
    }
  }, [offices, officeId]);

  const disabled =
    !officeId ||
    !userName ||
    !email ||
    !loginName ||
    (type === 'register' ? !password : false);

  return (
    <div className={styles.container} onClick={onClickContainer}>
      <div className={styles.description}>ユーザーを{renderType()}します</div>
      <div className={styles.textArea}>
        <div className={styles.title}>事業所</div>
        <SelectField
          values={offices?.map((office) => {
            return [office.id, office.displayName];
          })}
          set={setOfficeId}
          get={officeId}
        />
      </div>
      <div className={styles.textArea}>
        <div className={styles.title}>ユーザー名</div>
        <TextField get={userName} set={setUserName} maxLength={20} />
      </div>
      <div className={styles.textArea}>
        <div className={styles.title}>ログイン名</div>
        <TextField get={loginName} set={setLoginName} maxLength={30} />
      </div>
      {type === 'register' && (
        <>
          <div className={styles.textArea}>
            <div className={styles.title}>メールアドレス</div>
            <TextField get={email} set={setEmail} maxLength={100} />
            {duplicateEmailError && (
              <div className={styles.errorMessage}>
                すでに登録されているメールアドレスです。
              </div>
            )}
          </div>
          <div className={styles.textArea}>
            <div className={styles.title}>パスワード</div>
            <TextField get={password} set={setPassword} />
          </div>
        </>
      )}
      <div className={styles.textArea}>
        <div className={styles.title}>管理者</div>
        <CheckboxField set={setCheckAdmin} get={checkAdmin} />
      </div>
      {hasError && (
        <div className={styles.errorMessage}>
          ユーザー{renderType()}に失敗しました。
        </div>
      )}
      <div className={styles.buttonArea}>
        <PrimaryButton onClick={onClick} width={256} disabled={disabled}>
          {renderType()}
        </PrimaryButton>
      </div>
      <button onClick={onRequestClose} className={styles.closeIcon}>
        <CloseIcon />
      </button>
    </div>
  );
}

type Props = {
  user?: UserDetail;
  type: 'register' | 'update';
  onRequestClose: () => void;
  refresh: () => void;
};
