// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React from 'react';
import styles from './Section.module.scss';
import { AssessmentSheetUpdater } from './AssessmentSheetUpdater';
import { AssessmentSheetField } from './AssessmentSheetField';
import {
  BedHoshiki,
  FukushiKikiNoShiyo,
  FutonKaBed,
  Hiatari,
  JukyoKubun1,
  JukyoKubun2,
  KyoshitsuKaisu,
  ToiletHoshiki,
  Umu,
} from './types';
import {
  bedHoshikiLabels,
  fukushiKikiNoShiyoLabels,
  futonKaBedLabels,
  hiatariLabels,
  jukyoKubun1Labels,
  jukyoKubun2Labels,
  kyoshitsuKaisuLabels,
  toiletHoshikiLabels,
  umuLabels,
} from './labels';
import { checkboxWith, positiveIntegerWith, textWith } from './fields';

export function JukyoNadoNoJokyoSection(props: Props) {
  const { assessmentSheetUpdater, activated } = props;
  const assessmentSheetField = new AssessmentSheetField(activated);

  return (
    <>
      <div className={styles.card}>
        <h3 className={styles.title}>住居区分</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                住居区分
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.vertical}>
                  {assessmentSheetField.renderRadioField<JukyoKubun1 | null>(
                    assessmentSheetUpdater,
                    'jukyoNadoNoJokyo.jukyoKubun1',
                    jukyoKubun1Labels,
                    { tag: false },
                  )}
                  {assessmentSheetField.renderRadioField<JukyoKubun2 | null>(
                    assessmentSheetUpdater,
                    'jukyoNadoNoJokyo.jukyoKubun2',
                    jukyoKubun2Labels,
                    { tag: false },
                  )}
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'jukyoNadoNoJokyo.sonotaNoBai',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>居室等の状況</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                専用居室
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.kyoshitsuNadoNoJokyo.senyoKyoshitsu',
                umuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                階
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetField.renderRadioField<KyoshitsuKaisu | null>(
                    assessmentSheetUpdater,
                    'jukyoNadoNoJokyo.kyoshitsuNadoNoJokyo.kai.kaisu',
                    kyoshitsuKaisuLabels,
                    { tag: false },
                  )}
                  {assessmentSheetField.renderField<number | null>(
                    assessmentSheetUpdater,
                    'jukyoNadoNoJokyo.kyoshitsuNadoNoJokyo.kai.sonotaNoKaisu',
                    positiveIntegerWith({
                      width: 120,
                      trailing: <span className={styles.modifier}>階</span>,
                    }),
                    {
                      tag: false,
                      trailing: <span className={styles.modifier}>階</span>,
                    },
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                エレベーター
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.kyoshitsuNadoNoJokyo.kai.elevator',
                umuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                寝具
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<FutonKaBed | null>(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.kyoshitsuNadoNoJokyo.shingu.futonKaBed',
                futonKaBedLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                ベッド方式
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetField.renderRadioField<BedHoshiki | null>(
                    assessmentSheetUpdater,
                    'jukyoNadoNoJokyo.kyoshitsuNadoNoJokyo.shingu.bedHoshiki',
                    bedHoshikiLabels,
                    { tag: false },
                  )}
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'jukyoNadoNoJokyo.kyoshitsuNadoNoJokyo.shingu.sonotaNoBai',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                陽あたり
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Hiatari | null>(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.kyoshitsuNadoNoJokyo.hiatari',
                hiatariLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                暖房
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.kyoshitsuNadoNoJokyo.dambo',
                umuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                冷房
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.kyoshitsuNadoNoJokyo.reibo',
                umuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>トイレ</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                方式
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetField.renderRadioField<ToiletHoshiki | null>(
                    assessmentSheetUpdater,
                    'jukyoNadoNoJokyo.toilet.hoshiki.hoshiki',
                    toiletHoshikiLabels,
                    { tag: false },
                  )}
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'jukyoNadoNoJokyo.toilet.hoshiki.sonotaNoBai',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                手すり
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.toilet.tesuri',
                umuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                トイレまでの段差
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.toilet.toiletMadeNoDansa',
                umuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>浴室</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                自宅に
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.yokushitsu.jitakuNi',
                umuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                手すり
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.yokushitsu.tesuri',
                umuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                浴室までの段差
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.yokushitsu.yokushitsuMadeNoDansa',
                umuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>移動手段（室外）</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                福祉機器
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<FukushiKikiNoShiyo | null>(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.idoShudanShitsugai.fukushiKiki',
                fukushiKikiNoShiyoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                使用している場合
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  <div className={styles.horizontal}>
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.idoShudanShitsugai.kurumaisu',
                      checkboxWith('車いす'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.idoShudanShitsugai.dendoKurumaisu',
                      checkboxWith('電動車いす'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.idoShudanShitsugai.tsue',
                      checkboxWith('杖'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.idoShudanShitsugai.hokoki',
                      checkboxWith('歩行器'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.idoShudanShitsugai.sonota.riyo',
                      checkboxWith('その他'),
                    )}
                  </div>
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'jukyoNadoNoJokyo.idoShudanShitsugai.sonota.naiyo',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>移動手段（室内）</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                福祉機器
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<FukushiKikiNoShiyo | null>(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.idoShudanShitsunai.fukushiKiki',
                fukushiKikiNoShiyoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                使用している場合
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  <div className={styles.horizontal}>
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.idoShudanShitsunai.kurumaisu',
                      checkboxWith('車いす'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.idoShudanShitsunai.dendoKurumaisu',
                      checkboxWith('電動車いす'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.idoShudanShitsunai.tsue',
                      checkboxWith('杖'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.idoShudanShitsunai.hokoki',
                      checkboxWith('歩行器'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.idoShudanShitsunai.sonota.riyo',
                      checkboxWith('その他'),
                    )}
                  </div>
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'jukyoNadoNoJokyo.idoShudanShitsunai.sonota.naiyo',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>諸設備</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                調理器具
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'jukyoNadoNoJokyo.choriKigu.gas',
                    checkboxWith('ガス'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'jukyoNadoNoJokyo.choriKigu.ih',
                    checkboxWith('IH'),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                暖房器具
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  <div className={styles.horizontal}>
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.damboKigu.gas',
                      checkboxWith('ガス'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.damboKigu.denki',
                      checkboxWith('電気'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.damboKigu.toyu',
                      checkboxWith('灯油'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'jukyoNadoNoJokyo.damboKigu.sonota.riyo',
                      checkboxWith('その他'),
                    )}
                  </div>
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'jukyoNadoNoJokyo.damboKigu.sonota.naiyo',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>
          周辺環境・立地環境・その他住居に関する特記事項
        </h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                周辺環境・立地環境・その他住居に関する特記事項
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'jukyoNadoNoJokyo.shuhenKankyoRitchiKankyoSonotaJukyoNikansuruTokkiJiko',
                { width: 'auto', rows: 5 },
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

type Props = {
  assessmentSheetUpdater: AssessmentSheetUpdater;
  activated: boolean;
};
