import React from 'react';

export function CloseIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9674 2.88917C3.66986 2.59163 3.18745 2.59163 2.8899 2.88917C2.59236 3.18671 2.59236 3.66913 2.8899 3.96667L6.92258 7.99935L2.8899 12.032C2.59236 12.3296 2.59236 12.812 2.8899 13.1095C3.18745 13.4071 3.66986 13.4071 3.9674 13.1095L8.00008 9.07684L12.0328 13.1095C12.3303 13.4071 12.8127 13.4071 13.1103 13.1095C13.4078 12.812 13.4078 12.3296 13.1103 12.032L9.07758 7.99935L13.1103 3.96667C13.4078 3.66913 13.4078 3.18671 13.1103 2.88917C12.8127 2.59163 12.3303 2.59163 12.0328 2.88917L8.00008 6.92185L3.9674 2.88917Z"
        fill="none"
      />
    </svg>
  );
}
