import React from 'react';

export function CaretDownIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6043 9.16872C20.0405 9.44889 20.1297 9.97979 19.8036 10.3545L13.5364 17.5556L13.5292 17.5637C13.4082 17.6994 13.2523 17.8093 13.0739 17.885C12.8954 17.9607 12.699 18 12.5 18C12.301 18 12.1046 17.9607 11.9261 17.885C11.7477 17.8093 11.5918 17.6994 11.4708 17.5637L11.4636 17.5556L5.19641 10.3545C4.87027 9.97979 4.95951 9.44889 5.39572 9.16872C5.83194 8.88855 6.44995 8.96521 6.77608 9.33995L12.5 15.9168L18.2239 9.33995C18.5501 8.96521 19.1681 8.88855 19.6043 9.16872Z"
        fill="none"
      />
    </svg>
  );
}
