import React, { RefObject, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../../state/store';
import styles from './ProjectCareRecipientProfile.module.scss';
import { SimpleCareRecipient } from '../../../../domain/carerecipient/SimpleCareRecipient';
import { EditIcon } from '../../components/icon/EditIcon';
import { Modal } from '../../components/Modal';
import { CareRecipientEditingDialog } from '../../carerecipient/components/CareRecipientEditingDialog';
import { displayForResponsibleUser } from '../../misc/display';

export function ProjectCareRecipientProfile(props: Props) {
  const { careRecipient, globalHeaderRef, onCompleteEditCareRecipient } = props;

  const [openCareRecipientUpdateDialog, setOpenCareRecipientUpdateDialog] =
    useState(false);

  const rsLoginIn = useSelector(
    (state: State) => state.authentication.rsLoginIn,
  );

  const styleContaine = rsLoginIn ? styles.containerRsLogin : styles.container;

  const onClickEditButton = () => {
    setOpenCareRecipientUpdateDialog(true);
    // プロジェクト新規作成モーダルよりグローバルヘッダが上に来てしまう事象の解消.root.scssと同じ値になっています
    if (globalHeaderRef.current?.style != null) {
      globalHeaderRef.current!.style!.zIndex = '100';
    }
  };

  const renderCareRecipientUpdateDialog = !careRecipient
    ? () => {}
    : () => {
        const onRequestClose = () => {
          setOpenCareRecipientUpdateDialog(false);
          if (globalHeaderRef.current?.style != null) {
            globalHeaderRef.current!.style!.zIndex = '150';
          }
        };
        const onComplete = () => {
          onCompleteEditCareRecipient();
        };
        return (
          <Modal
            open={openCareRecipientUpdateDialog}
            onRequestClose={onRequestClose}
          >
            <CareRecipientEditingDialog
              careRecipient={careRecipient}
              onComplete={onComplete}
              onRequestClose={onRequestClose}
            />
          </Modal>
        );
      };

  return (
    <div className={styleContaine}>
      <span className={styles.name}>{careRecipient?.name}</span>
      <span className={styles.phonetic}>{careRecipient?.phonetic}</span>
      {!rsLoginIn && (
        <span className={styles.responsibleUser}>
          担当者：
          {displayForResponsibleUser(careRecipient?.responsibleUser ?? null)}
        </span>
      )}
      {!rsLoginIn && (
        <button className={styles.editButton} onClick={onClickEditButton}>
          <EditIcon />
          <span>利用者情報編集</span>
        </button>
      )}
      {!rsLoginIn && renderCareRecipientUpdateDialog()}
    </div>
  );
}

type Props = {
  careRecipient: SimpleCareRecipient | null;
  globalHeaderRef: RefObject<HTMLDivElement>;
  onCompleteEditCareRecipient: () => void;
};
