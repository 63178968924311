export class DynamicPath<T> {
  template: string;

  constructor(template: string) {
    this.template = template;
  }

  build(params: T): string {
    let path = this.template;
    Object.entries(params).forEach(([name, value]) => {
      path = path.replace(
        new RegExp(`:${name}\\b`, 'g'),
        encodeURIComponent(value?.toString()),
      );
    });
    return path;
  }
}

export const Paths = {
  root: '/',
  login: '/login',
  careRecipients: '/careRecipients',
  careRecipient: new DynamicPath<{ careRecipientId: string }>(
    '/careRecipients/:careRecipientId',
  ),
  assessmentSheet: new DynamicPath<{
    careRecipientId: string;
    projectId: string;
  }>('/careRecipients/:careRecipientId/projects/:projectId/assessmentSheet'),
  assessmentSheetDownloadExcel: new DynamicPath<{ projectId: string }>(
    '/api/assessmentSheet/downloadExcel?projectId=:projectId',
  ),
  tableOne: new DynamicPath<{ careRecipientId: string; projectId: string }>(
    '/careRecipients/:careRecipientId/projects/:projectId/tableOne',
  ),
  tableOneByProjectId: new DynamicPath<{ projectId: string }>(
    '/projects/:projectId/tableOne',
  ),
  tableOneDownloadExcel: new DynamicPath<{ projectId: string }>(
    '/api/tableOne/downloadExcel?projectId=:projectId',
  ),
  tableTwo: new DynamicPath<{ careRecipientId: string; projectId: string }>(
    '/careRecipients/:careRecipientId/projects/:projectId/tableTwo',
  ),
  tableTwoByProjectId: new DynamicPath<{ projectId: string }>(
    '/projects/:projectId/tableTwo',
  ),
  tableTwoDownloadExcel: new DynamicPath<{ projectId: string }>(
    '/api/tableTwo/downloadExcel?projectId=:projectId',
  ),
  tableThree: new DynamicPath<{ careRecipientId: string; projectId: string }>(
    '/careRecipients/:careRecipientId/projects/:projectId/tableThree',
  ),
  tableThreeByProjectId: new DynamicPath<{ projectId: string }>(
    '/projects/:projectId/tableThree',
  ),
  tableThreeDownloadExcel: new DynamicPath<{ projectId: string }>(
    '/api/tableThree/downloadExcel?projectId=:projectId',
  ),
  userSettings: '/setting',
  userSetting: new DynamicPath<{ type: string }>('/setting?type=:type'),
  userSettingDisplayName: '/setting/displayName',
  userSettingEmail: '/setting/email',
  userSettingPassword: '/setting/password',
  passwordResetRequest: '/password/reset/request',
  passwordReset: new DynamicPath<{ token: string }>('/password/token/:token'),
  help: '/help',
  notifications: '/notifications',
  notification: new DynamicPath<{ notificationId: string }>(
    '/notification/:notificationId',
  ),
  admin: '/admin',
  adminNotification: '/admin/notification',
  entryPoint: '/careRecipients/entry-point',
};
