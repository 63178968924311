import { Dispatch } from 'react';
import { GetActions } from './type';
import { TableThreeApi } from '../../api/careplan/tablethree/TableThreeApi';

export const get =
  (projectId: string) => async (dispatch: Dispatch<GetActions>) => {
    dispatch({
      type: 'TABLETHREE_GET',
      payload: {},
    });
    const result = await TableThreeApi.get(projectId);
    switch (result.type) {
      case 'SUCCESS':
        dispatch({
          type: 'TABLETHREE_GET_SUCCESS',
          payload: {
            projectId: result.projectId,
            tableThree: result.tableThree,
            latestChangeLog: result.latestChangeLog,
          },
        });
        break;
      case 'ERROR':
        dispatch({
          type: 'TABLETHREE_GET_FAILURE',
          payload: {},
        });
        break;
    }
  };
