import React, { ButtonHTMLAttributes } from 'react';
import styles from './SearchButton.module.scss';
import { SearchIcon } from '../icon/SearchIcon';

export function SearchButton(props: ButtonHTMLAttributes<any>) {
  return (
    <button type={'submit'} className={styles.button} tabIndex={-1} {...props}>
      <SearchIcon />
    </button>
  );
}
