import React, { useRef, useState, useEffect, useCallback } from 'react';
import styles from './TableThreeWeeklyServiceSchedule.module.scss';
import { TableThreeWeeklyServiceScheduleArea } from './TableThreeWeeklyServiceScheduleArea';
import { TableThreeWeeklyServiceScheduleBox } from './TableThreeWeeklyServiceScheduleBox';
import { WeeklyServiceApi } from '../../../../state/api/careplan/tablethree/weeklyservice/WeeklyServiceApi';
import { WeeklyService } from '../../../../domain/careplan/tablethree/weeklyservice/WeeklyService';
import { useSelector } from 'react-redux';
import { State } from '../../../../state/store';
import { Get } from '../../../../state/api/careplan/tablethree/weeklyservice/Get';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export function TableThreeWeeklyServiceSchedule(props: Props) {
  const { projectId, activated, refleshTableThree } = props;

  const WEEKS = ['日', '月', '火', '水', '木', '金', '土'];

  const { tableThree } = useSelector(
    (state: State) =>
      state.tableThree.slots[projectId] ?? {
        tableThree: null,
      },
  );

  const [weeklyServices, setWeeklyServices] = useState<WeeklyService[]>([]);
  const [tableThreeId, setTableThreeId] = useState('');

  const dragInfo = useRef<WeeklyService | null>(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!tableThree) return;
    if (tableThreeId !== tableThree.id) {
      setTableThreeId(tableThree.id);
    }
    // eslint-disable-next-line
  }, [tableThree]);

  const retrieveWeeklyServices = useCallback(() => {
    if (!tableThreeId) return;
    WeeklyServiceApi.get(tableThreeId).then((res) => {
      if (res instanceof Get) {
        const setWeeklyService = res.weeklyServices.map((weeklyService) => {
          return {
            ...weeklyService,
            startTime: `${weeklyService.startTime.slice(0, 2)}:${weeklyService.startTime.slice(2)}`,
            endTime: `${weeklyService.endTime.slice(0, 2)}:${weeklyService.endTime.slice(2)}`,
          };
        });
        setWeeklyServices(setWeeklyService);
        if (isFirstRender.current) {
          isFirstRender.current = false;
        } else {
          refleshTableThree();
        }
      }
    });
    // eslint-disable-next-line
  }, [tableThreeId]);

  useEffect(() => {
    retrieveWeeklyServices();
  }, [retrieveWeeklyServices]);

  const renderScheduleBox = (week: string) => {
    const renderScheduleInfos = weeklyServices.filter((weeklyService) => {
      return weeklyService.dayOfWeek === week;
    });
    // 開始時間と終了時間 - 開始時間でソート
    renderScheduleInfos.sort((first, second) => {
      const firstStartTime = dayjs(`2000-01-01T${first.startTime}:00Z`);
      const firstEndTime = dayjs(`2000-01-01T${first.endTime}:00Z`);
      const secondStartTime = dayjs(`2000-01-01T${second.startTime}:00Z`);
      const secondEndTime = dayjs(`2000-01-01T${second.endTime}:00Z`);
      const firstDiffTime = firstEndTime.diff(firstStartTime);
      const secondDiffTime = secondEndTime.diff(secondStartTime);
      return firstDiffTime < secondDiffTime ? 1 : -1;
    });
    const renderedSchedules: WeeklyService[] = [];

    return renderScheduleInfos.map((renderScheduleInfo, index) => {
      let bookingRenderdScheduleCount = 1;
      let bookingAllCount = 0;
      renderedSchedules.forEach((renderedSchedule) => {
        const renderdScheduleStartTime = dayjs(
          `2000-01-01T${renderedSchedule.startTime}:00Z`,
        );
        const renderdScheduleEndTime = dayjs(
          `2000-01-01T${renderedSchedule.endTime}:00Z`,
        );
        const weeklyServiceStartTime = dayjs(
          `2000-01-01T${renderScheduleInfo.startTime}:00Z`,
        );
        const weeklyServiceEndTime = dayjs(
          `2000-01-01T${renderScheduleInfo.endTime}:00Z`,
        );
        if (
          (weeklyServiceStartTime.isSameOrAfter(renderdScheduleStartTime) &&
            weeklyServiceStartTime.isSameOrBefore(renderdScheduleEndTime)) ||
          (weeklyServiceEndTime.isSameOrAfter(renderdScheduleStartTime) &&
            weeklyServiceEndTime.isSameOrBefore(renderdScheduleEndTime)) ||
          (renderdScheduleStartTime.isSameOrAfter(weeklyServiceStartTime) &&
            renderdScheduleStartTime.isSameOrBefore(weeklyServiceEndTime)) ||
          (renderdScheduleEndTime.isSameOrAfter(weeklyServiceStartTime) &&
            renderdScheduleEndTime.isSameOrBefore(weeklyServiceEndTime)) ||
          (weeklyServiceStartTime.isSame(renderdScheduleStartTime) &&
            renderdScheduleEndTime.isSame(renderdScheduleEndTime))
        ) {
          bookingRenderdScheduleCount += 1;
        }
      });
      renderScheduleInfos.forEach((weeklyService) => {
        const renderScheduleStartTime = dayjs(
          `2000-01-01T${renderScheduleInfo.startTime}:00Z`,
        );
        const renderScheduleEndTime = dayjs(
          `2000-01-01T${renderScheduleInfo.endTime}:00Z`,
        );
        const weeklyServiceStartTime = dayjs(
          `2000-01-01T${weeklyService.startTime}:00Z`,
        );
        const weeklyServiceEndTime = dayjs(
          `2000-01-01T${weeklyService.endTime}:00Z`,
        );
        if (
          (renderScheduleStartTime.isSameOrAfter(weeklyServiceStartTime) &&
            renderScheduleStartTime.isSameOrBefore(weeklyServiceEndTime)) ||
          (renderScheduleEndTime.isSameOrAfter(weeklyServiceStartTime) &&
            renderScheduleEndTime.isSameOrBefore(weeklyServiceEndTime)) ||
          (weeklyServiceStartTime.isSameOrAfter(renderScheduleStartTime) &&
            weeklyServiceStartTime.isSameOrBefore(renderScheduleEndTime)) ||
          (weeklyServiceEndTime.isSameOrAfter(renderScheduleStartTime) &&
            weeklyServiceEndTime.isSameOrBefore(renderScheduleEndTime)) ||
          (renderScheduleStartTime.isSame(weeklyServiceStartTime) &&
            renderScheduleEndTime.isSame(weeklyServiceEndTime))
        ) {
          bookingAllCount += 1;
        }
      });
      const width =
        bookingRenderdScheduleCount === 1 ? 1 : 1 / bookingRenderdScheduleCount;
      const left = (1 / bookingAllCount) * (bookingRenderdScheduleCount - 1);
      const zIndex =
        parseInt(renderScheduleInfo.startTime.slice(0, 2)) +
        bookingRenderdScheduleCount -
        1;
      renderedSchedules.push(renderScheduleInfo);
      return (
        <TableThreeWeeklyServiceScheduleBox
          activated={activated}
          time={'0'}
          week={week}
          dragInfo={dragInfo}
          weeklyServices={weeklyServices}
          weeklyService={renderScheduleInfo}
          projectId={projectId}
          refresh={retrieveWeeklyServices}
          width={width}
          left={left}
          key={index}
          zIndex={zIndex}
        />
      );
    });
  };

  return (
    <div className={styles.week}>
      {WEEKS.map((week) => (
        <div className={styles.scheduleContents} key={week}>
          {Array(25)
            .fill(0)
            .map((_, index) => (
              <TableThreeWeeklyServiceScheduleArea
                activated={activated}
                time={`${String(index).padStart(2, '0')}`}
                week={week}
                dragInfo={dragInfo}
                weeklyServices={weeklyServices}
                projectId={projectId}
                refresh={retrieveWeeklyServices}
                key={index}
              />
            ))}
          <div className={styles.box}>
            {weeklyServices && renderScheduleBox(week)}
          </div>
        </div>
      ))}
    </div>
  );
}

type Props = {
  projectId: string;
  activated: boolean;
  refleshTableThree: () => void;
};
