import React, { RefObject } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../../state/store';
import styles from './ProjectHeader.module.scss';
import { ProjectSheetType } from '../../../../domain/project/ProjectSheetType';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../../../Paths';
import { TextButton } from '../../components/button/TextButton';
import { LeftArrowIcon } from '../../components/icon/LeftArrowIcon';
import { ProjectCareRecipientProfile } from './ProjectCareRecipientProfile';
import { CareRecipientActivation } from '../../carerecipient/components/CareRecipientActivation';
import { ProjectSwitcher } from './ProjectSwitcher';
import { ProjectCreation } from './ProjectCreation';
import { Project } from '../../../../domain/project/Project';
import { SimpleCareRecipient } from '../../../../domain/carerecipient/SimpleCareRecipient';

export function ProjectHeader(props: Props) {
  const {
    careRecipient,
    projectId,
    sheetType,
    projects,
    refresh,
    globalHeaderRef,
    onCompleteEditCareRecipient,
  } = props;

  const selectedProject = projects.find((project) => project.id === projectId);

  const history = useHistory();

  const rsLoginIn = useSelector(
    (state: State) => state.authentication.rsLoginIn,
  );

  const onClickBackButton = () => {
    history.push(Paths.careRecipients);
  };

  const onChangeProject = (projectId: string) => {
    if (!careRecipient) return;
    history.push(pathFor(careRecipient.id, projectId, sheetType));
  };

  const onCreateProject = (projectId: string) => {
    refresh();
    if (!careRecipient) return;
    history.push(pathFor(careRecipient.id, projectId, sheetType));
  };

  return (
    <div className={styles.container}>
      <div className={styles.careRecipient}>
        {!rsLoginIn && (
          <TextButton onClick={onClickBackButton} leading={<LeftArrowIcon />}>
            利用者一覧
          </TextButton>
        )}
        {careRecipient && (
          <ProjectCareRecipientProfile
            careRecipient={careRecipient}
            globalHeaderRef={globalHeaderRef}
            onCompleteEditCareRecipient={onCompleteEditCareRecipient}
          />
        )}
        {careRecipient && !rsLoginIn && (
          <CareRecipientActivation
            careRecipient={careRecipient}
            refresh={refresh}
          />
        )}
      </div>
      {!rsLoginIn && (
        <div className={styles.project}>
          <ProjectSwitcher
            projects={projects}
            selectedProject={selectedProject ?? null}
            onChange={onChangeProject}
          />
          <ProjectCreation
            selectedProject={selectedProject ?? null}
            onCreate={onCreateProject}
            globalHeaderRef={globalHeaderRef}
          />
        </div>
      )}
    </div>
  );
}

type Props = {
  careRecipient: SimpleCareRecipient | null;
  projectId: string;
  sheetType: ProjectSheetType;
  projects: Project[];
  refresh: () => void;
  globalHeaderRef: RefObject<HTMLDivElement>;
  onCompleteEditCareRecipient: () => void;
};

function pathFor(
  careRecipientId: string,
  projectId: string,
  sheetType: ProjectSheetType,
): string {
  const params = { careRecipientId, projectId };
  switch (sheetType) {
    case ProjectSheetType.ASSESSMENT_SHEET:
      return Paths.assessmentSheet.build(params);
    case ProjectSheetType.TABLE_ONE:
      return Paths.tableOne.build(params);
    case ProjectSheetType.TABLE_TWO:
      return Paths.tableTwo.build(params);
    case ProjectSheetType.TABLE_THREE:
      return Paths.tableTwo.build(params);
  }
}
