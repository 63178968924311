import React from 'react';
import { EnhancedPasswordInput } from './EnhancedPasswordInput';
import { FieldContainer } from './FieldContainer';

type Props = {
  label: string;
  value: string;
  setValue: (value: string) => void;
};

export function PasswordField(props: Props) {
  const { label, value, setValue } = props;

  return (
    <FieldContainer label={label}>
      <EnhancedPasswordInput value={value} setValue={setValue} />
    </FieldContainer>
  );
}
