import { ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateItem } from '../../../../state/usecase/tableone/action';
import { TableOne } from '../../../../domain/careplan/tableone/TableOne';
import { TableOnePath } from '../../../../domain/careplan/tableone/TableOnePath';
import { TableOneUpdaterOf, FieldProps } from './TableOneUpdaterOf';
import { GroupedTableOneUpdater } from './GroupedTableOneUpdater';

export class TableOneUpdater implements TableOneUpdaterOf {
  private readonly projectId: string;

  private readonly tableOne: TableOne | null;

  private readonly activated: boolean;

  constructor(
    projectId: string,
    tableOne: TableOne | null,
    activated: boolean,
  ) {
    this.projectId = projectId;
    this.tableOne = tableOne;
    this.activated = activated;
  }

  of<T>(path: string, build: (props: FieldProps<T>) => ReactNode): ReactNode {
    const pathObject = new TableOnePath(path);

    const [readonly, setReadonly] = useState(false);
    const dispatch = useDispatch();

    const value = this.tableOne?.getValue<T>(pathObject);
    const update = (value: T) => {
      const originalValue = this.tableOne?.getValue<T>(pathObject);
      if (originalValue === undefined) return;
      if (value === originalValue) return;
      setReadonly(true);
      const onSuccess = () => {
        setReadonly(false);
      };
      const onFailure = () => {
        setReadonly(false);
      };
      dispatch(
        updateItem(
          this.projectId,
          pathObject,
          value,
          originalValue,
          onSuccess,
          onFailure,
        ),
      );
    };
    const disabled = value === undefined || !this.activated;

    return build({ path, value, update, disabled, readonly });
  }

  group(
    build: (groupedTableOneUpdater: GroupedTableOneUpdater) => ReactNode,
  ): ReactNode {
    const groupedTableOneUpdater = new GroupedTableOneUpdater(
      this.projectId,
      this.tableOne,
      this.activated,
    );
    return build(groupedTableOneUpdater);
  }
}
