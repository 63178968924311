// 2表叩き台 サービス種別
export class ChatGptSupportServiceType {
  text: string; // 内容
  insuranceBenefit: string; // 保険給付対象
  establishment: string; // 事業所名
  frequency: string; // 頻度
  periodStartDate: string; // 期間開始日
  periodEndDate: string; // 	期間終了日

  constructor(
    text: string,
    insuranceBenefit: string,
    establishment: string,
    frequency: string,
    periodStartDate: string,
    periodEndDate: string,
  ) {
    this.text = text;
    this.insuranceBenefit = insuranceBenefit;
    this.establishment = establishment;
    this.frequency = frequency;
    this.periodStartDate = periodStartDate;
    this.periodEndDate = periodEndDate;
  }
}
