import React, { ReactNode } from 'react';
import { Question } from './Question';
import { Accordion } from '../Accordion';

export function FAQ(props: Props) {
  const { question, children } = props;

  return (
    <Accordion trigger={<Question question={question} />} content={children} />
  );
}

type Props = {
  question: string;
  children: ReactNode;
};
