import React, { useLayoutEffect } from 'react';
import { GlobalHeader } from '../components/GlobalHeader';
import styles from './HelpPage.module.scss';
import { GlobalContainer } from '../components/GlobalContainer';
import { PrimaryButton } from '../components/button/PrimaryButton';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../../Paths';
import { Category } from './Category';
import { SubCategory } from './SubCategory';
import { FAQ } from './Contents/FAQ/FAQ';
import { Answer } from './Contents/FAQ/Answer';
import { Section } from './component/Section';

export function HelpPage() {
  const history = useHistory();

  // React.jsは特に何も対策しないと遷移元のスクロール位置を引き継ぐ。
  // ヘルプ画面でそれはやめておきたいため、初回表示時にリセットする。
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onClickBackToTopPage = () => {
    history.push(Paths.root);
  };

  return (
    <GlobalContainer>
      <GlobalHeader />
      <div className={styles.container}>
        <h1 className={styles.title}>ヘルプ</h1>
        <div className={styles.mainArea}>
          <Category section={Section.FAQ}>
            <SubCategory title={'アセスメント'}>
              <FAQ question={'対応しているアセスメント方式は何でしょうか？'}>
                <Answer>
                  社会福祉法人全国社会福祉協議会様が公開されている居宅サービス計画ガイドラインのみの対応となります。
                </Answer>
              </FAQ>
            </SubCategory>
            <SubCategory title={'文章提案'}>
              <FAQ
                question={
                  '第2表の作成支援機能を利用するためには、必ずアセスメントシートに情報を入力する必要があるのでしょうか？'
                }
              >
                <Answer>
                  アセスメントシートの入力が必須ではありませんが、一部機能がご利用いただけません。ご了承ください。
                </Answer>
              </FAQ>
              <FAQ
                question={
                  '使えば使うほどミルモぷらんの文章提案は良くなっていくのでしょうか？'
                }
              >
                <Answer>
                  リアルタイムにAIがケアプランを学習しているわけではありませんが、蓄積されるデータを活用することで、文章提案精度の向上を目指しています。
                </Answer>
              </FAQ>
              <FAQ
                question={
                  '第2表の生活全般の解決すべき課題（ニーズ）で「提案文言が表示できませんでした。」と表示されます。なぜでしょうか？'
                }
              >
                <Answer>
                  アセスメントシートのみ要介護認定項目を必須項目としています。入力されているか、ご確認ください。
                </Answer>
              </FAQ>
              <FAQ
                question={
                  '長期目標で「提案文言が表示できませんでした。」と表示されます。なぜでしょうか？'
                }
              >
                <Answer>
                  アセスメントシートの要介護認定項目と、該当する行の「生活全般の解決すべき課題（ニーズ）」の入力が必要です。ご確認ください。
                </Answer>
              </FAQ>
              <FAQ
                question={
                  '短期目標で「提案文言が表示できませんでした。」と表示されます。なぜでしょうか？'
                }
              >
                <Answer>
                  アセスメントシートの要介護認定項目と、該当する行の「長期目標」の入力が必要です。ご確認ください。
                </Answer>
              </FAQ>
              <FAQ
                question={
                  'サービス内容で「提案文言が表示できませんでした。」と表示されます。なぜでしょうか？'
                }
              >
                <Answer>
                  アセスメントシートの要介護認定項目と、該当する行の「短期目標」の入力が必要です。ご確認ください。
                </Answer>
              </FAQ>
              <FAQ question={'提案される文章は5つまでですか？'}>
                <Answer>
                  ミルモぷらんが提案できる数は5つまでです。適切な文章が提案にない場合は、編集したいセルをクリックすることで、文章を自由に入力することが可能です。
                </Answer>
              </FAQ>
              <FAQ
                question={
                  '想定していた提案が出てきませんでした。提案された文章以外に入力することはできますか？'
                }
              >
                <Answer>
                  編集したいセルをクリックすることで、文章を自由に入力していただくことが可能です。また、自由入力された文章についてもAIが認識し、次の提案の選択肢に反映されます。
                </Answer>
              </FAQ>
              <FAQ
                question={
                  '提案された文章に◯◯や例示が含まれていますが、どう考えればよいでしょうか？'
                }
              >
                <Answer>
                  ミルモぷらんが提案する文章には、ケアマネジャー様自身で編集していただくことを前提とした文章が含まれます。◯◯の部分を編集していただき、ご利用者様に適した具体的な文章を作成してください。
                  <br />
                  ◯◯に続く括弧内の文章に「：」が含まれている場合があります。「：」の前が抽象的な事柄、後ろがその具体例を指しています。
                  例えば、「〇〇（家事：掃除機掛け・洗濯・毎食の調理・朝食の調理等）ができる。」と提案された場合、◯◯には家事に関する適切な事柄を入力してください。
                </Answer>
              </FAQ>
              <FAQ question={'自分で入力した文章が候補として提案されますか？'}>
                <Answer>
                  入力した文章が候補として提案されることはありません。また、登録する機能もございません。
                </Answer>
              </FAQ>
              <FAQ
                question={'提案される文章は介護的/医学的に正しいものですか？'}
              >
                <Answer>
                  提案される文章は介護的/医学的に正しいことを保証するものではありません。弊社で収集したケアプランデータをAIにより解析し、提案しております。
                </Answer>
              </FAQ>
            </SubCategory>
            <SubCategory title={'ケアプラン'}>
              <FAQ
                question={
                  '第2表の行を追加/削除するときはどうすればよいのでしょうか？'
                }
              >
                <Answer>
                  セルに表示されている「＋ボタン」をクリックすると下に行が追加されます。また、「ーボタン」をクリックすると行が削除されます。
                </Answer>
              </FAQ>
              <FAQ
                question={
                  '第2表の行の並び替えをするときはどうすればよいでしょうか？'
                }
              >
                <Answer>
                  並び替えたいセルの上/下矢印ボタンをクリックしていただくことで並び替えることができます。
                </Answer>
              </FAQ>
              <FAQ
                question={
                  '誤って第2表の内容を削除してしまいました。もとに戻すことはできますか？'
                }
              >
                <Answer>
                  もとに戻す機能はございません。再度入力をお願いいたします。
                </Answer>
              </FAQ>
              <FAQ
                question={
                  '前回の第2表をコピーして新しい第2表を作成するには、どうすればいいでしょうか？'
                }
              >
                <Answer>
                  ケアプラン第2表画面の右上に、「新規作成」というボタンがありますので、そこで「はじめからケアプランを作成する」か「ケアプランをコピーして作成する」かを選択できます。
                </Answer>
              </FAQ>
            </SubCategory>
            <SubCategory title={'知識補助'}>
              <FAQ
                question={
                  '医療情報はどのような監修が行われているのでしょうか？'
                }
              >
                <Answer>
                  疾患の選定、内容の記述は内容について在宅医療の経験を持つ医師が監修しております。ただし、疾患に関する知識情報は医療者専門家によって解釈が異なったり、研究により情報が日々更新されたりすることが考えられるため、情報の正確性・最新性については、各自でご確認の上、ご利用いただきますようお願いいたします。
                </Answer>
              </FAQ>
            </SubCategory>
            <SubCategory title={'利用者一覧'}>
              <FAQ question={'属性とはなんですか？'}>
                <Answer>
                  「契約終了」した利用者は、属性に（終了者）と表示され編集ができなくなります。
                </Answer>
              </FAQ>
            </SubCategory>
            <SubCategory title={'その他'}>
              <FAQ question={'第2表以外の帳表は作成できないのでしょうか？'}>
                <Answer>
                  現在は第2表とアセスメントシート以外は作成できません。今後、機能追加等による対応を検討しております。
                </Answer>
              </FAQ>
              <FAQ question={'「シート切替」とはなんですか？'}>
                <Answer>
                  過去に作成したアセスメントシートと居宅介護サービス（2）に切り替える際に使用する機能です。
                </Answer>
              </FAQ>
              <FAQ
                question={
                  'ミルモぷらんで作成した第2表やアセスメントシートは印刷できますか？'
                }
              >
                <Answer>
                  どちらも、エクセルでダウンロード後、印刷できます。エクセル上ではデータ編集はできませんが、印刷範囲の調整等は可能です。
                </Answer>
              </FAQ>
              <FAQ
                question={
                  'ウェブ上での入力はスマホ、タブレットに対応してるのでしょうか？'
                }
              >
                <Answer>
                  スマホ、タブレットには対応しておりません。ご了承ください。
                </Answer>
              </FAQ>
              <FAQ
                question={
                  '所属のケアマネジャーが変更となる場合、手続きは必要でしょうか？'
                }
              >
                <Answer>
                  お手続きが必要となります。弊社までご連絡ください。
                  <br />
                  ミルモぷらんサポート担当
                  <br />
                  株式会社ウェルモ
                  <br />
                  TEL: 03-6205-7308
                  <br />
                  受付時間 10:00~18:00（平日）
                  <br />
                  e-mail：info_milmoplan@welmo.co.jp
                </Answer>
              </FAQ>
              <FAQ
                question={'解約したいのですが、手続きはどうすれば良いですか？'}
              >
                <Answer>
                  まずは弊社までご連絡ください。お手続きの方法をご案内いたします。
                  <br />
                  ミルモぷらんサポート担当
                  <br />
                  株式会社ウェルモ
                  <br />
                  TEL: 03-6205-7308
                  <br />
                  受付時間 10:00~18:00（平日）
                  <br />
                  e-mail：info_milmoplan@welmo.co.jp
                </Answer>
              </FAQ>
              <FAQ question={'ミルモネットとはどのようなものですか？'}>
                <Answer>
                  ミルモネットは弊社が非収益事業として開発・提供している地域ケア情報見える化サイトです。詳細はこちらをご覧ください。(
                  <a
                    href={'https://welmo.co.jp/service/milmo-net/'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    className={styles.link}
                  >
                    https://welmo.co.jp/service/milmo-net/
                  </a>
                  )
                </Answer>
              </FAQ>
            </SubCategory>
          </Category>
        </div>
        <div className={styles.backToTopButtonArea}>
          <PrimaryButton width={256} onClick={onClickBackToTopPage}>
            トップページに戻る
          </PrimaryButton>
        </div>
      </div>
    </GlobalContainer>
  );
}
