const diseaseCategoryType = Symbol();
type CategoryId =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11';
export class DiseaseCategory {
  [diseaseCategoryType]: any;

  id: CategoryId;

  name: string;

  constructor(id: CategoryId, name: string) {
    this.id = id;
    this.name = name;
  }
}
