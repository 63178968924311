import React, { useState } from 'react';
import { Thumbnail } from '../../../../../domain/establishment/EstablishmentDetail';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Thumbnails.module.scss';
// Sliderコンポーネントで生成されるdivに付与されているクラスに対してスタイルを当てるためのCSS
import './ThumbnailsSlider.scss';
import { LeftArrowButton } from '../../../suggestion/components/establishment/components/LeftArrowButton';
import { RightArrowButton } from '../../../suggestion/components/establishment/components/RightArrowButton';

export function Thumbnails(props: Props) {
  const { thumbnails } = props;

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const [showSlideButton, setShowSlideButton] = useState(false);

  // サムネイルがない場合は事業所情報を左詰で表示するため、このコンポーネントでは何もしない
  if (!thumbnails || !thumbnails.length) return null;

  const settings = {
    // react-slickのbug対応(https://github.com/akiran/react-slick/issues/1171)
    infinite: thumbnails.length > 2,
    speed: 500,
    slidesToScroll: 1,
    arrows: false,
  };

  const previousImage = () => {
    nav2.slickPrev();
  };

  const nextImage = () => {
    nav2.slickNext();
  };

  const onMouseOver = () => {
    setShowSlideButton(true);
  };

  const onMouseLeave = () => {
    setShowSlideButton(false);
  };

  return (
    <div
      className={styles.container}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.slider1}>
        <Slider
          {...settings}
          asNavFor={nav2}
          ref={(slider1) => setNav1(slider1)}
          slidesToShow={1}
        >
          {thumbnails.map((thumbnail) => {
            return (
              <div key={thumbnail.imageUrl} className={styles.mainImage}>
                <img
                  src={thumbnail.imageUrl}
                  alt={thumbnail.imageDescription}
                  className={styles.img}
                />
              </div>
            );
          })}
        </Slider>
      </div>
      <div className={styles.slider2} id={'slider2'}>
        <Slider
          {...settings}
          asNavFor={nav1}
          ref={(slider2) => setNav2(slider2)}
          slidesToShow={3}
          focusOnSelect={true}
        >
          {thumbnails.map((thumbnail) => {
            return (
              <div key={thumbnail.imageUrl} className={styles.sliderImage}>
                <img
                  src={thumbnail.imageUrl}
                  alt={thumbnail.imageDescription}
                  className={styles.img}
                />
              </div>
            );
          })}
        </Slider>
      </div>
      <div className={styles.previousButton}>
        <LeftArrowButton onClick={previousImage} visible={showSlideButton} />
      </div>
      <div className={styles.nextButton}>
        <RightArrowButton onClick={nextImage} visible={showSlideButton} />
      </div>
    </div>
  );
}

type Props = {
  thumbnails: Thumbnail[] | null;
};
