import React, { ButtonHTMLAttributes } from 'react';
import styles from './CareRecipientRegistrationButton.module.scss';
import { PrimaryButton } from '../../components/button/PrimaryButton';

export function CareRecipientRegistrationButton(
  props: ButtonHTMLAttributes<any>,
) {
  return (
    <div className={styles.container}>
      <PrimaryButton width={256} {...props} />
    </div>
  );
}
