import React from 'react';
import { ReactNode } from 'react';
import { Label } from './labels';
import styles from './TableOneContent.module.scss';
import { GroupedTableOneUpdater } from './GroupedTableOneUpdater';
import { FieldProps } from './TableOneUpdaterOf';
import { MultilineTextFieldProps } from '../../components/field/MultilineTextField';
import {
  radioWith,
  multilineTextWith,
  multilineLabelWith,
  labelWith,
} from './fields';

export class TableOneField {
  private readonly activated: boolean | null;

  constructor(activated: boolean) {
    this.activated = activated;
  }

  renderField<T>(
    tableOneUpdater: GroupedTableOneUpdater,
    path: string,
    build: (props: FieldProps<T>) => ReactNode,
    prop: Props = {},
  ): ReactNode {
    if (this.activated) {
      if (prop.tag === undefined || prop.tag) {
        return (
          <div className={styles.field}>
            {tableOneUpdater.of<T>(path, build)}
          </div>
        );
      } else {
        return <>{tableOneUpdater.of<T>(path, build)}</>;
      }
    } else {
      if (prop.tag === undefined || prop.tag) {
        return (
          <div className={styles.labelText}>
            {prop.leading}
            {tableOneUpdater.of<T>(path, labelWith(null))}
            {prop.trailing}
          </div>
        );
      } else {
        return (
          <div className={styles.labelTextPaddingNo}>
            {prop.leading}
            {tableOneUpdater.of<T>(path, labelWith(null))}
            {prop.trailing}
          </div>
        );
      }
    }
  }

  renderDateField<T>(
    tableOneUpdater: GroupedTableOneUpdater,
    path: string,
    build: (props: FieldProps<T>) => ReactNode,
    prop: Props = {},
  ): ReactNode {
    if (this.activated) {
      return (
        <div className={styles.field}>{tableOneUpdater.of<T>(path, build)}</div>
      );
    } else {
      return (
        <div className={styles.labelText}>
          {prop.leading}
          {tableOneUpdater.of<T>(path, labelWith(null))}
          {prop.trailing}
        </div>
      );
    }
  }

  renderTextareaField(
    tableOneUpdater: GroupedTableOneUpdater,
    path: string,
    props: MultilineTextFieldProps,
  ): ReactNode {
    if (this.activated) {
      return (
        <div className={styles.field}>
          {tableOneUpdater.of<string | null>(path, multilineTextWith(props))}
        </div>
      );
    } else {
      return (
        <div className={styles.field}>
          {tableOneUpdater.of<string | null>(path, multilineLabelWith(props))}
        </div>
      );
    }
  }

  renderRadioField<T>(
    tableOneUpdater: GroupedTableOneUpdater,
    path: string,
    values: Label<T>[],
    prop: Props = {},
  ): ReactNode {
    if (this.activated) {
      if (prop.tag === undefined || prop.tag) {
        if (prop.radioPattern === 'vertical') {
          return (
            <div className={styles.field}>
              <div className={styles.vertical}>
                {tableOneUpdater.of<T>(path, radioWith(values))}
              </div>
            </div>
          );
        } else {
          return (
            <div className={styles.field}>
              <div className={styles.horizontal}>
                {tableOneUpdater.of<T>(path, radioWith(values))}
              </div>
            </div>
          );
        }
      } else {
        if (prop.radioPattern === 'vertical') {
          return (
            <div className={styles.vertical}>
              {tableOneUpdater.of<T>(path, radioWith(values))}
            </div>
          );
        } else {
          return (
            <div className={styles.horizontal}>
              {tableOneUpdater.of<T>(path, radioWith(values))}
            </div>
          );
        }
      }
    } else {
      if (prop.tag === undefined || prop.tag) {
        return (
          <div className={styles.labelText}>
            {prop.leading}
            {tableOneUpdater.of<T>(path, labelWith(values))}
            {prop.trailing}
          </div>
        );
      } else {
        return (
          <div className={styles.labelTextPaddingNo}>
            {prop.leading}
            {tableOneUpdater.of<T>(path, labelWith(values))}
            {prop.trailing}
          </div>
        );
      }
    }
  }
}

type Props = {
  leading?: ReactNode;
  trailing?: ReactNode;
  radioPattern?: string;
  tag?: boolean;
};
