import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ProjectApi } from '../../../state/api/project/ProjectApi';

export function RedirectToTableTwo() {
  const { projectId } = useParams<PathParams>();
  const history = useHistory();
  ProjectApi.findProject(projectId).then((project) => {
    history.push(
      `/careRecipients/${project.careRecipientId}/projects/${projectId}/tableTwo`,
    );
  });
  return null;
}

type PathParams = {
  projectId: string;
};
