import React, { ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateItem } from '../../../../../state/usecase/assessmentsheet/action';
import { AssessmentSheet } from '../../../../../domain/assessmentsheet/AssessmentSheet';
import { AssessmentSheetPath } from '../../../../../domain/assessmentsheet/AssessmentSheetPath';
import {
  AssessmentSheetUpdaterOf,
  FieldProps,
} from './AssessmentSheetUpdaterOf';
import { SaveIndicator, SaveIndicatorStatus } from './SaveIndicator';

export class GroupedAssessmentSheetUpdater implements AssessmentSheetUpdaterOf {
  private readonly projectId: string;

  private readonly assessmentSheet: AssessmentSheet | null;

  readonly saveIndicator: ReactNode;

  private readonly setSaveIndicatorStatus: (
    saveIndicatorStatus: SaveIndicatorStatus,
  ) => void = () => {};

  private readonly activated: boolean;

  constructor(
    projectId: string,
    assessmentSheet: AssessmentSheet | null,
    activated: boolean,
  ) {
    this.projectId = projectId;
    this.assessmentSheet = assessmentSheet;
    const [saveIndicatorStatus, setSaveIndicatorStatus] = useState(
      SaveIndicatorStatus.INITIAL,
    );
    this.saveIndicator = <SaveIndicator status={saveIndicatorStatus} />;
    this.setSaveIndicatorStatus = setSaveIndicatorStatus;
    this.activated = activated;
  }

  of<T>(path: string, build: (props: FieldProps<T>) => ReactNode): ReactNode {
    const pathObject = new AssessmentSheetPath(path);

    const [readonly, setReadonly] = useState(false);
    const dispatch = useDispatch();

    const value = this.assessmentSheet?.getValue<T>(pathObject);
    const update = (value: T) => {
      const originalValue = this.assessmentSheet?.getValue<T>(pathObject);
      if (originalValue === undefined) return;
      if (value === originalValue) return;
      setReadonly(true);
      this.setSaveIndicatorStatus(SaveIndicatorStatus.SAVING);
      const onSuccess = () => {
        this.setSaveIndicatorStatus(SaveIndicatorStatus.SUCCESSFUL);
        setReadonly(false);
      };
      const onFailure = () => {
        this.setSaveIndicatorStatus(SaveIndicatorStatus.FAILED);
        setReadonly(false);
      };
      dispatch(
        updateItem(
          this.projectId,
          pathObject,
          value,
          originalValue,
          onSuccess,
          onFailure,
        ),
      );
    };
    const disabled = value === undefined || !this.activated;

    return build({ path, value, update, disabled, readonly });
  }
}
