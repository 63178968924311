export enum EstablishmentDetailSection {
  RINEN_TOKUCHO = 'rinenTokucho',
  EIGYO_JIKAN = 'eigyoJikan',
  SHOKUJI = 'shokuji',
  NYUUYOKU = 'nyuuyoku',
  STAFF = 'staff',
  RYOUKIN = 'ryoukin',
  RECREATION = 'recreation',
  REHABILITATION = 'rehabilitation',
}

export function establishmentDetailSectionId(
  section: EstablishmentDetailSection,
): string {
  return `establishmentDetailSection_${section}`;
}
