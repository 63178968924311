import { Disease } from '../../../domain/diseaseknowledge/Disease';

export class Lookup {
  extractedDiseases: Disease[];

  constructor(extractedDiseases: Disease[]) {
    this.extractedDiseases = extractedDiseases;
  }

  static async extract(response: Response): Promise<Lookup> {
    const json = await response.json();
    return new Lookup(json);
  }
}
