import React, { FormEvent, MouseEvent, useState } from 'react';
import styles from './CareRecipientRegistrationDialog.module.scss';
import { useSelector } from 'react-redux';
import { State } from '../../../../state/store';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../../../Paths';
import { TextField } from '../../components/field/TextField';
import { CloseIcon } from '../../components/icon/CloseIcon';
import { CareRecipientRegistrationField } from './CareRecipientRegistrationField';
import { CareRecipientResponsibleUserField } from './CareRecipientResponsibleUserField';
import { CareRecipientRegistrationButton } from './CareRecipientRegistrationButton';
import { CareRecipientApi } from '../../../../state/api/carerecipient/CareRecipientApi';
import { Register } from '../../../../state/api/carerecipient/Register';

export function CareRecipientRegistrationDialog(props: Props) {
  const { onRequestClose } = props;

  const user = useSelector((state: State) => state.authentication.user);
  const [name, setName] = useState('');
  const [phonetic, setPhonetic] = useState('');
  const [responsibleUserId, setResponsibleUserId] = useState(user?.id);
  const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
  const buttonDisabled =
    fieldDisabled || name === '' || phonetic === '' || !responsibleUserId;
  const history = useHistory();

  const onClickContainer = (event: MouseEvent<any>) => event.stopPropagation();

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (!responsibleUserId) return;
    setFieldDisabled(true);
    CareRecipientApi.register(name, phonetic, responsibleUserId).then((res) => {
      setFieldDisabled(false);
      if (res instanceof Register) {
        const careRecipientId = res.id;
        history.push(Paths.careRecipient.build({ careRecipientId }));
      } else {
        // TODO 利用者登録に失敗時の処理
      }
    });
  };

  return (
    <div className={styles.container} onClick={onClickContainer}>
      <h2 className={styles.title}>利用者を登録する</h2>
      <p className={styles.description}>
        新しく受け入れた利用者の氏名、フリガナを入力し、担当者を選択してください。
      </p>
      <form onSubmit={onSubmit}>
        <CareRecipientRegistrationField label={'氏名'}>
          <TextField
            get={name}
            set={setName}
            required={true}
            maxLength={20}
            disabled={fieldDisabled}
          />
        </CareRecipientRegistrationField>
        <CareRecipientRegistrationField label={'フリガナ'}>
          <TextField
            get={phonetic}
            set={setPhonetic}
            required={true}
            maxLength={30}
            pattern={'^[ァ-ー 　]*$'}
            disabled={fieldDisabled}
          />
        </CareRecipientRegistrationField>
        <CareRecipientRegistrationField label={'担当者'}>
          <CareRecipientResponsibleUserField
            get={responsibleUserId}
            set={setResponsibleUserId}
            required={true}
            disabled={fieldDisabled}
          />
        </CareRecipientRegistrationField>
        <CareRecipientRegistrationButton disabled={buttonDisabled}>
          利用者登録
        </CareRecipientRegistrationButton>
      </form>
      <button className={styles.close} onClick={onRequestClose}>
        <CloseIcon />
      </button>
    </div>
  );
}

type Props = {
  onRequestClose?: () => void;
};
