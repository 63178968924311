import React, { HTMLAttributes } from 'react';
import styles from './CareRecipientNoList.module.scss';

export function CareRecipientNoList(props: HTMLAttributes<HTMLDivElement>) {
  const { children, ...rest } = props;
  return (
    <div className={styles.container} {...rest}>
      <p className={styles.message}>まだ利用者が登録されていません。</p>
      {children}
    </div>
  );
}
