import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { GlobalContainer } from '../components/GlobalContainer';
import { GlobalHeader } from '../components/GlobalHeader';
import 'react-tabs/style/react-tabs.css';
import styles from './AdminHome.module.scss';
import CorporationList from './corporation/CorporationList';
import OfficeList from './office/OfficeList';
import UserList from './user/UserList';
import History from './history/History';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../../Paths';
import { SecondaryButton } from '../components/button/SecondaryButton';

export default function AdminHome() {
  const history = useHistory();

  const onClickAdminNotification = () => {
    history.push(Paths.adminNotification);
  };

  return (
    <>
      <GlobalContainer>
        <GlobalHeader />
        <div className={styles.container}>
          <Tabs>
            <TabList>
              <Tab>法人</Tab>
              <Tab>事業所</Tab>
              <Tab>ユーザー</Tab>
              <Tab>操作情報</Tab>
            </TabList>
            <TabPanel>
              <CorporationList />
            </TabPanel>
            <TabPanel>
              <OfficeList />
            </TabPanel>
            <TabPanel>
              <UserList />
            </TabPanel>
            <TabPanel>
              <History />
            </TabPanel>
          </Tabs>
          <div className={styles.adminNotificationLink}>
            <SecondaryButton onClick={onClickAdminNotification}>
              管理者用お知らせ画面
            </SecondaryButton>
          </div>
        </div>
      </GlobalContainer>
    </>
  );
}
