export class FetchSuggestionShortTermGoals {
  shortTermGoals: string[];

  constructor(shortTermGoals: string[]) {
    this.shortTermGoals = shortTermGoals;
  }

  static async extract(
    response: Response,
  ): Promise<FetchSuggestionShortTermGoals> {
    const json = await response.json();
    return new FetchSuggestionShortTermGoals(json.shortTermGoals);
  }
}
