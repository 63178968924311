import React, { Attributes, RefObject } from 'react';
import styles from './SupportServiceComponent.module.scss';
import { CarePlanTableTwo } from '../../../../domain/careplan/tabletwo/CarePlanTableTwo';
import { LongTermGoal } from '../../../../domain/careplan/tabletwo/longtermgoal/LongTermGoal';
import { Need } from '../../../../domain/careplan/tabletwo/need/Need';
import { ShortTermGoal } from '../../../../domain/careplan/tabletwo/shorttermgoal/ShortTermGoal';
import { SupportService } from '../../../../domain/careplan/tabletwo/supportservice/SupportService';
import { SuggestionSupportServiceDialog } from '../../suggestion/components/SuggestionSupportServiceDialog';
import { TableTwoCellWithIcons } from './TableTwoCellWithIcons';
import { ShiftAction } from '../../../../domain/careplan/tabletwo/ShiftAction';
import { SupportServiceTypeComponent } from './SupportServiceTypeComponent';
import { EditedShortTermGoal } from '../../../../state/api/careplan/tabletwo/shorttermgoal/EditedShortTermGoal';
import { EditedSupoprtService } from '../../../../state/api/careplan/tabletwo/supportservice/EditedSupoprtService';
import { ChangeLog } from '../../../../domain/changelog/ChangeLog';
import { SupportServiceApi } from '../../../../state/api/careplan/tabletwo/supportservice/SupportServiceApi';
import { ShortTermGoalApi } from '../../../../state/api/careplan/tabletwo/shorttermgoal/ShortTermGoalApi';

export function SupportServiceComponent(props: Props) {
  const {
    projectId,
    carePlanTableTwo,
    need,
    longTermGoal,
    shortTermGoal,
    supportService,
    activated,
    refresh,
    tableTwoHeaderRef,
  } = props;

  const update = (supportServiceId: string, text: string | null) => {
    SupportServiceApi.update(supportServiceId, text).then((res) => {
      if (res instanceof EditedSupoprtService) {
        refresh(
          carePlanTableTwo.replaceSupportService(
            need,
            longTermGoal,
            shortTermGoal,
            res.supportService,
          ),
          res.changeLog,
        );
      }
    });
  };

  const handleTextUpdate = (text: string | null) => {
    update(supportService.id, text);
  };

  const handleAdd = () => {
    ShortTermGoalApi.addSupportService(
      shortTermGoal.id,
      supportService.id,
    ).then((res) => {
      if (res instanceof EditedShortTermGoal) {
        refresh(
          carePlanTableTwo.replaceShortTermGoal(
            need,
            longTermGoal,
            res.shortTermGoal,
          ),
          res.changeLog,
        );
      }
    });
  };

  const shiftSupportService = (
    shortTermGoalId: string,
    supportServiceId: string,
    shiftAction: ShiftAction,
  ) => {
    ShortTermGoalApi.shiftSupportService(
      shortTermGoalId,
      supportServiceId,
      shiftAction,
    ).then((res) => {
      if (res instanceof EditedShortTermGoal) {
        refresh(
          carePlanTableTwo.replaceShortTermGoal(
            need,
            longTermGoal,
            res.shortTermGoal,
          ),
          res.changeLog,
        );
      }
    });
  };

  const handleShiftUp = () => {
    shiftSupportService(shortTermGoal.id, supportService.id, ShiftAction.UP);
  };

  const handleShiftDown = () => {
    shiftSupportService(shortTermGoal.id, supportService.id, ShiftAction.DOWN);
  };

  const handleRemove = () => {
    ShortTermGoalApi.removeSupportService(
      shortTermGoal.id,
      supportService.id,
    ).then((res) => {
      if (res instanceof EditedShortTermGoal) {
        refresh(
          carePlanTableTwo.replaceShortTermGoal(
            need,
            longTermGoal,
            res.shortTermGoal,
          ),
          res.changeLog,
        );
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.textCell}>
        <TableTwoCellWithIcons
          initialText={supportService.text}
          isFirstRowCell={shortTermGoal.isFirstSubItem(supportService)}
          isLastRowCell={shortTermGoal.isLastSubItem(supportService)}
          suggestionDialog={(openSuggestionDialog, onSelect) => {
            return (
              <SuggestionSupportServiceDialog
                open={openSuggestionDialog}
                projectId={projectId}
                needText={need.text}
                longTermGoalText={longTermGoal.text}
                shortTermGoalText={shortTermGoal.text}
                onSelect={onSelect}
              />
            );
          }}
          handleTextUpdate={handleTextUpdate}
          handleAdd={handleAdd}
          handleShiftUp={handleShiftUp}
          handleShiftDown={handleShiftDown}
          handleRemove={handleRemove}
          activated={activated}
          tableTwoHeaderRef={tableTwoHeaderRef}
        />
      </div>
      <div className={styles.rest}>
        {supportService.subItems.items.map((supportServiceType) => {
          return (
            <SupportServiceTypeComponent
              key={supportServiceType.id}
              projectId={projectId}
              carePlanTableTwo={carePlanTableTwo}
              need={need}
              longTermGoal={longTermGoal}
              shortTermGoal={shortTermGoal}
              supportService={supportService}
              supportServiceType={supportServiceType}
              activated={activated}
              refresh={refresh}
              tableTwoHeaderRef={tableTwoHeaderRef}
            />
          );
        })}
      </div>
    </div>
  );
}

type Props = Attributes & {
  projectId: string;
  need: Need;
  carePlanTableTwo: CarePlanTableTwo;
  longTermGoal: LongTermGoal;
  shortTermGoal: ShortTermGoal;
  supportService: SupportService;
  activated: boolean;
  refresh: (carePlanTableTwo: CarePlanTableTwo, changeLog: ChangeLog) => void;
  tableTwoHeaderRef: RefObject<HTMLDivElement>;
};
