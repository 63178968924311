import { ChatGptShortTermGoal } from '../shorttermgoal/ChatGptShortTermGoal';

// 2表叩き台 長期目標
export class ChatGptLongTermGoal {
  text: string; // 内容
  periodStartDate: string; // 期間開始日
  periodEndDate: string; // 期間終了日
  shortTermGoals: ChatGptShortTermGoal[] | null; // 短期目標

  constructor(
    text: string,
    periodStartDate: string,
    periodEndDate: string,
    shortTermGoals: ChatGptShortTermGoal[] | null,
  ) {
    this.text = text;
    this.periodStartDate = periodStartDate;
    this.periodEndDate = periodEndDate;
    this.shortTermGoals = shortTermGoals;
  }
}
