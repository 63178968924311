import React, { ReactNode } from 'react';
import styles from './Category.module.scss';
import { labelForSection, Section } from './component/Section';

export function Category(props: Props) {
  const { section, children } = props;
  return (
    <div>
      <div className={styles.titleArea} id={labelForSection(section)}>
        <div className={styles.title}>{labelForSection(section)}</div>
      </div>
      <div>{children}</div>
    </div>
  );
}

type Props = {
  section: Section;
  children: ReactNode;
};
