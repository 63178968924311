import React from 'react';
import styles from './ProjectDropdown.module.scss';
import classNames from 'classnames';
import { Project } from '../../../../domain/project/Project';
import { Link } from 'react-router-dom';
import { displayForProject } from '../../misc/display';
import { jpFormat } from 'dayjs-jp-format';
import dayjs from 'dayjs';

dayjs.extend(jpFormat);

export function ProjectDropdown(props: Props) {
  const { projects, selectedProject, onChange } = props;

  const onClick = (projectId: string) => () => {
    onChange?.(projectId);
  };

  return (
    <ul className={styles.container}>
      {(projects ?? []).map((project) => {
        const selected = project.id === selectedProject?.id;
        const className = classNames(styles.link, {
          [styles.selected]: selected,
        });
        return (
          <li key={project.id}>
            <Link className={className} to={'#'} onClick={onClick(project.id)}>
              {display(project, selected)}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

type Props = {
  projects: Project[] | null;
  selectedProject: Project | null;
  onChange?: (projectId: string) => void;
};

function display(project: Project, selected: boolean): string {
  const display = `${displayForProject(project)} ${project.createdAt
    .toDate()
    .toLocaleDateString('ja-JP-u-ca-japanese', {
      era: 'narrow',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })}に作成`;
  return selected ? `${display} -表示中-` : display;
}
