import React from 'react';
import styles from './TabBar.module.scss';
import { SupportServiceTypeCategory } from '../../../../../domain/establishment/SupportServiceTypeCategory';
import classNames from 'classnames';

export function TabBar(props: Props) {
  const {
    category,
    onChangeTab,
    setScrollTop,
    tsushokeiCount,
    homonkeiCount,
    shortstayCount,
  } = props;

  const onClickTab = (category: SupportServiceTypeCategory) => {
    setScrollTop(0);
    onChangeTab(category);
  };

  return (
    <div className={styles.container}>
      <ul className={styles.tabs}>
        <li className={styles.tab} onClick={() => onClickTab('通所系')}>
          <div
            className={classNames({ [styles.active]: category === '通所系' })}
          >
            通所系
          </div>
          <div className={styles.searchCount}>{tsushokeiCount}</div>
        </li>
        <li className={styles.tab} onClick={() => onClickTab('訪問系')}>
          <div
            className={classNames({ [styles.active]: category === '訪問系' })}
          >
            訪問系
          </div>
          <div className={styles.searchCount}>{homonkeiCount}</div>
        </li>
        <li className={styles.tab} onClick={() => onClickTab('短期入所系')}>
          <div
            className={classNames({
              [styles.active]: category === '短期入所系',
            })}
          >
            短期入所系
          </div>
          <div className={styles.searchCount}>{shortstayCount}</div>
        </li>
      </ul>
    </div>
  );
}

type Props = {
  category: SupportServiceTypeCategory;
  onChangeTab: (category: SupportServiceTypeCategory) => void;
  setScrollTop: (scrollTop: number) => void;
  tsushokeiCount: number;
  homonkeiCount: number;
  shortstayCount: number;
};
