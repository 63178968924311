export class Create {
  id: string;

  constructor(id: string) {
    this.id = id;
  }

  static async extract(response: Response): Promise<Create> {
    const json = await response.json();
    return new Create(json.id);
  }
}
