import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { InputField, InputFieldProps } from './InputField';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './InputField.module.scss';
import calStyles from './Calendar.module.scss';
import ja from 'date-fns/locale/ja';
import dayjs from 'dayjs';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';

export function DateField(props: InternalDateFieldProps) {
  const encode = (value: string) => value;
  const decode = (value: string) => value;

  const [selectedDate, setSelectedDate] = useState<Date>();

  const { get, set, from, to, initialDate, onUpdate: update } = props;
  const pastListUp: number = from ?? 20; // カレンダーに表示する過去の年数（デフォルト20年前から）
  const futureListUp: number = to ?? 5; // カレンダーに表示する未来の年数（デフォルト5年後まで）
  const years: Array<number> = Array.from(
    { length: pastListUp + futureListUp + 1 },
    (v, k) => k + getYear(new Date()) - pastListUp,
  );

  const months = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
  ];

  const japaneseYear = (year: number, date: Date): string => {
    return new Date(year, date.getMonth(), date.getDay()).toLocaleDateString(
      'ja-JP-u-ca-japanese',
      { era: 'long', year: 'numeric' },
    );
  };

  const onDateChange = (date: Date | [Date, Date] | null) => {
    if (date instanceof Date) {
      setSelectedDate(date);
    }
  };

  const value = (): string => {
    if (selectedDate != null) {
      return selectedDate.toLocaleDateString('ja-JP-u-ca-japanese', {
        era: 'narrow',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
    if (get != null && get !== '') {
      return new Date(get).toLocaleDateString('ja-JP-u-ca-japanese', {
        era: 'narrow',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
    if (initialDate != null && initialDate !== '') {
      return new Date(initialDate).toLocaleDateString('ja-JP-u-ca-japanese', {
        era: 'narrow',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
    return '';
  };

  useEffect(() => {
    if (get) {
      setSelectedDate(dayjs(get).toDate());
    }
  }, [get, initialDate]);

  useEffect(() => {
    if (selectedDate) {
      set?.(dayjs(selectedDate).format('YYYY-MM-DD'));
    }
  }, [selectedDate, set]);

  const selectedDateValue = (): Date => {
    return selectedDate ?? dayjs(initialDate).toDate();
  };

  return (
    <InputField encode={encode} decode={decode} {...props}>
      {(_) => (
        <>
          <ReactDatePicker
            wrapperClassName={styles.input}
            selected={selectedDateValue()}
            value={value()}
            onChange={(date) => onDateChange(date)}
            onBlur={() =>
              update(dayjs(selectedDateValue()).format('YYYY-MM-DD'))
            }
            locale={ja}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <>
                <div className={calStyles.prevMonth}>
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    {'<'}
                  </button>
                </div>
                <div className={calStyles.yearMonth}>
                  <select
                    className={calStyles.pointer}
                    value={getYear(date)}
                    onChange={({ target: { value } }) =>
                      changeYear(parseInt(value))
                    }
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {japaneseYear(option, date)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={calStyles.yearMonth}>
                  <select
                    className={calStyles.pointer}
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}月
                      </option>
                    ))}
                  </select>
                </div>
                <div className={calStyles.nextMonth}>
                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    {'>'}
                  </button>
                </div>
              </>
            )}
          />
        </>
      )}
    </InputField>
  );
}

type Props = {
  from?: number;
  to?: number;
  initialDate?: string;
};

export type DateFieldProps = InputFieldProps<InputHTMLAttributes<any>, string> &
  Props;
export type InternalDateFieldProps = DateFieldProps & {
  onUpdate: (value: string) => void;
};
