import React, {
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  TextareaHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import styles from './ChatGptTextarea.module.scss';

export function ChatGptTextarea(props: TextareaHTMLAttributes<any> & Props) {
  const {
    initialText,
    onUpdate,
    activated,
    onChange: givenOnChange,
    onFocus: givenOnFocus,
    onBlur: givenOnBlur,
    onClick: givenOnClick,
    placeholder,
    ...rest
  } = props;

  const [text, setText] = useState(initialText ?? '');

  useEffect(() => {
    setText(initialText ?? '');
  }, [initialText]);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    givenOnChange?.(event);
    setText(event.target.value);
  };

  const onFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    givenOnFocus?.(event);
  };

  const onBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    givenOnBlur?.(event);
    const currentText = text === '' ? null : text;
    if (currentText !== initialText) onUpdate?.(currentText);
  };

  const onClick = (event: MouseEvent<HTMLTextAreaElement>) => {
    givenOnClick?.(event);
  };

  return (
    <>
      {/* 参考: https://qiita.com/tsmd/items/fce7bf1f65f03239eef0 */}
      <div className={styles.wrapper}>
        <textarea
          placeholder={placeholder}
          disabled={!activated}
          className={styles.input}
          value={text}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          {...rest}
        />
      </div>
    </>
  );
}

type Props = {
  initialText: string | null;
  onUpdate?: (text: string | null) => void;
  activated: boolean;
  placeholder?: string;
};
