import { CarePlanTableTwoPeriod } from '../CarePlanTableTwoPeriod';
import { ShortTermGoal } from '../shorttermgoal/ShortTermGoal';
import { SubItems } from '../SubItems';

const LongTermGoalType = Symbol();

// 長期目標
export class LongTermGoal {
  [LongTermGoalType]: any;

  id: string;

  text: string | null;

  period: CarePlanTableTwoPeriod | null;

  subItems: SubItems<ShortTermGoal>;

  constructor(
    id: string,
    text: string | null,
    period: CarePlanTableTwoPeriod | null,
    subItems: SubItems<ShortTermGoal>,
  ) {
    this.id = id;
    this.text = text;
    this.period = period;
    this.subItems = subItems;
  }

  isFirstSubItem(shortTermGoal: ShortTermGoal): boolean {
    return (
      this.subItems.items.findIndex((item) => shortTermGoal === item) === 0
    );
  }

  isLastSubItem(shortTermGoal: ShortTermGoal): boolean {
    return (
      this.subItems.items.findIndex((item) => shortTermGoal === item) ===
      this.subItems.items.length - 1
    );
  }
}
