import { EstablishmentFavorite } from '../../../../domain/establishment/EstablishmentFavorite';

export class FetchEstablishmentFavoriteList {
  favoriteList: EstablishmentFavorite[];

  constructor(favoriteList: EstablishmentFavorite[]) {
    this.favoriteList = favoriteList;
  }

  static async extract(
    response: Response,
  ): Promise<FetchEstablishmentFavoriteList> {
    const json = await response.json();
    return new FetchEstablishmentFavoriteList(json.favoriteEstablishments);
  }
}
