import React, { TextareaHTMLAttributes } from 'react';
import { InputField, InputFieldProps } from './InputField';

export function MultilineTextField(props: MultilineTextFieldProps) {
  const encode = (value: string) => value;
  const decode = (value: string) => value;

  return (
    <InputField encode={encode} decode={decode} {...props}>
      {(props) => <textarea {...props} />}
    </InputField>
  );
}

export type MultilineTextFieldProps = InputFieldProps<
  TextareaHTMLAttributes<any>,
  string
>;
