import React, {
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  ReactNode,
  TextareaHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import styles from './TableTwoTextarea.module.scss';

export function TableTwoTextarea(props: TextareaHTMLAttributes<any> & Props) {
  const {
    initialText,
    onUpdate,
    activated,
    suggestionDialog,
    onChange: givenOnChange,
    onFocus: givenOnFocus,
    onBlur: givenOnBlur,
    onClick: givenOnClick,
    ...rest
  } = props;

  const [text, setText] = useState(initialText ?? '');
  const [openSuggestionDialog, setOpenSuggestionDialog] = useState(false);

  useEffect(() => {
    setText(initialText ?? '');
  }, [initialText]);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    givenOnChange?.(event);
    setText(event.target.value);
  };

  const onFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    givenOnFocus?.(event);
    setOpenSuggestionDialog(true);
  };

  const onBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    givenOnBlur?.(event);
    const currentText = text === '' ? null : text;
    if (currentText !== initialText) onUpdate?.(currentText);
    setOpenSuggestionDialog(false);
  };

  const onClick = (event: MouseEvent<HTMLTextAreaElement>) => {
    givenOnClick?.(event);
    setOpenSuggestionDialog(true);
  };

  const onSelect = (selectedText: string) => {
    // 入力テキストの後ろに選択内容を追加
    onUpdate?.(text + selectedText);
    setOpenSuggestionDialog(false);
  };

  return (
    <>
      {/* 参考: https://qiita.com/tsmd/items/fce7bf1f65f03239eef0 */}
      <div className={styles.wrapper}>
        <div className={styles.content} aria-hidden>
          {text}
          {'\u200b'}
        </div>
        <textarea
          disabled={!activated}
          className={styles.input}
          value={text}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          {...rest}
        />
      </div>
      {suggestionDialog?.(openSuggestionDialog, onSelect)}
    </>
  );
}

type Props = {
  initialText: string | null;
  onUpdate?: (text: string | null) => void;
  activated: boolean;
  suggestionDialog?: (
    open: boolean,
    onSelect: (text: string) => void,
  ) => ReactNode;
};
