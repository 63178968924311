import React, { RefObject, useEffect } from 'react';
import { TableThreeBody } from './components/TableThreeBody';
import { TableThreeHeader } from './components/TableThreeHeader';
import { TableThreeFooter } from './components/TableThreeFooter';
import styles from './TableThreeFragment.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../../state/store';
import { get } from '../../../state/usecase/tablethree/action';

export function TableThreeFragment(props: Props) {
  const { projectId, activated } = props;

  const dispatch = useDispatch();
  const { latestChangeLog } = useSelector(
    (state: State) => state.tableThree.slots[projectId],
  ) ?? {
    latestChangeLog: null,
  };

  const refleshTableThree = () => {
    dispatch(get(projectId));
  };

  useEffect(() => {
    dispatch(get(projectId));
  }, [dispatch, projectId]);

  return (
    <div className={styles.container}>
      <TableThreeHeader
        latestChangeLog={latestChangeLog}
        projectId={projectId}
      />
      <TableThreeBody
        projectId={projectId}
        activated={activated}
        refleshTableThree={refleshTableThree}
      />
      <TableThreeFooter activated={activated} />
    </div>
  );
}

type Props = {
  projectId: string;
  globalHeaderHeight: () => number;
  projectHeaderHeight: () => number;
  globalHeaderRef: RefObject<HTMLDivElement>;
  projectHeaderRef: RefObject<HTMLDivElement>;
  activated: boolean;
};
