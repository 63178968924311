import { useCallback, useEffect, useState } from 'react';
import { LoginHistoryItem } from '../../../../domain/user/LoginHistoryItem';
import { OperationHistoryItem } from '../../../../domain/user/OperationHistoryItem';
import { ProjectsMadeCountItem } from '../../../../domain/user/ProjectsMadeCountItem';
import { AdminHistoryApi } from '../../../../state/api/admin/history/AdminHistoryApi';
import { today } from '../../../../domain/fundamental/LocalDate';
import useMounted from '../../common/useMounted';
import { CareRecipientCreatedCountItem } from '../../../../domain/user/CareRecipientCreatedCountItem';

export default function useHistories() {
  const [loginHistory, setLoginHistory] = useState<LoginHistoryItem[]>([]);
  const [operationHistory, setOperationHistory] = useState<
    OperationHistoryItem[]
  >([]);
  const [projectsMadeCounts, setProjectsMadeCounts] =
    useState<ProjectsMadeCountItem[]>();
  const [careRecipientCreatedHistory, setCareRecipientCreatedHistory] =
    useState<CareRecipientCreatedCountItem[]>();

  const mounted = useMounted();

  const retrieveHistories = useCallback(
    (startDate: string, endDate: string) => {
      AdminHistoryApi.histories(startDate, endDate).then((res) => {
        if (mounted) {
          setLoginHistory(res.loginHistory);
          setOperationHistory(res.operationHistory);
          setProjectsMadeCounts(res.projectsMadeCount);
          setCareRecipientCreatedHistory(res.careRecipientCreatedHistory);
        }
      });
    },
    [mounted],
  );

  useEffect(() => {
    retrieveHistories(
      today().subtract(7, 'day').format('YYYY-MM-DD'),
      today().format('YYYY-MM-DD'),
    );
  }, [retrieveHistories]);

  return {
    loginHistory,
    operationHistory,
    projectsMadeCounts,
    careRecipientCreatedHistory,
    retrieveHistories,
  };
}
