import React, { Attributes, RefObject } from 'react';
import styles from './NeedComponent.module.scss';
import { CarePlanTableTwo } from '../../../../domain/careplan/tabletwo/CarePlanTableTwo';
import { Need } from '../../../../domain/careplan/tabletwo/need/Need';
import { SuggestionNeedDialog } from '../../suggestion/components/SuggestionNeedDialog';
import { ShiftAction } from '../../../../domain/careplan/tabletwo/ShiftAction';
import { TableTwoCellWithIcons } from './TableTwoCellWithIcons';
import { LongTermGoalComponent } from './LongTermGoalComponent';
import { NeedApi } from '../../../../state/api/careplan/tabletwo/need/NeedApi';
import { ChangeLog } from '../../../../domain/changelog/ChangeLog';
import { CarePlanTableTwoApi } from '../../../../state/api/careplan/tabletwo/CarePlanTableTwoApi';
import { EditedCaraPlanTableTwo } from '../../../../state/api/careplan/tabletwo/EditedCaraPlanTableTwo';
import { EditedNeed } from '../../../../state/api/careplan/tabletwo/need/EditedNeed';

export function NeedComponent(props: Props) {
  const {
    projectId,
    carePlanTableTwo,
    need,
    activated,
    refresh,
    tableTwoHeaderRef,
  } = props;

  const onUpdate = (text: string | null) => {
    NeedApi.update(need.id, text).then((res) => {
      if (res instanceof EditedNeed) {
        refresh(carePlanTableTwo.replaceNeed(res.need), res.changeLog);
      }
    });
  };

  const handleAdd = () => {
    CarePlanTableTwoApi.addNeed(projectId, need.id).then((res) => {
      if (res instanceof EditedCaraPlanTableTwo) {
        refresh(res.carePlanTableTwo, res.changeLog);
      }
    });
  };

  const shiftNeed = (
    projectId: string,
    needId: string,
    shiftAction: ShiftAction,
  ) => {
    CarePlanTableTwoApi.shiftNeed(projectId, needId, shiftAction).then(
      (res) => {
        if (res instanceof EditedCaraPlanTableTwo) {
          refresh(res.carePlanTableTwo, res.changeLog);
        }
      },
    );
  };

  const handleShiftUp = () => {
    shiftNeed(projectId, need.id, ShiftAction.UP);
  };

  const handleShiftDown = () => {
    shiftNeed(projectId, need.id, ShiftAction.DOWN);
  };

  const handleRemove = () => {
    CarePlanTableTwoApi.removeNeed(projectId, need.id).then((res) => {
      if (res instanceof EditedCaraPlanTableTwo) {
        refresh(res.carePlanTableTwo, res.changeLog);
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.textCell}>
        <TableTwoCellWithIcons
          initialText={need.text}
          isFirstRowCell={carePlanTableTwo.isFirstSubItem(need)}
          isLastRowCell={carePlanTableTwo.isLastSubItem(need)}
          suggestionDialog={(openSuggestionDialog, onSelect) => {
            return (
              <SuggestionNeedDialog
                open={openSuggestionDialog}
                projectId={projectId}
                onSelect={onSelect}
              />
            );
          }}
          handleTextUpdate={onUpdate}
          handleAdd={handleAdd}
          handleShiftUp={handleShiftUp}
          handleShiftDown={handleShiftDown}
          handleRemove={handleRemove}
          activated={activated}
          tableTwoHeaderRef={tableTwoHeaderRef}
        />
      </div>
      <div className={styles.rest}>
        {need.subItems.items.map((longTermGoal) => {
          return (
            <LongTermGoalComponent
              key={longTermGoal.id}
              projectId={projectId}
              need={need}
              longTermGoal={longTermGoal}
              activated={activated}
              carePlanTableTwo={carePlanTableTwo}
              refresh={refresh}
              tableTwoHeaderRef={tableTwoHeaderRef}
            />
          );
        })}
      </div>
    </div>
  );
}

type Props = Attributes & {
  projectId: string;
  carePlanTableTwo: CarePlanTableTwo;
  need: Need;
  activated: boolean;
  refresh: (carePlanTableTwo: CarePlanTableTwo, changeLog: ChangeLog) => void;
  tableTwoHeaderRef: RefObject<HTMLDivElement>;
};
