import { Establishment } from '../../../../domain/establishment/Establishment';

export class FetchEstablishmentList {
  tsushokeiCount: number;
  homonkeiCount: number;
  shortstayCount: number;
  establishmentList: Establishment[];

  constructor(
    tsushokeiCount: number,
    homonkeiCount: number,
    shortstayCount: number,
    establishmentList: Establishment[],
  ) {
    this.tsushokeiCount = tsushokeiCount;
    this.homonkeiCount = homonkeiCount;
    this.shortstayCount = shortstayCount;
    this.establishmentList = establishmentList;
  }

  static async extract(response: Response): Promise<FetchEstablishmentList> {
    const json = await response.json();
    return new FetchEstablishmentList(
      json.tsushokeiCount,
      json.homonkeiCount,
      json.shortstayCount,
      json.list,
    );
  }
}
