import React from 'react';
import styles from './CareRecipientListScopeByActivatedField.module.scss';
import {
  ToggleField,
  ToggleFieldProps,
} from '../../components/field/ToggleField';

export function CareRecipientListScopeByActivatedField(
  props: ToggleFieldProps,
) {
  return (
    <div className={styles.container}>
      <ToggleField {...props}>利用終了者を表示</ToggleField>
    </div>
  );
}
