export class FetchSuggestionNeeds {
  needs: string[];

  constructor(needs: string[]) {
    this.needs = needs;
  }

  static async extract(response: Response): Promise<FetchSuggestionNeeds> {
    const json = await response.json();
    return new FetchSuggestionNeeds(json.needs);
  }
}
