import React, { RefObject } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../../state/store';
import styles from './TableOneHeader.module.scss';
import { ChangeLog } from '../../../../domain/changelog/ChangeLog';
import { ExcelDownLoadButton } from '../../components/button/ExcelDownloadButton';
import { Paths } from '../../../../Paths';
import { jpFormat } from 'dayjs-jp-format';
import dayjs from 'dayjs';
import { SakuseiKubunContent } from '../../tablecommon/components/SakuseiKubunContent';

dayjs.extend(jpFormat);

export function TableOneHeader(props: Props) {
  const {
    projectId,
    latestChangeLog,
    globalHeaderRef,
    projectHeaderRef,
    activated,
  } = props;

  const rsLoginIn = useSelector(
    (state: State) => state.authentication.rsLoginIn,
  );

  const stylesLastEditedAt = rsLoginIn
    ? styles.lastEditedAtRsLogin
    : styles.lastEditedAt;

  const formatEditedAt = (latestChangeLog: ChangeLog | null) => {
    return latestChangeLog?.when
      .toDate()
      .toLocaleDateString('ja-JP-u-ca-japanese', {
        era: 'narrow',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
  };

  return (
    <div>
      <div className={styles.container}>
        <h2 className={styles.title}>居宅サービス計画(1)</h2>
      </div>
      <div className={styles.container}>
        <div></div>
        <SakuseiKubunContent
          projectId={projectId}
          globalHeaderRef={globalHeaderRef}
          projectHeaderRef={projectHeaderRef}
          activated={activated}
        />
      </div>
      <div className={styles.container}>
        <div></div>
        <div className={styles.menu}>
          {latestChangeLog && (
            <div className={stylesLastEditedAt}>
              最終更新: {formatEditedAt(latestChangeLog)}
            </div>
          )}
          {!rsLoginIn && (
            <ExcelDownLoadButton
              path={Paths.tableOneDownloadExcel.build({ projectId })}
            />
          )}
        </div>
      </div>
    </div>
  );
}

type Props = {
  projectId: string;
  latestChangeLog: ChangeLog | null;
  globalHeaderRef: RefObject<HTMLDivElement>;
  projectHeaderRef: RefObject<HTMLDivElement>;
  activated: boolean;
};
