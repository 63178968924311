import { ReactNode } from 'react';
import { SanteiRiyu } from './types';

export type Label<T> = [T, ReactNode];

export const santeiRiyuLabels: Label<SanteiRiyu | null>[] = [
  [1, '一人暮らし'],
  [2, '家族等が障害、疾病等'],
  [3, 'その他'],
];
