import React, { useState } from 'react';
import styles from './NotificationForm.module.scss';
import { PrimaryButton } from '../../components/button/PrimaryButton';
import { TextField } from '../../components/field/TextField';
import { MultilineTextField } from '../../components/field/MultilineTextField';
import { post } from '../../../../state/api/functions';
import { Notification } from '../../../../domain/notification/Notification';
import { DateField } from '../../components/field/DateField';
import dayjs from 'dayjs';
import DialogBase from './DialogBase';

export default function NotificationForm(props: Props) {
  const { onRequestClose, notification, refreshNotifications, type } = props;

  const [publishedDate, setPublishedDate] = useState(
    notification?.publishedDate || dayjs().format('YYYY-MM-DD'),
  );
  const [title, setTitle] = useState(notification?.title || '');
  const [detail, setDetail] = useState(notification?.detail || '');

  const onClickButton = () => {
    if (type === '登録') {
      const body = { publishedDate, title, detail };
      post('/api/notification/register', body).then(async (_) => {
        refreshNotifications();
        onRequestClose();
      });
    } else {
      const body = { id: notification?.id, publishedDate, title, detail };
      post('/api/notification/update', body).then(async (_) => {
        refreshNotifications();
        onRequestClose();
      });
    }
  };

  const disabled = !publishedDate || !title || !detail;

  return (
    <DialogBase onRequestClose={onRequestClose}>
      <h1 className={styles.title}>お知らせ{type}</h1>
      <div className={styles.sectionTitle}>公開日</div>
      <DateField onUpdate={setPublishedDate} get={publishedDate} />
      <div className={styles.sectionTitle}>タイトル</div>
      <TextField get={title} set={setTitle} maxLength={100} />
      <div className={styles.sectionTitle}>詳細</div>
      <MultilineTextField get={detail} set={setDetail} rows={10} />
      <div className={styles.buttonArea}>
        <PrimaryButton onClick={onClickButton} width={256} disabled={disabled}>
          {type}
        </PrimaryButton>
      </div>
    </DialogBase>
  );
}

type Props = {
  onRequestClose: () => void;
  notification: Notification | null;
  refreshNotifications: () => void;
  type: '登録' | '更新';
};
