import React from 'react';
import styles from './LoginError.module.scss';

// TODO: 近藤が勝手に作っただけなので、ちゃんと下釜くんに見てもらう
export function LoginError() {
  return (
    <p className={styles.container}>
      ログインに失敗しました。入力内容をご確認の上、再度お試しください。
    </p>
  );
}
