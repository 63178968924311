import { CarePlanTableTwoPeriod } from '../CarePlanTableTwoPeriod';
import { SubItems } from '../SubItems';
import { SupportService } from '../supportservice/SupportService';

const ShortTermGoalType = Symbol();

// 短期目標
export class ShortTermGoal {
  [ShortTermGoalType]: any;

  id: string;

  text: string | null;

  period: CarePlanTableTwoPeriod | null;

  subItems: SubItems<SupportService>;

  constructor(
    id: string,
    text: string | null,
    period: CarePlanTableTwoPeriod | null,
    subItems: SubItems<SupportService>,
  ) {
    this.id = id;
    this.text = text;
    this.period = period;
    this.subItems = subItems;
  }

  isFirstSubItem(supportService: SupportService): boolean {
    return (
      this.subItems.items.findIndex((item) => supportService === item) === 0
    );
  }

  isLastSubItem(supportService: SupportService): boolean {
    return (
      this.subItems.items.findIndex((item) => supportService === item) ===
      this.subItems.items.length - 1
    );
  }
}
