import 'whatwg-fetch';
import { ShiftAction } from '../../../../../domain/careplan/tabletwo/ShiftAction';
import { ApiError } from '../../../ApiError';
import { post } from '../../../functions';
import { EditedSupoprtService } from './EditedSupoprtService';

export const SupportServiceApi = new (class {
  async update(
    id: string,
    text: string | null,
  ): Promise<EditedSupoprtService | ApiError> {
    const body = {
      id,
      text,
    };
    const response = await post('/api/tableTwo/supportService/update', body);
    return (response.ok ? EditedSupoprtService.extract : ApiError.extract)(
      response,
    );
  }

  async addSupportServiceType(
    supportServiceId: string,
    supportServiceTypeId: string,
  ): Promise<EditedSupoprtService | ApiError> {
    const body = {
      supportServiceId,
      supportServiceTypeId,
    };
    const response = await post(
      '/api/tableTwo/supportService/addSubItem',
      body,
    );
    return (response.ok ? EditedSupoprtService.extract : ApiError.extract)(
      response,
    );
  }

  async shiftSupportServiceType(
    supportServiceId: string,
    supportServiceTypeId: string,
    action: ShiftAction,
  ): Promise<EditedSupoprtService | ApiError> {
    const body = {
      supportServiceId,
      supportServiceTypeId,
      action,
    };
    const response = await post(
      '/api/tableTwo/supportService/shiftSubItem',
      body,
    );
    return (response.ok ? EditedSupoprtService.extract : ApiError.extract)(
      response,
    );
  }

  async removeSupportServiceType(
    supportServiceId: string,
    supportServiceTypeId: string,
  ): Promise<EditedSupoprtService | ApiError> {
    const body = {
      supportServiceId,
      supportServiceTypeId,
    };
    const response = await post(
      '/api/tableTwo/supportService/removeSubItem',
      body,
    );
    return (response.ok ? EditedSupoprtService.extract : ApiError.extract)(
      response,
    );
  }
})();
