import React from 'react';
import { RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from '../../../state/store';
import { NotFoundPage } from '../misc/NotFoundPage';

export default function AdminOnly(props: RouteProps) {
  const user = useSelector((state: State) => state.authentication.user);
  const { children } = props;

  if (!user || !user.isAdmin) {
    return <NotFoundPage />;
  }
  return <>{children}</>;
}
