import { NonWeeklyService } from '../../../../../domain/careplan/tablethree/nonweeklyservice/NonWeeklyService';

export class Get {
  type: 'SUCCESS' = 'SUCCESS';

  nonWeeklyServices: NonWeeklyService[];

  constructor(nonWeeklyServices: NonWeeklyService[]) {
    this.nonWeeklyServices = nonWeeklyServices;
  }

  static path = '/api/nonWeeklyService/getList';

  static async extract(response: Response): Promise<Get> {
    const json = await response.json();
    return new Get(json.nonWeeklyServices);
  }
}
