import { GlobalHeader } from '../components/GlobalHeader';
import { GlobalContainer } from '../components/GlobalContainer';
import React, { useState } from 'react';
import styles from './AccountSettingPage.module.scss';
import { TextButton } from '../components/button/TextButton';
import { useHistory, useLocation } from 'react-router-dom';
import { Paths } from '../../../Paths';
import { CloseIcon } from '../components/icon/CloseIcon';
import { CircledCheckIcon } from '../components/icon/CircledCheckIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { User } from '../../../domain/user/User';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function AccountSettingPage() {
  const qs: URLSearchParams = useQuery();
  const history = useHistory();
  const myself: User = useSelector(
    (store: RootState) => store.authentication.user,
  ) ?? { id: '', displayName: '', email: '', isAdmin: false };

  const fieldName = (key: string | null): string => {
    if (key === 'displayName') return '表示名';
    if (key === 'email') return 'メールアドレス';
    if (key === 'password') return 'パスワード';
    return '';
  };
  const queryParam: string = fieldName(qs.get('type'));
  const [messageVisible, setMessageVisible] = useState<boolean>(
    queryParam !== '',
  );

  const moveToUpdateDisplayNamePage = () => {
    history.push(Paths.userSettingDisplayName);
  };

  const moveToUpdateEmailPage = () => {
    history.push(Paths.userSettingEmail);
  };

  const moveToUpdatePasswordPage = () => {
    history.push(Paths.userSettingPassword);
  };

  const hideNotification = () => {
    setMessageVisible(false);
  };

  return (
    <GlobalContainer>
      <GlobalHeader />
      <div className={styles.card}>
        <div className={styles.contents}>
          <h2 className={styles.title}>アカウント設定</h2>

          <div className={styles.field}>
            <div className={styles.content}>
              <div className={styles.label}>表示名</div>
              <span>{myself.displayName}</span>
            </div>
            <div className={styles.textButton}>
              <TextButton
                style={{ color: '#0950B4' }}
                onClick={moveToUpdateDisplayNamePage}
              >
                変更
              </TextButton>
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.content}>
              <div className={styles.label}>メールアドレス</div>
              <span>{myself.email}</span>
            </div>
            <div className={styles.textButton}>
              <TextButton
                style={{ color: '#0950B4' }}
                onClick={moveToUpdateEmailPage}
              >
                変更
              </TextButton>
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.content}>
              <div className={styles.label}>パスワード</div>
              <span>********</span>
            </div>
            <div className={styles.textButton}>
              <TextButton
                style={{ color: '#0950B4' }}
                onClick={moveToUpdatePasswordPage}
              >
                変更
              </TextButton>
            </div>
          </div>
        </div>
      </div>
      {messageVisible && (
        <div className={styles.notification}>
          <div className={styles.checked}>
            <CircledCheckIcon />
          </div>
          <div>{queryParam !== '' && `${queryParam}を変更しました`}</div>
          <button className={styles.close} onClick={hideNotification}>
            <CloseIcon />
          </button>
        </div>
      )}
    </GlobalContainer>
  );
}
