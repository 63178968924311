export class Register {
  type: 'SUCCESS' = 'SUCCESS';

  id: string;

  constructor(id: string) {
    this.id = id;
  }

  static path = '/api/careRecipient/register';

  static async extract(response: Response): Promise<Register> {
    const json = await response.json();
    return new Register(json.id);
  }
}
