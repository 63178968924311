import { GetList } from './GetList';
import { ApiError } from '../ApiError';
import { get } from '../functions';

class FetchApiUserApi {
  async getList(): Promise<GetList | ApiError> {
    const response = await get(GetList.path);
    return (response.ok ? GetList.extract : ApiError.extract)(response);
  }
}

export const userApi = new FetchApiUserApi();
