import 'whatwg-fetch';
import { ApiError } from '../ApiError';
import { get, queryString } from '../functions';
import { DiseaseKnowledge } from './DiseaseKnowledge';
import { DiseaseCategories } from './DiseaseCategories';
import { SearchResult } from './SearchResult';
import { Lookup } from './Lookup';

export const DiseaseKnowledgeApi = new (class {
  // 医療知識詳細を取得
  async get(diseaseId: string): Promise<DiseaseKnowledge | ApiError> {
    const params = { diseaseId };
    const response = await get(
      `/api/diseaseKnowledge/get?${queryString(params)}`,
    );
    return (response.ok ? DiseaseKnowledge.extract : ApiError.extract)(
      response,
    );
  }

  // 医療知識一覧を取得
  async getList(): Promise<DiseaseCategories | ApiError> {
    const response = await get('/api/diseaseKnowledge/getList');
    return (response.ok ? DiseaseCategories.extract : ApiError.extract)(
      response,
    );
  }

  // 疾患知識参照
  async lookup(diseaseNames: string[]): Promise<Lookup | ApiError> {
    const params = { diseaseNames };
    const response = await get(
      `/api/diseaseKnowledge/lookup?${queryString(params)}`,
    );
    return (response.ok ? Lookup.extract : ApiError.extract)(response);
  }

  // 医療知識 キーワード検索
  async search(keyword: string): Promise<SearchResult | ApiError> {
    const params = { keyword };
    const response = await get(
      `/api/diseaseKnowledge/search?${queryString(params)}`,
    );
    return (response.ok ? SearchResult.extract : ApiError.extract)(response);
  }
})();
