import React, { useState } from 'react';
import styles from './TableThreeFooter.module.scss';
import {
  AutoSavedIndicator,
  AutoSavedIndicatorStatus,
} from '../../tabletwo/components/AutoSavedIndicator';
import { SecondaryButton } from '../../components/button/SecondaryButton';

export function TableThreeFooter(props: Props) {
  const activated = props.activated;
  const [autoSavedIndicatorStatus, setAutoSavedIndicatorStatus] = useState(
    AutoSavedIndicatorStatus.INITIAL,
  );

  const handleSaveButtonClick = () => {
    if (autoSavedIndicatorStatus === AutoSavedIndicatorStatus.VISIBLE) return; // setTimeoutの重複を防ぐ
    setAutoSavedIndicatorStatus(AutoSavedIndicatorStatus.VISIBLE);
    setTimeout(() => {
      setAutoSavedIndicatorStatus(AutoSavedIndicatorStatus.HIDDEN);
    }, 2000);
  };

  const renderSave = () => {
    if (activated) {
      return (
        <div>
          <AutoSavedIndicator status={autoSavedIndicatorStatus} />
          <SecondaryButton onClick={handleSaveButtonClick}>
            保存
          </SecondaryButton>
        </div>
      );
    }
  };

  return <div className={styles.container}>{renderSave()}</div>;
}

type Props = {
  activated: boolean;
};
