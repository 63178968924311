import React from 'react';
import { NewPasswordField } from './field/NewPasswordField';

type Props = {
  label: string;
  value: string;
  setValue: (value: string) => void;
};

export const containsNumber = (password: string): boolean => {
  const regex = new RegExp('\\d');
  return regex.test(password);
};

export const containsUppercase = (password: string): boolean => {
  const regex = new RegExp('[A-Z]');
  return regex.test(password);
};

export const containsLowercase = (password: string): boolean => {
  const regex = new RegExp('[a-z]');
  return regex.test(password);
};

export const isEightOrMoreCharacters = (password: string): boolean => {
  return password.length >= 8;
};

export function EnhancedNewPasswordField(props: Props) {
  const { label, value, setValue } = props;

  return (
    <NewPasswordField
      label={label}
      value={value}
      setValue={setValue}
      containsNumber={containsNumber(value)}
      containsUppercase={containsUppercase(value)}
      containsLowercase={containsLowercase(value)}
      isEightOrMoreCharacters={isEightOrMoreCharacters(value)}
    />
  );
}
