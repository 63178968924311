import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AutoSavedIndicator,
  AutoSavedIndicatorStatus,
} from '../../tabletwo/components/AutoSavedIndicator';
import { SecondaryButton } from '../../components/button/SecondaryButton';
import styles from './TableOneFooter.module.scss';
import { State } from '../../../../state/store';

export function TableOneFooter(props: Props) {
  const activated = props.activated;
  const [autoSavedIndicatorStatus, setAutoSavedIndicatorStatus] = useState(
    AutoSavedIndicatorStatus.INITIAL,
  );
  const rsLoginIn = useSelector(
    (state: State) => state.authentication.rsLoginIn,
  );

  const handleSaveButtonClick = () => {
    if (autoSavedIndicatorStatus === AutoSavedIndicatorStatus.VISIBLE) return; // setTimeoutの重複を防ぐ
    setAutoSavedIndicatorStatus(AutoSavedIndicatorStatus.VISIBLE);
    setTimeout(() => {
      setAutoSavedIndicatorStatus(AutoSavedIndicatorStatus.HIDDEN);
    }, 2000);
  };

  const renderSave = () => {
    if (activated && !rsLoginIn) {
      return (
        <div className={styles.save}>
          <AutoSavedIndicator status={autoSavedIndicatorStatus} />
          <SecondaryButton onClick={handleSaveButtonClick}>
            保存
          </SecondaryButton>
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <ul className={styles.messages}></ul>
      {renderSave()}
    </div>
  );
}

type Props = {
  activated: boolean;
};
