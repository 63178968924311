// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React from 'react';
import styles from './Section.module.scss';
import { AssessmentSheetUpdater } from './AssessmentSheetUpdater';
import { AssessmentSheetField } from './AssessmentSheetField';
import {
  AruTokidokiaruNai,
  DekiruMimamoriDekinai,
  GaishutsuHindo,
  Genjo,
  JiritsuMimamoriIchibuZen,
  Kaminikusa,
  KazokuJisshi,
  Keikaku,
  Mondai,
  NashiIchibuZen,
  NashiMimamoriIchibuZen,
  Umu,
  YoEnjoKeikaku,
} from './types';
import {
  aruTokidokiaruNaiLabels,
  dekiruMimamoriDekinaiLabels,
  gaishutsuHindoLabels,
  genjoLabels,
  jiritsuMimamoriIchibuZenLabels,
  kaminikusaLabels,
  kazokuJisshiLabels,
  keikakuLabels,
  mondaiLabels,
  nashiIchibuZenLabels,
  nashiMimamoriIchibuZenLabels,
  umuLabels,
  yoEnjoKeikakuLabels,
} from './labels';
import { checkboxWith, textWith } from './fields';

export function SeikatsuKinoSection(props: Props) {
  const { assessmentSheetUpdater, activated } = props;
  const assessmentSheetField = new AssessmentSheetField(activated);

  return (
    <>
      <div className={styles.card}>
        <h3 className={styles.title}>要介護認定項目</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                2-1 移乗
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiMimamoriIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.ijo',
                nashiMimamoriIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                2-2 移動
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiMimamoriIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.ido',
                nashiMimamoriIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                2-3 えん下
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruMimamoriDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.enge',
                dekiruMimamoriDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                2-4 食事摂取
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiMimamoriIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokujiSesshu',
                nashiMimamoriIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                2-5 排尿
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiMimamoriIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.hainyo',
                nashiMimamoriIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                2-6 排便
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiMimamoriIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haiben',
                nashiMimamoriIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                2-7 口腔清潔
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.kokuSeiketsu',
                nashiIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                2-8 洗顔
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sengan',
                nashiIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                2-9 整髪
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.seihatsu',
                nashiIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                2-10 上衣の着脱
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiMimamoriIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.uwagiNoChakudatsu',
                nashiMimamoriIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                2-11 ズボン等の着脱
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiMimamoriIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.zubonNadoNoChakudatsu',
                nashiMimamoriIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                2-12 外出頻度
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<GaishutsuHindo | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.gaishutsuHindo',
                gaishutsuHindoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                2-13 飲水摂取
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<JiritsuMimamoriIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.insuiSesshu',
                jiritsuMimamoriIchibuZenLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（食事）移乗介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.ijoKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.ijoKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.ijoKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.ijoKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（食事）移動介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.idoKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.idoKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.idoKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.idoKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（食事）摂取介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.sesshuKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.sesshuKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.sesshuKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.sesshuKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                現状
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Genjo | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.sesshuKaijoGenjoKeikaku.genjo',
                genjoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Keikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.sesshuKaijoGenjoKeikaku.keikaku',
                keikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（食事）主食</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                現状
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  <div className={styles.horizontal}>
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.shushoku.genjo.futsuShoku',
                      checkboxWith('普通食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.shushoku.genjo.kayuShoku',
                      checkboxWith('粥食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.shushoku.genjo.keikoEiyo',
                      checkboxWith('経口栄養'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.shushoku.genjo.keiKanEiyo',
                      checkboxWith('経管栄養'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.shushoku.genjo.sonota',
                      checkboxWith('その他'),
                    )}
                  </div>
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.shushoku.genjo.sonotaNaiyo',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  <div className={styles.horizontal}>
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.shushoku.keikaku.futsuShoku',
                      checkboxWith('普通食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.shushoku.keikaku.kayuShoku',
                      checkboxWith('粥食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.shushoku.keikaku.keikoEiyo',
                      checkboxWith('経口栄養'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.shushoku.keikaku.keiKanEiyo',
                      checkboxWith('経管栄養'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.shushoku.keikaku.sonota',
                      checkboxWith('その他'),
                    )}
                  </div>
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.shushoku.keikaku.sonotaNaiyo',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（食事）副食</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                現状
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  <div className={styles.horizontal}>
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.fukushoku.genjo.futsuShoku',
                      checkboxWith('普通食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.fukushoku.genjo.kizamiShoku',
                      checkboxWith('刻み食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.fukushoku.genjo.mixerShoku',
                      checkboxWith('ミキサー食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.fukushoku.genjo.sonota',
                      checkboxWith('その他'),
                    )}
                  </div>
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.fukushoku.genjo.sonotaNaiyo',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  <div className={styles.horizontal}>
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.fukushoku.keikaku.futsuShoku',
                      checkboxWith('普通食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.fukushoku.keikaku.kizamiShoku',
                      checkboxWith('刻み食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.fukushoku.keikaku.mixerShoku',
                      checkboxWith('ミキサー食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.fukushoku.keikaku.sonota',
                      checkboxWith('その他'),
                    )}
                  </div>
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.fukushoku.keikaku.sonotaNaiyo',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（食事）特記、解決すべき課題など</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                （食事）特記、解決すべき課題など
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.shokuji.tokkiKaiketsuSuBekiKadaiNado',
                { width: 'auto', rows: 5 },
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（排泄等）準備・後始末</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.jumbiAtoshimatsu.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.jumbiAtoshimatsu.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.jumbiAtoshimatsu.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.jumbiAtoshimatsu.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（排泄等）移乗移動介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.ijoIdoKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.ijoIdoKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.ijoIdoKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.ijoIdoKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（排泄等）排尿介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                現状
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Genjo | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijoGenjoKeikaku.genjoKeikaku.genjo',
                genjoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Keikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijoGenjoKeikaku.genjoKeikaku.keikaku',
                keikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（排泄等）排便介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                現状
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Genjo | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.genjoKeikaku.genjo',
                genjoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Keikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.genjoKeikaku.keikaku',
                keikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（排泄等）口腔清潔介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.kokuSeiketsuKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.kokuSeiketsuKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.kokuSeiketsuKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.kokuSeiketsuKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（排泄等）洗面介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.semmenKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.semmenKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.semmenKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.semmenKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（排泄等）整容介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.seiyoKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.seiyoKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.seiyoKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.seiyoKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（排泄等）更衣介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.koiKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.koiKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.koiKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.koiKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（排泄等）排尿介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                現状
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijoGenjoKeikaku.genjo.toilet',
                    checkboxWith('トイレ'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijoGenjoKeikaku.genjo.portableToilet',
                    checkboxWith('ポータブルトイレ'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijoGenjoKeikaku.genjo.nyoShuKi',
                    checkboxWith('尿収器'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijoGenjoKeikaku.genjo.donyo',
                    checkboxWith('導尿'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijoGenjoKeikaku.genjo.omutsu',
                    checkboxWith('おむつ'),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijoGenjoKeikaku.keikaku.toilet',
                    checkboxWith('トイレ'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijoGenjoKeikaku.keikaku.portableToilet',
                    checkboxWith('ポータブルトイレ'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijoGenjoKeikaku.keikaku.nyoShuKi',
                    checkboxWith('尿収器'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijoGenjoKeikaku.keikaku.donyo',
                    checkboxWith('導尿'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.hainyoKaijoGenjoKeikaku.keikaku.omutsu',
                    checkboxWith('おむつ'),
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（排泄等）排便介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                現状
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.genjo.toilet',
                    checkboxWith('トイレ'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.genjo.portableToilet',
                    checkboxWith('ポータブルトイレ'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.genjo.sashikomiBenki',
                    checkboxWith('差し込み便器'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.genjo.omutsu',
                    checkboxWith('おむつ'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.genjo.tekiben',
                    checkboxWith('摘便'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.genjo.kancho',
                    checkboxWith('浣腸'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.genjo.jinkoKomon',
                    checkboxWith('人工肛門'),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.keikaku.toilet',
                    checkboxWith('トイレ'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.keikaku.portableToilet',
                    checkboxWith('ポータブルトイレ'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.keikaku.sashikomiBenki',
                    checkboxWith('差し込み便器'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.keikaku.omutsu',
                    checkboxWith('おむつ'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.keikaku.tekiben',
                    checkboxWith('摘便'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.keikaku.kancho',
                    checkboxWith('浣腸'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.haibenKaijoGenjoKeikaku.keikaku.jinkoKomon',
                    checkboxWith('人工肛門'),
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（排泄等）特記、解決すべき課題など</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                （排泄等）特記、解決すべき課題など
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.haisetsuNado.tokkiKaiketsuSuBekiKadaiNado',
                { width: 'auto', rows: 5 },
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（外出）移送・外出介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.gaishutsu.isoGaishutsuKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.gaishutsu.isoGaishutsuKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.gaishutsu.isoGaishutsuKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.gaishutsu.isoGaishutsuKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（外出）特記、解決すべき課題など</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                （外出）特記、解決すべき課題など
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.gaishutsu.tokkiKaiketsuSuBekiKadaiNado',
                { width: 'auto', rows: 5 },
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>その他食事の現状</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                食事場所
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.vertical}>
                  <div className={styles.horizontal}>
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiBasho.shokudo',
                      checkboxWith('食堂'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiBasho.kyoshitsuBedJo',
                      checkboxWith('居室ベッド上'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiBasho.futonJo',
                      checkboxWith('布団上'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiBasho.sonotaKyoshitsuNai',
                      checkboxWith('その他居室内'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiBasho.sonota',
                      checkboxWith('その他'),
                    )}
                  </div>
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiBasho.sonotaNaiyo',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                食堂までの段差
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokudoMadeNoDansa',
                umuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                咀嚼の状況
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.vertical}>
                  {assessmentSheetField.renderRadioField<Mondai | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.soshakuNoJokyo.mondai',
                    mondaiLabels,
                    { tag: false },
                  )}
                  {assessmentSheetField.renderRadioField<Kaminikusa | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.soshakuNoJokyo.kamiNikuSa',
                    kaminikusaLabels,
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                食事の内容
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.vertical}>
                  <div className={styles.horizontal}>
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiNoNaiyo.ippanShoku',
                      checkboxWith('一般食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiNoNaiyo.tonyoShoku',
                      checkboxWith('糖尿食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiNoNaiyo.koketsuatsuShoku',
                      checkboxWith('高血圧食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiNoNaiyo.koKaiyoShoku',
                      checkboxWith('抗潰瘍食'),
                    )}
                    {assessmentSheetUpdater.of<boolean>(
                      'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiNoNaiyo.sonota',
                      checkboxWith('その他'),
                    )}
                  </div>
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiNoNaiyo.tonyoShokuCalorie',
                    textWith({
                      width: 280,
                      leading: <span className={styles.modifier}>糖尿食</span>,
                      trailing: (
                        <span className={styles.modifier}>Kカロリー</span>
                      ),
                    }),
                    {
                      tag: false,
                      leading: <span className={styles.leading}>糖尿食</span>,
                      trailing: (
                        <span className={styles.trailing}>Kカロリー</span>
                      ),
                    },
                  )}
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiNoNaiyo.koketsuatsuShokuGram',
                    textWith({
                      width: 280,
                      leading: (
                        <span className={styles.modifier}>高血圧食</span>
                      ),
                      trailing: <span className={styles.modifier}>g</span>,
                    }),
                    {
                      tag: false,
                      leading: <span className={styles.leading}>高血圧食</span>,
                      trailing: <span className={styles.trailing}>g</span>,
                    },
                  )}
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaShokujiNoGenjo.shokujiNoNaiyo.sonotaNaiyo',
                    textWith({
                      width: 280,
                      leading: <span className={styles.modifier}>その他</span>,
                    }),
                    {
                      tag: false,
                      leading: <span className={styles.leading}>その他</span>,
                    },
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>その他排泄の状況</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                尿意
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<AruTokidokiaruNai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaHaisetsuNoJokyo.nyoi',
                aruTokidokiaruNaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                便意
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<AruTokidokiaruNai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seikatsuKino.sonotaHaisetsuNoJokyo.beni',
                aruTokidokiaruNaiLabels,
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

type Props = {
  assessmentSheetUpdater: AssessmentSheetUpdater;
  activated: boolean;
};
