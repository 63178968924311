import React, { RefObject, useEffect, useState } from 'react';
import styles from './NewNotificationBox.module.scss';
import { get } from '../../../state/api/functions';
import { Notification } from '../../../domain/notification/Notification';
import dayjs from 'dayjs';
import useMounted from '../../hooks/common/useMounted';

export default function NewNotificationBox(props: Props) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { containerRef } = props;
  const mounted = useMounted();

  useEffect(() => {
    get('/api/notification/latest').then(async (response) => {
      if (!mounted) return;
      const json = await response.json();
      setNotifications(json.notifications);
    });
  }, [mounted]);

  const render = () => {
    if (!notifications || notifications.length === 0) {
      if (containerRef.current) {
        containerRef.current.style.paddingTop = '40px';
      }
      return null;
    }
    if (containerRef.current) {
      containerRef.current.style.paddingTop = '24px';
    }
    return (
      <div className={styles.container}>
        <div className={styles.title}>新着のお知らせ</div>
        <ul className={styles.listContainer}>
          {notifications.map((notification) => {
            return (
              <li className={styles.row} key={notification.id}>
                <div className={styles.publishedDate}>
                  {dayjs(notification.publishedDate).format('YYYY年MM月DD日')}
                </div>
                <div className={styles.notification}>{notification.title}</div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return <>{render()}</>;
}

type Props = {
  containerRef: RefObject<HTMLDivElement>;
};
