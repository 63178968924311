import React from 'react';
import { del } from '../../../../state/api/functions';
import styles from './DeleteNotificationDialog.module.scss';
import { Notification } from '../../../../domain/notification/Notification';
import { DangerButton } from '../../components/button/DangerButton';
import dayjs from 'dayjs';
import DialogBase from './DialogBase';

export default function DeleteNotificationDialog(props: Props) {
  const { notification, onRequestClose, refreshNotifications } = props;

  const onClick = () => {
    del(`/api/notification/${notification.id}`).then((_) => {
      refreshNotifications();
      onRequestClose();
    });
  };

  return (
    <DialogBase onRequestClose={onRequestClose}>
      <div className={styles.title}>お知らせを削除します</div>
      <div className={styles.sectionTitle}>公開日</div>
      <div>{dayjs(notification.publishedDate).format('YYYY年MM月DD日')}</div>
      <div className={styles.sectionTitle}>タイトル</div>
      <div>{notification.title}</div>
      <div className={styles.sectionTitle}>詳細</div>
      <div className={styles.detail}>{notification.detail}</div>
      <div className={styles.buttonArea}>
        <DangerButton onClick={onClick} width={256}>
          削除
        </DangerButton>
      </div>
    </DialogBase>
  );
}

type Props = {
  notification: Notification;
  onRequestClose: () => void;
  refreshNotifications: () => void;
};
