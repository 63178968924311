import React from 'react';
import styles from './DiseaseKnowledgeDetailIndex.module.scss';
import classNames from 'classnames';
import {
  DiseaseMenuSection,
  diseaseMenuSectionId,
} from '../DiseaseMenuSection';
import { HashLink } from 'react-router-hash-link';

export function DiseaseKnowledgeDetailIndex(props: Props) {
  const { activeSection } = props;

  const renderLink = (
    section: DiseaseMenuSection,
    label: string,
    sectionsForJudge: DiseaseMenuSection[] = [section],
  ) => {
    const to = `#${diseaseMenuSectionId(section)}`;
    const active = activeSection
      ? sectionsForJudge.includes(activeSection)
      : false;
    const className = classNames(styles.link, { [styles.active]: active });
    return (
      <HashLink to={to} smooth className={className}>
        {label}
      </HashLink>
    );
  };

  return (
    <ul className={styles.itemIndex}>
      <li className={styles.itemIndexShikkanTitle}>
        {renderLink(DiseaseMenuSection.ABSTRACT_DISEASE, '疾患の概要', [])}
      </li>
      <li className={styles.itemIndexContents}>
        {renderLink(DiseaseMenuSection.ABSTRACT_DISEASE, '病気の概要')}
      </li>
      <li className={styles.itemIndexContents}>
        {renderLink(DiseaseMenuSection.SYMPTOM, '主な症状')}
      </li>
      <li className={styles.itemIndexContents}>
        {renderLink(
          DiseaseMenuSection.MISLEADING_DISEASE,
          '症状に変化があった場合他に疑った方がいい病気',
        )}
      </li>
      <li className={styles.itemIndexAdviceTitle}>
        {renderLink(
          DiseaseMenuSection.WATCH,
          'ケアマネジメントにおけるアドバイス',
          [],
        )}
      </li>
      <li className={styles.itemIndexContents}>
        {renderLink(DiseaseMenuSection.WATCH, '日常生活の注意点')}
      </li>
      <li className={styles.itemIndexContents}>
        {renderLink(
          DiseaseMenuSection.ASSESMENT_CHECKPOINT,
          'アセスメント作成時のチェックポイント',
        )}
      </li>
      <li className={styles.itemIndexContents}>
        {renderLink(
          DiseaseMenuSection.SUGGESTING_SERVICE,
          '検討を進めるサービス',
        )}
      </li>
      <li className={styles.itemIndexContents}>
        {renderLink(
          DiseaseMenuSection.ADVICE,
          '熟練ケアマネからのワンポイントアドバイス',
        )}
      </li>
    </ul>
  );
}

type Props = {
  activeSection: DiseaseMenuSection | null;
};
