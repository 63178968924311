import React from 'react';

export function CopyButtonIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.90731 2H3.90732C3.30732 2 2.81641 2.49091 2.81641 3.09091V10.1818C2.81641 10.4818 3.06186 10.7273 3.36186 10.7273C3.66186 10.7273 3.90732 10.4818 3.90732 10.1818V3.63636C3.90732 3.33636 4.15277 3.09091 4.45277 3.09091H9.90731C10.2073 3.09091 10.4528 2.84545 10.4528 2.54545C10.4528 2.24545 10.2073 2 9.90731 2ZM10.2291 4.50363L12.8637 7.13818C13.0655 7.33999 13.18 7.61818 13.18 7.90727V12.9091C13.18 13.5091 12.6891 14 12.0891 14H6.08367C5.48367 14 4.99822 13.5091 4.99822 12.9091L5.00367 5.27272C5.00367 4.67272 5.48913 4.18181 6.08913 4.18181H9.45458C9.74367 4.18181 10.0219 4.29636 10.2291 4.50363ZM12.3618 8.00001H9.9073C9.6073 8.00001 9.36185 7.75456 9.36185 7.45456V5.00001L12.3618 8.00001Z"
        fill="#7B7C88"
      />
    </svg>
  );
}
