import React from 'react';

export function LeftArrowIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8875 12.7362C10.7007 13.027 10.3468 13.0865 10.097 12.8691L5.29627 8.69092L5.29086 8.68616C5.2004 8.60543 5.12711 8.50156 5.07667 8.38257C5.02623 8.26358 5 8.13268 5 8C5 7.86732 5.02623 7.73642 5.07667 7.61743C5.12711 7.49844 5.2004 7.39457 5.29086 7.31384L5.29627 7.30908L10.097 3.13094C10.3468 2.91351 10.7007 2.97301 10.8875 3.26382C11.0743 3.55463 11.0232 3.96663 10.7734 4.18406L6.38882 8L10.7734 11.8159C11.0232 12.0334 11.0743 12.4454 10.8875 12.7362Z"
        fill="none"
      />
    </svg>
  );
}
