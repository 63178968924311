import { ShortTermGoal } from '../../../../../domain/careplan/tabletwo/shorttermgoal/ShortTermGoal';
import { shortTermGoal } from './ShortTermGoalObject';
import { ChangeLog } from '../../../../../domain/changelog/ChangeLog';
import { changeLog } from '../../../changelog/LatestChangeLog';

export class EditedShortTermGoal {
  type: 'SUCCESS' = 'SUCCESS';

  shortTermGoal: ShortTermGoal;

  changeLog: ChangeLog;

  constructor(shortTermGoal: ShortTermGoal, changeLog: ChangeLog) {
    this.shortTermGoal = shortTermGoal;
    this.changeLog = changeLog;
  }

  static path = '/api/tableTwo/shortTermGoal/addSubItem';

  static async extract(response: Response): Promise<EditedShortTermGoal> {
    const json = await response.json();
    return new EditedShortTermGoal(
      shortTermGoal(json.shortTermGoal),
      changeLog(json.changeLog),
    );
  }
}
