import React from 'react';
import styles from './Failed.module.scss';

export function Failed() {
  return (
    <div className={styles.container}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 3C7 3 3 7 3 12C3 17 7 21 12 21C17 21 21 17 21 12C21 7 17 3 12 3Z"
          fill="#FF0000"
        />
        <path
          d="M15.6001 8.59922L15.3001 8.29922C15.0001 7.99922 14.6001 7.99922 14.3001 8.29922L10.7001 11.8992L10.4001 12.1992L8.2001 14.3992C7.9001 14.6992 7.9001 15.0992 8.2001 15.3992L8.5001 15.6992C8.8001 15.9992 9.2001 15.9992 9.5001 15.6992L11.7001 13.4992L12.0001 13.1992L15.6001 9.59922C15.9001 9.29922 15.9001 8.89922 15.6001 8.59922Z"
          fill="white"
        />
        <path
          d="M15.4 15.5992L15.7 15.2992C16 14.9992 16 14.5992 15.7 14.2992L12.1 10.6992L11.8 10.3992L9.59995 8.29922C9.29995 7.99922 8.89995 7.99922 8.59995 8.29922L8.29995 8.59922C7.99995 8.89922 7.99995 9.29922 8.29995 9.59922L10.5 11.7992L10.8 12.0992L14.4 15.6992C14.7 15.8992 15.1 15.8992 15.4 15.5992Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
