import { fromPeriodObject, PeriodObject } from '../PeriodObject';
import {
  supportService,
  SupportServiceObject,
} from '../supportservice/SupportServiceObject';
import { ShortTermGoal } from '../../../../../domain/careplan/tabletwo/shorttermgoal/ShortTermGoal';
import { SubItems } from '../../../../../domain/careplan/tabletwo/SubItems';

export type ShortTermGoalObject = {
  id: string;
  details: {
    text: string | null;
    period: PeriodObject | null;
  };
  supportServices: SupportServiceObject[];
};

export function shortTermGoal(object: ShortTermGoalObject): ShortTermGoal {
  return new ShortTermGoal(
    object.id,
    object.details.text,
    object.details.period && fromPeriodObject(object.details.period),
    new SubItems(object.supportServices.map(supportService)),
  );
}
