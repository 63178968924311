import { SupportService } from '../../../../../domain/careplan/tabletwo/supportservice/SupportService';
import { supportService } from './SupportServiceObject';
import { ChangeLog } from '../../../../../domain/changelog/ChangeLog';
import { changeLog } from '../../../changelog/LatestChangeLog';

export class EditedSupoprtService {
  type: 'SUCCESS' = 'SUCCESS';

  supportService: SupportService;

  changeLog: ChangeLog;

  constructor(supportService: SupportService, changeLog: ChangeLog) {
    this.supportService = supportService;
    this.changeLog = changeLog;
  }

  static path = '/api/tableTwo/supportService/update';

  static async extract(response: Response): Promise<EditedSupoprtService> {
    const json = await response.json();
    return new EditedSupoprtService(
      supportService(json.supportService),
      changeLog(json.changeLog),
    );
  }
}
