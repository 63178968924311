import React from 'react';

export function ArrowAboveIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.39572 15.8313C3.95951 15.5511 3.87027 15.0202 4.19641 14.6455L10.4636 7.44441L10.4708 7.43629C10.5918 7.3006 10.7477 7.19067 10.9261 7.11501C11.1046 7.03935 11.301 7 11.5 7C11.699 7 11.8954 7.03935 12.0739 7.11501C12.2523 7.19067 12.4082 7.3006 12.5292 7.43629L12.5364 7.44441L18.8036 14.6455C19.1297 15.0202 19.0405 15.5511 18.6043 15.8313C18.1681 16.1114 17.5501 16.0348 17.2239 15.6601L11.5 9.08323L5.77608 15.6601C5.44995 16.0348 4.83194 16.1114 4.39572 15.8313Z"
        fill="#7B7C88"
      />
    </svg>
  );
}
