import 'whatwg-fetch';
import { CarePlanTableTwoResponse } from './CarePlanTableTwoResponse';
import { ApiError } from '../../ApiError';
import { get, post, queryString } from '../../functions';
import { EditedCaraPlanTableTwo } from './EditedCaraPlanTableTwo';
import { ShiftAction } from '../../../../domain/careplan/tabletwo/ShiftAction';
import { ChatGptNeed } from '../../../../domain/careplan/tabletwo/need/ChatGptTableTwo';
import { RegisterTableTwo } from './RegisterTableTwo';

export const CarePlanTableTwoApi = new (class {
  async get(projectId: string): Promise<CarePlanTableTwoResponse | ApiError> {
    const params = {
      projectId: projectId,
    };
    const response = await get(`/api/tableTwo/get?${queryString(params)}`);
    return (response.ok ? CarePlanTableTwoResponse.extract : ApiError.extract)(
      response,
    );
  }

  async addNeed(
    projectId: string,
    needId: string,
  ): Promise<EditedCaraPlanTableTwo | ApiError> {
    const body = {
      projectId,
      needId,
    };
    const response = await post('/api/tableTwo/addSubItem', body);
    return (response.ok ? EditedCaraPlanTableTwo.extract : ApiError.extract)(
      response,
    );
  }

  async shiftNeed(
    projectId: string,
    needId: string,
    action: ShiftAction,
  ): Promise<EditedCaraPlanTableTwo | ApiError> {
    const body = {
      projectId,
      needId,
      action,
    };
    const response = await post('/api/tableTwo/shiftSubItem', body);
    return (response.ok ? EditedCaraPlanTableTwo.extract : ApiError.extract)(
      response,
    );
  }

  async removeNeed(
    projectId: string,
    needId: string,
  ): Promise<EditedCaraPlanTableTwo | ApiError> {
    const body = {
      projectId,
      needId,
    };
    const response = await post('/api/tableTwo/removeSubItem', body);
    return (response.ok ? EditedCaraPlanTableTwo.extract : ApiError.extract)(
      response,
    );
  }

  async registerTableTwo(
    projectId: string,
    tableTwo: ChatGptNeed[],
  ): Promise<RegisterTableTwo | ApiError> {
    const body = {
      projectId,
      tableTwo,
    };
    const response = await post('/api/tableTwo/registerTableTwo', body);
    return (response.ok ? RegisterTableTwo.extract : ApiError.extract)(
      response,
    );
  }
})();
