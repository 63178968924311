import React from 'react';
import styles from './Icon.module.scss';

type Props = {
  onClick: () => void;
};

export function ClosedEyeIcon(props: Props) {
  const { onClick } = props;

  return (
    <svg
      onClick={onClick}
      className={styles.icon}
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.39909 1.39295C1.08 1.07386 1.08 0.558408 1.39909 0.239318C1.71818 -0.0797725 2.24181 -0.0797725 2.5609 0.239318L15.9054 13.6002C16.2245 13.9193 16.2245 14.4347 15.9054 14.7538C15.5863 15.0729 15.0709 15.0729 14.7518 14.7538L12.5263 12.5284C11.43 12.9538 10.2436 13.1993 8.99998 13.1993C4.90908 13.1993 1.41545 10.6547 0 7.06294C0.629998 5.45112 1.68545 4.06022 3.0109 3.00476L1.39909 1.39295ZM13.0908 7.06277C13.0908 4.80459 11.2581 2.97187 8.99992 2.97187C8.58265 2.97187 8.18174 3.05369 7.79719 3.16823L6.02174 1.39278C6.96265 1.09006 7.96083 0.926422 8.99992 0.926422C13.0908 0.926422 16.5844 3.47096 17.9999 7.05459C17.4354 8.49458 16.5354 9.75458 15.3981 10.7609L12.8945 8.25731C13.009 7.88095 13.0908 7.48004 13.0908 7.06277ZM8.99986 11.1538C6.74169 11.1538 4.90897 9.32111 4.90897 7.06293C4.90897 6.43293 5.05624 5.83566 5.30987 5.31203L6.59442 6.59657C6.56987 6.74384 6.54533 6.8993 6.54533 7.06293C6.54533 8.42111 7.64169 9.51747 8.99986 9.51747C9.1635 9.51747 9.31077 9.49293 9.46623 9.4602L10.7508 10.7447C10.219 11.0066 9.62986 11.1538 8.99986 11.1538ZM11.43 6.79293C11.3073 5.64748 10.4073 4.75566 9.26999 4.63293L11.43 6.79293Z"
        fill="#7B7C88"
      />
    </svg>
  );
}
