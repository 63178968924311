import React from 'react';
import styles from './AddressInfo.module.scss';

export function AddressInfo(props: Props) {
  const { postalCode, address, establishmentNumber, tel } = props;

  return (
    <div className={styles.container}>
      <p className={styles.title}>住所</p>
      <p className={styles.content}>〒 {postalCode}</p>
      <p className={styles.content}>{address}</p>
      <p className={styles.title}>事業所番号</p>
      <p className={styles.content}>{establishmentNumber}</p>
      <p className={styles.title}>TEL</p>
      <p className={styles.tel}>{tel}</p>
    </div>
  );
}

type Props = {
  postalCode: string;
  address: string;
  establishmentNumber: string;
  tel: string;
};
