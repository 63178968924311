import { fromPeriodObject, PeriodObject } from '../PeriodObject';
import { InsuranceBenefit } from '../../../../../domain/careplan/tabletwo/supportservicetype/InsuranceBenefit';
import { SupportServiceType } from '../../../../../domain/careplan/tabletwo/supportservicetype/SupportServiceType';

export type InsuranceBenefitString = 'INCLUDED' | 'EXCLUDED';

export type SupportServiceTypeObject = {
  id: string;
  details: {
    code: string | null;
    text: string | null;
    insuranceBenefit: InsuranceBenefitString | null;
    establishment: string | null;
    frequency: string | null;
    period: PeriodObject | null;
  };
};

export function supportServiceType(
  object: SupportServiceTypeObject,
): SupportServiceType {
  return new SupportServiceType(
    object.id,
    object.details.code,
    object.details.text,
    object.details.insuranceBenefit
      ? insuranceBenefit(object.details.insuranceBenefit)
      : null,
    object.details.establishment,
    object.details.frequency,
    object.details.period && fromPeriodObject(object.details.period),
  );
}

export function insuranceBenefit(
  string: InsuranceBenefitString,
): InsuranceBenefit {
  switch (string) {
    case 'INCLUDED':
      return InsuranceBenefit.INCLUDED;
    case 'EXCLUDED':
      return InsuranceBenefit.EXCLUDED;
  }
}
