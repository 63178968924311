import { ApiError } from '../../ApiError';
import { del, get, post } from '../../functions';
import { Corporation } from '../../../../domain/corporation/Corporation';

export const AdminCorporationApi = new (class {
  async corporations(): Promise<Corporation[]> {
    const response = await get('api/admin/corporations');
    return response.json();
  }

  async delete(corporationId: string): Promise<void> {
    const response = await del(`api/admin/corporation/delete/${corporationId}`);
    if (!response.ok) {
      throw new ApiError(response.status.toString(), response.statusText);
    }
  }

  async update(corporationId: string, corporationName: string): Promise<void> {
    const body = { corporationId, corporationName };
    const response = await post('api/admin/corporation/update', body);
    if (!response.ok) {
      throw new ApiError(response.status.toString(), response.statusText);
    }
  }

  async register(corporationName: string): Promise<void> {
    const body = { corporationName };
    const response = await post('api/admin/corporation/register', body);
    if (!response.ok) {
      throw new ApiError(response.status.toString(), response.statusText);
    }
  }
})();
