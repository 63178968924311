import { MainActivities } from '../../../../../domain/careplan/tablethree/mainactivities/MainActivities';

export class Get {
  type: 'SUCCESS' = 'SUCCESS';

  mainActivities: MainActivities[];

  constructor(mainActicities: MainActivities[]) {
    this.mainActivities = mainActicities;
  }

  static path = '/api/mainActivities/getList';

  static async extract(response: Response): Promise<Get> {
    const json = await response.json();
    return new Get(json.mainActivities);
  }
}
