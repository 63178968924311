export enum DiseaseMenuSection {
  ABSTRACT_DISEASE = 'abstractDisease',
  SYMPTOM = 'symptom',
  MISLEADING_DISEASE = 'misleadingDisease',
  WATCH = 'watch',
  ASSESMENT_CHECKPOINT = 'assessmentCheckpoint',
  SUGGESTING_SERVICE = 'suggestingService',
  ADVICE = 'advice',
}

export function diseaseMenuSectionId(section: DiseaseMenuSection): string {
  return `diseaseMenuSection_${section}`;
}
