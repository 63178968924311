// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React from 'react';
import styles from './Section.module.scss';
import { AssessmentSheetUpdater } from './AssessmentSheetUpdater';
import { AssessmentSheetField } from './AssessmentSheetField';
import { DoBekkyo, Seibetsu } from './types';
import { doBekkyoLabels, seibetsuLabels } from './labels';
import { checkboxWith, textWith } from './fields';

export function KazokuJokyoToInformalNaShienNoJokyoSection(props: Props) {
  const { assessmentSheetUpdater, activated } = props;
  const assessmentSheetField = new AssessmentSheetField(activated);

  return (
    <>
      <div className={styles.card}>
        <h3 className={styles.title}>家族の介護の状況・課題</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族の介護の状況・課題
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.kazokuNoKaigoNoJokyoKadai',
                { width: 'auto', rows: 5 },
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>家族1</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                主たる介護者
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[0].shutaruKaigoSha',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                氏名
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[0].shimei',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                性別
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Seibetsu | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[0].seibetsu',
                seibetsuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                続柄
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[0].tsuzukigara',
                textWith({ width: 70 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                同別居
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DoBekkyo | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[0].doBekkyo',
                doBekkyoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                就労の状況
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[0].shuroNoJokyo',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                健康状態等
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[0].kenkoJotaiNado',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                特記事項
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[0].tokkiJiko',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>家族2</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                主たる介護者
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[1].shutaruKaigoSha',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                氏名
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[1].shimei',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                性別
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Seibetsu | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[1].seibetsu',
                seibetsuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                続柄
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[1].tsuzukigara',
                textWith({ width: 70 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                同別居
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DoBekkyo | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[1].doBekkyo',
                doBekkyoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                就労の状況
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[1].shuroNoJokyo',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                健康状態等
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[1].kenkoJotaiNado',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                特記事項
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[1].tokkiJiko',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>家族3</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                主たる介護者
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[2].shutaruKaigoSha',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                氏名
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[2].shimei',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                性別
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Seibetsu | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[2].seibetsu',
                seibetsuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                続柄
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[2].tsuzukigara',
                textWith({ width: 70 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                同別居
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DoBekkyo | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[2].doBekkyo',
                doBekkyoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                就労の状況
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[2].shuroNoJokyo',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                健康状態等
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[2].kenkoJotaiNado',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                特記事項
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[2].tokkiJiko',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>家族4</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                主たる介護者
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[3].shutaruKaigoSha',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                氏名
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[3].shimei',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                性別
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Seibetsu | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[3].seibetsu',
                seibetsuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                続柄
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[3].tsuzukigara',
                textWith({ width: 70 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                同別居
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DoBekkyo | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[3].doBekkyo',
                doBekkyoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                就労の状況
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[3].shuroNoJokyo',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                健康状態等
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[3].kenkoJotaiNado',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                特記事項
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[3].tokkiJiko',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>家族5</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                主たる介護者
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[4].shutaruKaigoSha',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                氏名
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[4].shimei',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                性別
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Seibetsu | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[4].seibetsu',
                seibetsuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                続柄
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[4].tsuzukigara',
                textWith({ width: 70 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                同別居
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DoBekkyo | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[4].doBekkyo',
                doBekkyoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                就労の状況
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[4].shuroNoJokyo',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                健康状態等
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[4].kenkoJotaiNado',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                特記事項
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.kazokuKoseiToKaigoJokyo.ichiran[4].tokkiJiko',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>活用している支援</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                支援提供者
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.informalNaShienKatsuyoJokyo.katsuyoShiTeIruShien.shienTeikyoSha',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                内容
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.informalNaShienKatsuyoJokyo.katsuyoShiTeIruShien.naiyo',
                { width: 'auto', rows: 5 },
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                特記事項
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.informalNaShienKatsuyoJokyo.katsuyoShiTeIruShien.tokkiJiko',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>
          本人が受けたい支援/今後必要になると思われる支援
        </h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                支援提供者
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.informalNaShienKatsuyoJokyo.honninGaUkeTaiShienKongoHitsuyoNiNaruToOmowaReruShien.shienTeikyoSha',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                内容
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.informalNaShienKatsuyoJokyo.honninGaUkeTaiShienKongoHitsuyoNiNaruToOmowaReruShien.naiyo',
                { width: 'auto', rows: 5 },
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                特記事項
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'kazokuJokyoToInformalNaShienNoJokyo.informalNaShienKatsuyoJokyo.honninGaUkeTaiShienKongoHitsuyoNiNaruToOmowaReruShien.tokkiJiko',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

type Props = {
  assessmentSheetUpdater: AssessmentSheetUpdater;
  activated: boolean;
};
