import React, { MouseEvent, useState } from 'react';
import { FetchEstablishmentApi } from '../../../../../../state/api/suggestion/establishment/EstablishmentApi';
import { EstablishmentFavorite } from '../../../../../../domain/establishment/EstablishmentFavorite';
import { offsetDateTime } from '../../../../../../domain/fundamental/OffsetDateTime';
import { Establishment } from '../../../../../../domain/establishment/Establishment';
import styles from './Favorite.module.scss';
import { BookmarkIcon } from '../../../../components/icon/BookmarkIcon';

export function Favorite(props: Props) {
  const {
    establishment,
    projectId,
    establishmentFavoriteList,
    setEstablishmentFavoriteList,
  } = props;

  const initFavorite = () => {
    const jigyoshobangoList = establishmentFavoriteList.map(
      (value) => value.jigyoshobango,
    );
    return jigyoshobangoList.includes(establishment.jigyoshoJoho.jigyoshobango);
  };
  const [favorite, setFavorite] = useState(initFavorite());

  const onClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (!favorite) {
      FetchEstablishmentApi.establishmentFavoriteRegister(
        projectId,
        establishment.jigyoshoJoho.jigyoshobango,
      );
      setFavorite(true);
      establishmentFavoriteList.push(
        new EstablishmentFavorite(
          projectId,
          establishment.jigyoshoJoho.jigyoshobango,
          offsetDateTime(new Date().toLocaleDateString()),
        ),
      );
      setEstablishmentFavoriteList(establishmentFavoriteList);
    } else {
      FetchEstablishmentApi.establishmentFavoriteDelete(
        projectId,
        establishment.jigyoshoJoho.jigyoshobango,
      );
      setFavorite(false);
      const filterEstablishmentFavoriteList = establishmentFavoriteList.filter(
        (establishmentFavorite) =>
          establishmentFavorite.jigyoshobango !==
          establishment.jigyoshoJoho.jigyoshobango,
      );
      setEstablishmentFavoriteList(filterEstablishmentFavoriteList);
    }
  };

  return (
    <div className={styles.icon} onClick={onClick}>
      <BookmarkIcon checked={initFavorite()} />
    </div>
  );
}

type Props = {
  establishment: Establishment;
  projectId: string;
  establishmentFavoriteList: EstablishmentFavorite[];
  setEstablishmentFavoriteList: (value: EstablishmentFavorite[]) => void;
};
