import React from 'react';

export function BookmarkIcon(props: Props) {
  const { checked } = props;

  return checked ? (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#D9ECFF" />
      <path
        d="M20.1666 8.5H11.8333C10.9166 8.5 10.1666 9.25 10.1666 10.1667V22.3626C10.1666 22.901 10.7172 23.2641 11.2121 23.052L15.7045 21.1266C15.8932 21.0458 16.1067 21.0458 16.2954 21.1266L20.7879 23.052C21.2828 23.2641 21.8333 22.901 21.8333 22.3626V10.1667C21.8333 9.25 21.0833 8.5 20.1666 8.5Z"
        fill="#007EFC"
      />
    </svg>
  ) : (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#F0F4F7" />
      <path
        d="M20.1666 8.5H11.8333C10.9166 8.5 10.1666 9.25 10.1666 10.1667V22.3626C10.1666 22.901 10.7172 23.2641 11.2121 23.052L15.7045 21.1266C15.8932 21.0458 16.1067 21.0458 16.2954 21.1266L20.7879 23.052C21.2828 23.2641 21.8333 22.901 21.8333 22.3626V10.1667C21.8333 9.25 21.0833 8.5 20.1666 8.5Z"
        fill="#A6AEC1"
      />
    </svg>
  );
}

type Props = {
  checked: boolean;
};
