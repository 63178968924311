import React, { useCallback, useState } from 'react';
import styles from './FileUploadArea.module.scss';
import { DownloadIcon } from '../../components/icon/DownloadIcon';
import { CloseIcon } from '../../components/icon/CloseIcon';

export function FileUploadArea(props: Props) {
  const { title, setFile } = props;

  const [file, setAreaFile] = useState<File | undefined>();

  const fileDisabled = !file;
  const fileEnabled = !fileDisabled;

  const onChangeFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      e.target.style.color = '#000111';
      setFile(files[0]);
      setAreaFile(files[0]);
    } else {
      e.target.style.color = 'gray';
      setFile(undefined);
      setAreaFile(undefined);
    }
  }, []);

  const onClearFile = () => {
    const inputValue = document.getElementById(
      'file-input',
    ) as HTMLInputElement;
    if (inputValue) {
      inputValue.style.color = 'gray';
      inputValue.value = '';
      setFile(undefined);
      setAreaFile(undefined);
    }
  };

  const onuploadFile = () => {
    const inputValue = document.getElementById(
      'file-input',
    ) as HTMLInputElement;
    if (inputValue) {
      inputValue.click();
    }
  };

  return (
    <>
      <div className={styles.uploadArea}>
        <div className={styles.iconArea}>
          <button
            id="fileSelect"
            type="button"
            onClick={onuploadFile}
            className={styles.iconButtonArea}
          >
            <span className={styles.uploadIcon}>
              <DownloadIcon />
            </span>
            <span className={styles.uploadTitle}>{title}</span>
          </button>
        </div>
        <div className={styles.inputArea}>
          <input
            type="file"
            accept=".pdf"
            onChange={onChangeFile}
            className={styles.fileInput}
            id="file-input"
          />
          {fileEnabled && (
            <button
              id="fileClear"
              type="button"
              onClick={onClearFile}
              disabled={fileDisabled}
            >
              <CloseIcon />
            </button>
          )}
        </div>
      </div>
    </>
  );
}

type Props = {
  title: string;
  setFile: (value: File | undefined) => void;
};
