import 'whatwg-fetch';
import { ApiError } from '../../../ApiError';
import { get, queryString, post, del } from '../../../functions';
import { Get } from './Get';

export const NonWeeklyServiceApi = new (class {
  async get(carePlanTableThreeId: string): Promise<Get | ApiError> {
    const params = { carePlanTableThreeId };
    const response = await get(`${Get.path}?${queryString(params)}`);
    return (response.ok ? Get.extract : ApiError.extract)(response);
  }

  async register(carePlanTableThreeId: string, ordinal: number) {
    const body = {
      carePlanTableThreeId,
      ordinal,
    };
    const response = await post('/api/nonWeeklyService/register', body);
    if (!response.ok) {
      return ApiError.extract(response);
    }
  }

  async update(
    id: string,
    carePlanTableThreeId: string,
    ordinal: number,
    startDate: string | null,
    endDate: string | null,
    establishmentId: string | null,
    establishmentName: string | null,
    kaigoServiceName: string | null,
    numberOfCredits: string | null,
    productCode: string | null,
  ) {
    const body = {
      id,
      carePlanTableThreeId,
      ordinal,
      startDate,
      endDate,
      establishmentId,
      establishmentName,
      kaigoServiceName,
      numberOfCredits,
      productCode,
    };
    const response = await post('/api/nonWeeklyService/update', body);
    if (!response.ok) {
      return ApiError.extract(response);
    }
  }

  async delete(id: string): Promise<void | ApiError> {
    const response = await del(`/api/nonWeeklyService/delete/${id}`);
    if (!response.ok) {
      throw new ApiError(response.status.toString(), response.statusText);
    }
  }
})();
