import React, { InputHTMLAttributes } from 'react';
import styles from './RadioField.module.scss';
import classNames from 'classnames';

export function RadioField<T>(props: RadioFieldProps<T>) {
  const { disabled, children, for: value, get, set, ...rest } = props;
  const checked = get === value;

  const onChange = () => {
    set?.(value);
  };

  const fieldClassName = classNames(styles.field, {
    [styles.checked]: checked,
    [styles.disabled]: disabled,
  });

  return (
    <label className={fieldClassName}>
      <input
        type={'radio'}
        className={styles.input}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        {...rest}
      />
      <span className={styles.display} />
      <span className={styles.label}>{children}</span>
    </label>
  );
}

type Props<T> = {
  for: T;
  get?: T;
  set?: (value: T) => void;
};

export type RadioFieldProps<T> = InputHTMLAttributes<any> & Props<T>;
