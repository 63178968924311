import React from 'react';
import styles from './Icon.module.scss';

type Props = {
  onClick: () => void;
};

export function OpenedEyeIcon(props: Props) {
  const { onClick } = props;

  return (
    <svg
      onClick={onClick}
      className={styles.icon}
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0.900024C4.90909 0.900024 1.41545 3.44457 0 7.03639C1.41545 10.6282 4.90909 13.1728 9 13.1728C13.0909 13.1728 16.5845 10.6282 18 7.03639C16.5845 3.44457 13.0909 0.900024 9 0.900024ZM8.99999 11.1274C6.74181 11.1274 4.90908 9.29464 4.90908 7.03646C4.90908 4.77828 6.74181 2.94555 8.99999 2.94555C11.2582 2.94555 13.0909 4.77828 13.0909 7.03646C13.0909 9.29464 11.2582 11.1274 8.99999 11.1274ZM6.54553 7.03628C6.54553 5.6781 7.64189 4.58174 9.00007 4.58174C10.3583 4.58174 11.4546 5.6781 11.4546 7.03628C11.4546 8.39446 10.3583 9.49083 9.00007 9.49083C7.64189 9.49083 6.54553 8.39446 6.54553 7.03628Z"
        fill="#7B7C88"
      />
    </svg>
  );
}
