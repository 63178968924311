import React from 'react';

export function EditIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8057 3.75499C14.0657 4.01499 14.0657 4.43499 13.8057 4.69499L12.5857 5.91499L10.0857 3.41499L11.3057 2.19499C11.4303 2.07016 11.5994 2 11.7757 2C11.9521 2 12.1212 2.07016 12.2457 2.19499L13.8057 3.75499ZM1.99902 13.6683V11.6417C1.99902 11.5483 2.03236 11.4683 2.09902 11.4017L9.37236 4.12832L11.8724 6.62832L4.59236 13.9017C4.53236 13.9683 4.44569 14.0017 4.35902 14.0017H2.33236C2.14569 14.0017 1.99902 13.855 1.99902 13.6683Z"
        fill="none"
      />
    </svg>
  );
}
