import {
  supportServiceType,
  SupportServiceTypeObject,
} from '../supportservicetype/SupportServiceTypeObject';
import { SubItems } from '../../../../../domain/careplan/tabletwo/SubItems';
import { SupportService } from '../../../../../domain/careplan/tabletwo/supportservice/SupportService';

export type SupportServiceObject = {
  id: string;
  details: {
    text: string | null;
  };
  supportServiceTypes: SupportServiceTypeObject[];
};

export function supportService(object: SupportServiceObject): SupportService {
  return new SupportService(
    object.id,
    object.details.text,
    new SubItems(object.supportServiceTypes.map(supportServiceType)),
  );
}
