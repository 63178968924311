import { User } from '../../../domain/user/User';

export class GetList {
  type: 'SUCCESS' = 'SUCCESS';

  users: User[];

  constructor(users: User[]) {
    this.users = users;
  }

  static path = '/api/user/getList';

  static async extract(response: Response): Promise<GetList> {
    const json = await response.json();
    return new GetList(json);
  }
}
