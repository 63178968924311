import { OffsetDateTime } from '../fundamental/OffsetDateTime';

const establishmentFavoriteType = Symbol();

export class EstablishmentFavorite {
  [establishmentFavoriteType]: any;

  projectId: string;

  jigyoshobango: string;

  createdAt: OffsetDateTime;

  constructor(
    projectId: string,
    jigyoshobango: string,
    createdAt: OffsetDateTime,
  ) {
    this.projectId = projectId;
    this.jigyoshobango = jigyoshobango;
    this.createdAt = createdAt;
  }
}
