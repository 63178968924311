import React, { ButtonHTMLAttributes } from 'react';
import { LeftArrowIcon } from './LeftArrowIcon';
import styles from './LeftArrowButton.module.scss';
import classNames from 'classnames';

export function LeftArrowButton(props: Props & ButtonHTMLAttributes<any>) {
  const { onClick, visible } = props;
  return (
    <button
      className={classNames(
        styles.container,
        visible ? styles.visible : styles.hidden,
      )}
      onClick={onClick}
    >
      <LeftArrowIcon />
    </button>
  );
}

type Props = {
  visible: boolean;
};
