import React from 'react';

export function DownloadIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99969 4.33325H8.05969C8.65302 4.33325 8.94635 5.05325 8.52635 5.47325L5.46635 8.53325C5.20635 8.79325 4.78635 8.79325 4.52635 8.53325L1.46635 5.47325C1.04635 5.05325 1.34635 4.33325 1.93969 4.33325H2.99969V0.999919C2.99969 0.633252 3.29969 0.333252 3.66635 0.333252H6.33302C6.69969 0.333252 6.99969 0.633252 6.99969 0.999919V4.33325ZM0.999674 11.6666C0.633008 11.6666 0.333008 11.3666 0.333008 10.9999C0.333008 10.6333 0.633008 10.3333 0.999674 10.3333H8.99967C9.36634 10.3333 9.66634 10.6333 9.66634 10.9999C9.66634 11.3666 9.36634 11.6666 8.99967 11.6666H0.999674Z"
        fill="none"
      />
    </svg>
  );
}
