import React, { InputHTMLAttributes, KeyboardEvent } from 'react';
import { InputField, InputFieldProps } from './InputField';

export function PositiveNumberField(props: PositiveNumberFieldProps) {
  const {
    numberType: givenNumberType,
    onKeyPress: givenOnKeyPress,
    ...rest
  } = props;
  const numberType = givenNumberType ?? 'integer';
  const encode = (value: number | null) => value?.toString() ?? '';
  const decode = decodeForNumberType(numberType);
  const transform = transformForNumberType(numberType);
  const permittedChars = permittedCharsForNumberType(numberType);

  // type="number"であっても、"+", "-"の入力を許可されているため、それを防ぐ
  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    givenOnKeyPress?.(event);
    if (!permittedChars.includes(event.key)) event.preventDefault();
  };

  return (
    <InputField encode={encode} decode={decode} transform={transform} {...rest}>
      {(props) => (
        <input
          type={'number'}
          min={0}
          step={1}
          onKeyPress={onKeyPress}
          {...props}
        />
      )}
    </InputField>
  );
}

type NumberType = 'integer' | 'float';

type Props = {
  numberType?: NumberType;
};

export type PositiveNumberFieldProps = InputFieldProps<
  InputHTMLAttributes<any>,
  number | null
> &
  Props;

function decodeForNumberType(
  numberType: NumberType,
): (value: string) => number | null {
  const parseBy = (parse: (value: string) => number) => (value: string) => {
    return value === '' ? null : parse(value);
  };
  switch (numberType) {
    case 'integer':
      return parseBy((value) => parseInt(value, 10));
    case 'float':
      return parseBy(parseFloat);
  }
}

function transformForNumberType(
  numberType: NumberType,
): (value: string) => string {
  switch (numberType) {
    case 'integer':
      return (value) => value.replace(/^0+|[^\d]/g, '');
    case 'float':
      return (value) => value.replace(/^0+|[^\d.]/g, '');
  }
}

function permittedCharsForNumberType(numberType: NumberType): string[] {
  const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  switch (numberType) {
    case 'integer':
      return digits;
    case 'float':
      return [...digits, '.'];
  }
}
