import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './CheckListItem.module.scss';
import { CheckIcon } from './icon/CheckIcon';

type Props = {
  children: ReactNode;
  checked: boolean;
};

export function CheckListItem(props: Props) {
  const { children, checked } = props;

  const stylesText = classNames({
    [styles.checkedText]: checked,
    [styles.text]: !checked,
  });

  return (
    <div className={styles.item}>
      <span className={styles.checkIcon}>
        <CheckIcon checked={checked} />
      </span>
      {<span className={stylesText}>{children}</span>}
    </div>
  );
}
