import React, { MouseEvent, useLayoutEffect, useState } from 'react';
import styles from './ProjectSheetTabBar.module.scss';
import classNames from 'classnames';
import { ProjectSheetType } from '../../../../domain/project/ProjectSheetType';
import { Link } from 'react-router-dom';
import { DynamicPath, Paths } from '../../../../Paths';

export function ProjectSheetTabBar(props: Props) {
  return (
    <ul className={styles.tabs}>
      {assessmentSheetTab(props)}
      {tableOneTab(props)}
      {tableTwoTab(props)}
      {tableThreeTab(props)}
    </ul>
  );
}

type Props = {
  careRecipientId: string;
  projectId: string;
  sheetType: ProjectSheetType;
};

const createTab = (
  tabSheetType: ProjectSheetType,
  label: string,
  dynamicPath: DynamicPath<{ careRecipientId: string; projectId: string }>,
) => {
  return (props: Props) => {
    const { careRecipientId, projectId, sheetType } = props;

    const [scrollTops, setScrollTops] = useState<{
      [ProjectSheetType.ASSESSMENT_SHEET]: number;
      [ProjectSheetType.TABLE_ONE]: number;
      [ProjectSheetType.TABLE_TWO]: number;
      [ProjectSheetType.TABLE_THREE]: number;
    }>({
      [ProjectSheetType.ASSESSMENT_SHEET]: 0,
      [ProjectSheetType.TABLE_ONE]: 0,
      [ProjectSheetType.TABLE_TWO]: 0,
      [ProjectSheetType.TABLE_THREE]: 0,
    });

    useLayoutEffect(() => {
      applyScrollTop(scrollTops[sheetType]);
    }, [sheetType, scrollTops]);

    const className = classNames(styles.tab, {
      [styles.active]: sheetType === tabSheetType,
    });

    const onClick = (event: MouseEvent) => {
      if (sheetType === tabSheetType) event.preventDefault();
      const scrollTop = (document.scrollingElement ?? document.documentElement)
        .scrollTop;
      setScrollTops({
        ...scrollTops,
        [sheetType]: scrollTop,
      });
    };

    return (
      <li className={className}>
        <Link
          to={dynamicPath.build({ careRecipientId, projectId })}
          onClick={onClick}
        >
          {label}
        </Link>
      </li>
    );
  };
};

const assessmentSheetTab = createTab(
  ProjectSheetType.ASSESSMENT_SHEET,
  'アセスメント',
  Paths.assessmentSheet,
);

const tableOneTab = createTab(
  ProjectSheetType.TABLE_ONE,
  '居宅サービス計画(1)',
  Paths.tableOne,
);

const tableTwoTab = createTab(
  ProjectSheetType.TABLE_TWO,
  '居宅サービス計画(2)',
  Paths.tableTwo,
);

const tableThreeTab = createTab(
  ProjectSheetType.TABLE_THREE,
  '週間サービス計画(3)',
  Paths.tableThree,
);

function applyScrollTop(scrollTop: number) {
  (document.scrollingElement ?? document.documentElement).scrollTop = scrollTop;
}
