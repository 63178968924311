const tableThreeType = Symbol();

export class TableThree {
  [tableThreeType]: any;

  id: string;
  projectId: string;

  constructor(id: string, projectId: string) {
    this.id = id;
    this.projectId = projectId;
  }
}
