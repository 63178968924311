import { Disease } from '../../../domain/diseaseknowledge/Disease';
import { DiseaseCategory } from '../../../domain/diseaseknowledge/DiseaseCategory';
import { DiseaseCategoryWithDisease } from '../../../domain/diseaseknowledge/DiseaseCategoryWithDisease';

export class DiseaseCategories {
  diseaseCategories: DiseaseCategoryWithDisease[];

  constructor(diseaseCategories: DiseaseCategoryWithDisease[]) {
    this.diseaseCategories = diseaseCategories;
  }

  static async extract(response: Response): Promise<DiseaseCategories> {
    const json = await response.json();
    const categories = json.map(diseaseCategories);
    return new DiseaseCategories(categories);
  }
}

type Item = {
  category: DiseaseCategory;
  diseases: ItemDisease[];
};

type ItemDisease = {
  disease: Disease;
};

function diseaseCategories(item: Item): DiseaseCategoryWithDisease {
  return new DiseaseCategoryWithDisease(
    item.category,
    item.diseases.map((itemDisease) => {
      return new Disease(itemDisease.disease.id, itemDisease.disease.name);
    }),
  );
}
