import React, { FormEvent, MouseEvent, useState } from 'react';
import styles from './ProjectCreationDialog.module.scss';
import { Project } from '../../../../domain/project/Project';
import { RadioField } from '../../components/field/RadioField';
import { PrimaryButton } from '../../components/button/PrimaryButton';
import { CloseIcon } from '../../components/icon/CloseIcon';
import { displayForProject } from '../../misc/display';
import { ProjectApi } from '../../../../state/api/project/ProjectApi';
import { Create } from '../../../../state/api/project/Create';

export function ProjectCreationDialog(props: Props) {
  const { selectedProject, onCreate, onRequestClose } = props;

  const [fromScratch, setFromScratch] = useState<boolean | null>(null);
  const [onCreating, setOnCreating] = useState<boolean>(false);
  const buttonDisabled = onCreating || !selectedProject || fromScratch === null;

  const onClickContainer = (event: MouseEvent<any>) => event.stopPropagation();

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (!selectedProject || fromScratch === null) return;
    setOnCreating(true);
    ProjectApi.create(selectedProject.id, fromScratch).then((res) => {
      setOnCreating(false);
      if (res instanceof Create) {
        onCreate?.(res.id);
        onRequestClose?.();
      }
    });
  };

  return (
    <div className={styles.container} onClick={onClickContainer}>
      <h2 className={styles.title}>ケアプランを新しく作成</h2>
      <p className={styles.description}>
        新しいケアプランを作成します。初めから作成するか、現在表示しているケアプランを複製するか選べます。
      </p>
      <ul className={styles.values}>
        <li>
          <RadioField
            for={true}
            get={fromScratch}
            set={setFromScratch}
            readOnly={onCreating}
          >
            初めからケアプランを作成
          </RadioField>
        </li>
        <li>
          <RadioField
            for={false}
            get={fromScratch}
            set={setFromScratch}
            readOnly={onCreating}
          >
            表示中
            {selectedProject && `（${displayForProject(selectedProject)}）`}
            のケアプランを複製
          </RadioField>
        </li>
      </ul>
      <form onSubmit={onSubmit}>
        <div className={styles.action}>
          <PrimaryButton width={256} disabled={buttonDisabled}>
            新規作成
          </PrimaryButton>
        </div>
      </form>
      <button className={styles.close} onClick={onRequestClose}>
        <CloseIcon />
      </button>
    </div>
  );
}

type Props = {
  selectedProject: Project | null;
  onCreate?: (projectId: string) => void;
  onRequestClose?: () => void;
};
