import React, {
  useEffect,
  useCallback,
  useRef,
  RefObject,
  useState,
  MouseEvent,
} from 'react';
import styles from './DiseaseKnowledgeDetailDialog.module.scss';
import { LeftArrowIcon } from '../../components/icon/LeftArrowIcon';
import { DiseaseCategory } from '../DiseaseCategory';
import {
  DiseaseMenuSection,
  diseaseMenuSectionId,
} from '../DiseaseMenuSection';
import { DiseaseKnowledgeDetailIndex } from './DiseaseKnowledgeDetailIndex';
import { SecondaryButton } from '../../components/button/SecondaryButton';
import { DiseaseKnowledgeApi } from '../../../../state/api/diseaseknowledge/DiseaseKnowledgeApi';
import { DiseaseKnowledge } from '../../../../state/api/diseaseknowledge/DiseaseKnowledge';
import { DiseaseDetail } from '../../../../domain/diseaseknowledge/DiseaseDetail';
import { DiseaseKnowledgeDialogMode } from '../../components/MedicalKnowledgeButton';

export function DiseaseKnowledgeDetailDialog(props: Props) {
  const { diseaseId, dialogContainerRef, setDialogMode } = props;
  const [disease, setDisease] = useState<DiseaseDetail>();

  const [activeSection, setActiveSection] = useState<DiseaseMenuSection | null>(
    DiseaseMenuSection.ABSTRACT_DISEASE,
  );
  const abstractDiseaseSectionRef = useRef<HTMLHeadingElement>(null);
  const symptomRef = useRef<HTMLHeadingElement>(null);
  const misleadingDiseaseRef = useRef<HTMLHeadingElement>(null);
  const watchRef = useRef<HTMLHeadingElement>(null);
  const assessmentCheckpointRef = useRef<HTMLHeadingElement>(null);
  const suggestingServiceRef = useRef<HTMLHeadingElement>(null);
  const adviceRef = useRef<HTMLHeadingElement>(null);
  const sectionRefs = [
    {
      section: DiseaseMenuSection.ABSTRACT_DISEASE,
      value: abstractDiseaseSectionRef,
    },
    { section: DiseaseMenuSection.SYMPTOM, value: symptomRef },
    {
      section: DiseaseMenuSection.MISLEADING_DISEASE,
      value: misleadingDiseaseRef,
    },
    { section: DiseaseMenuSection.WATCH, value: watchRef },
    {
      section: DiseaseMenuSection.ASSESMENT_CHECKPOINT,
      value: assessmentCheckpointRef,
    },
    {
      section: DiseaseMenuSection.SUGGESTING_SERVICE,
      value: suggestingServiceRef,
    },
    { section: DiseaseMenuSection.ADVICE, value: adviceRef },
  ];
  const indexRef = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(() => {
    const dialogScrollHeight = dialogContainerRef.current?.scrollHeight ?? 0;
    const dialogScrollTop = dialogContainerRef.current?.scrollTop ?? 0;
    const dialogOffsetHeight = dialogContainerRef.current?.offsetHeight ?? 0;
    const dialogScrollBottom = dialogScrollTop + dialogOffsetHeight;
    if (sectionRefs.every((sectionRef) => sectionRef.value.current)) {
      const sectionOffsetBottoms = sectionRefs.map((sectionRef) => {
        const { section, value: ref } = sectionRef;
        const offsetTop = ref.current!.offsetTop;
        const offsetHeight = ref.current!.offsetHeight;
        const offsetBottom = offsetTop + offsetHeight;
        return { section, offsetTop, offsetBottom };
      });
      sectionOffsetBottoms.forEach((sectionOffsetBottom) => {
        // スクロールエリアの上半分の場合
        if (dialogScrollTop < dialogScrollHeight / 2) {
          // スクロールTopが要素のTopを越えている場合
          if (dialogScrollTop > sectionOffsetBottom.offsetTop - 50)
            setActiveSection(sectionOffsetBottom.section);
        } else {
          if (dialogScrollBottom > sectionOffsetBottom.offsetBottom - 50)
            setActiveSection(sectionOffsetBottom.section);
        }
      });
    }
    if (indexRef.current) {
      stickyIndexIf(dialogScrollTop, indexRef.current);
    }
  }, [sectionRefs, dialogContainerRef]);

  useEffect(() => {
    const current = dialogContainerRef.current;
    current?.addEventListener('scroll', onScroll);
    return () => {
      current?.removeEventListener('scroll', onScroll);
    };
  }, [dialogContainerRef, onScroll]);

  useEffect(() => {
    let mounted = true;
    DiseaseKnowledgeApi.get(diseaseId).then((res) => {
      if (res instanceof DiseaseKnowledge && mounted) {
        setDisease(res.disease);
      }
    });
    return () => {
      mounted = false;
    };
  }, [diseaseId]);

  const openListDialogButton = () => {
    setDialogMode('list');
  };

  const onClickContainer = (event: MouseEvent<any>) => event.stopPropagation();

  const itemContent = (itemContents: string[]) => {
    return (
      <ul className={styles.itemContents}>
        {itemContents.map((value, index) => {
          return <li key={index}>{value}</li>;
        })}
      </ul>
    );
  };

  const detailContent = (
    menuSection: DiseaseMenuSection,
    elementRef: React.RefObject<HTMLHeadingElement>,
    itemName: string,
    itemContents: string[],
  ) => {
    return (
      <>
        <h3
          className={styles.itemName}
          id={diseaseMenuSectionId(menuSection)}
          ref={elementRef}
        >
          {itemName}
        </h3>
        {itemContent(itemContents)}
      </>
    );
  };

  const detailContentWatch = () => {
    if (!disease) return null;
    return (
      <>
        <h3
          className={styles.itemName}
          id={diseaseMenuSectionId(DiseaseMenuSection.WATCH)}
          ref={watchRef}
        >
          日常生活の注意点
        </h3>
        <h4 className={styles.subItemName}>【服薬に関して】</h4>
        {itemContent(disease.watch.medicines)}
        <h4 className={styles.subItemName}>【食事に関して】</h4>
        {itemContent(disease.watch.meals)}
        <h4 className={styles.subItemName}>【生活・環境に関して】</h4>
        {itemContent(disease.watch.lifestyleAndEnvironments)}
        <h4 className={styles.subItemName}>
          【介護者側が注意すべきことに関して】
        </h4>
        {itemContent(disease.watch.carestaffs)}
        <h4 className={styles.subItemName}>【その他】</h4>
        {itemContent(disease.watch.others)}
      </>
    );
  };

  const detailContentAssessmentCheckpoint = () => {
    if (!disease) return null;
    return (
      <>
        <h3
          className={styles.itemName}
          id={diseaseMenuSectionId(DiseaseMenuSection.ASSESMENT_CHECKPOINT)}
          ref={assessmentCheckpointRef}
        >
          アセスメント作成時のチェックポイント
        </h3>
        <h4 className={styles.subItemName}>
          【心身機能・身体構造（身体機能、運動機能、精神機能に関すること等）】
        </h4>
        {itemContent(disease.assessmentCheckpoint.impairments)}
        <h4 className={styles.subItemName}>
          【活動（起床、買い物、コミュニケーションに関すること等）】
        </h4>
        {itemContent(disease.assessmentCheckpoint.activities)}
        <h4 className={styles.subItemName}>
          【参加（地域での活動や、対人関係に関すること等）】
        </h4>
        {itemContent(disease.assessmentCheckpoint.participations)}
        <h4 className={styles.subItemName}>
          【環境因子（福祉用具の使用状況、家族などの援助者について等）】
        </h4>
        {itemContent(disease.assessmentCheckpoint.environments)}
        <h4 className={styles.subItemName}>
          【個人因子（個人の価値観、趣味、ライフスタイルについて等）】
        </h4>
        {itemContent(disease.assessmentCheckpoint.personals)}
      </>
    );
  };

  const detailContentSuggestingService = () => {
    return (
      <>
        <h3
          className={styles.itemName}
          id={diseaseMenuSectionId(DiseaseMenuSection.SUGGESTING_SERVICE)}
          ref={suggestingServiceRef}
        >
          検討をすすめるサービス
        </h3>
        {disease &&
          disease.suggestingServices.map((suggestingService, index) => {
            return (
              <div key={index}>
                <h4
                  className={styles.subItemName}
                >{`【${suggestingService.title}】`}</h4>
                <ul className={styles.itemContents}>
                  <li>{suggestingService.detail}</li>
                </ul>
              </div>
            );
          })}
      </>
    );
  };

  const detail = () => {
    if (!disease) return null;
    return (
      <>
        <h2 className={styles.title}>
          {disease.name}
          <div className={styles.diseaseCategory}>
            <DiseaseCategory categoryId={disease.category.id} />
            <span>{disease.category.name}</span>
          </div>
        </h2>
        <div className={styles.columns}>
          <div className={styles.content}>
            {detailContent(
              DiseaseMenuSection.ABSTRACT_DISEASE,
              abstractDiseaseSectionRef,
              '病気の概要',
              disease.abstractDiseases,
            )}
            {detailContent(
              DiseaseMenuSection.SYMPTOM,
              symptomRef,
              '主な症状',
              disease.symptoms,
            )}
            {detailContent(
              DiseaseMenuSection.MISLEADING_DISEASE,
              misleadingDiseaseRef,
              '症状に変化があった場合他に疑った方が良い病気',
              disease.misleadingDiseases,
            )}
            {detailContentWatch()}
            {detailContentAssessmentCheckpoint()}
            {detailContentSuggestingService()}
            {detailContent(
              DiseaseMenuSection.ADVICE,
              adviceRef,
              '熟練ケアマネからのワンポイントアドバイス',
              disease.advices,
            )}
          </div>
          <div className={styles.index}>
            <div ref={indexRef}>
              <DiseaseKnowledgeDetailIndex activeSection={activeSection} />
            </div>
            {/*
             * indexをfixedにした際、要素サイズが無くなりcontentが広がってしまう。
             * それを防ぐため、indexと同等のサイズのものを配置することによってcontentが広がるのを防ぐ。
             * もちろん目に見えてはいけないため非表示。
             */}
            <div className={styles.hidden}>
              <DiseaseKnowledgeDetailIndex
                activeSection={DiseaseMenuSection.ABSTRACT_DISEASE}
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <SecondaryButton width={256} onClick={openListDialogButton}>
            医療知識一覧に戻る
          </SecondaryButton>
        </div>
      </>
    );
  };

  return (
    <div
      className={styles.container}
      ref={dialogContainerRef}
      onClick={onClickContainer}
    >
      <button
        className={styles.listDialogButton}
        onClick={openListDialogButton}
      >
        <LeftArrowIcon />
        <span>医療知識一覧画面</span>
      </button>
      {disease && detail()}
    </div>
  );
}

function stickyIndexIf(scrollTop: number, index: HTMLDivElement) {
  const threshold = 100;
  if (scrollTop >= threshold) {
    index.style.position = 'fixed';
    index.style.top = '20px';
  } else {
    index.style.position = 'static';
    index.style.top = '0';
  }
}

type Props = {
  diseaseId: string;
  dialogContainerRef: RefObject<HTMLDivElement>;
  setDialogMode: (dialogMode: DiseaseKnowledgeDialogMode) => void;
};
