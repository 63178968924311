import { Dispatch } from 'react';
import {
  LoginActions,
  LogoutActions,
  MyselfAction,
  MyselfActionType,
  VerifyActions,
} from './type';
import { AuthenticationApi } from '../../api/authentication/AuthenticationApi';
import { EntryPointApi } from '../../api/entry-point/EntryPointApi';
import { MyselfApi } from '../../api/myself/MyselfApi';
import {
  UpdateEmailRequestBody,
  UpdateDisplayNameRequestBody,
} from '../../api/myself/MyselfApiRequestBody';
import { User } from '../../../domain/user/User';
import { ROOT_TYPE } from '../../RootAction';

export const login =
  (identifier: string, password: string) =>
  async (dispatch: Dispatch<LoginActions>) => {
    dispatch({
      type: 'AUTHENTICATION_LOGIN',
      payload: {},
    });
    try {
      await AuthenticationApi.login(identifier, password);
      const user: User = await MyselfApi.retrieveProfile();
      dispatch({
        type: 'AUTHENTICATION_LOGIN_SUCCESS',
        payload: {
          user,
        },
      });
    } catch (error) {
      dispatch({
        type: 'AUTHENTICATION_LOGIN_FAILURE',
        payload: {},
      });
    }
  };

export const logout = () => async (dispatch: Dispatch<LogoutActions>) => {
  dispatch({
    type: 'AUTHENTICATION_LOGOUT',
    payload: {},
  });
  const result = await AuthenticationApi.logout();
  switch (result.type) {
    case 'SUCCESS':
      dispatch({
        type: ROOT_TYPE.RESET_ALL,
        payload: {},
      });
      break;
    case 'ERROR':
      dispatch({
        type: 'AUTHENTICATION_LOGOUT_FAILURE',
        payload: {},
      });
      break;
  }
};

export const verify = () => async (dispatch: Dispatch<VerifyActions>) => {
  dispatch({
    type: 'AUTHENTICATION_VERIFY',
    payload: {},
  });
  const result = await AuthenticationApi.verify();
  switch (result.type) {
    case 'SUCCESS':
      const rsResult = await EntryPointApi.getRsLoginStatus();
      dispatch({
        type: 'AUTHENTICATION_VERIFY_SUCCESS',
        payload: {
          user: await MyselfApi.retrieveProfile(),
          rsLoginIn: rsResult.type === 'SUCCESS' ? rsResult.rsLoginIn : false,
        },
      });
      break;
    case 'ERROR':
      dispatch({
        type: 'AUTHENTICATION_VERIFY_FAILURE',
        payload: {},
      });
      break;
  }
};

export const updateDisplayName =
  (myself: User, afterCompletion: () => void) =>
  async (dispatch: Dispatch<MyselfAction>) => {
    try {
      const requestBody: UpdateDisplayNameRequestBody = {
        displayName: myself.displayName,
      };
      await MyselfApi.updateDisplayName(requestBody);
      dispatch(updateMyselfSuccess(myself));
      afterCompletion();
    } catch (error) {
      dispatch(updateMyselfFailure());
    }
  };

export const updateEmail =
  (myself: User, afterCompletion: () => void) =>
  async (dispatch: Dispatch<MyselfAction>) => {
    try {
      const requestBody: UpdateEmailRequestBody = { email: myself.email };
      await MyselfApi.updateEmail(requestBody);
      dispatch(updateMyselfSuccess(myself));
      afterCompletion();
    } catch (error) {
      dispatch(updateMyselfFailure());
    }
  };

export const updateMyselfSuccess = (myself: User): MyselfAction => ({
  type: MyselfActionType.MYSELF_UPDATE_SUCCESS,
  payload: { myself },
});

export const updateMyselfFailure = (): MyselfAction => ({
  type: MyselfActionType.MYSELF_UPDATE_FAILURE,
});

export const clearMyselfFailure = (): MyselfAction => ({
  type: MyselfActionType.MYSELF_CLEAR_FAILURE,
});
