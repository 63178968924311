import React, { memo } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom';
import { ProjectSheetType } from './domain/project/ProjectSheetType';
import { Paths } from './Paths';
import { VerifyAuthentication } from './app/view/components/VerifyAuthentication';
import { LoginPage } from './app/view/login/LoginPage';
import { CareRecipientListPage } from './app/view/carerecipient/CareRecipientListPage';
import { CareRecipientPage } from './app/view/carerecipient/CareRecipientPage';
import { ProjectPage } from './app/view/project/ProjectPage';
import { NotFoundPage } from './app/view/misc/NotFoundPage';
import { HelpPage } from './app/view/help/HelpPage';
import { DisplayNameChangePage } from './app/view/setting/displayname/DisplayNameChangePage';
import { PasswordChangePage } from './app/view/setting/password/PasswordChangePage';
import { AccountSettingPage } from './app/view/setting/AccountSettingPage';
import { PasswordResetRequestPage } from './app/view/passwordreset/PasswordResetRequestPage';
import { PasswordResetPage } from './app/view/passwordreset/PasswordResetPage';
import { EmailChangePage } from './app/view/setting/email/EmailChangePage';
import { RedirectToTableOne } from './app/view/project/RedirectToTableOne';
import { RedirectToTableTwo } from './app/view/project/RedirectToTableTwo';
import AdminHome from './app/view/admin/AdminHome';
import AdminOnly from './app/view/admin/AdminOnly';
import RsLoginDeny from './app/view/admin/RsLoginDeny';
import AdminNotification from './app/view/admin/notification/AdminNotification';
import NotificationPage from './app/view/notification/NotificationPage';
import NotificationDetailPage from './app/view/notification/NotificationDetailPage';
import { EntryPointPage } from './app/view/entry-point/EntryPointPage';
import { useTracking } from './app/hooks/common/useTracking';
import { RedirectToTableThree } from './app/view/project/RedirectToTableThree';

function AuthenticatedOnlyRoute(props: RouteProps) {
  const { children: authenticated } = props;
  const unauthenticated = <Redirect to={Paths.login} />;
  return (
    <Route {...props}>
      <VerifyAuthentication
        authenticated={authenticated}
        unauthenticated={unauthenticated}
      />
    </Route>
  );
}

function UnauthenticatedOnlyRoute(props: RouteProps) {
  const { children: unauthenticated } = props;
  const authenticated = <Redirect to={Paths.root} />;
  return (
    <Route {...props}>
      <VerifyAuthentication
        authenticated={authenticated}
        unauthenticated={unauthenticated}
      />
    </Route>
  );
}

function AuthenticatedOptionallyRoute(props: RouteProps) {
  const { children } = props;
  return (
    <Route {...props}>
      <VerifyAuthentication
        authenticated={children}
        unauthenticated={children}
      />
    </Route>
  );
}

const Router: React.FC = memo(function useRoute() {
  useTracking('UA-115108957-11');
  useTracking('G-7E9P16X68Q');

  return (
    <Switch>
      <AuthenticatedOptionallyRoute exact path={Paths.entryPoint}>
        <EntryPointPage />
      </AuthenticatedOptionallyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.root}>
        <Redirect to={Paths.careRecipients} />
      </AuthenticatedOnlyRoute>
      <UnauthenticatedOnlyRoute exact path={Paths.login}>
        <LoginPage />
      </UnauthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.careRecipients}>
        <RsLoginDeny>
          <CareRecipientListPage />
        </RsLoginDeny>
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.careRecipient.template}>
        <CareRecipientPage />
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.assessmentSheet.template}>
        <ProjectPage sheetType={ProjectSheetType.ASSESSMENT_SHEET} />
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.tableOne.template}>
        <ProjectPage sheetType={ProjectSheetType.TABLE_ONE} />
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.tableTwo.template}>
        <ProjectPage sheetType={ProjectSheetType.TABLE_TWO} />
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.tableThree.template}>
        <ProjectPage sheetType={ProjectSheetType.TABLE_THREE} />
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.userSettings}>
        <RsLoginDeny>
          <AccountSettingPage />
        </RsLoginDeny>
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.userSettingDisplayName}>
        <RsLoginDeny>
          <DisplayNameChangePage />
        </RsLoginDeny>
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.userSettingEmail}>
        <RsLoginDeny>
          <EmailChangePage />
        </RsLoginDeny>
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.userSettingPassword}>
        <RsLoginDeny>
          <PasswordChangePage />
        </RsLoginDeny>
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.tableOneByProjectId.template}>
        <RedirectToTableOne />
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.tableTwoByProjectId.template}>
        <RedirectToTableTwo />
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.tableThreeByProjectId.template}>
        <RedirectToTableThree />
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.notifications}>
        <RsLoginDeny>
          <NotificationPage />
        </RsLoginDeny>
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.notification.template}>
        <RsLoginDeny>
          <NotificationDetailPage />
        </RsLoginDeny>
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.admin}>
        <AdminOnly>
          <RsLoginDeny>
            <AdminHome />
          </RsLoginDeny>
        </AdminOnly>
      </AuthenticatedOnlyRoute>
      <AuthenticatedOnlyRoute exact path={Paths.adminNotification}>
        <AdminOnly>
          <RsLoginDeny>
            <AdminNotification />
          </RsLoginDeny>
        </AdminOnly>
      </AuthenticatedOnlyRoute>
      <UnauthenticatedOnlyRoute exact path={Paths.passwordResetRequest}>
        <PasswordResetRequestPage />
      </UnauthenticatedOnlyRoute>
      <UnauthenticatedOnlyRoute exact path={Paths.passwordReset.template}>
        <PasswordResetPage />
      </UnauthenticatedOnlyRoute>
      <AuthenticatedOptionallyRoute exact path={Paths.help}>
        <RsLoginDeny>
          <HelpPage />
        </RsLoginDeny>
      </AuthenticatedOptionallyRoute>
      <AuthenticatedOptionallyRoute>
        <NotFoundPage />
      </AuthenticatedOptionallyRoute>
    </Switch>
  );
});

function App() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;
