import dayjs from 'dayjs';

export type LocalDate = dayjs.Dayjs;

export function localDate(string: string): LocalDate {
  return dayjs(string);
}

export function today(): LocalDate {
  return dayjs().endOf('date');
}

export function afterNMonths(localDate: LocalDate, months: number): LocalDate {
  switch (localDate.date()) {
    case localDate.startOf('month').date():
      return localDate.add(months - 1, 'month').endOf('month');
    default:
      return localDate.add(months, 'month').endOf('month');
  }
}
