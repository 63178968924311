import React from 'react';

export function LargeCheckIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9429 8.89064C18.2163 9.16401 18.2163 9.60723 17.9429 9.88059L11.7139 16.1096C11.4406 16.383 10.9973 16.383 10.724 16.1096L6.49497 11.8806C6.22161 11.6072 6.22161 11.164 6.49498 10.8906L7.39064 9.99497C7.66401 9.72161 8.10723 9.72161 8.38059 9.99497L10.724 12.3384C10.9973 12.6117 11.4406 12.6117 11.7139 12.3384L16.0573 7.99497C16.3307 7.72161 16.7739 7.72161 17.0473 7.99498L17.9429 8.89064Z"
        fill="white"
      />
    </svg>
  );
}
