import 'whatwg-fetch';
import { ApiError } from '../../ApiError';
import { post } from '../../functions';
import { FetchSuggestionLongTermGoals } from './FetchSuggestionLongTermGoals';

export const SuggestionLongTermGoalApi = new (class {
  async fetchSuggestionLongTermGoals(
    projectId: string,
    needText: string,
  ): Promise<FetchSuggestionLongTermGoals | ApiError> {
    const body = {
      projectId,
      needText,
    };
    const response = await post('/api/suggestion/longTermGoals', body);
    return (
      response.ok ? FetchSuggestionLongTermGoals.extract : ApiError.extract
    )(response);
  }
})();
