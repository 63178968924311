export class PasswordChangeRequest {
  currentPassword: string;
  newPassword: string;

  private constructor(currentPassword: string, newPassword: string) {
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
  }

  static init(): PasswordChangeRequest {
    return new PasswordChangeRequest('', '');
  }

  createWithCurrentPassword(currentPassword: string): PasswordChangeRequest {
    return new PasswordChangeRequest(currentPassword, this.newPassword);
  }

  createWithNewPassword(newPassword: string): PasswordChangeRequest {
    return new PasswordChangeRequest(this.currentPassword, newPassword);
  }
}
