import React, { useEffect, useState } from 'react';
import styles from './DiseaseKnowledgeList.module.scss';
import { DiseaseCategory } from '../DiseaseCategory';
import { Link } from 'react-router-dom';
import { DiseaseKnowledgeApi } from '../../../../state/api/diseaseknowledge/DiseaseKnowledgeApi';
import { DiseaseCategories } from '../../../../state/api/diseaseknowledge/DiseaseCategories';
import { DiseaseCategoryWithDisease } from '../../../../domain/diseaseknowledge/DiseaseCategoryWithDisease';
import { DiseaseKnowledgeDialogMode } from '../../components/MedicalKnowledgeButton';

export function DiseaseKnowledgeList(props: Props) {
  const { setDialogMode } = props;
  const [diseaseCategories, setDiseaseCategories] = useState<
    DiseaseCategoryWithDisease[]
  >([]);

  const openDetailDialogButton = (diseaseId: string) => () => {
    setDialogMode({ diseaseId: diseaseId });
  };

  useEffect(() => {
    let mounted = true;
    DiseaseKnowledgeApi.getList().then((res) => {
      if (res instanceof DiseaseCategories && mounted) {
        setDiseaseCategories(res.diseaseCategories);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <ul className={styles.diseaseCategories}>
      {(diseaseCategories ?? []).map((category) => {
        return (
          <li key={category.id} className={styles.diseaseCategory}>
            <h3 className={styles.diseaseCategoryName}>
              <DiseaseCategory categoryId={category.id} />
              {category.name}
            </h3>
            <ul>
              {category.diseases.map((disease) => {
                return (
                  <li key={disease.id} className={styles.diseaseName}>
                    <Link to={'#'} onClick={openDetailDialogButton(disease.id)}>
                      {disease.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
        );
      })}
      <li className={styles.superVisor}>
        <p className={styles.superVisorTitle}>監修</p>
        <div className={styles.superVisorName}>
          <p>{'藤田医科大学　連携地域医療学　准教授'}</p>
          <p>{'大杉　泰弘'}</p>
        </div>
      </li>
    </ul>
  );
}

type Props = {
  setDialogMode: (dialogMode: DiseaseKnowledgeDialogMode) => void;
};
