import { User } from '../../../domain/user/User';
import { ChangeLog } from '../../../domain/changelog/ChangeLog';
import { offsetDateTime } from '../../../domain/fundamental/OffsetDateTime';
import { ChangeLogWhat } from '../../../domain/changelog/ChangeLogWhat';

type ChangeLogWhatString =
  | 'ASSESSMENT_SHEET'
  | 'ISSUE_ANALYSIS_TABLE'
  | 'TABLE_ONE'
  | 'TABLE_TWO'
  | 'TABLE_THREE';

export type LatestChangeLog = {
  who: User;
  when: string;
  what: ChangeLogWhatString;
};

export function changeLog(latestChangeLog: LatestChangeLog): ChangeLog {
  return new ChangeLog(
    latestChangeLog.who,
    offsetDateTime(latestChangeLog.when),
    changeLogWhat(latestChangeLog.what),
  );
}

function changeLogWhat(string: ChangeLogWhatString): ChangeLogWhat {
  switch (string) {
    case 'ASSESSMENT_SHEET':
      return ChangeLogWhat.ASSESSMENT_SHEET;
    case 'ISSUE_ANALYSIS_TABLE':
      return ChangeLogWhat.ISSUE_ANALYSIS_TABLE;
    case 'TABLE_ONE':
      return ChangeLogWhat.TABLE_ONE;
    case 'TABLE_TWO':
      return ChangeLogWhat.TABLE_TWO;
    case 'TABLE_THREE':
      return ChangeLogWhat.TABLE_THREE;
  }
}
