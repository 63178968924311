import React, { MouseEvent, ReactElement, RefObject } from 'react';
import styles from './Result.module.scss';
import { Card } from './Card';
import { EmptyCard } from './EmptyCard';
import { replaceKanaHalfToFull, replaceHalfToFull } from './ReplaceCharacter';
import { SupportServiceTypeCategory } from '../../../../../domain/establishment/SupportServiceTypeCategory';
import { Establishment } from '../../../../../domain/establishment/Establishment';
import { EstablishmentFavorite } from '../../../../../domain/establishment/EstablishmentFavorite';
import { Loading } from '../../../components/loading/Loading';

export function Result(props: Props) {
  const {
    category,
    setScrollTop,
    scrollBody,
    establishmentList,
    onSearching,
    setEstablishment,
    setDetailVisible,
    keyword,
    projectId,
    establishmentFavoriteList,
    setEstablishmentFavoriteList,
    filterFavorite,
    tableTwoEstablishmentList,
    confirmEstablishments,
  } = props;

  const result = () => {
    if (!establishmentList || !establishmentList.length) return zeroResult();

    let filteredList;
    switch (category) {
      case '通所系':
        filteredList = establishmentList.filter((establishment) =>
          ['6', '7', '8', '9', '33'].includes(
            establishment.jigyoshoJoho.serviceShubetsuCode,
          ),
        );
        break;
      case '訪問系':
        filteredList = establishmentList.filter((establishment) =>
          ['1', '3', '4', '5', '31'].includes(
            establishment.jigyoshoJoho.serviceShubetsuCode,
          ),
        );
        break;
      case '短期入所系':
        filteredList = establishmentList.filter((establishment) =>
          ['25', '28', '30'].includes(
            establishment.jigyoshoJoho.serviceShubetsuCode,
          ),
        );
        break;
    }

    if (tableTwoEstablishmentList && tableTwoEstablishmentList.length) {
      let tagetList = filteredList.filter((establishment) =>
        tableTwoEstablishmentList.includes(
          establishment.jigyoshoJoho.jigyoshomei,
        ),
      );
      let notTagetList = filteredList.filter(
        (establishment) =>
          !tableTwoEstablishmentList.includes(
            establishment.jigyoshoJoho.jigyoshomei,
          ),
      );
      filteredList = [...tagetList, ...notTagetList];
    }

    // keyword検索
    const replaceKeyword = replaceKanaHalfToFull(replaceHalfToFull(keyword));
    filteredList = filteredList.filter((establishment) =>
      replaceKeyword
        .split(/[\s]+/)
        .every((value) =>
          replaceKanaHalfToFull(
            replaceHalfToFull(
              establishment.jigyoshoJoho.jigyoshomei.replace(/[\s]+/g, ''),
            ),
          ).includes(value),
        ),
    );

    const jigyoshobangoList = establishmentFavoriteList.map(
      (value) => value.jigyoshobango,
    );
    if (filterFavorite) {
      filteredList = filteredList.filter((establishment) =>
        jigyoshobangoList.includes(establishment.jigyoshoJoho.jigyoshobango),
      );
    }

    if (!filteredList || !filteredList.length) return zeroResult();

    return (
      <ul className={styles.container}>
        {filteredList.slice(0, MAX_SEARCH_COUNT).map((establishment) => {
          return (
            <Card
              establishment={establishment}
              category={category}
              key={`${establishment.jigyoshoJoho.jigyoshobango}${establishment.jigyoshoJoho.serviceShubetsuCode}`}
              setScrollTop={setScrollTop}
              scrollBody={scrollBody}
              setDetailVisible={setDetailVisible}
              setEstablishment={setEstablishment}
              projectId={projectId}
              establishmentFavoriteList={establishmentFavoriteList}
              setEstablishmentFavoriteList={setEstablishmentFavoriteList}
              confirmEstablishments={confirmEstablishments}
            />
          );
        })}
        {emptyCards(filteredList)}
      </ul>
    );
  };

  const emptyCards = (filteredList: Establishment[]): ReactElement[] | null => {
    if (!filteredList || filteredList.length % 3 === 0) return null;
    const makeCount = 3 - (filteredList.length % 3);
    let arr = [];
    for (let i = 0; i < makeCount; i++) {
      arr.push(<EmptyCard key={i} />);
    }
    return arr;
  };

  const zeroResult = () => {
    return (
      <div className={styles.zeroResultContainer}>
        <div className={styles.zeroResultText}>該当する事業所が0件でした。</div>
      </div>
    );
  };

  return <>{onSearching ? <Loading /> : result()}</>;
}

const MAX_SEARCH_COUNT = 99;

type Props = {
  category: SupportServiceTypeCategory;
  setScrollTop: (scrollTop: number) => void;
  scrollBody: RefObject<HTMLDivElement> | null;
  establishmentList: Establishment[];
  onSearching: boolean;
  setEstablishment: (establishment: Establishment) => void;
  setDetailVisible: (value: boolean) => void;
  keyword: string;
  projectId: string;
  establishmentFavoriteList: EstablishmentFavorite[];
  setEstablishmentFavoriteList: (value: EstablishmentFavorite[]) => void;
  filterFavorite: boolean;
  tableTwoEstablishmentList?: string[];
  confirmEstablishments: (
    event: MouseEvent,
    establishment: {
      supportServiceType: string;
      supportServiceTypeId: number;
      establishmentName: string;
      establishmentId: string;
    },
  ) => void;
};
