import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './EntryPointPage.module.scss';
import { GlobalHeader } from '../components/GlobalHeader';
import { GlobalContainer } from '../components/GlobalContainer';
import { EntryPointApi } from '../../../state/api/entry-point/EntryPointApi';
import { Paths } from '../../../Paths';

export function EntryPointPage() {
  const history = useHistory();
  const [authError, setAuthError] = useState<boolean>(false);

  const qs: URLSearchParams = new URLSearchParams(useLocation().search);
  const projectId: string = qs.get('projectId') ?? '';
  const token: string = qs.get('AccessToken') ?? '';

  const redirectCarePlanTableTwo = useCallback(() => {
    const entrypoint = EntryPointApi.getCarePlanTableTwoUrl(projectId, token);
    entrypoint
      .then((res) => {
        if (res.type === 'SUCCESS') {
          window.location.href = res.url;
        } else {
          setAuthError(true);
        }
      })
      .catch((res) => {
        setAuthError(true);
      });
  }, [projectId, token]);

  const goToLogin = () => {
    history.push(Paths.login);
  };

  useEffect(() => {
    redirectCarePlanTableTwo();
  }, [redirectCarePlanTableTwo]);

  return (
    <GlobalContainer rsLoginView={true}>
      <GlobalHeader rsLoginView={true} />
      <div className={styles.card}>
        <div className={styles.contents}>
          {!authError ? (
            <div className={styles.description}>認証中…</div>
          ) : (
            <div className={styles.description}>
              <h2 className={styles.title}>認証に失敗しました</h2>
              <div className={styles.button}>
                <span className={styles.textLink} onClick={goToLogin}>
                  ログイン画面に戻る
                </span>
              </div>
              <p className={styles.message}>projectId: {projectId}</p>
              <p className={styles.message}>AccessToken: {token}</p>
            </div>
          )}
        </div>
      </div>
    </GlobalContainer>
  );
}
