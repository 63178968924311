import React from 'react';

export function RightArrowIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.13123 3.26382C4.34914 2.973 4.76206 2.91351 5.05352 3.13094L10.6544 7.30908L10.6607 7.31384C10.7662 7.39457 10.8517 7.49844 10.9105 7.61743C10.9694 7.73642 11 7.86732 11 8C11 8.13268 10.9694 8.26358 10.9105 8.38257C10.8517 8.50156 10.7662 8.60543 10.6607 8.68616L10.6544 8.69092L5.05352 12.8691C4.76206 13.0865 4.34914 13.027 4.13123 12.7362C3.91332 12.4454 3.97294 12.0334 4.2644 11.8159L9.37971 8L4.2644 4.18406C3.97294 3.96663 3.91332 3.55463 4.13123 3.26382Z"
        fill="none"
      />
    </svg>
  );
}
