import React from 'react';
import styles from './CareRecipientListSyllabaryColumnItem.module.scss';
import { SyllabaryColumn } from '../../../../domain/carerecipient/SyllabaryColumn';
import { displayForSyllabaryColumn } from '../../misc/display';

export function CareRecipientListSyllabaryColumnItem(props: Props) {
  const { syllabaryColumn } = props;
  return (
    <div className={styles.container}>
      {displayForSyllabaryColumn(syllabaryColumn)}
    </div>
  );
}

type Props = {
  syllabaryColumn: SyllabaryColumn;
};
