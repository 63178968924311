import React, { useState } from 'react';
import { today } from '../../../../domain/fundamental/LocalDate';
import styles from './History.module.scss';
import { DateField } from '../../components/field/DateField';
import { PrimaryButton } from '../../components/button/PrimaryButton';
import useHistories from '../../../hooks/admin/history/useHistories';

export default function History() {
  const {
    loginHistory,
    operationHistory,
    projectsMadeCounts,
    careRecipientCreatedHistory,
    retrieveHistories,
  } = useHistories();
  // ここ1週間ほどをBizに報告することが多いので、初期値は1週前〜本日とする
  const [startDate, setStartDate] = useState(
    today().subtract(7, 'day').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(today().format('YYYY-MM-DD'));

  const onClickSearch = () => {
    retrieveHistories(startDate, endDate);
  };

  return (
    <div>
      <div className={styles.inputArea}>
        <div className={styles.duration}>期間:</div>
        <DateField
          onUpdate={(val) => {
            setStartDate(val);
          }}
          width={200}
          get={startDate}
        />
        <div className={styles.dash}>〜</div>
        <DateField
          onUpdate={(val) => {
            setEndDate(val);
          }}
          width={200}
          get={endDate}
        />
        <div className={styles.searchButtonArea}>
          <PrimaryButton onClick={onClickSearch}>検索</PrimaryButton>
        </div>
      </div>
      <div className={styles.histories}>
        <div className={styles.loginHistory}>
          <h1 className={styles.title}>ログイン状況</h1>
          <ul className={styles.listContainer}>
            {!loginHistory || loginHistory.length === 0 ? (
              <div>指定された期間にログイン履歴がありません</div>
            ) : (
              loginHistory.map((history, i) => {
                return (
                  <li className={styles.row} key={i}>
                    <div className={styles.displayName}>
                      {history.displayName}（{history.officeName}）
                    </div>
                    <div className={styles.times}>{history.loginTimes}</div>
                  </li>
                );
              })
            )}
          </ul>
        </div>
        <div className={styles.operationHistory}>
          <div className={styles.title}>操作履歴</div>
          <ul className={styles.listContainer}>
            {!operationHistory || operationHistory.length === 0 ? (
              <div>指定された期間に操作履歴がありません</div>
            ) : (
              operationHistory.map((history, i) => {
                return (
                  <li className={styles.row} key={i}>
                    <div className={styles.displayName}>
                      {history.displayName}（{history.officeName}）
                    </div>
                    <div className={styles.times}>{history.operationTimes}</div>
                  </li>
                );
              })
            )}
          </ul>
        </div>
        <div className={styles.projectsMadeCount}>
          <div className={styles.title}>プロジェクト作成数</div>
          <ul className={styles.listContainer}>
            {!projectsMadeCounts || projectsMadeCounts.length === 0 ? (
              <div>指定された期間にプロジェクトが作成されていません</div>
            ) : (
              projectsMadeCounts.map((history, i) => {
                return (
                  <li className={styles.row} key={i}>
                    <div className={styles.displayName}>
                      {history.displayName}
                    </div>
                    <div className={styles.times}>{history.count}</div>
                  </li>
                );
              })
            )}
          </ul>
        </div>
        <div className={styles.careRecipientCreateCount}>
          <div className={styles.title}>利用者作成数</div>
          <ul className={styles.listContainer}>
            {!careRecipientCreatedHistory ||
            careRecipientCreatedHistory?.length === 0 ? (
              <div>指定された期間に利用者作成がありません</div>
            ) : (
              careRecipientCreatedHistory.map((history, i) => {
                return (
                  <li className={styles.row} key={i}>
                    <div className={styles.displayName}>
                      {history.displayName}
                    </div>
                    <div className={styles.times}>{history.count}</div>
                  </li>
                );
              })
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
