import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { List } from './list/List';
import { CloseIcon } from '../../../components/icon/CloseIcon';
import styles from './EstablishmentPage.module.scss';
import { ShortTermGoal } from '../../../../../domain/careplan/tabletwo/shorttermgoal/ShortTermGoal';
import { Need } from '../../../../../domain/careplan/tabletwo/need/Need';
import { LongTermGoal } from '../../../../../domain/careplan/tabletwo/longtermgoal/LongTermGoal';
import { SupportServiceTypeCategory } from '../../../../../domain/establishment/SupportServiceTypeCategory';
import { EstablishmentSearchCondition } from '../../../../../domain/establishment/SearchCondition';
import { ErrorPage } from './error/ErrorPage';
import { SupportServiceTypeApi } from '../../../../../state/api/careplan/tabletwo/supportservicetype/SupportServiceTypeApi';
import { EditedSupoprtService } from '../../../../../state/api/careplan/tabletwo/supportservice/EditedSupoprtService';
import { CarePlanTableTwo } from '../../../../../domain/careplan/tabletwo/CarePlanTableTwo';
import { ChangeLog } from '../../../../../domain/changelog/ChangeLog';
import { FetchEstablishmentApi } from '../../../../../state/api/suggestion/establishment/EstablishmentApi';
import { FetchEstablishmentList } from '../../../../../state/api/suggestion/establishment/FetchEstablishmentList';
import { FetchSuggestionEstablishmentSearchCondition } from '../../../../../state/api/suggestion/establishment/FetchSuggestionEstablishmentSearchCondition';
import { SuggestedCondition } from '../../../../../state/usecase/suggestion/establishment/reducer';
import { Establishment } from '../../../../../domain/establishment/Establishment';
import { Detail } from './detail/Detail';
import { FetchEstablishmentFavoriteList } from '../../../../../state/api/suggestion/establishment/FetchEstablishmentFavoriteList';
import { EstablishmentFavorite } from '../../../../../domain/establishment/EstablishmentFavorite';

export function EstablishmentPage(props: Props) {
  const {
    carePlanTableTwo,
    need,
    longTermGoal,
    shortTermGoal,
    supportServiceText,
    projectId,
    onRequestClose,
    supportServiceId,
    supportServiceTypeId,
    refresh,
  } = props;

  const [listFetchingError, setListFetchingError] = useState<boolean>(false);

  useEffect(() => {
    setOnGettingSearchCondition(true);
    let mounted = true;
    FetchEstablishmentApi.fetchSuggestedEstablishmentSearchCondition(
      projectId,
      supportServiceText ?? '',
    ).then((result) => {
      if (!mounted) return;
      setOnGettingSearchCondition(false);
      if (result instanceof FetchSuggestionEstablishmentSearchCondition) {
        setSuggestedCondition({
          supportServiceTypes: result.suggestedSupportServiceTypes,
          seikatsuhogo: result.seikatsuhogo,
          nyuyokukaijo: result.nyuyokukaijo,
          tsuintojokokaijo: result.tsuintojokokaijo,
          kobetsukinokunren1: result.kobetsukinokunren1,
          kobetsukinokunren2: result.kobetsukinokunren2,
          staffPt: result.staffPt,
          staffOt: result.staffOt,
          staffSt: result.staffSt,
          kokuCare: result.kokuCare,
          kinkyujiHomonKaigo: result.kinkyujiHomonKaigo,
          torokukakutankyuin: result.torokukakutankyuin,
          shintaikaigo20min: result.shintaikaigo20min,
          kinkyujiHomonKango: result.kinkyujiHomonKaigo,
          teizuigataHomonkaigoRenkei: result.teizuigataHomonkaigoRenkei,
          kyuByojiHomonkango: result.kyuByojiHomonkango,
          zaitakuMitori: result.zaitakuMitori,
          terminalCare: result.terminalCare,
          denwasodan24: result.denwasodan24,
          rehabilitation: result.rehabilitation,
          zaitakuchudoukeire: result.zaitakuchudoukeire,
          joshiBenjoKurumaisu: result.joshiBenjoKurumaisu,
          kyoyoBenjoKurumaisu: result.kyoyoBenjoKurumaisu,
          danseiBenjoKurumaisu: result.danseiBenjoKurumaisu,
          jakuneseininchishoukeire: result.jakuneseininchishoukeire,
          kobetsukinokunren: result.kobetsukinokunren,
          shikaeiseishi: result.shikaeiseishi,
          kanrieiyoshi: result.kanrieiyoshi,
          ryoyoshoku: result.ryoyoshoku,
          tokubetsukanri1: result.tokubetsukanri1,
          tokubetsukanri2: result.tokubetsukanri2,
          keikaneiyoho: result.keikaneiyoho,
          zaitakuchushinjomyakueiyoho: result.zaitakuchushinjomyakueiyoho,
          zaitakusansoryoho: result.zaitakusansoryoho,
          jinkokokyuryoho: result.jinkokokyuryoho,
          kikankanyure: result.kikankanyure,
          jinroHokorou: result.jinroHokorou,
          bokoryuKateteru: result.bokoryuKateteru,
          jinkokomon: result.jinkokomon,
          jinkoboko: result.jinkoboko,
          sogejikyotakunaikaijo: result.sogejikyotakunaikaijo,
          kyuin: result.kyuin,
          mayaku: result.mayaku,
          seikatsukoikojoRehabilitation: result.seikatsukoikojoRehabilitation,
          teikijunkaiservice: result.teikijunkaiservice,
        });
      }
    });
    return () => {
      mounted = false;
    };
  }, [projectId, supportServiceText]);

  const onClickContainer = (event: MouseEvent<any>) => event.stopPropagation();

  const onClickClose = () => {
    setListFetchingError(false);
    onRequestClose();
  };

  const doAdd = useRef(false);

  const [selectedEstablishments, setSelectedEstablishments] = useState<
    { text: string; establishmentName: string }[]
  >([]);

  const selectEstablishment = (establishment: {
    text: string;
    establishmentName: string;
  }) => {
    setSelectedEstablishments([...selectedEstablishments, establishment]);
  };

  const deselectEstablishment = (establishment: {
    text: string;
    establishmentName: string;
  }) => {
    setSelectedEstablishments(
      selectedEstablishments.filter(
        (e) =>
          !(
            e.establishmentName === establishment.establishmentName &&
            e.text === establishment.text
          ),
      ),
    );
  };

  const selectAndDoAdd = (establishment: {
    text: string;
    establishmentName: string;
  }) => {
    doAdd.current = true;
    // すでに事業所選択画面で選択済みの場合は事業所詳細画面の「選択する」ボタンを押下しても追加しない
    if (
      selectedEstablishments.some(
        (e) =>
          e.establishmentName === establishment.establishmentName &&
          e.text === establishment.text,
      )
    ) {
      setSelectedEstablishments([...selectedEstablishments]);
    } else {
      setSelectedEstablishments([...selectedEstablishments, establishment]);
    }
  };

  const addEstablishmentAndClose = useCallback(() => {
    SupportServiceTypeApi.addEstablishments(
      selectedEstablishments,
      supportServiceId,
      supportServiceTypeId,
    ).then((res) => {
      if (res instanceof EditedSupoprtService) {
        refresh(
          carePlanTableTwo.replaceSupportService(
            need,
            longTermGoal,
            shortTermGoal,
            res.supportService,
          ),
          res.changeLog,
        );
      }
    });
    setSelectedEstablishments([]);
    onRequestClose();
  }, [
    selectedEstablishments,
    supportServiceId,
    supportServiceTypeId,
    carePlanTableTwo,
    need,
    longTermGoal,
    shortTermGoal,
    onRequestClose,
    setSelectedEstablishments,
    refresh,
  ]);

  useEffect(() => {
    if (doAdd.current) {
      addEstablishmentAndClose();
      doAdd.current = false;
    }
  }, [selectedEstablishments, addEstablishmentAndClose]);

  const confirmEstablishments = (event: MouseEvent) => {
    event.stopPropagation();
    addEstablishmentAndClose();
  };

  const [category, setCategory] =
    useState<SupportServiceTypeCategory>('通所系');
  const onChangeTab = (category: SupportServiceTypeCategory) => {
    setCategory(category);
  };

  const [suggestedCondition, setSuggestedCondition] =
    useState<SuggestedCondition>(defaultSuggestedCondition);
  const [onGettingSearchCondition, setOnGettingSearchCondition] =
    useState<boolean>(false);

  const [condition, setCondition] = useState<EstablishmentSearchCondition>(
    EstablishmentSearchCondition.fromSuggestedCondition(suggestedCondition),
  );

  const [tsushokeiCount, setTsushokeiCount] = useState<number>(0);
  const [homonkeiCount, setHomonkeiCount] = useState<number>(0);
  const [shortstayCount, setShortstayCount] = useState<number>(0);
  const [establishmentList, setEstablishmentList] = useState<Establishment[]>(
    [],
  );
  const [onSearchingList, setOnSearchingList] = useState(false);
  const [establishmentFavoriteList, setEstablishmentFavoriteList] = useState<
    EstablishmentFavorite[]
  >([]);

  useEffect(() => {
    if (!onGettingSearchCondition) {
      let mounted = true;
      setCondition(
        EstablishmentSearchCondition.fromSuggestedCondition(suggestedCondition),
      );
      setOnSearchingList(true);
      FetchEstablishmentApi.fetchEstablishmentList(
        projectId,
        EstablishmentSearchCondition.fromSuggestedCondition(
          suggestedCondition,
        ).toQueryString(),
      ).then((res) => {
        if (!mounted) return;
        if (res instanceof FetchEstablishmentList) {
          setTsushokeiCount(res.tsushokeiCount);
          setHomonkeiCount(res.homonkeiCount);
          setShortstayCount(res.shortstayCount);
          setEstablishmentList(res.establishmentList);
          setOnSearchingList(false);
        } else {
          setListFetchingError(true);
        }
      });
      return () => {
        mounted = false;
      };
    }
  }, [suggestedCondition, projectId, onGettingSearchCondition]);

  useEffect(() => {
    let mounted = true;
    FetchEstablishmentApi.fetchEstablishmentFavoriteList(projectId).then(
      (result) => {
        if (!mounted) return;
        if (result instanceof FetchEstablishmentFavoriteList) {
          setEstablishmentFavoriteList(result.favoriteList);
        }
      },
    );
    return () => {
      mounted = false;
    };
  }, [projectId]);

  const [scrollTop, setScrollTop] = useState(0);

  const [detailVisible, setDetailVisible] = useState(false);
  const [establishment, setEstablishment] = useState<Establishment | null>(
    null,
  );

  const render = () => {
    if (listFetchingError) {
      return <ErrorPage onClickClose={onClickClose} />;
    }

    if (detailVisible) {
      return (
        <Detail
          selectEstablishment={selectAndDoAdd}
          projectId={projectId}
          establishment={establishment}
          setDetailVisible={setDetailVisible}
          establishmentFavoriteList={establishmentFavoriteList}
          setEstablishmentFavoriteList={setEstablishmentFavoriteList}
        />
      );
    }

    return (
      <>
        <List
          shortTermGoal={shortTermGoal}
          supportServiceText={supportServiceText}
          projectId={projectId}
          selectedEstablishments={selectedEstablishments}
          selectEstablishment={selectEstablishment}
          deselectEstablishment={deselectEstablishment}
          confirmEstablishments={confirmEstablishments}
          category={category}
          onChangeTab={onChangeTab}
          condition={condition}
          setCondition={setCondition}
          scrollTop={scrollTop}
          setScrollTop={setScrollTop}
          tsushokeiCount={tsushokeiCount}
          homonkeiCount={homonkeiCount}
          shortstayCount={shortstayCount}
          establishmentList={establishmentList}
          setTsushokeiCount={setTsushokeiCount}
          setHomonkeiCount={setHomonkeiCount}
          setShortstayCount={setShortstayCount}
          setEstablishmentList={setEstablishmentList}
          onGettingSearchCondition={onGettingSearchCondition}
          setEstablishment={setEstablishment}
          setDetailVisible={setDetailVisible}
          onSearchingList={onSearchingList}
          setOnSearchingList={setOnSearchingList}
          establishmentFavoriteList={establishmentFavoriteList}
          setEstablishmentFavoriteList={setEstablishmentFavoriteList}
        />
      </>
    );
  };

  return (
    <div className={styles.container} onClick={onClickContainer}>
      <button className={styles.closeButton} onClick={onClickClose}>
        <CloseIcon />
      </button>
      {render()}
    </div>
  );
}

type Props = {
  carePlanTableTwo: CarePlanTableTwo;
  need: Need;
  longTermGoal: LongTermGoal;
  shortTermGoal: ShortTermGoal;
  supportServiceText: string | null;
  projectId: string;
  onRequestClose: () => void;
  supportServiceId: string;
  supportServiceTypeId: string;
  refresh: (carePlanTableTwo: CarePlanTableTwo, changeLog: ChangeLog) => void;
};

const defaultSuggestedCondition: SuggestedCondition = {
  supportServiceTypes: null,
  seikatsuhogo: false,
  nyuyokukaijo: false,
  tsuintojokokaijo: false,
  kobetsukinokunren1: false,
  kobetsukinokunren2: false,
  staffPt: false,
  staffOt: false,
  staffSt: false,
  kokuCare: false,
  kinkyujiHomonKaigo: false,
  torokukakutankyuin: false,
  shintaikaigo20min: false,
  kinkyujiHomonKango: false,
  teizuigataHomonkaigoRenkei: false,
  kyuByojiHomonkango: false,
  zaitakuMitori: false,
  terminalCare: false,
  denwasodan24: false,
  rehabilitation: false,
  zaitakuchudoukeire: false,
  joshiBenjoKurumaisu: false,
  kyoyoBenjoKurumaisu: false,
  danseiBenjoKurumaisu: false,
  jakuneseininchishoukeire: false,
  kobetsukinokunren: false,
  shikaeiseishi: false,
  kanrieiyoshi: false,
  ryoyoshoku: false,
  tokubetsukanri1: false,
  tokubetsukanri2: false,
  keikaneiyoho: false,
  zaitakuchushinjomyakueiyoho: false,
  zaitakusansoryoho: false,
  jinkokokyuryoho: false,
  kikankanyure: false,
  jinroHokorou: false,
  bokoryuKateteru: false,
  jinkokomon: false,
  jinkoboko: false,
  sogejikyotakunaikaijo: false,
  kyuin: false,
  mayaku: false,
  seikatsukoikojoRehabilitation: false,
  teikijunkaiservice: false,
};
