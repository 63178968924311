import { ReactNode } from 'react';
import { SyllabaryColumn } from '../../../domain/carerecipient/SyllabaryColumn';
import { User } from '../../../domain/user/User';
import { Project } from '../../../domain/project/Project';

export function displayForSyllabaryColumn(
  syllabaryColumn: SyllabaryColumn,
): string {
  switch (syllabaryColumn) {
    case 'A':
      return 'あ';
    case 'KA':
      return 'か';
    case 'SA':
      return 'さ';
    case 'TA':
      return 'た';
    case 'NA':
      return 'な';
    case 'HA':
      return 'は';
    case 'MA':
      return 'ま';
    case 'YA':
      return 'や';
    case 'RA':
      return 'ら';
    case 'WA':
      return 'わ';
    case 'OTHER':
      return 'その他';
  }
}

export function displayForResponsibleUser(
  responsibleUser: User | null,
): ReactNode {
  return responsibleUser?.displayName ?? '-';
}

export function displayForProject(project: Project): string {
  return project.version > 1 ? `第${project.version}版` : '初版';
}
