const tableOnePathType = Symbol();

export class TableOnePath {
  [tableOnePathType]: any;

  path: string;

  constructor(path: string) {
    this.path = path;
  }

  find(content: any): ParentAndProperty {
    const props = this.path
      .split('.')
      .flatMap((part) => [pathForProperty(part), ...extractIndices(part)]);
    const [lastProp, ...initProps] = props.reverse();
    const object = initProps.reduceRight(
      (object, prop) => object[prop],
      content,
    );
    return {
      parent: object,
      property: lastProp,
    };
  }

  parse(): PlaceholderAndIndices {
    return {
      placeholder: pathForPlaceholder(this.path),
      indices: [...extractIndices(this.path)],
    };
  }
}

type ParentAndProperty = {
  parent: any;
  property: string | number;
};

type PlaceholderAndIndices = {
  placeholder: string;
  indices: number[];
};

const indexRegExp = /\[(\d+)]/g;

function pathForPlaceholder(string: string): string {
  return string.replace(indexRegExp, '[]');
}

function pathForProperty(string: string): string {
  return string.replace(indexRegExp, '');
}

function* extractIndices(string: string): Generator<number> {
  let match = indexRegExp.exec(string);
  while (match) {
    yield parseInt(match[1], 10);
    match = indexRegExp.exec(string);
  }
}
