import React, { useEffect, useRef, RefObject } from 'react';
import styles from './TableOneFragment.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../state/store';
import { get } from '../../../state/usecase/tableone/action';
import { TableOneFooter } from './components/TableOneFooter';
import { TableOneHeader } from './components/TableOneHeader';
import { TableOneContent } from './components/TableOneContent';

export function TableOneFragment(props: Props) {
  const {
    projectId,
    globalHeaderHeight,
    projectHeaderHeight,
    activated,
    globalHeaderRef,
    projectHeaderRef,
  } = props;

  const dispatch = useDispatch();
  const { tableOne, latestChangeLog } = useSelector(
    (state: State) => state.tableOne.slots[projectId],
  ) ?? { tableOne: null, latestChangeLog: null };
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(get(projectId));
  }, [dispatch, projectId]);

  return (
    <div className={styles.container}>
      <div ref={headerRef}>
        <TableOneHeader
          latestChangeLog={latestChangeLog}
          projectId={projectId}
          globalHeaderRef={globalHeaderRef}
          projectHeaderRef={projectHeaderRef}
          activated={activated}
        />
      </div>

      <TableOneContent
        projectId={projectId}
        tableOne={tableOne}
        globalHeaderHeight={globalHeaderHeight}
        projectHeaderHeight={projectHeaderHeight}
        headerHeight={() => headerRef.current?.clientHeight ?? 0}
        activated={activated}
      />

      <TableOneFooter activated={activated} />
    </div>
  );
}

type Props = {
  projectId: string;
  globalHeaderHeight: () => number;
  projectHeaderHeight: () => number;
  globalHeaderRef: RefObject<HTMLDivElement>;
  projectHeaderRef: RefObject<HTMLDivElement>;
  activated: boolean;
};
