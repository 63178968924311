import React, {
  DragEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './TableThreeWeeklyServiceScheduleBox.module.scss';
import { Modal } from '../../components/Modal';
import { TableThreeWeeklyServiceDialog } from './TableThreeWeeklyServiceDialog';
import { WeeklyService } from '../../../../domain/careplan/tablethree/weeklyservice/WeeklyService';
import { WeeklyServiceApi } from '../../../../state/api/careplan/tablethree/weeklyservice/WeeklyServiceApi';
import { useSelector } from 'react-redux';
import { State } from '../../../../state/store';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export function TableThreeWeeklyServiceScheduleBox(props: Props) {
  const {
    activated,
    dragInfo,
    weeklyServices,
    weeklyService,
    projectId,
    refresh,
    width,
    left,
    zIndex,
  } = props;

  const { tableThree } = useSelector(
    (state: State) =>
      state.tableThree.slots[projectId] ?? {
        tableThree: null,
      },
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [height, setHeight] = useState('0px');

  const targetRef = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLImageElement>(null);
  const diffRef = useRef(0);
  const endTime = useRef(weeklyService.endTime);

  useEffect(() => {
    endTime.current = weeklyService.endTime;
  }, [weeklyService]);

  const MinHeight = 6;

  const onDragStart = useCallback((dragItem: WeeklyService) => {
    dragInfo.current = dragItem;
    // eslint-disable-next-line
  }, []);

  const handleChangeHeight = useCallback(
    (clientY: number) => {
      if (clientY === 0 || targetRef.current === null) return;
      const { bottom } = targetRef.current.getBoundingClientRect();
      diffRef.current = Math.round(clientY - bottom);
      const currentHeightString = window
        .getComputedStyle(targetRef.current)
        .getPropertyValue('height');
      const currentHeight = parseFloat(currentHeightString);
      let nextHeight;

      if (diffRef.current > 5) {
        let newMinutes = parseTimeToMinutes(endTime.current) + (15 % (24 * 60));
        if (newMinutes > 1500) {
          newMinutes = 1500;
          nextHeight = currentHeight;
        } else {
          nextHeight = currentHeight + 6;
        }
        endTime.current = formatMinutesToTime(newMinutes);
      } else if (diffRef.current < 0) {
        nextHeight = currentHeight - 6;
        const newMinutes =
          parseTimeToMinutes(endTime.current) - (15 % (24 * 60));
        endTime.current = formatMinutesToTime(newMinutes);
      } else {
        nextHeight = currentHeight;
      }

      if (MinHeight > nextHeight) {
        nextHeight = MinHeight;
        const newMinutes =
          parseTimeToMinutes(weeklyService.startTime) + (30 % (24 * 60));
        endTime.current = formatMinutesToTime(newMinutes);
      }

      targetRef.current.style.height = `${nextHeight}px`;
    },
    [weeklyService.startTime],
  );

  const handleOnDrag = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (!activated) return;
      handleChangeHeight(e.clientY);
    },
    // eslint-disable-next-line
    [handleChangeHeight],
  );

  const handleOnDragStart = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!activated) return;
    if (dragRef.current !== null) {
      e.dataTransfer.setDragImage(dragRef.current, 0, 0);
    }
    // eslint-disable-next-line
  }, []);

  const handleOnDragEnd = useCallback(
    (e: DragEvent<HTMLDivElement>, startHeight: string) => {
      e.stopPropagation();
      if (!activated) return;

      // 同じ時間帯に6つ以上登録させない
      const existWeeklyServices = weeklyServices.filter((exitWeeklyService) => {
        return (
          exitWeeklyService.dayOfWeek === weeklyService.dayOfWeek &&
          exitWeeklyService.id !== weeklyService.id
        );
      });
      const newScheduleStartTime = dayjs(
        `2000-01-01T${weeklyService.startTime}:00Z`,
      );
      const newderScheduleEndTime = dayjs(`2000-01-01T${endTime.current}:00Z`);
      for (
        let i = newScheduleStartTime;
        i.isSameOrBefore(newderScheduleEndTime);
        i = i.add(15, 'm')
      ) {
        let duplicateCount = 0;
        existWeeklyServices.forEach((existWeeklyService) => {
          const weeklyServiceStartTime = dayjs(
            `2000-01-01T${existWeeklyService.startTime}:00Z`,
          );
          const weeklyServiceEndTime = dayjs(
            `2000-01-01T${existWeeklyService.endTime}:00Z`,
          );
          if (
            i.isSameOrAfter(weeklyServiceStartTime) &&
            i.isSameOrBefore(weeklyServiceEndTime)
          ) {
            duplicateCount += 1;
          }
        });
        if (duplicateCount >= 5) {
          if (targetRef.current) {
            targetRef.current.style.height = startHeight;
          }
          return;
        }
      }

      WeeklyServiceApi.update(
        weeklyService.id,
        tableThree.id,
        weeklyService.dayOfWeek,
        weeklyService.startTime.replace(':', ''),
        endTime.current.replace(':', ''),
        weeklyService.establishmentId,
        weeklyService.establishmentName,
        weeklyService.supportServiceTypeId,
        weeklyService.supportServiceType,
        weeklyService.kaigoServiceName,
        weeklyService.remarks,
      ).then(() => {
        refresh();
      });
    },
    // eslint-disable-next-line
    [weeklyService, tableThree, refresh],
  );

  const onClickTableThree = () => {
    if (!activated) return;
    setOpenDialog(true);
  };

  const onRequestCloseTableThreeDialog = () => {
    setOpenDialog(false);
  };

  const calculateTortalHours = (time: string) => {
    const timeArray = time.split(':');
    const startHours = parseInt(timeArray[0], 10);
    const startMinutes = parseInt(timeArray[1], 10);
    return startHours + startMinutes / 60;
  };

  const startTortalHours = calculateTortalHours(weeklyService.startTime);
  const top = 24 * startTortalHours;
  const endTortalHours = calculateTortalHours(weeklyService.endTime);
  if (height !== `${(endTortalHours - startTortalHours) * 24 - 7}px`) {
    setHeight(`${(endTortalHours - startTortalHours) * 24 - 7}px`);
  }

  return (
    <>
      <div
        className={styles.schduleBox}
        key={weeklyService.dayOfWeek}
        style={{ top: top }}
      >
        <div
          className={styles.schduleBoxContainer}
          style={{
            width: `calc((100% - 0px) * ${width})`,
            left: `calc((100% - 0px) * ${left} + 0px)`,
            zIndex: `${zIndex}`,
          }}
        >
          <div
            onClick={() => onClickTableThree()}
            onDragStart={() => onDragStart(weeklyService)}
            draggable={activated}
          >
            <div
              ref={targetRef}
              className={styles.period}
              style={{ height: height }}
            >
              <span className={styles.label}>
                {weeklyService.kaigoServiceName}
              </span>
            </div>
            <div
              className={styles.dragBar}
              onDrag={handleOnDrag}
              onDragStart={handleOnDragStart}
              onDragEnd={(e) => handleOnDragEnd(e, height)}
              draggable={activated}
            >
              <img
                ref={dragRef}
                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                alt=""
              />
            </div>
          </div>
        </div>

        <div className={styles.popup}>
          <div>サービス内容：{weeklyService.kaigoServiceName}</div>
          <div>事業所名：{weeklyService.establishmentName}</div>
          <div>曜日：{weeklyService.dayOfWeek}</div>
          <div>
            時間：
            <span>{weeklyService.startTime}</span>〜
            <span>{weeklyService.endTime}</span>
          </div>
        </div>
      </div>

      <Modal open={openDialog} onRequestClose={onRequestCloseTableThreeDialog}>
        <TableThreeWeeklyServiceDialog
          onRequestClose={onRequestCloseTableThreeDialog}
          projectId={projectId}
          weeklyServices={weeklyServices}
          weeklyService={weeklyService}
          refresh={refresh}
        />
      </Modal>
    </>
  );
}

const parseTimeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

const formatMinutesToTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
};

type Props = {
  activated: boolean;
  time: string;
  week: string;
  dragInfo: React.MutableRefObject<WeeklyService | null>;
  weeklyServices: WeeklyService[];
  weeklyService: WeeklyService;
  projectId: string;
  refresh: () => void;
  width: number;
  left: number;
  zIndex: number;
};
