import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { User } from '../../../../../domain/user/User';
import { Paths } from '../../../../../Paths';
import { RootState } from '../../../../../state/store';
import {
  clearMyselfFailure,
  updateDisplayName,
} from '../../../../../state/usecase/authentication/action';
import { DisplayNameChangeForm } from './DisplayNameChangeForm';

export function EnhancedDisplayNameChangeForm() {
  const dispatch = useDispatch();
  const myself: User = useSelector(
    (store: RootState) =>
      store.authentication.user ?? {
        id: '',
        displayName: '',
        email: '',
        isAdmin: false,
      },
  );
  const updateFailed: boolean = useSelector(
    (store: RootState) => store.authentication.updateFailed,
  );
  const [displayName, setDisplayName] = useState<string>(myself.displayName);
  const history = useHistory();

  useEffect(
    () => () => {
      dispatch(clearMyselfFailure());
    },
    [dispatch],
  );

  const validate = (): boolean => displayName !== '';

  const backToSettingPage = (type?: string) =>
    type
      ? history.push(Paths.userSetting.build({ type }))
      : history.push(Paths.userSettings);

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const updatedMyself: User = {
      ...myself,
      displayName,
    };
    dispatch(
      updateDisplayName(updatedMyself, () => backToSettingPage('displayName')),
    );
  };

  const onCancel = (event: FormEvent) => {
    event.preventDefault();

    backToSettingPage();
  };

  return (
    <DisplayNameChangeForm
      displayName={displayName}
      setDisplayName={setDisplayName}
      validate={validate}
      updateFailed={updateFailed}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
}
