import 'whatwg-fetch';
import { Get } from './Get';
import { ApiError } from './../../ApiError';
import { get, queryString } from '../../functions';

export const TableThreeApi = new (class {
  async get(projectId: string): Promise<Get | ApiError> {
    const params = { projectId };
    const response = await get(`${Get.path}?${queryString(params)}`);
    return (response.ok ? Get.extract : ApiError.extract)(response);
  }
})();
