import 'whatwg-fetch';
import { ProjectListResponse } from './ProjectListResponse';
import { Create } from './Create';
import { ApiError } from '../ApiError';
import { get, post, queryString } from '../functions';
import { Project } from '../../../domain/project/Project';

export const ProjectApi = new (class {
  async getList(
    careRecipientId: string,
  ): Promise<ProjectListResponse | ApiError> {
    const params = { careRecipientId };
    const response = await get(`/api/project/getList?${queryString(params)}`);
    return (response.ok ? ProjectListResponse.extract : ApiError.extract)(
      response,
    );
  }

  async create(
    sourceProjectId: string,
    fromScratch: boolean,
  ): Promise<Create | ApiError> {
    const body = { sourceProjectId, fromScratch };
    const response = await post('/api/project/create', body);
    return (response.ok ? Create.extract : ApiError.extract)(response);
  }

  async findProject(projectId: string): Promise<Project> {
    return await get(`/api/project/${projectId}`).then((response) =>
      response.json(),
    );
  }
})();
