import { Reducer } from 'redux';
import { TableCommonActions } from './type';
import { TableCommon } from '../../../domain/careplan/tablecommon/TableCommon';
import { ChangeLog } from '../../../domain/changelog/ChangeLog';

type Slot = {
  tableCommon: TableCommon;
  latestChangeLog: ChangeLog | null;
};

export type TableCommonState = Readonly<{
  slots: { [key: string]: Slot };
  getting: boolean;
}>;

const initialState: TableCommonState = {
  slots: {},
  getting: false,
};

export const tableCommonReducer: Reducer<
  TableCommonState,
  TableCommonActions
> = (state = initialState, action): TableCommonState => {
  switch (action.type) {
    case 'TABLE_COMMON_GET':
      return {
        ...state,
        getting: true,
      };
    case 'TABLE_COMMON_GET_SUCCESS':
      return {
        ...state,
        slots: {
          ...state.slots,
          [action.payload.projectId]: {
            tableCommon: action.payload.tableCommon,
            latestChangeLog: action.payload.latestChangeLog,
          },
        },
        getting: false,
      };
    case 'TABLE_COMMON_GET_FAILURE':
      return {
        ...state,
        getting: false,
      };
    case 'TABLE_COMMON_UPDATE_ITEM':
      return updateSlot(state, action.payload.projectId, (slot) => {
        return {
          tableCommon: slot.tableCommon.updateValue(
            action.payload.path,
            action.payload.value,
          ),
          latestChangeLog: slot.latestChangeLog,
        };
      });
    case 'TABLE_COMMON_UPDATE_ITEM_SUCCESS':
      return updateSlot(state, action.payload.projectId, (slot) => {
        return {
          tableCommon: slot.tableCommon,
          latestChangeLog: action.payload.changeLog,
        };
      });
    case 'TABLE_COMMON_UPDATE_ITEM_FAILURE':
      return updateSlot(state, action.payload.projectId, (slot) => {
        return {
          tableCommon: slot.tableCommon.updateValue(
            action.payload.path,
            action.payload.value,
          ),
          latestChangeLog: slot.latestChangeLog,
        };
      });
    default:
      return state;
  }
};

function updateSlot(
  state: TableCommonState,
  projectId: string,
  update: (slot: Slot) => Slot,
): TableCommonState {
  const slot = state.slots[projectId];
  if (!slot) return state;
  return {
    ...state,
    slots: {
      ...state.slots,
      [projectId]: update(slot),
    },
  };
}
