import React from 'react';
import { TextButton } from '../../../../../components/button/TextButton';
import { LeftArrowIcon } from '../../../../../components/icon/LeftArrowIcon';

export function BackButton(props: Props) {
  const { onClick } = props;

  return (
    <>
      <TextButton leading={<LeftArrowIcon />} onClick={onClick}>
        事業所提案画面
      </TextButton>
    </>
  );
}

type Props = {
  onClick: () => void;
};
