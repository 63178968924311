import React from 'react';
import styles from './CareRecipientList.module.scss';
import { CareRecipientRow } from '../../../../domain/carerecipient/CareRecipientRow';
import { CareRecipientListSyllabaryColumnItem } from './CareRecipientListSyllabaryColumnItem';
import { CareRecipientListItem } from './CareRecipientListItem';
import { syllabaryColumnId } from './functions';

export function CareRecipientList(props: Props) {
  const { careRecipients, refresh } = props;

  let lastSyllabaryColumn: string | null = null;

  return (
    <ul>
      {careRecipients.flatMap((careRecipient) => {
        const item = (
          <li key={careRecipient.id} className={styles.item}>
            <CareRecipientListItem
              careRecipient={careRecipient}
              refresh={refresh}
            />
          </li>
        );
        const syllabaryColumn = careRecipient.syllabaryColumn;
        if (syllabaryColumn === lastSyllabaryColumn) {
          return [item];
        } else {
          lastSyllabaryColumn = syllabaryColumn; // MUTATE lastSyllabaryColumn!
          return [
            <li
              key={syllabaryColumnId(syllabaryColumn)}
              id={syllabaryColumnId(syllabaryColumn)}
              className={styles.syllabaryColumnItem}
            >
              <CareRecipientListSyllabaryColumnItem
                syllabaryColumn={syllabaryColumn}
              />
            </li>,
            item,
          ];
        }
      })}
    </ul>
  );
}

type Props = {
  careRecipients: CareRecipientRow[];
  refresh: () => void;
};
