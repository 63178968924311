import React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../../state/store';
import styles from './AssessmentSheetHeader.module.scss';
import { ChangeLog } from '../../../../domain/changelog/ChangeLog';
import { AssessmentSheetSection } from '../AssessmentSheetSection';
import { AssessmentSheetSectionTitle } from './AssessmentSheetSectionTitle';
import { Paths } from '../../../../Paths';
import { ExcelDownLoadButton } from '../../components/button/ExcelDownloadButton';
import { jpFormat } from 'dayjs-jp-format';
import dayjs from 'dayjs';

dayjs.extend(jpFormat);

export function AssessmentSheetHeader(props: Props) {
  const { projectId, latestChangeLog } = props;

  const rsLoginIn = useSelector(
    (state: State) => state.authentication.rsLoginIn,
  );

  const stylesLastEditedAt = rsLoginIn
    ? styles.lastEditedAtRsLogin
    : styles.lastEditedAt;

  const formatEditedAt = (latestChangeLog: ChangeLog) => {
    return latestChangeLog.when
      .toDate()
      .toLocaleDateString('ja-JP-u-ca-japanese', {
        era: 'narrow',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
  };

  return (
    <div className={styles.container}>
      <AssessmentSheetSectionTitle section={AssessmentSheetSection.FACE_SHEET}>
        フェースシート
      </AssessmentSheetSectionTitle>
      <div className={styles.menu}>
        {latestChangeLog && (
          <div className={stylesLastEditedAt}>
            最終更新: {formatEditedAt(latestChangeLog)}
          </div>
        )}
        {!rsLoginIn && (
          <ExcelDownLoadButton
            path={Paths.assessmentSheetDownloadExcel.build({ projectId })}
          />
        )}
      </div>
    </div>
  );
}

type Props = {
  projectId: string;
  latestChangeLog: ChangeLog | null;
};
