import { User } from '../../../domain/user/User';
import { ROOT_TYPE } from '../../RootAction';

export type LoginAction = {
  type: 'AUTHENTICATION_LOGIN';
  payload: {};
};

export type LoginSuccessAction = {
  type: 'AUTHENTICATION_LOGIN_SUCCESS';
  payload: {
    user: User;
  };
};

export type LoginFailureAction = {
  type: 'AUTHENTICATION_LOGIN_FAILURE';
  payload: {};
};

export type LoginActions =
  | LoginAction
  | LoginSuccessAction
  | LoginFailureAction;

export type LogoutAction = {
  type: 'AUTHENTICATION_LOGOUT';
  payload: {};
};

export type LogoutSuccessAction = {
  type: typeof ROOT_TYPE.RESET_ALL;
  payload: {};
};

export type LogoutFailureAction = {
  type: 'AUTHENTICATION_LOGOUT_FAILURE';
  payload: {};
};

export type LogoutActions =
  | LogoutAction
  | LogoutSuccessAction
  | LogoutFailureAction;

export type VerifyAction = {
  type: 'AUTHENTICATION_VERIFY';
  payload: {};
};

export type VerifySuccessAction = {
  type: 'AUTHENTICATION_VERIFY_SUCCESS';
  payload: {
    user: User;
    rsLoginIn: boolean;
  };
};

export type VerifyFailureAction = {
  type: 'AUTHENTICATION_VERIFY_FAILURE';
  payload: {};
};

export type VerifyActions =
  | VerifyAction
  | VerifySuccessAction
  | VerifyFailureAction;

export const MyselfActionType = {
  MYSELF_UPDATE_SUCCESS: 'MYSELF_UPDATE_SUCCESS',
  MYSELF_UPDATE_FAILURE: 'MYSELF_UPDATE_FAILURE',
  MYSELF_CLEAR_FAILURE: 'MYSELF_CLEAR_FAILURE',
} as const;

type MyselfActionType =
  (typeof MyselfActionType)[keyof typeof MyselfActionType];

export type MyselfAction = {
  type: MyselfActionType;
  payload?: {
    myself: User;
  };
};

export type AuthenticationActions =
  | LoginActions
  | LogoutActions
  | VerifyActions
  | MyselfAction;
