import { User } from '../user/User';
import { OffsetDateTime } from '../fundamental/OffsetDateTime';
import { ChangeLogWhat } from './ChangeLogWhat';

const changeLogType = Symbol();

export class ChangeLog {
  [changeLogType]: any;

  who: User;

  when: OffsetDateTime;

  what: ChangeLogWhat;

  constructor(who: User, when: OffsetDateTime, what: ChangeLogWhat) {
    this.who = who;
    this.when = when;
    this.what = what;
  }
}
