import { EstablishmentDetail } from '../../../../domain/establishment/EstablishmentDetail';

export class FetchEstablishmentDetail {
  establishmentDetail: EstablishmentDetail;

  constructor(establishmentDetail: EstablishmentDetail) {
    this.establishmentDetail = establishmentDetail;
  }

  static async extract(response: Response): Promise<FetchEstablishmentDetail> {
    const json = await response.json();
    return new FetchEstablishmentDetail(json);
  }
}
