import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import styles from './TextButton.module.scss';
import { styleForWidth } from '../element/Width';

export function TextButton(props: ButtonHTMLAttributes<any> & Props) {
  const { children, leading, trailing, ...rest } = props;
  const buttonStyle = styleForWidth('intrinsic');
  return (
    <button className={styles.button} style={buttonStyle} {...rest}>
      <span className={styles.focus}>
        {leading && <span className={styles.leading}>{leading}</span>}
        <span className={styles.text}>{children}</span>
        {trailing && <span className={styles.trailing}>{trailing}</span>}
      </span>
    </button>
  );
}

type Props = {
  leading?: ReactNode;
  trailing?: ReactNode;
};
