import 'whatwg-fetch';
import { get, queryString, post } from '../../../functions';
import { Get } from './Get';
import { ApiError } from '../../../ApiError';

export const MainActivitiesApi = new (class {
  async get(carePlanTableThreeId: string): Promise<Get | ApiError> {
    const params = { carePlanTableThreeId };
    const response = await get(`${Get.path}?${queryString(params)}`);
    return (response.ok ? Get.extract : ApiError.extract)(response);
  }

  async register(
    carePlanTableThreeId: string,
    jikan: number,
    activityContent: string,
  ) {
    const body = {
      carePlanTableThreeId,
      jikan,
      activityContent,
    };
    const response = await post('/api/mainActivities/register', body);
    if (!response.ok) {
      return ApiError.extract(response);
    }
  }

  async update(
    id: string,
    carePlanTableThreeId: string,
    jikan: number,
    activityContent: string,
  ) {
    const body = {
      id,
      carePlanTableThreeId,
      jikan,
      activityContent,
    };
    const response = await post('/api/mainActivities/update', body);
    if (!response.ok) {
      return ApiError.extract(response);
    }
  }
})();
