// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React from 'react';
import styles from './Section.module.scss';
import { AssessmentSheetUpdater } from './AssessmentSheetUpdater';
import { AssessmentSheetField } from './AssessmentSheetField';
import {
  DekiruIchibuZen,
  DekiruIchibuZenOkonatteinai,
  DekiruSasaeDekinai,
  DekiruTedeSasaeDekinai,
  DekiruTsukamaruDekinai,
  FutsuIchimeterMenomaeMienaiHandanfuno,
  FutsuYattoOgoeKikoenaiHandanfuno,
  Genjo,
  KazokuJisshi,
  Keikaku,
  Umu,
  YoEnjoKeikaku,
} from './types';
import {
  dekiruIchibuZenLabels,
  dekiruIchibuZenOkonatteinaiLabels,
  dekiruSasaeDekinaiLabels,
  dekiruTedeSasaeDekinaiLabels,
  dekiruTsukamaruDekinaiLabels,
  futsuIchimeterMenomaeMienaiHandanfunoLabels,
  futsuYattoOgoeKikoenaiHandanfunoLabels,
  genjoLabels,
  kazokuJisshiLabels,
  keikakuLabels,
  umuLabels,
  yoEnjoKeikakuLabels,
} from './labels';
import { checkboxWith, textWith } from './fields';

export function KihonDosaSection(props: Props) {
  const { assessmentSheetUpdater, activated } = props;
  const assessmentSheetField = new AssessmentSheetField(activated);

  return (
    <>
      <div className={styles.card}>
        <h3 className={styles.title}>要介護認定項目</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-1 麻痺等
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.mahiNado.nai',
                    checkboxWith('問題ない'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.mahiNado.hidariUe',
                    checkboxWith('左上肢'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.mahiNado.migiUe',
                    checkboxWith('右上肢'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.mahiNado.hidariShita',
                    checkboxWith('左下肢'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.mahiNado.migiShita',
                    checkboxWith('右下肢'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.mahiNado.hoka',
                    checkboxWith('その他'),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-2 拘縮
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.koshuku.nai',
                    checkboxWith('問題ない'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.koshuku.kata',
                    checkboxWith('肩関節'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.koshuku.mata',
                    checkboxWith('股関節'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.koshuku.hiza',
                    checkboxWith('膝関節'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.koshuku.hoka',
                    checkboxWith('その他'),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-3 寝返り
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruTsukamaruDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.negaeri',
                dekiruTsukamaruDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-4 起き上がり
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruTsukamaruDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.okiagari',
                dekiruTsukamaruDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-5 座位保持
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruTedeSasaeDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.zaiHoji',
                dekiruTedeSasaeDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-6 両足での立位保持
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruSasaeDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.ryoashiDeNoRitsuiHoji',
                dekiruSasaeDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-7 歩行
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruTsukamaruDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.hoko',
                dekiruTsukamaruDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-8 立ち上がり
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruTsukamaruDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.tachiagari',
                dekiruTsukamaruDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-9 片足での立位保持
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruTsukamaruDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.kataashiDeNoRitsuiHoji',
                dekiruTsukamaruDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-10 洗身
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruIchibuZenOkonatteinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.senshin',
                dekiruIchibuZenOkonatteinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-11 つめ切り
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.tsumeKiri',
                dekiruIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-12 視力
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<FutsuIchimeterMenomaeMienaiHandanfuno | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.shiryoku',
                futsuIchimeterMenomaeMienaiHandanfunoLabels,
                { radioPattern: 'vertical' },
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-13 聴力
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<FutsuYattoOgoeKikoenaiHandanfuno | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.choryoku',
                futsuYattoOgoeKikoenaiHandanfunoLabels,
                { radioPattern: 'vertical' },
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                1-14 関節の動き
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.kansetsuNoUgoki.nai',
                    checkboxWith('問題ない'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.kansetsuNoUgoki.kata',
                    checkboxWith('肩関節'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.kansetsuNoUgoki.hiji',
                    checkboxWith('肘関節'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.kansetsuNoUgoki.mata',
                    checkboxWith('股関節'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.kansetsuNoUgoki.hiza',
                    checkboxWith('膝関節'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.kansetsuNoUgoki.ashi',
                    checkboxWith('足関節'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.kansetsuNoUgoki.hoka',
                    checkboxWith('その他'),
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（体位変換・起居）体位変換介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.taiiHenkanKikyo.taiiHenkanKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.taiiHenkanKikyo.taiiHenkanKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.taiiHenkanKikyo.taiiHenkanKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.taiiHenkanKikyo.taiiHenkanKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（体位変換・起居）起居介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.taiiHenkanKikyo.kikyoKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.taiiHenkanKikyo.kikyoKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.taiiHenkanKikyo.kikyoKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.taiiHenkanKikyo.kikyoKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（体位変換・起居）リハビリの必要性</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                （体位変換・起居）リハビリの必要性
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.taiiHenkanKikyo.rehabilitationNoHitsuyoSei',
                umuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>
          （体位変換・起居）特記、解決すべき課題など
        </h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                （体位変換・起居）特記、解決すべき課題など
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.taiiHenkanKikyo.tokkiKaiketsuSuBekiKadaiNado',
                { width: 'auto', rows: 5 },
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（入浴）準備・後始末</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.jumbiAtoshimatsu.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.jumbiAtoshimatsu.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.jumbiAtoshimatsu.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.jumbiAtoshimatsu.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（入浴）移乗移動介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.ijoIdoKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.ijoIdoKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.ijoIdoKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.ijoIdoKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                現状
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Genjo | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.ijoIdoKaijoGenjoKeikaku.genjo',
                genjoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Keikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.ijoIdoKaijoGenjoKeikaku.keikaku',
                keikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（入浴）洗身介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.senshinKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.senshinKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.senshinKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.senshinKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                現状
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Genjo | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.senshinKaijoGenjoKeikaku.genjo',
                genjoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Keikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.senshinKaijoGenjoKeikaku.keikaku',
                keikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（入浴）洗髪介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.sempatsuKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.sempatsuKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.sempatsuKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.sempatsuKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（入浴）清拭・部分浴</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.seishikiBubunYoku.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.seishikiBubunYoku.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.seishikiBubunYoku.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.seishikiBubunYoku.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（入浴）褥瘡・皮膚疾患の対応</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.jokusoHifuShikkanNoTaio.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.jokusoHifuShikkanNoTaio.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.jokusoHifuShikkanNoTaio.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.jokusoHifuShikkanNoTaio.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（入浴）特記、解決すべき課題など</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                （入浴）特記、解決すべき課題など
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.nyuyoku.tokkiKaiketsuSuBekiKadaiNado',
                { width: 'auto', rows: 5 },
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>コミュニケーションの状況・方法</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                視聴覚
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.communicationNoJokyoHoho.shichokaku.meganeShiyo',
                    checkboxWith('眼鏡使用'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.communicationNoJokyoHoho.shichokaku.contactShiyo',
                    checkboxWith('コンタクト使用'),
                  )}
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.communicationNoJokyoHoho.shichokaku.hochokiShiyo',
                    checkboxWith('補聴器使用'),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                電話
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.communicationNoJokyoHoho.denwa',
                umuLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                言語障害
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.vertical}>
                  {assessmentSheetField.renderRadioField<Umu | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.communicationNoJokyoHoho.gengoShogai.umu',
                    umuLabels,
                    { tag: false },
                  )}
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.communicationNoJokyoHoho.gengoShogai.naiyo',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                コミュニケーション支援機器の使用
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.vertical}>
                  {assessmentSheetField.renderRadioField<Umu | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.communicationNoJokyoHoho.communicationShienKikiNoShiyo.umu',
                    umuLabels,
                    { tag: false },
                  )}
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.communicationNoJokyoHoho.communicationShienKikiNoShiyo.naiyo',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                特記、解決すべき課題など
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.kihonDosa.communicationNoJokyoHoho.tokkiKaiketsuSuBekiKadaiNado',
                { width: 'auto', rows: 5 },
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

type Props = {
  assessmentSheetUpdater: AssessmentSheetUpdater;
  activated: boolean;
};
