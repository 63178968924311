import React from 'react';
import styles from './Header.module.scss';

export function Header(props: Props) {
  const { shortTermGoal, supportServiceText } = props;

  return (
    <>
      <div className={styles.row}>
        <div className={styles.title}>短期目標</div>
        <div className={styles.content}>{shortTermGoal}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.title}>サービス内容</div>
        <div className={styles.content}>{supportServiceText}</div>
      </div>
    </>
  );
}

type Props = {
  shortTermGoal: string | null;
  supportServiceText: string | null;
};
