// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React from 'react';
import styles from './Section.module.scss';
import { AssessmentSheetUpdater } from './AssessmentSheetUpdater';
import { AssessmentSheetField } from './AssessmentSheetField';
import {
  DekiruTokubetsuNanDekinai,
  DoZaYoko,
  JiritsuIchibuZen,
  KazokuJisshi,
  NaiTokidokiAru,
  NashiAru,
  NashiIchibuZen,
  NashiMimamoriIchibuZen,
  Umu,
  YoEnjoKeikaku,
} from './types';
import {
  dekiruTokubetsuNanDekinaiLabels,
  doZaYokoLabels,
  jiritsuIchibuZenLabels,
  kazokuJisshiLabels,
  naiTokidokiAruLabels,
  nashiAruLabels,
  nashiIchibuZenLabels,
  nashiMimamoriIchibuZenLabels,
  umuLabels,
  yoEnjoKeikakuLabels,
} from './labels';
import { checkboxWith, textWith } from './fields';

export function ShakaiSeikatsuRyokuSection(props: Props) {
  const { assessmentSheetUpdater, activated } = props;
  const assessmentSheetField = new AssessmentSheetField(activated);

  return (
    <>
      <div className={styles.card}>
        <h3 className={styles.title}>要介護認定項目</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                5-1 薬の内服
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kusuriNoNaifuku',
                nashiIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                5-2 金銭の管理
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kinsenNoKanri',
                nashiIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                5-3 日常の意思決定
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruTokubetsuNanDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.nichijoNoIshiKettei',
                dekiruTokubetsuNanDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                5-4 集団への不適応
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.shudanHeNoFuTekio',
                naiTokidokiAruLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                5-5 買い物
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiMimamoriIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kaimono',
                nashiMimamoriIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                5-6 簡単な調理
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiMimamoriIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kantanNaChori',
                nashiMimamoriIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                5-7 電話の利用
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<JiritsuIchibuZen | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.denwaNoRiyo',
                jiritsuIchibuZenLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                5-8 日中の活動（生活）状況等
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DoZaYoko | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.nitchuNoKatsudoJokyoNado',
                doZaYokoLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                5-9 家族・居住環境・社会参加の状況などの変化
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NashiAru | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kazokuKyojuKankyoShakaiSankaNoJokyoNadoNoHenka',
                nashiAruLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>金銭管理</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kinsenKanri.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kinsenKanri.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kinsenKanri.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kinsenKanri.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>買い物</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kaimonoEnjo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kaimonoEnjo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kaimonoEnjo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kaimonoEnjo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>調理</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.chori.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.chori.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.chori.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.chori.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>準備・後始末</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.jumbiAtoshimatsu.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.jumbiAtoshimatsu.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.jumbiAtoshimatsu.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.jumbiAtoshimatsu.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>定期的な相談・助言</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.teikiTekiNaSodanJogen.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.teikiTekiNaSodanJogen.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.teikiTekiNaSodanJogen.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.teikiTekiNaSodanJogen.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>各種書類作成代行</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kakushuShoruiSakuseiDaiko.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kakushuShoruiSakuseiDaiko.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kakushuShoruiSakuseiDaiko.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kakushuShoruiSakuseiDaiko.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>余暇活動支援</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.yokaKatsudoShien.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.yokaKatsudoShien.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.yokaKatsudoShien.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.yokaKatsudoShien.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>移送・外出介助</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.isoGaishutsuKaijo.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.isoGaishutsuKaijo.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.isoGaishutsuKaijo.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.isoGaishutsuKaijo.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>代読・代筆</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.daidokuDaihitsu.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.daidokuDaihitsu.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.daidokuDaihitsu.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.daidokuDaihitsu.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>話し相手</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.hanashiaite.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.hanashiaite.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.hanashiaite.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.hanashiaite.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>安否確認</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.ampiKakunin.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.ampiKakunin.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.ampiKakunin.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.ampiKakunin.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>緊急連絡手段の確保</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kinkyuRenrakuShudanNoKakuho.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kinkyuRenrakuShudanNoKakuho.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kinkyuRenrakuShudanNoKakuho.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kinkyuRenrakuShudanNoKakuho.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>家族連絡の確保</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kazokuRenrakuNoKakuho.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kazokuRenrakuNoKakuho.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kazokuRenrakuNoKakuho.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kazokuRenrakuNoKakuho.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>社会活動への支援</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.shakaiKatsudoHeNoShien.enjoNoGenjo.kazokuJisshi',
                kazokuJisshiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                サービス実施
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.shakaiKatsudoHeNoShien.enjoNoGenjo.serviceJisshi',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                希望
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.horizontal}>
                  {assessmentSheetUpdater.of<boolean>(
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.shakaiKatsudoHeNoShien.kibo',
                    checkboxWith(),
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                要援助→計画
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.shakaiKatsudoHeNoShien.yoEnjoKeikaku',
                yoEnjoKeikakuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>社会活動の状況</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                家族等近親者との交流
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.vertical}>
                  {assessmentSheetField.renderRadioField<Umu | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.shakaiKatsudoNoJokyo.kazokuNadoKinshinShaToNoKoryu.umu',
                    umuLabels,
                    { tag: false },
                  )}
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.shakaiKatsudoNoJokyo.kazokuNadoKinshinShaToNoKoryu.naiyo',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                地域近隣との交流
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.vertical}>
                  {assessmentSheetField.renderRadioField<Umu | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.shakaiKatsudoNoJokyo.chiikiKinrinToNoKoryu.umu',
                    umuLabels,
                    { tag: false },
                  )}
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.shakaiKatsudoNoJokyo.chiikiKinrinToNoKoryu.naiyo',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                友人知人との交流
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.vertical}>
                  {assessmentSheetField.renderRadioField<Umu | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.shakaiKatsudoNoJokyo.yujinChijinToNoKoryu.umu',
                    umuLabels,
                    { tag: false },
                  )}
                  {assessmentSheetField.renderField<string | null>(
                    assessmentSheetUpdater,
                    'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.shakaiKatsudoNoJokyo.yujinChijinToNoKoryu.naiyo',
                    textWith({ width: 280 }),
                    { tag: false },
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>緊急連絡・見守りの方法</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                緊急連絡・見守りの方法
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.kinkyuRenrakuMimamoriNoHoho',
                textWith({ width: 'auto' }),
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>特記、解決すべき課題など</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                特記、解決すべき課題など
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.shakaiSeikatsuRyoku.tokkiKaiketsuSuBekiKadaiNado',
                { width: 'auto', rows: 5 },
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

type Props = {
  assessmentSheetUpdater: AssessmentSheetUpdater;
  activated: boolean;
};
