import { TableCommon } from '../../../../domain/careplan/tablecommon/TableCommon';
import { ChangeLog } from '../../../../domain/changelog/ChangeLog';
import { changeLog } from '../../changelog/LatestChangeLog';

export class Get {
  type: 'SUCCESS' = 'SUCCESS';

  projectId: string;

  tableCommon: TableCommon;

  latestChangeLog: ChangeLog | null;

  constructor(
    projectId: string,
    tableCommon: TableCommon,
    latestChangeLog: ChangeLog | null,
  ) {
    this.projectId = projectId;
    this.tableCommon = tableCommon;
    this.latestChangeLog = latestChangeLog;
  }

  static path = '/api/tableCommon/get';

  static async extract(response: Response): Promise<Get> {
    const json = await response.json();
    const tableCommon = new TableCommon(json.tableCommon);
    const latestChangeLog = json.latestChangeLog
      ? changeLog(json.latestChangeLog)
      : null;
    return new Get(json.project.id, tableCommon, latestChangeLog);
  }
}
