import React from 'react';

export function SearchIcon() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0323 11.9438C12.9758 11.8852 12.8978 11.8521 12.8164 11.8521H12.2234C12.1451 11.8521 12.07 11.821 12.0147 11.7656C11.9051 11.656 11.8997 11.4806 11.9963 11.3595C14.2346 8.55418 13.8105 4.48831 11.0712 2.11427C8.2622 -0.32018 4.04872 0.0543512 1.61427 2.86333C-0.82018 5.67232 -0.445649 9.88579 2.36333 12.3202C4.82149 14.4142 8.34193 14.4721 10.8588 12.494C10.9806 12.3983 11.1556 12.4046 11.2651 12.5141C11.3208 12.5698 11.3521 12.6454 11.3521 12.7242V13.3196C11.3521 13.3991 11.3837 13.4754 11.4399 13.5317L16.2897 18.3815C16.4069 18.4987 16.5969 18.4987 16.714 18.3815L17.7917 17.3038C17.9073 17.1882 17.909 17.0012 17.7955 16.8834L13.0323 11.9438ZM6.76407 11.8521C4.23599 11.8521 2.08244 9.79216 2.08244 7.26407C2.08244 4.73599 4.14235 2.58244 6.67044 2.58244C9.19852 2.58244 11.3521 4.64235 11.3521 7.17044C11.3521 9.79216 9.29216 11.8521 6.76407 11.8521Z"
        fill="none"
      />
    </svg>
  );
}
