import React, { ButtonHTMLAttributes } from 'react';
import styles from './CloseButton.module.scss';
import { CloseIcon } from '../icon/CloseIcon';

export function CloseButton(props: ButtonHTMLAttributes<any>) {
  return (
    <button className={styles.button} tabIndex={-1} {...props}>
      <CloseIcon />
    </button>
  );
}
