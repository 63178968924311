import 'whatwg-fetch';
import { ShiftAction } from '../../../../../domain/careplan/tabletwo/ShiftAction';
import { ApiError } from '../../../ApiError';
import { post } from '../../../functions';
import { EditedNeed } from './EditedNeed';

export const NeedApi = new (class {
  async update(
    id: string,
    text: string | null,
  ): Promise<EditedNeed | ApiError> {
    const body = {
      id,
      text,
    };
    const response = await post('/api/tableTwo/need/update', body);
    return (response.ok ? EditedNeed.extract : ApiError.extract)(response);
  }

  async addLongTermGoal(
    needId: string,
    longTermGoalId: string,
  ): Promise<EditedNeed | ApiError> {
    const body = {
      needId,
      longTermGoalId,
    };
    const response = await post('/api/tableTwo/need/addSubItem', body);
    return (response.ok ? EditedNeed.extract : ApiError.extract)(response);
  }

  async shiftLongTermGoal(
    needId: string,
    longTermGoalId: string,
    action: ShiftAction,
  ): Promise<EditedNeed | ApiError> {
    const body = {
      needId,
      longTermGoalId,
      action,
    };
    const response = await post('/api/tableTwo/need/shiftSubItem', body);
    return (response.ok ? EditedNeed.extract : ApiError.extract)(response);
  }

  async removeLongTermGoal(
    needId: string,
    longTermGoalId: string,
  ): Promise<EditedNeed | ApiError> {
    const body = {
      needId,
      longTermGoalId,
    };
    const response = await post('/api/tableTwo/need/removeSubItem', body);
    return (response.ok ? EditedNeed.extract : ApiError.extract)(response);
  }
})();
