import 'whatwg-fetch';
import { CarePlanTableTwoPeriod } from '../../../../../domain/careplan/tabletwo/CarePlanTableTwoPeriod';
import { InsuranceBenefit } from '../../../../../domain/careplan/tabletwo/supportservicetype/InsuranceBenefit';
import { ApiError } from '../../../ApiError';
import { post } from '../../../functions';
import { EditedSupportServiceType } from './EditedSupportServiceType';
import { toPeriodObject } from '../PeriodObject';
import { EditedCaraPlanTableTwo } from '../EditedCaraPlanTableTwo';
import { EditedSupoprtService } from '../supportservice/EditedSupoprtService';

export const SupportServiceTypeApi = new (class {
  async update(
    id: string,
    code: string | null,
    text: string | null,
    insuranceBenefit: InsuranceBenefit | null,
    establishment: string | null,
    frequency: string | null,
    period: CarePlanTableTwoPeriod | null,
  ): Promise<EditedSupportServiceType | ApiError> {
    const body = {
      id,
      code,
      text,
      insuranceBenefit,
      establishment,
      frequency,
      period: period && toPeriodObject(period),
    };
    const response = await post(
      '/api/tableTwo/supportServiceType/update',
      body,
    );
    return (response.ok ? EditedSupportServiceType.extract : ApiError.extract)(
      response,
    );
  }

  async copyPeriod(
    supportServiceTypeId: string,
  ): Promise<EditedCaraPlanTableTwo | ApiError> {
    const body = {
      supportServiceTypeId,
    };
    const response = await post(
      '/api/tableTwo/supportServiceType/copyPeriod',
      body,
    );
    return (response.ok ? EditedCaraPlanTableTwo.extract : ApiError.extract)(
      response,
    );
  }

  async addEstablishments(
    establishments: { text: string; establishmentName: string }[],
    supportServiceId: string,
    supportServiceTypeId: string,
  ): Promise<EditedSupoprtService | ApiError> {
    const body = {
      establishments,
      supportServiceId,
      supportServiceTypeId,
    };
    const response = await post(
      '/api/tableTwo/supportServiceType/addEstablishments',
      body,
    );
    return (response.ok ? EditedSupoprtService.extract : ApiError.extract)(
      response,
    );
  }
})();
