import React from 'react';

export function CategoryKokyukikeiShikkanIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.15293 10.8543C8.99013 10.9854 8.7517 10.9588 8.62175 10.7951C8.4931 10.633 8.51935 10.3974 8.68053 10.2676L10.2659 8.99101C10.3344 8.93463 10.3905 8.86459 10.4308 8.78528C10.4711 8.70597 10.4947 8.61911 10.5 8.53018V5.12509C10.5 4.91794 10.6679 4.75 10.8751 4.75C11.0822 4.75 11.2502 4.91793 11.2502 5.12509V8.53018C11.2451 8.73014 11.1974 8.92666 11.1103 9.10641C11.0231 9.28616 10.8987 9.44494 10.7453 9.57199L9.15293 10.8543Z"
        fill="#7B7C88"
      />
      <path
        d="M4.25582 19.1026H4.19729C3.45559 19.085 3.19511 18.7655 3.10708 18.5005C2.62364 17.0433 3.91401 11.972 4.0628 11.3982C4.42382 10.0067 5.37105 8.77548 6.95855 7.63377C8.12605 6.79448 9.26719 6.79313 9.88959 7.04874C10.3328 7.27084 10.747 7.5473 11.1228 7.87183L10.6255 8.43751C10.3125 8.1711 9.97128 7.9402 9.6081 7.74898C9.56833 7.73323 8.62513 7.36466 7.39418 8.25075C5.95547 9.2858 5.10341 10.3776 4.78841 11.5913C4.24867 13.6735 3.50787 17.3259 3.8184 18.2628C3.8184 18.2628 3.95244 18.3979 4.60478 18.329C6.42998 18.1301 9.21044 16.7539 9.39944 16.3295C9.90632 15.1876 10.1538 13.9461 10.1237 12.6956V11.553H10.8743V12.6862C10.905 14.0328 10.6402 15.3697 10.0987 16.6014C9.71936 17.5887 5.89202 19.1026 4.25582 19.1026Z"
        fill="#7B7C88"
      />
      <path
        d="M15.377 10.7949C15.2471 10.9587 15.0087 10.9853 14.8458 10.8542L13.2647 9.58099C13.1085 9.45373 12.9815 9.29392 12.8924 9.11253C12.8033 8.93114 12.7543 8.73247 12.7487 8.53018V5.12532C12.7487 4.91804 12.9167 4.75 13.124 4.75C13.3313 4.75 13.4993 4.91803 13.4993 5.12532V8.53018C13.5052 8.62132 13.5301 8.7102 13.5722 8.79105C13.6144 8.8719 13.673 8.94294 13.7441 8.99956L15.3182 10.2678C15.4792 10.3975 15.5055 10.6329 15.377 10.7949Z"
        fill="#7B7C88"
      />
      <path
        d="M19.743 19.1026C18.1072 19.1026 14.2799 17.5887 13.9005 16.6009C13.3725 15.365 13.1083 14.0311 13.1249 12.6857V11.553H13.8751V12.6861C13.8578 13.9382 14.105 15.1797 14.6002 16.3282C14.7888 16.7539 17.5693 18.1283 19.394 18.3267C20.0481 18.3978 20.1799 18.261 20.1808 18.2597C20.4914 17.3236 19.7506 13.6694 19.2104 11.589C18.8976 10.3739 18.0433 9.28263 16.6046 8.24848C15.3728 7.36239 14.4305 7.73096 14.3911 7.74671C14.0278 7.93792 13.6865 8.16882 13.3733 8.43524L12.8765 7.86956C13.2521 7.54504 13.6662 7.26858 14.1092 7.04647C14.732 6.79221 15.8727 6.79356 17.0407 7.6315C18.6282 8.77321 19.5754 10.0045 19.9364 11.3959C20.0852 11.9697 21.3756 17.041 20.8922 18.4982C20.8028 18.7632 20.5436 19.0832 19.8015 19.1003L19.743 19.1026Z"
        fill="#7B7C88"
      />
      <path
        d="M15.002 12.6837C14.8751 12.8503 14.6369 12.8817 14.4713 12.7536L12.6149 11.319C12.5263 11.2505 12.4273 11.1956 12.3189 11.1673C12.2542 11.1504 12.1889 11.1361 12.123 11.1244C12.0392 11.1096 11.9534 11.1099 11.8697 11.1252C11.8061 11.1368 11.743 11.1508 11.6805 11.1672C11.572 11.1956 11.4729 11.2506 11.3842 11.3192L9.52866 12.7544C9.36249 12.8829 9.1234 12.8513 8.99638 12.684C8.87097 12.5187 8.90213 12.2833 9.06621 12.1565L11.0073 10.6553C11.0588 10.6155 11.1139 10.5802 11.1733 10.5535C11.4339 10.4364 11.7133 10.3667 11.9985 10.3479C12.2757 10.3664 12.5475 10.4322 12.8024 10.5423C12.8659 10.5697 12.9246 10.6068 12.9794 10.6491L14.9323 12.1567C15.0965 12.2833 15.1275 12.5187 15.002 12.6837Z"
        fill="#7B7C88"
      />
    </svg>
  );
}
