// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React from 'react';
import styles from './Section.module.scss';
import { AssessmentSheetUpdater } from './AssessmentSheetUpdater';
import { AssessmentSheetField } from './AssessmentSheetField';
import { Umu } from './types';
import { umuLabels } from './labels';
import { textWith } from './fields';

export function ZentaiNoMatomeSection(props: Props) {
  const { assessmentSheetUpdater, activated } = props;
  const assessmentSheetField = new AssessmentSheetField(activated);

  return (
    <>
      <div className={styles.card}>
        <h3 className={styles.title}>全体のまとめ</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                全体のまとめ
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'zentaiNoMatome.zentaiNoMatome',
                { width: 'auto', rows: 10 },
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>災害時の対応の必要性について</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                必要性の有無
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'zentaiNoMatome.saigaiJiNoTaioNoHitsuyoSeiNitsuite',
                umuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>
          （災害時の対応が有の場合）災害時の連絡先（家族以外/民生委員等）
        </h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                氏名
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'zentaiNoMatome.saigaiJiNoRenrakuSaki.shimei',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                本人との関係
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'zentaiNoMatome.saigaiJiNoRenrakuSaki.honninToNoKankei',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                電話番号
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'zentaiNoMatome.saigaiJiNoRenrakuSaki.denwaBango',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                FAX
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'zentaiNoMatome.saigaiJiNoRenrakuSaki.fax',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                メール
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderField<string | null>(
                assessmentSheetUpdater,
                'zentaiNoMatome.saigaiJiNoRenrakuSaki.mail',
                textWith({ width: 280 }),
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>（災害時の対応が有の場合）災害時の備考</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                （災害時の対応が有の場合）災害時の備考
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'zentaiNoMatome.saigaiJiBiko',
                { width: 'auto', rows: 5 },
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>権利擁護に関する対応の必要性について</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                必要性の有無
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<Umu | null>(
                assessmentSheetUpdater,
                'zentaiNoMatome.kenriYogoNikansuruTaioNoHitsuyoSeiNitsuite',
                umuLabels,
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>
          （権利擁護に関する対応が有の場合）権利擁護の備考
        </h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                （権利擁護に関する対応が有の場合）権利擁護の備考
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderTextareaField(
                assessmentSheetUpdater,
                'zentaiNoMatome.kenriYogoBiko',
                { width: 'auto', rows: 5 },
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

type Props = {
  assessmentSheetUpdater: AssessmentSheetUpdater;
  activated: boolean;
};
