export class ChatgptUsageCount {
  textCount: number;
  fileCount: number;
  sendCount: number;

  constructor(textCount: number, fileCount: number, sendCount: number) {
    this.textCount = textCount;
    this.fileCount = fileCount;
    this.sendCount = sendCount;
  }

  static async extract(response: Response): Promise<ChatgptUsageCount> {
    const json = await response.json();
    return new ChatgptUsageCount(
      json.textCount,
      json.fileCount,
      json.sendCount,
    );
  }
}
