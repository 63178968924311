import { ChatgptManageUsage } from '../../../domain/chatgpt/ChatgptManageUsage';

export class GetManageList {
  chatgptManageUsages: ChatgptManageUsage[];

  constructor(chatgptManageUsages: ChatgptManageUsage[]) {
    this.chatgptManageUsages = chatgptManageUsages;
  }

  static path = '/api/chatgpt/manage/getSetting';

  static async extract(response: Response): Promise<GetManageList> {
    const json = await response.json();
    return new GetManageList(json);
  }
}
