import { ReactNode } from 'react';
import {
  AruTokidokiaruNai,
  BedHoshiki,
  DekiruDekinai,
  DekiruIchibuZen,
  DekiruIchibuZenOkonatteinai,
  DekiruMimamoriDekinai,
  DekiruSasaeDekinai,
  DekiruTedeSasaeDekinai,
  DekiruTokidokiHotondoDekinai,
  DekiruTokubetsuNanDekinai,
  DekiruTsukamaruDekinai,
  DoBekkyo,
  DoZaYoko,
  FukushiKikiNoShiyo,
  FutonKaBed,
  FutsuIchimeterMenomaeMienaiHandanfuno,
  FutsuYattoOgoeKikoenaiHandanfuno,
  GaishutsuHindo,
  Genjo,
  GenzaiNoEiyoJotai,
  Hiatari,
  IgakuTekiKanriNoHitsuyoSei,
  JiritsuIchibuZen,
  JiritsuMimamoriIchibuZen,
  JukyoKubun1,
  JukyoKubun2,
  JushinJokyo,
  KaigoHokenRiyoShaFutanWariai,
  Kaminikusa,
  KansenshoNoUmu,
  KazokuJisshi,
  Keikaku,
  KogakuKaigoServiceHiGaitoRiyoShaFutan,
  KokiKoreiShaIryoHokenIchibuFutanKin,
  KurumaisuNoShiyo,
  KyoshitsuKaisu,
  Mondai,
  NaiTokidokiAru,
  NashiAru,
  NashiIchibuZen,
  NashiMimamoriIchibuZen,
  NinchishoHantei,
  OkugaiHoko,
  Seibetsu,
  SeinenKokenSeido,
  SeishinShogaiShaHokenFukushiTecho,
  ServiceRiyoNiyoruSeikatsuKinoNoIjiKaizenNoMitoshi,
  ShinshoTechoTokyuKyu,
  ShinshoTechoTokyuShu,
  ShisetsuKubun,
  ShogaiKoreiShaHantei,
  ShokujiKoi,
  TechoNadoNoUmu,
  TeikiFuTeiki,
  ToiletHoshiki,
  TokuninashiAri,
  TsujiruTokidokiTsujinai,
  TsukiShu,
  UketsukeBasho,
  Umu,
  YoEnjoKeikaku,
  YoKaigoNintei,
} from './types';

export type Label<T> = [T, ReactNode];

export const uketsukeBashoLabels: Label<UketsukeBasho | null>[] = [
  [null, '未選択'],
  ['HOMON', '訪問'],
  ['DENWA', '電話'],
  ['RAISHO', '来所'],
  ['SONOTA', 'その他'],
];

export const seibetsuLabels: Label<Seibetsu | null>[] = [
  [null, '未選択'],
  ['DANSEI', '男性'],
  ['JOSEI', '女性'],
];

export const kaigoHokenRiyoShaFutanWariaiLabels: Label<KaigoHokenRiyoShaFutanWariai | null>[] =
  [
    [null, '未選択'],
    ['ICHIWARI', '1割'],
    ['NIWARI', '2割'],
    ['SANWARI', '3割'],
  ];

export const kokiKoreiShaIryoHokenIchibuFutanKinLabels: Label<KokiKoreiShaIryoHokenIchibuFutanKin | null>[] =
  [
    [null, '未選択'],
    ['ICHIWARI_FUTAN', '1割負担'],
    ['SANWARI_FUTAN', '3割負担'],
  ];

export const kogakuKaigoServiceHiGaitoRiyoShaFutanLabels: Label<KogakuKaigoServiceHiGaitoRiyoShaFutan | null>[] =
  [
    [null, '未選択'],
    ['DAI_5DANKAI', '第5段階'],
    ['DAI_4DANKAI', '第4段階（低所得者等以外）'],
    ['DAI_3DANKAI', '第3段階（市町村民税世帯非課税者等）'],
    ['DAI_2DANKAI', '第2段階（老齢福祉年金受給者等）'],
    ['DAI_1DANKAI', '第1段階'],
  ];

export const yoKaigoNinteiLabels: Label<YoKaigoNintei | null>[] = [
  [null, '未選択'],
  ['HI_GAITO', '非該当'],
  ['YO_SHIEN1', '要支援1'],
  ['YO_SHIEN2', '要支援2'],
  ['YO_KAIGO1', '要介護1'],
  ['YO_KAIGO2', '要介護2'],
  ['YO_KAIGO3', '要介護3'],
  ['YO_KAIGO4', '要介護4'],
  ['YO_KAIGO5', '要介護5'],
];

export const techoNadoNoUmuLabels: Label<TechoNadoNoUmu | null>[] = [
  [null, '未選択'],
  ['ARI', '有'],
  ['NASHI', '無'],
];

export const shinshoTechoTokyuShuLabels: Label<ShinshoTechoTokyuShu | null>[] =
  [
    [null, '未選択'],
    ['ISSHU', '1種'],
    ['NISHU', '2種'],
  ];

export const shinshoTechoTokyuKyuLabels: Label<ShinshoTechoTokyuKyu | null>[] =
  [
    [null, '未選択'],
    ['IKKYU', '1級'],
    ['NIKYU', '2級'],
    ['SANKYU', '3級'],
    ['YONKYU', '4級'],
    ['GOKYU', '5級'],
    ['ROKKYU', '6級'],
    ['NANAKYU', '7級'],
  ];

export const seishinShogaiShaHokenFukushiTechoLabels: Label<SeishinShogaiShaHokenFukushiTecho | null>[] =
  [
    [null, '未選択'],
    ['IKKYU', '1級'],
    ['NIKYU', '2級'],
    ['SANKYU', '3級'],
  ];

export const shogaiKoreiShaHanteiLabels: Label<ShogaiKoreiShaHantei | null>[] =
  [
    [null, '未選択'],
    ['JIRITSU', '自立'],
    ['J1', 'J1'],
    ['J2', 'J2'],
    ['A1', 'A1'],
    ['A2', 'A2'],
    ['B1', 'B1'],
    ['B2', 'B2'],
    ['C1', 'C1'],
    ['C2', 'C2'],
  ];

export const ninchishoHanteiLabels: Label<NinchishoHantei | null>[] = [
  [null, '未選択'],
  ['JIRITSU', '自立'],
  ['I', 'I'],
  ['II_A', 'II a'],
  ['II_B', 'II b'],
  ['III_A', 'III a'],
  ['III_B', 'III b'],
  ['IV', 'IV'],
  ['M', 'M'],
];

export const doBekkyoLabels: Label<DoBekkyo | null>[] = [
  [null, '未選択'],
  ['DOKYO', '同居'],
  ['BEKKYO', '別居'],
];

export const umuLabels: Label<Umu | null>[] = [
  [null, '未選択'],
  ['ARI', 'あり'],
  ['NASHI', 'なし'],
];

export const shisetsuKubunLabels: Label<ShisetsuKubun | null>[] = [
  [null, '未選択'],
  ['KAIGO_ROJIN_FUKUSHI_SHISETSU', '介護老人福祉施設'],
  ['KAIGO_ROJIN_HOKEN_SHISETSU', '介護老人保健施設'],
  ['KAIGO_RYOYOGATA_IRYO_SHISETSU', '介護療養型医療施設（介護医療院）'],
  [
    'NINCHISHO_TAIOGATA_KYODO_SEIKATSU_KAIGO_TEKIYO_SHISETSU',
    '認知症対応型共同生活介護適用施設（グループホーム）',
  ],
  [
    'TOKUTEI_SHISETSU_NYUKYOSHA_SEIKATSU_KAIGO_TEKIYO_SHISETSU',
    '特定施設入居者生活介護適用施設（ケアハウス等）',
  ],
  [
    'IRYO_KIKAN_IRYO_HOKEN_TEKIYO_RYOYO_BYOSHO',
    '医療機関（医療保険適用療養病床）',
  ],
  ['IRYO_KIKAN_RYOYO_BYOSHO_IGAI', '医療機関（療養病床以外）'],
  ['SONOTA_NO_SHISETSU', 'その他の施設'],
];

export const seinenKokenSeidoLabels: Label<SeinenKokenSeido | null>[] = [
  [null, '未選択'],
  ['SEINEN_KOKEN', '成年後見'],
  ['HOSA', '保佐'],
  ['HOJO', '補助'],
];

export const jukyoKubun1Labels: Label<JukyoKubun1 | null>[] = [
  [null, '未選択'],
  ['IKKO_DATE', '一戸建て'],
  ['SHUGO_JUTAKU', '集合住宅'],
];

export const jukyoKubun2Labels: Label<JukyoKubun2 | null>[] = [
  [null, '未選択'],
  ['CHINTAI', '賃貸'],
  ['SHOYU', '所有'],
  ['SHATAKU_NADO', '社宅等'],
  ['KOEI_JUTAKU', '公営住宅'],
  ['SONOTA', 'その他'],
];

export const kyoshitsuKaisuLabels: Label<KyoshitsuKaisu | null>[] = [
  [null, '未選択'],
  ['IKKAI', '1階'],
  ['NIKAI', '2階'],
  ['SONOTA', 'その他'],
];

export const futonKaBedLabels: Label<FutonKaBed | null>[] = [
  [null, '未選択'],
  ['FUTON', '布団'],
  ['BED', 'ベッド'],
];

export const bedHoshikiLabels: Label<BedHoshiki | null>[] = [
  [null, '未選択'],
  ['KOTEISHIKI', '固定式'],
  ['GATCH', 'ギャッチ'],
  ['DENDO', '電動'],
  ['SONOTA', 'その他'],
];

export const hiatariLabels: Label<Hiatari | null>[] = [
  [null, '未選択'],
  ['YOI', '良'],
  ['FUTSU', '普通'],
  ['WARUI', '悪'],
];

export const toiletHoshikiLabels: Label<ToiletHoshiki | null>[] = [
  [null, '未選択'],
  ['WASHIKI', '和式'],
  ['YOSHIKI', '洋式'],
  ['SONOTA', 'その他'],
];

export const fukushiKikiNoShiyoLabels: Label<FukushiKikiNoShiyo | null>[] = [
  [null, '未選択'],
  ['SHIYO_SHITEIRU', '使用している'],
  ['SHIYO_SHITEINAI', '使用していない'],
];

export const teikiFuTeikiLabels: Label<TeikiFuTeiki | null>[] = [
  [null, '未選択'],
  ['TEIKI', '定期'],
  ['FUTEIKI', '不定期'],
];

export const tsukiShuLabels: Label<TsukiShu | null>[] = [
  [null, '未選択'],
  ['TSUKI', '月'],
  ['SHU', '週'],
];

export const jushinJokyoLabels: Label<JushinJokyo | null>[] = [
  [null, '未選択'],
  ['TSUIN', '通院'],
  ['OSHIN', '往診'],
];

export const dekiruTsukamaruDekinaiLabels: Label<DekiruTsukamaruDekinai | null>[] =
  [
    ['DEKIRU', 'つかまらないでできる'],
    ['TSUKAMARU', '何かにつかまればできる'],
    ['DEKINAI', 'できない'],
  ];

export const dekiruTedeSasaeDekinaiLabels: Label<DekiruTedeSasaeDekinai | null>[] =
  [
    ['DEKIRU', 'できる'],
    ['TEDE', '自分の手で支えればできる'],
    ['SASAE', '支えてもらえばできる'],
    ['DEKINAI', 'できない'],
  ];

export const dekiruSasaeDekinaiLabels: Label<DekiruSasaeDekinai | null>[] = [
  ['DEKIRU', '支えなしでできる'],
  ['SASAE', '何か支えがあればできる'],
  ['DEKINAI', 'できない'],
];

export const dekiruIchibuZenOkonatteinaiLabels: Label<DekiruIchibuZenOkonatteinai | null>[] =
  [
    ['DEKIRU', '介助されていない'],
    ['ICHIBU', '一部介助'],
    ['ZEN', '全介助'],
    ['OKONATTEINAI', '行っていない'],
  ];

export const dekiruIchibuZenLabels: Label<DekiruIchibuZen | null>[] = [
  ['DEKIRU', '介助されていない'],
  ['ICHIBU', '一部介助'],
  ['ZEN', '全介助'],
];

export const futsuIchimeterMenomaeMienaiHandanfunoLabels: Label<FutsuIchimeterMenomaeMienaiHandanfuno | null>[] =
  [
    ['FUTSU', '普通（日常生活に支障がない）'],
    ['ICHIMETER', '約1m離れた視力確認表の図が見える'],
    ['MENOMAE', '目の前に置いた視力確認表の図が見える'],
    ['MIENAI', 'ほとんど見えない'],
    ['HANDANFUNO', '見えているのか判断不能'],
  ];

export const futsuYattoOgoeKikoenaiHandanfunoLabels: Label<FutsuYattoOgoeKikoenaiHandanfuno | null>[] =
  [
    ['FUTSU', '普通'],
    ['YATTO', '普通の声がやっと聞き取れる'],
    ['OGOE', 'かなり大きな声なら何とか聞き取れる'],
    ['KIKOENAI', 'ほとんど聞こえない'],
    ['HANDANFUNO', '聞こえているのか判断不能'],
  ];

export const kazokuJisshiLabels: Label<KazokuJisshi | null>[] = [
  [null, '未選択'],
  ['JISSHI', '実施'],
  ['TOKIDOKI', '時々'],
];

export const yoEnjoKeikakuLabels: Label<YoEnjoKeikaku | null>[] = [
  [null, '未選択'],
  ['HANDAN', '判断'],
  ['KEIKAKU', '計画'],
];

export const genjoLabels: Label<Genjo | null>[] = [
  [null, '未選択'],
  ['MIMAMORI_NOMI', '見守りのみ'],
  ['KAIJO_ARI', '介助あり'],
];

export const keikakuLabels: Label<Keikaku | null>[] = [
  [null, '未選択'],
  ['MIMAMORI_HITSUYO', '見守り必要'],
  ['KAIJO_HITSUYO', '介助必要'],
];

export const nashiMimamoriIchibuZenLabels: Label<NashiMimamoriIchibuZen | null>[] =
  [
    ['NASHI', '介助されていない'],
    ['MIMAMORI', '見守り等'],
    ['ICHIBU', '一部介助'],
    ['ZEN', '全介助'],
  ];

export const dekiruMimamoriDekinaiLabels: Label<DekiruMimamoriDekinai | null>[] =
  [
    ['DEKIRU', 'できる'],
    ['MIMAMORI', '見守り等'],
    ['DEKINAI', 'できない'],
  ];

export const nashiIchibuZenLabels: Label<NashiIchibuZen | null>[] = [
  ['NASHI', '介助されていない'],
  ['ICHIBU', '一部介助'],
  ['ZEN', '全介助'],
];

export const gaishutsuHindoLabels: Label<GaishutsuHindo | null>[] = [
  ['SHU_ICHI', '週1回以上'],
  ['TSUKI_ICHI', '月1回以上'],
  ['TSUKI_ICHI_MIMAN', '月1回未満'],
];

export const jiritsuMimamoriIchibuZenLabels: Label<JiritsuMimamoriIchibuZen | null>[] =
  [
    ['JIRITSU', '自立'],
    ['MIMAMORI', '見守り等'],
    ['ICHIBU', '一部介助'],
    ['ZEN', '全介助'],
  ];

export const mondaiLabels: Label<Mondai | null>[] = [
  [null, '未選択'],
  ['MONDAI_NASHI', '問題なし'],
  ['MONDAI_ARI', '問題あり'],
];

export const kaminikusaLabels: Label<Kaminikusa | null>[] = [
  [null, '未選択'],
  ['KAMINIKUI', '噛みにくい'],
  ['TOKIDOKI_KAMINIKUI', '時々噛みにくい'],
  ['TOTEMO_KAMINIKUI', 'とても噛みにくい'],
];

export const aruTokidokiaruNaiLabels: Label<AruTokidokiaruNai | null>[] = [
  [null, '未選択'],
  ['ARU', 'ある'],
  ['TOKIDOKI_ARU', 'ときどきある'],
  ['NAI', 'ない'],
];

export const dekiruTokidokiHotondoDekinaiLabels: Label<DekiruTokidokiHotondoDekinai | null>[] =
  [
    ['DEKIRU', '調査対象者が意思を他者に伝達できる'],
    ['TOKIDOKI', 'ときどき伝達できる'],
    ['HOTONDO', 'ほとんど伝達できない'],
    ['DEKINAI', 'できない'],
  ];

export const dekiruDekinaiLabels: Label<DekiruDekinai | null>[] = [
  ['DEKIRU', 'できる'],
  ['DEKINAI', 'できない'],
];

export const naiTokidokiAruLabels: Label<NaiTokidokiAru | null>[] = [
  ['NAI', 'ない'],
  ['TOKIDOKI', 'ときどきある'],
  ['ARU', 'ある'],
];

export const tsujiruTokidokiTsujinaiLabels: Label<TsujiruTokidokiTsujinai | null>[] =
  [
    ['TSUJIRU', '介護者の言っていることが通じる'],
    ['TOKIDOKI', '介護者の言っていることがときどき通じる'],
    ['TSUJINAI', '介護者の言っていることが通じない'],
  ];

export const dekiruTokubetsuNanDekinaiLabels: Label<DekiruTokubetsuNanDekinai | null>[] =
  [
    ['DEKIRU', 'できる'],
    ['TOKUBETSU', '特別な場合を除いてできる'],
    ['NAN', '日常的に困難'],
    ['DEKINAI', 'できない'],
  ];

export const jiritsuIchibuZenLabels: Label<JiritsuIchibuZen | null>[] = [
  ['JIRITSU', '自立'],
  ['ICHIBU', '一部介助'],
  ['ZEN', '全介助'],
];

export const doZaYokoLabels: Label<DoZaYoko | null>[] = [
  ['DO', 'よく動いている'],
  ['ZA', '座っていることが多い'],
  ['YOKO', '横になっていることが多い'],
];

export const nashiAruLabels: Label<NashiAru | null>[] = [
  ['NASHI', 'ない'],
  ['ARU', 'ある'],
];

export const okugaiHokoLabels: Label<OkugaiHoko | null>[] = [
  [null, '未選択'],
  ['JIRITSU', '自立'],
  ['KAIJO_GA_AREBA_SHITEIRU', '介助があればしている'],
  ['SHITEINAI', 'していない'],
];

export const kurumaisuNoShiyoLabels: Label<KurumaisuNoShiyo | null>[] = [
  [null, '未選択'],
  ['MOCHIITEINAI', '用いていない'],
  ['OMO_NI_JIBUN_GA_SOSA_SHITEIRU', '主に自分で操作している'],
  ['OMO_NI_TANIN_GA_SOSA_SHITEIRU', '主に他人が操作している'],
];

export const shokujiKoiLabels: Label<ShokujiKoi | null>[] = [
  [null, '未選択'],
  [
    'JIRITSU_NAISHI_NANTOKA_JIBUN_DE_TABERARERU',
    '自立ないし何とか自分で食べられる',
  ],
  ['ZEMMEN_KAIJO', '全面介助'],
];

export const genzaiNoEiyoJotaiLabels: Label<GenzaiNoEiyoJotai | null>[] = [
  [null, '未選択'],
  ['RYOKO', '良好'],
  ['FURYO', '不良'],
];

export const serviceRiyoNiyoruSeikatsuKinoNoIjiKaizenNoMitoshiLabels: Label<ServiceRiyoNiyoruSeikatsuKinoNoIjiKaizenNoMitoshi | null>[] =
  [
    [null, '未選択'],
    ['KITAI_DEKIRU', '期待できる'],
    ['KITAI_DEKINAI', '期待できない'],
    ['FUMEI', '不明'],
  ];

export const igakuTekiKanriNoHitsuyoSeiLabels: Label<IgakuTekiKanriNoHitsuyoSei | null>[] =
  [
    [null, '未選択'],
    ['HITSUYO', '必要'],
    ['TOKUNI_HITSUYO', '特に必要'],
  ];

export const tokuninashiAriLabels: Label<TokuninashiAri | null>[] = [
  [null, '未選択'],
  ['TOKUNI_NASHI', '特になし'],
  ['ARI', 'あり'],
];

export const kansenshoNoUmuLabels: Label<KansenshoNoUmu | null>[] = [
  [null, '未選択'],
  ['NASHI', '無'],
  ['ARI', '有'],
  ['FUMEI', '不明'],
];
