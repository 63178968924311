import React from 'react';
import styles from './AssessmentSheetIndex.module.scss';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';
import {
  AssessmentSheetSection,
  assessmentSheetSectionId,
} from '../AssessmentSheetSection';

export function AssessmentSheetIndex(props: Props) {
  const { activeSection, scrollOffset } = props;

  const scroll = (element: HTMLElement) => {
    const top = element.getBoundingClientRect().top + window.pageYOffset - 16;
    window.scrollTo({ top: top + scrollOffset(), behavior: 'smooth' });
  };

  const renderLink = (
    section: AssessmentSheetSection,
    label: string,
    sectionsForJudge: AssessmentSheetSection[] = [section],
  ) => {
    const to = `#${assessmentSheetSectionId(section)}`;
    const active = activeSection
      ? sectionsForJudge.includes(activeSection)
      : false;
    const className = classNames(styles.link, { [styles.active]: active });
    return (
      <HashLink to={to} scroll={scroll} className={className}>
        {label}
      </HashLink>
    );
  };

  return (
    <ul className={styles.container}>
      <li>{renderLink(AssessmentSheetSection.FACE_SHEET, 'フェースシート')}</li>
      <li>
        {renderLink(
          AssessmentSheetSection.KAZOKU_JOKYO_TO_INFORMAL_NA_SHIEN_NO_JOKYO,
          '家族状況とインフォーマルな支援の状況',
        )}
      </li>
      <li>
        {renderLink(
          AssessmentSheetSection.SERVICE_RIYO_JOKYO,
          'サービス利用状況',
        )}
      </li>
      <li>
        {renderLink(AssessmentSheetSection.JUKYO_NADO_NO_JOKYO, '住居等の状況')}
      </li>
      <li>
        {renderLink(
          AssessmentSheetSection.HONNIN_NO_KENKO_JOTAI_JUSHIN_NADO_NO_JOKYO,
          '本人の健康状態・受診等の状況',
        )}
      </li>
      <li>
        {renderLink(
          AssessmentSheetSection.KIHON_DOSA,
          '本人の基本動作等の状況と援助内容の詳細',
          [],
        )}
        <ul className={styles.indent}>
          <li>
            {renderLink(
              AssessmentSheetSection.KIHON_DOSA,
              '基本（身体機能・起居）動作',
            )}
          </li>
          <li>
            {renderLink(
              AssessmentSheetSection.SEIKATSU_KINO,
              '生活機能（食事・排泄等）',
            )}
          </li>
          <li>{renderLink(AssessmentSheetSection.NINCHI_KINO, '認知機能')}</li>
          <li>
            {renderLink(
              AssessmentSheetSection.SEISHIN_KODO_SHOGAI,
              '精神・行動障害',
            )}
          </li>
          <li>
            {renderLink(
              AssessmentSheetSection.NINCHI_KINO_SEISHIN_KODOSHOGAI_ZEMPAN,
              '認知機能、精神・行動障害 全般',
            )}
          </li>
          <li>
            {renderLink(
              AssessmentSheetSection.SHAKAI_SEIKATSU_RYOKU,
              '社会生活（への適応）力',
            )}
          </li>
          <li>
            {renderLink(
              AssessmentSheetSection.IRYO_KENKO_KANKEI,
              '医療・健康関係',
            )}
          </li>
          <li>
            {renderLink(
              AssessmentSheetSection.KAIGO_NIKANSURU_ISHI_NO_IKEN,
              '介護に関する医師の意見',
            )}
          </li>
        </ul>
      </li>
      <li>
        {renderLink(AssessmentSheetSection.ZENTAI_NO_MATOME, '全体のまとめ')}
      </li>
    </ul>
  );
}

type Props = {
  activeSection: AssessmentSheetSection | null;
  scrollOffset: () => number;
};
