import React, { useState, MouseEvent, useEffect } from 'react';
import { Office } from '../../../../domain/office/Office';
import { TextField } from '../../components/field/TextField';
import { SelectField } from '../../components/field/SelectField';
import { CloseIcon } from '../../components/icon/CloseIcon';
import styles from './OfficeForm.module.scss';
import { PrimaryButton } from '../../components/button/PrimaryButton';
import { AdminOfficeApi } from '../../../../state/api/admin/office/AdminOfficeApi';
import { AdminCorporationApi } from '../../../../state/api/admin/corporation/AdminCorporationApi';
import { Corporation } from '../../../../domain/corporation/Corporation';

export default function OfficeForm(props: Props) {
  const { office, type, onRequestClose, refreshOffices } = props;
  const [corporationId, setCorporationId] = useState(
    office?.corporationId ?? '',
  );
  const [officeName, setOfficeName] = useState(office?.displayName ?? '');
  const [address, setAddress] = useState(office?.address ?? '');
  const [loginName, setLoginName] = useState(office?.loginName ?? '');

  const [hasError, setHasError] = useState(false);

  const [corporations, setCorporations] = useState<Corporation[]>([]);

  const renderType = () => {
    if (type === 'register') {
      return '登録';
    }
    return '更新';
  };

  const onClick = () => {
    if (type === 'register') {
      AdminOfficeApi.register(officeName, loginName, corporationId, address)
        .then(() => {
          setHasError(false);
          refreshOffices();
          onRequestClose();
        })
        .catch(() => {
          setHasError(true);
        });
    } else {
      AdminOfficeApi.update(
        office!!.id,
        officeName,
        loginName,
        address,
        corporationId,
      )
        .then(() => {
          setHasError(false);
          refreshOffices();
          onRequestClose();
        })
        .catch(() => {
          setHasError(true);
        });
    }
  };

  const onClickContainer = (event: MouseEvent<any>) => {
    event.stopPropagation();
  };

  useEffect(() => {
    let mounted = true;
    AdminCorporationApi.corporations().then((res) => {
      if (mounted) {
        setCorporations(res);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (corporations && corporations.length !== 0 && !corporationId) {
      setCorporationId(corporations[0].id);
    }
  }, [corporations, corporationId]);

  const disabled = !corporationId || !officeName || !loginName || !address;

  return (
    <div className={styles.container} onClick={onClickContainer}>
      <div className={styles.title}>事業所を{renderType()}します</div>
      <div className={styles.inputArea}>
        <div className={styles.corporation}>法人</div>
        <SelectField
          values={corporations?.map((corporation) => {
            return [corporation.id, corporation.name];
          })}
          set={setCorporationId}
          get={corporationId}
        />
      </div>
      <div className={styles.inputArea}>
        <div className={styles.officeName}>事業所名</div>
        <TextField get={officeName} set={setOfficeName} maxLength={40} />
      </div>
      <div className={styles.inputArea}>
        <div className={styles.loginName}>ログイン名</div>
        <TextField get={loginName} set={setLoginName} maxLength={30} />
      </div>
      <div className={styles.inputArea}>
        <div className={styles.address}>住所</div>
        <TextField get={address} set={setAddress} maxLength={100} />
      </div>
      <div className={styles.error}>
        {hasError && <div>事業所新規登録に失敗しました。</div>}
      </div>
      <div className={styles.buttonArea}>
        <PrimaryButton width={256} onClick={onClick} disabled={disabled}>
          {renderType()}
        </PrimaryButton>
      </div>
      <button className={styles.closeIcon} onClick={onRequestClose}>
        <CloseIcon />
      </button>
    </div>
  );
}

type Props = {
  office?: Office;
  type: 'register' | 'update';
  onRequestClose: () => void;
  refreshOffices: () => void;
};
