import React from 'react';
import styles from './CareRecipientRegistrationField.module.scss';
import {
  FieldContainer,
  FieldContainerProps,
} from '../../components/field/FieldContainer';

export function CareRecipientRegistrationField(props: FieldContainerProps) {
  return (
    <div className={styles.container}>
      <FieldContainer {...props} />
    </div>
  );
}
