import React from 'react';

export function ArrowUnderIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6043 8.16872C19.0405 8.44889 19.1297 8.97979 18.8036 9.35452L12.5364 16.5556L12.5292 16.5637C12.4082 16.6994 12.2523 16.8093 12.0739 16.885C11.8954 16.9607 11.699 17 11.5 17C11.301 17 11.1046 16.9607 10.9261 16.885C10.7477 16.8093 10.5918 16.6994 10.4708 16.5637L10.4636 16.5556L4.19641 9.35452C3.87027 8.97979 3.95951 8.44889 4.39572 8.16872C4.83194 7.88855 5.44995 7.96521 5.77608 8.33995L11.5 14.9168L17.2239 8.33995C17.5501 7.96521 18.1681 7.88855 18.6043 8.16872Z"
        fill="#7B7C88"
      />
    </svg>
  );
}
