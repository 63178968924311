import React, { Attributes, RefObject } from 'react';
import styles from './LongTermGoalComponent.module.scss';
import { CarePlanTableTwoPeriod } from '../../../../domain/careplan/tabletwo/CarePlanTableTwoPeriod';
import { LongTermGoal } from '../../../../domain/careplan/tabletwo/longtermgoal/LongTermGoal';
import { Need } from '../../../../domain/careplan/tabletwo/need/Need';
import { TableTwoPeriod } from './TableTwoPeriod';
import { SuggestionLongTermGoalDialog } from '../../suggestion/components/SuggestionLongTermGoalDialog';
import { ShiftAction } from '../../../../domain/careplan/tabletwo/ShiftAction';
import { TableTwoCellWithIcons } from './TableTwoCellWithIcons';
import { ShortTermGoalComponent } from './ShortTermGoalComponent';
import { LongTermGoalApi } from '../../../../state/api/careplan/tabletwo/longtermgoal/LongTermGoalApi';
import { CarePlanTableTwo } from '../../../../domain/careplan/tabletwo/CarePlanTableTwo';
import { ChangeLog } from '../../../../domain/changelog/ChangeLog';
import { NeedApi } from '../../../../state/api/careplan/tabletwo/need/NeedApi';
import { EditedNeed } from '../../../../state/api/careplan/tabletwo/need/EditedNeed';
import { EditedCaraPlanTableTwo } from '../../../../state/api/careplan/tabletwo/EditedCaraPlanTableTwo';
import { EditedLongTermGoal } from '../../../../state/api/careplan/tabletwo/longtermgoal/EditedLongTermGoal';

export function LongTermGoalComponent(props: Props) {
  const {
    projectId,
    carePlanTableTwo,
    need,
    longTermGoal,
    activated,
    refresh,
    tableTwoHeaderRef,
  } = props;

  const update = (
    id: string,
    text: string | null,
    period: CarePlanTableTwoPeriod | null,
  ) => {
    LongTermGoalApi.update(id, text, period).then((res) => {
      if (res instanceof EditedLongTermGoal) {
        refresh(
          carePlanTableTwo.replaceLongTermGoal(need, res.longTermGoal),
          res.changeLog,
        );
      }
    });
  };
  const onUpdate = (text: string | null) => {
    update(longTermGoal.id, text, longTermGoal.period);
  };

  const handlePeriodUpdate = (period: CarePlanTableTwoPeriod) => {
    update(longTermGoal.id, longTermGoal.text, period);
  };

  const handleAdd = () => {
    NeedApi.addLongTermGoal(need.id, longTermGoal.id).then((res) => {
      if (res instanceof EditedNeed) {
        refresh(carePlanTableTwo.replaceNeed(res.need), res.changeLog);
      }
    });
  };

  const shiftLongTermGoal = (
    needId: string,
    longTermGoalId: string,
    shiftAction: ShiftAction,
  ) => {
    NeedApi.shiftLongTermGoal(needId, longTermGoalId, shiftAction).then(
      (res) => {
        if (res instanceof EditedNeed) {
          refresh(carePlanTableTwo.replaceNeed(res.need), res.changeLog);
        }
      },
    );
  };

  const handleShiftUp = () => {
    shiftLongTermGoal(need.id, longTermGoal.id, ShiftAction.UP);
  };

  const handleShiftDown = () => {
    shiftLongTermGoal(need.id, longTermGoal.id, ShiftAction.DOWN);
  };

  const handleRemove = () => {
    NeedApi.removeLongTermGoal(need.id, longTermGoal.id).then((res) => {
      if (res instanceof EditedNeed) {
        refresh(carePlanTableTwo.replaceNeed(res.need), res.changeLog);
      }
    });
  };

  const onClickCopyPeriodButton = () => {
    LongTermGoalApi.copyPeriod(longTermGoal.id).then((res) => {
      if (res instanceof EditedCaraPlanTableTwo) {
        refresh(res.carePlanTableTwo, res.changeLog);
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.textCell}>
        <TableTwoCellWithIcons
          initialText={longTermGoal.text}
          isFirstRowCell={need.isFirstSubItem(longTermGoal)}
          isLastRowCell={need.isLastSubItem(longTermGoal)}
          suggestionDialog={(openSuggestionDialog, onSelect) => {
            return (
              <SuggestionLongTermGoalDialog
                open={openSuggestionDialog}
                projectId={projectId}
                needText={need.text}
                onSelect={onSelect}
              />
            );
          }}
          handleTextUpdate={onUpdate}
          handleAdd={handleAdd}
          handleShiftUp={handleShiftUp}
          handleShiftDown={handleShiftDown}
          handleRemove={handleRemove}
          activated={activated}
          tableTwoHeaderRef={tableTwoHeaderRef}
        />
      </div>
      <div className={styles.periodCell}>
        <TableTwoPeriod
          period={longTermGoal.period}
          category={'LongTermGoal'}
          onChange={handlePeriodUpdate}
          onClickCopyPeriod={onClickCopyPeriodButton}
          activated={activated}
        />
      </div>
      <div className={styles.rest}>
        {longTermGoal.subItems.items.map((shortTermGoal) => {
          return (
            <ShortTermGoalComponent
              key={shortTermGoal.id}
              projectId={projectId}
              carePlanTableTwo={carePlanTableTwo}
              need={need}
              longTermGoal={longTermGoal}
              shortTermGoal={shortTermGoal}
              activated={activated}
              refresh={refresh}
              tableTwoHeaderRef={tableTwoHeaderRef}
            />
          );
        })}
      </div>
    </div>
  );
}

type Props = Attributes & {
  projectId: string;
  carePlanTableTwo: CarePlanTableTwo;
  need: Need;
  longTermGoal: LongTermGoal;
  activated: boolean;
  refresh: (carePlanTableTwo: CarePlanTableTwo, changeLog: ChangeLog) => void;
  tableTwoHeaderRef: RefObject<HTMLDivElement>;
};
