import { GlobalHeader } from '../../components/GlobalHeader';
import { GlobalContainer } from '../../components/GlobalContainer';
import React, { useState } from 'react';
import styles from './PasswordChangePage.module.scss';
import { PrimaryButton } from '../../components/button/PrimaryButton';
import { CancelButton } from '../../components/button/CancelButton';
import { MyselfApi } from '../../../../state/api/myself/MyselfApi';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../../../Paths';
import { PasswordChangeRequest } from '../../../../domain/setting/password/PasswordChangeRequest';
import { PasswordField } from '../../components/field/PasswordField';
import {
  containsLowercase,
  containsNumber,
  containsUppercase,
  EnhancedNewPasswordField,
  isEightOrMoreCharacters,
} from '../../components/EnhancedNewPasswordField';

export function PasswordChangePage() {
  const [passwordChangeRequest, setPasswordChangeRequest] =
    useState<PasswordChangeRequest>(PasswordChangeRequest.init());
  const [currentPasswordMismatch, setCurrentPasswordMismatch] =
    useState<boolean>(false);

  const changePassword = () => {
    MyselfApi.updatePassword(
      passwordChangeRequest.currentPassword,
      passwordChangeRequest.newPassword,
      () => history.push(Paths.userSetting.build({ type: 'password' })),
      () => setCurrentPasswordMismatch(true),
    );
  };

  const setCurrentPassword = (currentPassword: string) => {
    setPasswordChangeRequest(
      passwordChangeRequest.createWithCurrentPassword(currentPassword),
    );
  };

  const setNewPassword = (newPassword: string) => {
    setPasswordChangeRequest(
      passwordChangeRequest.createWithNewPassword(newPassword),
    );
  };

  const disable = (): boolean => {
    const { currentPassword, newPassword } = passwordChangeRequest;

    if (currentPassword === '') return true;
    if (newPassword === '') return true;
    if (!containsNumber(newPassword)) return true;
    if (!containsUppercase(newPassword)) return true;
    if (!containsLowercase(newPassword)) return true;
    if (!isEightOrMoreCharacters(newPassword)) return true;

    return false;
  };

  const history = useHistory();

  const backToSetting = () => {
    history.push(Paths.userSettings);
  };

  return (
    <GlobalContainer>
      <GlobalHeader />
      <div className={styles.card}>
        <div className={styles.contents}>
          <h2 className={styles.title}>パスワードを変更する</h2>

          <div className={styles.field}>
            <PasswordField
              label="現在のパスワード"
              value={passwordChangeRequest.currentPassword}
              setValue={setCurrentPassword}
            />
            {currentPasswordMismatch && (
              <p className={styles.error}>現在のパスワードが一致しません</p>
            )}
          </div>

          <div className={styles.field}>
            <EnhancedNewPasswordField
              label="新しいパスワード"
              value={passwordChangeRequest.newPassword}
              setValue={setNewPassword}
            />
          </div>

          <div className={styles.button}>
            <PrimaryButton
              width={256}
              onClick={changePassword}
              disabled={disable()}
            >
              変更
            </PrimaryButton>
          </div>

          <div className={styles.button}>
            <CancelButton width={256} onClick={backToSetting}>
              キャンセル
            </CancelButton>
          </div>
        </div>
      </div>
    </GlobalContainer>
  );
}
