import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalHeader } from '../components/GlobalHeader';
import { GlobalContainer } from '../components/GlobalContainer';
import styles from './NotFoundPage.module.scss';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../../Paths';
import { PrimaryButton } from '../components/button/PrimaryButton';
import { State } from '../../../state/store';

export function NotFoundPage() {
  const rsLoginIn = useSelector(
    (state: State) => state.authentication.rsLoginIn,
  );
  const history = useHistory();
  const onClick = () => {
    history.push(Paths.root);
  };
  const goToBack = () => {
    history.goBack();
  };

  return (
    <GlobalContainer>
      <GlobalHeader />
      <div className={styles.container}>
        <div className={styles.message}>
          <p>404 | Not Found</p>
          <p>お探しのページは見つかりませんでした。</p>
        </div>
        {!rsLoginIn ? (
          <PrimaryButton width={256} onClick={onClick}>
            トップページに戻る
          </PrimaryButton>
        ) : (
          <PrimaryButton width={256} onClick={goToBack}>
            戻る
          </PrimaryButton>
        )}
      </div>
    </GlobalContainer>
  );
}
