import React, { DragEvent, useRef, useState } from 'react';
import styles from './TableThreeWeeklyServiceScheduleArea.module.scss';
import { Modal } from '../../components/Modal';
import { TableThreeWeeklyServiceDialog } from './TableThreeWeeklyServiceDialog';
import { WeeklyService } from '../../../../domain/careplan/tablethree/weeklyservice/WeeklyService';
import { WeeklyServiceApi } from '../../../../state/api/careplan/tablethree/weeklyservice/WeeklyServiceApi';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export function TableThreeWeeklyServiceScheduleArea(props: Props) {
  const {
    activated,
    time,
    week,
    dragInfo,
    weeklyServices,
    projectId,
    refresh,
  } = props;

  const dropInfo = useRef({ time: '', week: '' });
  const clickTime = useRef('');

  const [openDialog, setOpenDialog] = useState(false);

  const onClickTableThree = (time: string) => {
    if (!activated) return;
    setOpenDialog(true);
    clickTime.current = time;
  };

  const onRequestCloseTableThreeDialog = () => {
    setOpenDialog(false);
  };

  const onDragEnter = (time: string, week: string) => {
    if (!activated) return;
    if (
      dragInfo.current?.dayOfWeek === week &&
      dragInfo.current.startTime === `${time}`
    )
      return;
    dropInfo.current = { time: time, week: week };
  };

  const calculateTortalHours = (time: string) => {
    const timeArray = time.split(':');
    const startHours = parseInt(timeArray[0], 10);
    const startMinutes = parseInt(timeArray[1], 10);
    return startHours + startMinutes / 60;
  };

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    if (!activated) return;
    if (
      dragInfo.current?.dayOfWeek !== dropInfo.current.week ||
      dragInfo.current?.startTime !== dropInfo.current.time
    ) {
      const updateWeeklyService = weeklyServices.find((weeklyService) => {
        return (
          JSON.stringify(weeklyService) === JSON.stringify(dragInfo.current)
        );
      });
      if (updateWeeklyService) {
        const startTortalHours = calculateTortalHours(
          updateWeeklyService.startTime,
        );
        const endTortalHours = calculateTortalHours(
          updateWeeklyService.endTime,
        );
        const startAndEndDiff = endTortalHours - startTortalHours;
        updateWeeklyService.dayOfWeek = dropInfo.current.week;
        updateWeeklyService.startTime = dropInfo.current.time;
        const currentTortalHours = calculateTortalHours(dropInfo.current.time);
        let endTime = currentTortalHours + startAndEndDiff;
        if (endTime > 25) endTime = 25;
        const hours = Math.floor(endTime);
        const minutes = (endTime - hours) * 60;
        updateWeeklyService.endTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

        // 同じ時間帯に6つ以上登録させない
        const existWeeklyServices = weeklyServices.filter(
          (exitWeeklyService) => {
            return (
              exitWeeklyService.dayOfWeek === updateWeeklyService.dayOfWeek &&
              exitWeeklyService.id !== updateWeeklyService.id
            );
          },
        );
        const newScheduleStartTime = dayjs(
          `2000-01-01T${updateWeeklyService.startTime}:00Z`,
        );
        const newderScheduleEndTime = dayjs(
          `2000-01-01T${updateWeeklyService.endTime}:00Z`,
        );
        for (
          let i = newScheduleStartTime;
          i.isSameOrBefore(newderScheduleEndTime);
          i = i.add(15, 'm')
        ) {
          let duplicateCount = 0;
          existWeeklyServices.forEach((existWeeklyService) => {
            const weeklyServiceStartTime = dayjs(
              `2000-01-01T${existWeeklyService.startTime}:00Z`,
            );
            const weeklyServiceEndTime = dayjs(
              `2000-01-01T${existWeeklyService.endTime}:00Z`,
            );
            if (
              i.isSameOrAfter(weeklyServiceStartTime) &&
              i.isSameOrBefore(weeklyServiceEndTime)
            ) {
              duplicateCount += 1;
            }
          });
          if (duplicateCount >= 5) return;
        }

        if (e.altKey || e.ctrlKey) {
          WeeklyServiceApi.register(
            updateWeeklyService.carePlanTableThreeId,
            updateWeeklyService.dayOfWeek,
            updateWeeklyService.startTime.replace(':', ''),
            updateWeeklyService.endTime.replace(':', ''),
            updateWeeklyService.establishmentId,
            updateWeeklyService.establishmentName,
            updateWeeklyService.supportServiceTypeId,
            updateWeeklyService.supportServiceType,
            updateWeeklyService.kaigoServiceName,
            updateWeeklyService.remarks,
          ).then(() => {
            dragInfo.current = null;
            refresh();
          });
        } else {
          WeeklyServiceApi.update(
            updateWeeklyService.id,
            updateWeeklyService.carePlanTableThreeId,
            updateWeeklyService.dayOfWeek,
            updateWeeklyService.startTime.replace(':', ''),
            updateWeeklyService.endTime.replace(':', ''),
            updateWeeklyService.establishmentId,
            updateWeeklyService.establishmentName,
            updateWeeklyService.supportServiceTypeId,
            updateWeeklyService.supportServiceType,
            updateWeeklyService.kaigoServiceName,
            updateWeeklyService.remarks,
          ).then(() => {
            dragInfo.current = null;
            refresh();
          });
        }
      }
    }
  };

  return (
    <>
      <div className={styles.content} onClick={() => onClickTableThree(time)}>
        <div className={styles.display}>
          {Array(4)
            .fill(0)
            .map((_, index) => {
              const minute = String(15 * index).padStart(2, '0');
              return (
                <div
                  className={styles.block}
                  onDragEnter={() => onDragEnter(`${time}:${minute}`, week)}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => onDrop(e)}
                  key={index}
                  data-testid="tableThreeWeeklyServiceScheduleArea"
                ></div>
              );
            })}
        </div>
      </div>
      <Modal open={openDialog} onRequestClose={onRequestCloseTableThreeDialog}>
        <TableThreeWeeklyServiceDialog
          onRequestClose={onRequestCloseTableThreeDialog}
          projectId={projectId}
          weeklyServices={weeklyServices}
          time={clickTime.current}
          dayOfWeek={week}
          refresh={refresh}
        />
      </Modal>
    </>
  );
}

type Props = {
  activated: boolean;
  time: string;
  week: string;
  dragInfo: React.MutableRefObject<WeeklyService | null>;
  weeklyServices: WeeklyService[];
  projectId: string;
  refresh: () => void;
};
