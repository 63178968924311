import { DiseaseDetail } from '../../../domain/diseaseknowledge/DiseaseDetail';
import { Disease } from '../../../domain/diseaseknowledge/Disease';
import { DiseaseCategory } from '../../../domain/diseaseknowledge/DiseaseCategory';
import { Watch } from '../../../domain/diseaseknowledge/Watch';
import { AssessmentCheckpoint } from '../../../domain/diseaseknowledge/AssessmentCheckpoint';
import { SuggestingService } from '../../../domain/diseaseknowledge/SuggestingService';

export class DiseaseKnowledge {
  disease: DiseaseDetail;

  constructor(disease: DiseaseDetail) {
    this.disease = disease;
  }

  static async extract(response: Response): Promise<DiseaseKnowledge> {
    const json = await response.json();
    return new DiseaseKnowledge(disease(json));
  }
}

type Item = {
  disease: Disease;
  category: DiseaseCategory;
  description: string;
  abstractDiseases: string[];
  symptoms: string[];
  misleadingDiseases: string[];
  watch: Watch;
  assessmentCheckpoint: AssessmentCheckpoint;
  suggestingServices: SuggestingService[];
  advices: string[];
};

function disease(item: Item): DiseaseDetail {
  return new DiseaseDetail(
    item.disease,
    item.category,
    item.description,
    item.abstractDiseases,
    item.symptoms,
    item.misleadingDiseases,
    item.watch,
    item.assessmentCheckpoint,
    item.suggestingServices,
    item.advices,
  );
}
