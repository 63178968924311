import React, { ReactNode } from 'react';
import styles from './SubCategory.module.scss';

export function SubCategory(props: Props) {
  const { title, children } = props;
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div>{children}</div>
    </div>
  );
}

type Props = {
  title: string;
  children: ReactNode;
};
