import React, { ReactNode } from 'react';
import { SupportServiceTypeCategory } from '../../../../../../../domain/establishment/SupportServiceTypeCategory';
import styles from './Condition.module.scss';
import { CheckboxField } from '../../../../../components/field/CheckboxField';
import { SelectField } from '../../../../../components/field/SelectField';
import {
  availableSupportServiceType,
  CheckboxCondition,
  ConditionCode,
  ConditionLabels,
  EstablishmentSearchCondition,
  SupportServiceTypeLabels,
} from '../../../../../../../domain/establishment/SearchCondition';
import { FetchEstablishmentApi } from '../../../../../../../state/api/suggestion/establishment/EstablishmentApi';
import { FetchEstablishmentList } from '../../../../../../../state/api/suggestion/establishment/FetchEstablishmentList';
import { Establishment } from '../../../../../../../domain/establishment/Establishment';
import { TextField } from '../../../../../components/field/TextField';
import { CloseButton } from '../../../../../components/button/CloseButton';
import { SearchButton } from '../../../../../components/button/SearchButton';
import { ToggleField } from '../../../../../components/field/ToggleField';

export function Condition(props: Props) {
  const {
    category,
    projectId,
    condition,
    setCondition,
    setTsushokeiCount,
    setHomonkeiCount,
    setShortstayCount,
    setEstablishmentList,
    setOnSearching,
    keyword,
    setKeyword,
    filterFavorite,
    setFilterFavorite,
  } = props;

  const availableCheckbox: AvailableCheckbox = {
    通所系: [
      ConditionCode.生活保護の受入有,
      ConditionCode.PT,
      ConditionCode.OT,
      ConditionCode.ST,
      ConditionCode.個別機能訓練加算Ⅰ,
      ConditionCode.個別機能訓練加算Ⅱ,
      ConditionCode.機械浴,
      ConditionCode.個浴,
      ConditionCode.大浴槽,
      ConditionCode.リフト車あり,
      ConditionCode.宿泊あり,
    ],
    訪問系: [
      ConditionCode.生活保護の受入有,
      ConditionCode.緊急時訪問あり,
      ConditionCode.二十四時間対応,
      ConditionCode.ターミナルケア加算,
      ConditionCode.特定医療行為可,
      ConditionCode.通院等乗降介助,
      ConditionCode.短時間の訪問介助,
    ],
    短期入所系: [
      ConditionCode.ユニット型,
      ConditionCode.生活保護の受入有,
      ConditionCode.PT,
      ConditionCode.OT,
      ConditionCode.ST,
      ConditionCode.個別機能訓練加算Ⅰ,
      ConditionCode.個別機能訓練加算Ⅱ,
    ],
  };

  const holidayCheckbox: (
    | ConditionCode.土
    | ConditionCode.日
    | ConditionCode.祝
  )[] = [ConditionCode.土, ConditionCode.日, ConditionCode.祝];

  const rangeValues = [
    ['3', '3km圏内'],
    ['5', '5km圏内'],
    ['10', '10km圏内'],
    ['20', '20km圏内'],
    ['30', '30km圏内'],
  ] as [string, ReactNode][];

  const searchEstablishments = (projectId: string, queryString: string) => {
    setOnSearching(true);
    FetchEstablishmentApi.fetchEstablishmentList(projectId, queryString).then(
      (res) => {
        if (res instanceof FetchEstablishmentList) {
          setTsushokeiCount(res.tsushokeiCount);
          setHomonkeiCount(res.homonkeiCount);
          setShortstayCount(res.shortstayCount);
          setEstablishmentList(res.establishmentList);
        }
        setOnSearching(false);
      },
    );
  };

  const setConditionValue = (key: CheckboxCondition) => (checked: boolean) => {
    const updatedCondition = condition.updateCondition(key, checked);
    setCondition({
      ...updatedCondition,
    });
    searchEstablishments(projectId, condition.toQueryString());
  };

  const setSupportServiceType = (code: number) => (checked: boolean) => {
    const updatedCondition = condition.updateSupportServiceType(code, checked);
    setCondition({
      ...updatedCondition,
    });
    searchEstablishments(projectId, condition.toQueryString());
  };

  const setRange = (value: string) => {
    const updatedCondition = condition.updateRangeCondition(value);
    setCondition({
      ...updatedCondition,
    });
    searchEstablishments(projectId, condition.toQueryString());
  };

  const renderSupportServiceTypeConditions = () => {
    return availableSupportServiceType[category].map((code) => {
      return (
        <CheckboxField
          key={code}
          get={condition.condition[ConditionCode.サービス種別].has(code)}
          set={setSupportServiceType(code)}
        >
          {SupportServiceTypeLabels[code]}
        </CheckboxField>
      );
    });
  };

  const renderConditions = () => {
    return availableCheckbox[category].map((key: CheckboxCondition) => {
      const label = ConditionLabels[key];
      return (
        <CheckboxField
          key={label}
          get={condition.condition.checkboxes[key]}
          set={setConditionValue(key)}
        >
          {label}
        </CheckboxField>
      );
    });
  };

  const renderHolidayCheckbox = () => {
    return holidayCheckbox.map((holiday) => {
      const label = ConditionLabels[holiday];
      return (
        <CheckboxField
          key={label}
          get={condition.condition.checkboxes[holiday]}
          set={setConditionValue(holiday)}
        >
          {label}
        </CheckboxField>
      );
    });
  };

  const onClearKeyword = () => {
    setKeyword('');
  };

  const keywordTrailing = () => {
    return keyword ? (
      <>
        <div className={styles.closeIcon}>
          <CloseButton onClick={onClearKeyword} />
        </div>
        <SearchButton />
      </>
    ) : (
      <SearchButton />
    );
  };

  const favoriteOnClick = () => {
    setFilterFavorite(!filterFavorite);
  };

  return (
    <>
      <div className={styles.container}>
        <div>
          <div className={styles.title}>サービス種別</div>
          <div className={styles.checkboxArea}>
            {renderSupportServiceTypeConditions()}
          </div>
        </div>
        <div className={styles.conditionArea}>
          <div className={styles.title}>絞り込み</div>
          <div className={styles.checkboxArea}>{renderConditions()}</div>
        </div>
      </div>
      <div className={styles.commonConditionArea}>
        <div className={styles.commonGroup}>
          <div className={styles.rangeSelector}>
            <SelectField
              disabled={false}
              values={rangeValues}
              get={condition.condition[ConditionCode.範囲]}
              set={setRange}
            />
          </div>
          {renderHolidayCheckbox()}
          <div className={styles.keyword}>
            <TextField
              get={keyword}
              set={setKeyword}
              placeholder={'事業所名検索'}
              width={280}
              trailing={keywordTrailing()}
            />
          </div>
        </div>
        <div>
          <ToggleField get={filterFavorite} set={favoriteOnClick}>
            ブックマークした施設のみ表示
          </ToggleField>
        </div>
      </div>
    </>
  );
}

type Props = {
  category: SupportServiceTypeCategory;
  projectId: string;
  condition: EstablishmentSearchCondition;
  setCondition: (condition: EstablishmentSearchCondition) => void;
  setTsushokeiCount: (count: number) => void;
  setHomonkeiCount: (count: number) => void;
  setShortstayCount: (count: number) => void;
  setEstablishmentList: (establishments: Establishment[]) => void;
  setOnSearching: (value: boolean) => void;
  keyword: string;
  setKeyword: (value: string) => void;
  filterFavorite: boolean;
  setFilterFavorite: (value: boolean) => void;
};

type AvailableCheckbox = {
  [key in SupportServiceTypeCategory]: CheckboxCondition[];
};
