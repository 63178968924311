import React from 'react';
import styles from './Saving.module.scss';
import { Player } from '@lottiefiles/react-lottie-player';
import animationJson from './saving.json';

export function Saving() {
  return (
    <div className={styles.container}>
      <Player
        src={animationJson}
        background={'transparent'}
        style={{ width: '32px', height: '32px' }}
        speed={1.5}
        loop
        autoplay
      />
    </div>
  );
}
