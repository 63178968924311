import React, { MouseEvent, useEffect, useState } from 'react';
import styles from './SuggestionDialog.module.scss';
import { Loading } from '../../components/loading/Loading';
import { Link } from 'react-router-dom';
import { SuggestionSupportServiceApi } from '../../../../state/api/suggestion/supportservice/SuggestionSupportServiceApi';
import { FetchSuggestionSupportServices } from '../../../../state/api/suggestion/supportservice/FetchSuggestionSupportServices';

export function SuggestionSupportServiceDialog(props: Props) {
  const {
    open,
    projectId,
    needText,
    longTermGoalText,
    shortTermGoalText,
    onSelect,
  } = props;

  const [onFetching, setOnFetching] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [errorDetail, setErrorDetail] = useState<boolean>(false);

  const [suggestedSupportServices, setSuggestedSupportServices] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (!open) return;
    let mounted = true;
    SuggestionSupportServiceApi.fetchSuggestionSupportServices(
      projectId,
      needText || '',
      longTermGoalText || '',
      shortTermGoalText || '',
    ).then((res) => {
      if (!mounted) return;
      setOnFetching(false);
      setErrorCode('');
      if (res instanceof FetchSuggestionSupportServices) {
        setSuggestedSupportServices(res.supportServices);
      } else {
        setErrorCode(res.code);
      }
    });
    return () => {
      mounted = false;
    };
  }, [open, projectId, needText, longTermGoalText, shortTermGoalText]);

  // blurはmousedown時に発生するので、mousedownを抑止することによりTableTwoTextareaのblurが発生するのを防ぐ
  const onMouseDown = (event: MouseEvent<any>) => {
    event.preventDefault();
  };

  const onClick = (supportServiceText: string) => () => {
    onSelect?.(supportServiceText);
  };

  const renderSuggestionErrorDetail = () => {
    if (!errorDetail) {
      return (
        <Link
          className={styles.errorShow}
          to={'#'}
          onClick={() => setErrorDetail(true)}
        >
          理由を表示する
        </Link>
      );
    } else if (errorCode === 'MISSING_ASSESSMENT_SHEET_ITEMS') {
      return (
        <div className={styles.errorDetail}>
          ・アセスメントシート内の「要介護認定項目」の入力が不十分です。
        </div>
      );
    } else if (errorCode === 'INVALID_TEXT') {
      return (
        <div className={styles.errorDetail}>
          ・「ニーズ」「長期目標」「短期目標」のいずれか入力が不十分です（未入力、記号のみなど）。
        </div>
      );
    }
  };

  const renderSuggestionError = () => {
    return (
      <div>
        <div className={styles.error}>提案文言が表示できませんでした。</div>
        {renderSuggestionErrorDetail()}
      </div>
    );
  };

  const renderSuggestion = () => {
    if (onFetching) {
      return <Loading />;
    } else if (errorCode) {
      return renderSuggestionError();
    } else {
      return suggestedSupportServices.map((supportService, index) => {
        if (index < 5) {
          return (
            <label
              key={index}
              className={styles.suggestion}
              onClick={onClick(supportService)}
            >
              {supportService}
            </label>
          );
        }
        return;
      });
    }
  };

  if (!open) return null;

  return (
    <div className={styles.container} onMouseDown={onMouseDown}>
      <h2 className={styles.title}>文言提案</h2>
      {renderSuggestion()}
    </div>
  );
}

type Props = {
  open: boolean;
  projectId: string;
  needText: string | null;
  longTermGoalText: string | null;
  shortTermGoalText: string | null;
  onSelect?: (supportServiceText: string) => void;
};
