import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import { CancelButton } from '../../../components/button/CancelButton';
import { PrimaryButton } from '../../../components/button/PrimaryButton';
import styles from './DisplayNameForm.module.scss';
import { DisplayNameTextField } from './DisplayNameTextField';

type Props = {
  displayName: string;
  setDisplayName: Dispatch<SetStateAction<string>>;
  validate: () => boolean;
  updateFailed: boolean;
  onSubmit: (event: FormEvent) => void;
  onCancel: (event: FormEvent) => void;
};

export function DisplayNameChangeForm(props: Props) {
  const {
    displayName,
    setDisplayName,
    validate,
    updateFailed,
    onSubmit,
    onCancel,
  } = props;

  return (
    <form onSubmit={onSubmit}>
      <DisplayNameTextField get={displayName} set={setDisplayName} />
      {updateFailed && <p className={styles.error}>表示名変更に失敗しました</p>}
      <div className={styles.row} />
      <div className={styles.button}>
        <PrimaryButton width={256} disabled={!validate()}>
          変更
        </PrimaryButton>
      </div>
      <div className={styles.row} />
      <div className={styles.button}>
        <CancelButton width={256} onClick={onCancel}>
          キャンセル
        </CancelButton>
      </div>
    </form>
  );
}
