import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import styles from './ToggleField.module.scss';
import classNames from 'classnames';

export function ToggleField(props: ToggleFieldProps) {
  const { disabled, children, get, set, ...rest } = props;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    set?.(event.target.checked);
  };

  const fieldClassName = classNames(styles.field, {
    [styles.checked]: get,
    [styles.disabled]: disabled,
  });

  return (
    <label className={fieldClassName}>
      <span className={styles.label}>{children}</span>
      <input
        type={'checkbox'}
        className={styles.input}
        disabled={disabled}
        checked={get}
        onChange={onChange}
        {...rest}
      />
      <span className={styles.display} />
    </label>
  );
}

type Props = {
  get?: boolean;
  set?: (value: boolean) => void;
};

export type ToggleFieldProps = InputHTMLAttributes<any> & Props;
