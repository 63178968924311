// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React from 'react';
import styles from './Section.module.scss';
import { AssessmentSheetUpdater } from './AssessmentSheetUpdater';
import { AssessmentSheetField } from './AssessmentSheetField';
import {
  DekiruDekinai,
  DekiruTokidokiHotondoDekinai,
  NaiTokidokiAru,
  TsujiruTokidokiTsujinai,
} from './types';
import {
  dekiruDekinaiLabels,
  dekiruTokidokiHotondoDekinaiLabels,
  naiTokidokiAruLabels,
  tsujiruTokidokiTsujinaiLabels,
} from './labels';

export function NinchiKinoSection(props: Props) {
  const { assessmentSheetUpdater, activated } = props;
  const assessmentSheetField = new AssessmentSheetField(activated);

  return (
    <>
      <div className={styles.card}>
        <h3 className={styles.title}>要介護認定項目</h3>
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                3-1 意思の伝達
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruTokidokiHotondoDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKino.ishiNoDentatsu',
                dekiruTokidokiHotondoDekinaiLabels,
                { radioPattern: 'vertical' },
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                3-2 毎日の日課を理解する
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKino.mainichiNoNikkaORikaiSuru',
                dekiruDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                3-3 生年月日や年齢を答える
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKino.seinengappiYaNenreiOKotaeru',
                dekiruDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                3-4 面接調査の直前記憶
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKino.mensetsuChosaNoChokuzenKioku',
                dekiruDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                3-5 自分の名前を答える
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKino.jibunNoNamaeOKotaeru',
                dekiruDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                3-6 今の季節を理解する
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKino.imaNoKisetsuORikaiSuru',
                dekiruDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                3-7 自分のいる場所を答える
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<DekiruDekinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKino.jibunNoIruBashoOKotaeru',
                dekiruDekinaiLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                3-8 徘徊
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKino.haikai',
                naiTokidokiAruLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                3-9 外出すると戻れない（迷子）
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKino.gaishutsuSuruToModoreNai',
                naiTokidokiAruLabels,
              )}
            </div>
          );
        })}
        {assessmentSheetUpdater.group((assessmentSheetUpdater) => {
          return (
            <div className={styles.row}>
              <div className={styles.label}>
                3-10 介護者の発言への反応
                <div className={styles.saveIndicator}>
                  {assessmentSheetUpdater.saveIndicator}
                </div>
              </div>
              {assessmentSheetField.renderRadioField<TsujiruTokidokiTsujinai | null>(
                assessmentSheetUpdater,
                'honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKino.kaigoShaNoHatsugenHeNoHanno',
                tsujiruTokidokiTsujinaiLabels,
                { radioPattern: 'vertical' },
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

type Props = {
  assessmentSheetUpdater: AssessmentSheetUpdater;
  activated: boolean;
};
