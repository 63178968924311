import { GlobalHeader } from '../components/GlobalHeader';
import { GlobalContainer } from '../components/GlobalContainer';
import React, { useEffect, useState } from 'react';
import styles from './PasswordResetPage.module.scss';
import { PrimaryButton } from '../components/button/PrimaryButton';
import { Paths } from '../../../Paths';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { NotFoundPage } from '../misc/NotFoundPage';
import { MyselfApi } from '../../../state/api/myself/MyselfApi';
import {
  containsLowercase,
  containsNumber,
  containsUppercase,
  EnhancedNewPasswordField,
  isEightOrMoreCharacters,
} from '../components/EnhancedNewPasswordField';

enum ConfirmStatus {
  BEFORE_CONFIRMED,
  CONFIRMED,
}

export function PasswordResetPage() {
  const { token } = useParams<PathParams>();
  const [tokenValid, setTokenValid] = useState<boolean>(false);
  const [confirmingStatus, setConfirmingStatus] = useState<ConfirmStatus>(
    ConfirmStatus.BEFORE_CONFIRMED,
  );
  const [newPassword, setNewPassword] = useState<string>('');
  const [passwordResetError, setPasswordResetError] = useState<boolean>(false);
  const history = useHistory();
  const changePassword = () => {
    MyselfApi.resetNewPassword(
      newPassword,
      token,
      () => history.push(Paths.login),
      onFailureResetPassword,
    );
  };
  const onFailureResetPassword = (): void => {
    setPasswordResetError(true);
  };

  useEffect(() => {
    MyselfApi.confirmToken(token, onSuccessConfirm, onFailureConfirm);
  }, [token]);

  const onSuccessConfirm = () => {
    setTokenValid(true);
    setConfirmingStatus(ConfirmStatus.CONFIRMED);
  };
  const onFailureConfirm = () => {
    setTokenValid(false);
    setConfirmingStatus(ConfirmStatus.CONFIRMED);
  };

  const disable = (): boolean => {
    if (newPassword === '') return true;
    if (!containsNumber(newPassword)) return true;
    if (!containsUppercase(newPassword)) return true;
    if (!containsLowercase(newPassword)) return true;
    if (!isEightOrMoreCharacters(newPassword)) return true;

    return false;
  };

  if (confirmingStatus !== ConfirmStatus.CONFIRMED) return null;
  if (!tokenValid) return <NotFoundPage />;
  return (
    <GlobalContainer>
      <GlobalHeader />
      <div className={styles.card}>
        <div className={styles.contents}>
          <h2 className={styles.title}>新しいパスワード</h2>

          {passwordResetError && (
            <div className={styles.errorMessage}>
              パスワードのリセットに失敗しました。
            </div>
          )}
          <div className={styles.field}>
            <EnhancedNewPasswordField
              label="新しいパスワード"
              value={newPassword}
              setValue={setNewPassword}
            />
          </div>

          <div className={styles.button}>
            <PrimaryButton
              width={256}
              onClick={changePassword}
              disabled={disable()}
            >
              設定
            </PrimaryButton>
          </div>
        </div>
      </div>
    </GlobalContainer>
  );
}

type PathParams = {
  token: string;
};
