import React, { ReactElement, ReactNode } from 'react';
import Collapsible from 'react-collapsible';
import styles from './Accordion.module.scss';

export function Accordion(props: Props) {
  const { trigger, content } = props;

  return (
    <Collapsible
      trigger={trigger}
      transitionTime={100}
      className={styles.containerClose}
      openedClassName={styles.containerOpen}
    >
      {content}
    </Collapsible>
  );
}

type Props = {
  trigger: ReactElement;
  content: ReactNode;
};
