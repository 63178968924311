import 'whatwg-fetch';
import { ApiError } from '../../ApiError';
import { post } from '../../functions';
import { FetchSuggestionSupportServices } from './FetchSuggestionSupportServices';

export const SuggestionSupportServiceApi = new (class {
  async fetchSuggestionSupportServices(
    projectId: string,
    needText: string,
    longTermGoalText: string,
    shortTermGoalText: string,
  ): Promise<FetchSuggestionSupportServices | ApiError> {
    const body = {
      projectId,
      needText,
      longTermGoalText,
      shortTermGoalText,
    };
    const response = await post('/api/suggestion/supportServices', body);
    return (
      response.ok ? FetchSuggestionSupportServices.extract : ApiError.extract
    )(response);
  }
})();
