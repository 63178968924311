import React, { MouseEvent, useState, useEffect } from 'react';
import styles from './TableThreeWeeklyServiceDialog.module.scss';
import { PrimaryButton } from '../../components/button/PrimaryButton';
import { CloseIcon } from '../../components/icon/CloseIcon';
import { TextField } from '../../components/field/TextField';
import { CheckboxField } from '../../components/field/CheckboxField';
import { MultilineTextField } from '../../components/field/MultilineTextField';
import { Modal } from '../../components/Modal';
import { TableThreeEstablishmentPage } from './TableThreeEstablishmentPage';
import { CarePlanTableTwo } from '../../../../domain/careplan/tabletwo/CarePlanTableTwo';
import { CarePlanTableTwoApi } from '../../../../state/api/careplan/tabletwo/CarePlanTableTwoApi';
import { CarePlanTableTwoResponse } from '../../../../state/api/careplan/tabletwo/CarePlanTableTwoResponse';
import { WeeklyServiceApi } from '../../../../state/api/careplan/tablethree/weeklyservice/WeeklyServiceApi';
import { WeeklyService } from '../../../../domain/careplan/tablethree/weeklyservice/WeeklyService';
import { DangerButton } from '../../components/button/DangerButton';
import { useSelector } from 'react-redux';
import { State } from '../../../../state/store';
import { SelectField } from '../../components/field/SelectField';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export function TableThreeWeeklyServiceDialog(props: Props) {
  const {
    onRequestClose,
    projectId,
    weeklyServices,
    weeklyService,
    time,
    dayOfWeek,
    refresh,
  } = props;

  const { tableThree } = useSelector(
    (state: State) =>
      state.tableThree.slots[projectId] ?? {
        tableThree: null,
      },
  );

  const [invalidMessage, setInvalidMessage] = useState<string | null>(null);
  const [openEstablishmentPage, setOpenEstablishmentPage] = useState(false);
  const [carePlanTableTwo, setCarePlanTableTwo] = useState<CarePlanTableTwo>();
  const [tableTwoEstablishmentList, setTableTwoEstablishmentList] = useState<
    string[]
  >([]);
  const [hasDeleteError, setHasDeleteError] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [sunday, setSunday] = useState(
    weeklyService
      ? weeklyService.dayOfWeek === '日'
      : dayOfWeek
        ? dayOfWeek === '日'
        : false,
  );
  const [monday, setMonday] = useState(
    weeklyService
      ? weeklyService.dayOfWeek === '月'
      : dayOfWeek
        ? dayOfWeek === '月'
        : false,
  );
  const [thuesday, setThuesday] = useState(
    weeklyService
      ? weeklyService.dayOfWeek === '火'
      : dayOfWeek
        ? dayOfWeek === '火'
        : false,
  );
  const [wednesday, setWednesday] = useState(
    weeklyService
      ? weeklyService.dayOfWeek === '水'
      : dayOfWeek
        ? dayOfWeek === '水'
        : false,
  );
  const [thursday, setThursday] = useState(
    weeklyService
      ? weeklyService.dayOfWeek === '木'
      : dayOfWeek
        ? dayOfWeek === '木'
        : false,
  );
  const [friday, setFriday] = useState(
    weeklyService
      ? weeklyService.dayOfWeek === '金'
      : dayOfWeek
        ? dayOfWeek === '金'
        : false,
  );
  const [saturday, setSaturday] = useState(
    weeklyService
      ? weeklyService.dayOfWeek === '土'
      : dayOfWeek
        ? dayOfWeek === '土'
        : false,
  );
  const [startTimeHour, setStartTimeHour] = useState(
    weeklyService ? weeklyService.startTime.slice(0, 2) : time ? `${time}` : '',
  );
  const [startTimeMinutes, setStartTimeMinutes] = useState(
    weeklyService ? weeklyService.startTime.slice(-2) : time ? '00' : '',
  );
  const [endTimeHour, setEndTimeHour] = useState(
    weeklyService
      ? weeklyService.endTime.slice(0, 2)
      : time
        ? `${String(parseInt(time) + 1).padStart(2, '0')}`
        : '',
  );
  const [endTimeMinutes, setEndTimeMinutes] = useState(
    weeklyService ? weeklyService.endTime.slice(-2) : time ? '00' : '',
  );
  const [establishmentId, setEstablishmentId] = useState(
    weeklyService ? weeklyService.establishmentId : '',
  );
  const [establishmentName, setEstablishmentName] = useState(
    weeklyService ? weeklyService.establishmentName : '',
  );
  const [supportServiceTypeId, setSupportServiceTypeId] = useState(
    weeklyService ? weeklyService.supportServiceTypeId : null,
  );
  const [supportServiceType, setSupportServiceType] = useState(
    weeklyService ? weeklyService.supportServiceType : '',
  );
  const [kaigoServiceName, setKaigoServiceName] = useState(
    weeklyService ? weeklyService.kaigoServiceName : '',
  );
  const [remarks, setRemarks] = useState(
    weeklyService ? weeklyService.remarks : '',
  );

  useEffect(() => {
    let mounted = true;
    CarePlanTableTwoApi.get(projectId).then((res) => {
      if (res instanceof CarePlanTableTwoResponse && mounted) {
        setCarePlanTableTwo(res.carePlanTableTwo);
      }
    });
    return () => {
      mounted = false;
    };
  }, [projectId]);

  useEffect(() => {
    setTableTwoEstablishmentList(findValues(carePlanTableTwo, 'establishment'));
    // eslint-disable-next-line
  }, [carePlanTableTwo]);

  const findValues = (obj: any, key: string) => {
    let results: any[] = [];

    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        results = results.concat(findValues(item, key));
      });
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach((k) => {
        if (k === key) {
          results.push(obj[k]);
        } else {
          results = results.concat(findValues(obj[k], key));
        }
      });
    }
    return results;
  };

  const onClickContainer = (event: MouseEvent<any>) => {
    event.stopPropagation();
  };

  const onRequestEstablishmentPageOpen = () => {
    setOpenEstablishmentPage(true);
  };

  const onRequestEstablishmentPageClose = () => {
    setOpenEstablishmentPage(false);
  };

  const onClickDeleteButton = () => {
    if (weeklyService) {
      WeeklyServiceApi.delete(weeklyService.id)
        .then(() => {
          setHasDeleteError(false);
          refresh();
          onRequestClose();
        })
        .catch(() => {
          setHasDeleteError(true);
        });
    }
  };

  const onClickRegisterButton = () => {
    if (
      !tableThree ||
      (!sunday &&
        !monday &&
        !thuesday &&
        !wednesday &&
        !thursday &&
        !friday &&
        !saturday) ||
      !startTimeHour ||
      !startTimeMinutes ||
      !endTimeHour ||
      !endTimeMinutes ||
      !establishmentName ||
      !supportServiceType
    ) {
      return;
    }

    if (
      !(
        parseInt(startTimeHour) * 60 + parseInt(startTimeMinutes) <
        parseInt(endTimeHour) * 60 + parseInt(endTimeMinutes)
      )
    ) {
      setInvalidMessage(
        '「終了時間」は「開始時間」より後の時間を選択してください。',
      );
      return;
    }

    const dayOfWeeks: string[] = [];

    if (sunday) {
      dayOfWeeks.push('日');
    }
    if (monday) {
      dayOfWeeks.push('月');
    }
    if (thuesday) {
      dayOfWeeks.push('火');
    }
    if (wednesday) {
      dayOfWeeks.push('水');
    }
    if (thursday) {
      dayOfWeeks.push('木');
    }
    if (friday) {
      dayOfWeeks.push('金');
    }
    if (saturday) {
      dayOfWeeks.push('土');
    }

    // 同じ時間帯に6つ以上登録させない
    let errorFlag = false;
    dayOfWeeks.forEach((dayOfWeek) => {
      const existWeeklyServices = weeklyServices.filter((exitWeeklyService) => {
        return (
          exitWeeklyService.dayOfWeek === dayOfWeek &&
          exitWeeklyService.id !== weeklyService?.id
        );
      });
      const newScheduleStartTime = dayjs(
        `2000-01-01T${startTimeHour}:${startTimeMinutes}:00Z`,
      );
      const newderScheduleEndTime = dayjs(
        `2000-01-01T${endTimeHour}:${endTimeMinutes}:00Z`,
      );
      for (
        let i = newScheduleStartTime;
        i.isSameOrBefore(newderScheduleEndTime);
        i = i.add(15, 'm')
      ) {
        let duplicateCount = 0;
        existWeeklyServices.forEach((existWeeklyService) => {
          const weeklyServiceStartTime = dayjs(
            `2000-01-01T${existWeeklyService.startTime}:00Z`,
          );
          const weeklyServiceEndTime = dayjs(
            `2000-01-01T${existWeeklyService.endTime}:00Z`,
          );
          if (
            i.isSameOrAfter(weeklyServiceStartTime) &&
            i.isSameOrBefore(weeklyServiceEndTime)
          ) {
            duplicateCount += 1;
          }
        });
        if (duplicateCount >= 5) {
          setInvalidMessage('時間が６つ以上被っています。');
          errorFlag = true;
          return;
        }
      }
    });
    if (errorFlag) return;

    dayOfWeeks.forEach((dayOfWeek, index) => {
      if (weeklyService?.id && dayOfWeek === weeklyService?.dayOfWeek) {
        WeeklyServiceApi.update(
          weeklyService.id,
          tableThree.id,
          dayOfWeek,
          `${startTimeHour}${startTimeMinutes}`,
          `${endTimeHour}${endTimeMinutes}`,
          establishmentId,
          establishmentName,
          supportServiceTypeId,
          supportServiceType,
          kaigoServiceName,
          remarks,
        )
          .then(() => {
            setHasError(false);
            if (index === dayOfWeeks.length - 1) {
              refresh();
              onRequestClose();
            }
          })
          .catch(() => {
            setHasError(true);
          });
      } else {
        WeeklyServiceApi.register(
          tableThree.id,
          dayOfWeek,
          `${startTimeHour}${startTimeMinutes}`,
          `${endTimeHour}${endTimeMinutes}`,
          establishmentId,
          establishmentName,
          supportServiceTypeId,
          supportServiceType,
          kaigoServiceName,
          remarks,
        )
          .then(() => {
            setHasError(false);
            if (index === dayOfWeeks.length - 1) {
              refresh();
              onRequestClose();
            }
          })
          .catch(() => {
            setHasError(true);
          });
      }
    });
  };

  const disabled =
    (!sunday &&
      !monday &&
      !thuesday &&
      !wednesday &&
      !thursday &&
      !friday &&
      !saturday) ||
    !startTimeHour ||
    !startTimeMinutes ||
    !endTimeHour ||
    !endTimeMinutes ||
    !establishmentName ||
    !supportServiceType;

  return (
    <div className={styles.container} onClick={(e) => onClickContainer(e)}>
      <h3 className={styles.title}>期間設定</h3>
      <div className={styles.contents}>
        <div className={styles.dayOfWeekSelect}>
          <div className={styles.dayOfWeeks}>
            <div className={styles.dayOfWeek}>日</div>
            <CheckboxField get={sunday} set={setSunday} />
          </div>
          <div className={styles.dayOfWeeks}>
            <div className={styles.dayOfWeek}>月</div>
            <CheckboxField get={monday} set={setMonday} />
          </div>
          <div className={styles.dayOfWeeks}>
            <div className={styles.dayOfWeek}>火</div>
            <CheckboxField get={thuesday} set={setThuesday} />
          </div>
          <div className={styles.dayOfWeeks}>
            <div className={styles.dayOfWeek}>水</div>
            <CheckboxField get={wednesday} set={setWednesday} />
          </div>
          <div className={styles.dayOfWeeks}>
            <div className={styles.dayOfWeek}>木</div>
            <CheckboxField get={thursday} set={setThursday} />
          </div>
          <div className={styles.dayOfWeeks}>
            <div className={styles.dayOfWeek}>金</div>
            <CheckboxField get={friday} set={setFriday} />
          </div>
          <div className={styles.dayOfWeeks}>
            <div className={styles.dayOfWeek}>土</div>
            <CheckboxField get={saturday} set={setSaturday} />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <h4 className={styles.dateTitle}>開始時間</h4>
          <div className={styles.fields}>
            <div className={styles.field}>
              <SelectField
                values={[...Array(25)].map((_, index) => {
                  return [
                    `${index.toString().padStart(2, '0')}`,
                    `${index.toString().padStart(2, '0')}`,
                  ];
                })}
                get={startTimeHour}
                set={setStartTimeHour}
                width={80}
              />
              ：
              <SelectField
                values={['00', '15', '30', '45'].map((value) => {
                  return [value, value];
                })}
                get={startTimeMinutes}
                set={setStartTimeMinutes}
                width={80}
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <h4 className={styles.dateTitle}>終了時間</h4>
          <div className={styles.fields}>
            <div className={styles.field}>
              <SelectField
                values={[...Array(26)].map((_, index) => {
                  return [
                    `${index.toString().padStart(2, '0')}`,
                    `${index.toString().padStart(2, '0')}`,
                  ];
                })}
                get={endTimeHour}
                set={setEndTimeHour}
                width={80}
              />
              ：
              {endTimeHour === '25' ? (
                <SelectField
                  values={['00'].map((value) => {
                    return [value, value];
                  })}
                  get={endTimeMinutes}
                  set={setEndTimeMinutes}
                  width={80}
                />
              ) : (
                <SelectField
                  values={['00', '15', '30', '45'].map((value) => {
                    return [value, value];
                  })}
                  get={endTimeMinutes}
                  set={setEndTimeMinutes}
                  width={80}
                />
              )}
            </div>
          </div>
          <div className={styles.fields}></div>
        </div>
      </div>
      <h3 className={styles.title}>サービス内容</h3>
      <div className={styles.contents}>
        <div>
          <h4 className={styles.dateTitle}>*サービス種別</h4>
          <TextField
            width={300}
            get={supportServiceType}
            set={setSupportServiceType}
          />
          <span className={styles.search}>
            <PrimaryButton onClick={() => onRequestEstablishmentPageOpen()}>
              検索
            </PrimaryButton>
          </span>
        </div>
        <div className={styles.fieldContainer}>
          <h4 className={styles.dateTitle}>*事業所名</h4>
          <TextField
            width={300}
            get={establishmentName}
            set={setEstablishmentName}
          />
          <span className={styles.search}>
            <PrimaryButton onClick={() => onRequestEstablishmentPageOpen()}>
              検索
            </PrimaryButton>
          </span>
        </div>
        <Modal
          open={openEstablishmentPage}
          onRequestClose={onRequestEstablishmentPageClose}
        >
          <TableThreeEstablishmentPage
            supportServiceText={kaigoServiceName}
            projectId={projectId}
            onRequestClose={onRequestEstablishmentPageClose}
            tableTwoEstablishmentList={tableTwoEstablishmentList}
            setSupportServiceType={setSupportServiceType}
            setSupportServiceTypeId={setSupportServiceTypeId}
            setEstablishmentName={setEstablishmentName}
            setEstablishmentId={setEstablishmentId}
          />
        </Modal>
        <div className={styles.fieldContainer}>
          <h4 className={styles.dateTitle}>サービス内容</h4>
          <TextField
            width={300}
            get={kaigoServiceName}
            set={setKaigoServiceName}
          />
        </div>
      </div>
      <h3 className={styles.title}>その他</h3>
      <div className={styles.contents}>
        <div className={styles.textArea}>
          <h4 className={styles.dateTitle}>備考</h4>
          <span className={styles.focus}>
            <MultilineTextField get={remarks} set={setRemarks} />
          </span>
        </div>
      </div>
      <div>
        {hasError && (
          <div className={styles.errorMessage}>登録に失敗しました。</div>
        )}
        {hasDeleteError && (
          <div className={styles.errorMessage}>削除に失敗しました。</div>
        )}
        {invalidMessage && (
          <div className={styles.invalidMessage}>{invalidMessage}</div>
        )}
        <div className={styles.buttonContainer}>
          {weeklyService?.id ? (
            <>
              <DangerButton width={120} onClick={onClickDeleteButton}>
                削除
              </DangerButton>
              <PrimaryButton
                width={120}
                onClick={onClickRegisterButton}
                disabled={disabled}
              >
                更新
              </PrimaryButton>
            </>
          ) : (
            <PrimaryButton
              width={256}
              onClick={onClickRegisterButton}
              disabled={disabled}
            >
              登録
            </PrimaryButton>
          )}
        </div>
      </div>

      <button className={styles.close} onClick={onRequestClose}>
        <CloseIcon />
      </button>
    </div>
  );
}

type Props = {
  onRequestClose: () => void;
  projectId: string;
  weeklyServices: WeeklyService[];
  weeklyService?: WeeklyService;
  time?: string;
  dayOfWeek?: string;
  refresh: () => void;
};
