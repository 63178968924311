import React from 'react';
import styles from './LoginLogo.module.scss';

export function LoginLogo() {
  return (
    <h2 className={styles.container}>
      <img
        src={'/images/milmoplan_logo.svg'}
        width={200}
        height={25.3}
        alt={'ミルモぷらん'}
      />
    </h2>
  );
}
