import React, { MouseEvent } from 'react';
import { CancelButton } from '../../components/button/CancelButton';
import { DangerButton } from '../../components/button/DangerButton';
import { CloseIcon } from '../../components/icon/CloseIcon';
import styles from './CareRecipientDeletionDialog.module.scss';

type Props = {
  requestCareRecipientDeletionApi: () => void;
  closeDialog: () => void;
};

export function CareRecipientDeletionDialog(props: Props) {
  const { requestCareRecipientDeletionApi, closeDialog } = props;

  const onClickContainer = (event: MouseEvent<any>) => event.stopPropagation();

  return (
    <div className={styles.dialog} onClick={onClickContainer}>
      <button className={styles.closeIcon} onClick={closeDialog}>
        <CloseIcon />
      </button>
      <h2 className={styles.title}>利用者情報を削除する</h2>
      <div className={styles.row} />
      <p className={styles.description}>
        選択した利用者を削除します。この操作は取り消せません。キャンセルを押すと削除を中止できます。
      </p>
      <div className={styles.row2} />
      <div className={styles.action}>
        <DangerButton width={256} onClick={requestCareRecipientDeletionApi}>
          削除する
        </DangerButton>
        <div className={styles.row} />
        <CancelButton width={256} onClick={closeDialog}>
          キャンセル
        </CancelButton>
      </div>
    </div>
  );
}
