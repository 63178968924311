import React from 'react';

export function LeftArrowIcon() {
  return (
    <svg
      width="60"
      height="72"
      viewBox="0 0 60 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <rect
          x="4"
          y="16"
          width="32"
          height="32"
          rx="16"
          fill="#000111"
          fillOpacity="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.8875 36.7362C22.7007 37.027 22.3468 37.0865 22.097 36.8691L17.2963 32.6909L17.2909 32.6862C17.2004 32.6054 17.1271 32.5016 17.0767 32.3826C17.0262 32.2636 17 32.1327 17 32C17 31.8673 17.0262 31.7364 17.0767 31.6174C17.1271 31.4984 17.2004 31.3946 17.2909 31.3138L17.2963 31.3091L22.097 27.1309C22.3468 26.9135 22.7007 26.973 22.8875 27.2638C23.0743 27.5546 23.0232 27.9666 22.7734 28.1841L18.3888 32L22.7734 35.8159C23.0232 36.0334 23.0743 36.4454 22.8875 36.7362Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="-12"
          y="0"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.239216 0 0 0 0 0.239216 0 0 0 0 0.247059 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
