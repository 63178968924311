import React from 'react';

export function Loading() {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <img
        src={'/images/loading.gif'}
        width={30}
        height={30}
        alt={'読込中...'}
      />
    </div>
  );
}
